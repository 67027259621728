import './footer.scss'

const socialLinks = [
  {
    link: 'https://discord.gg/stabllabs',
    image: '/images/retro/footer/DiscordLogo.svg',
  },
  // {
  //   link: '#',
  //   image: '/images/retro/footer/YoutubeLogo.svg',
  // },
  {
    link: 'https://twitter.com/Retro_finance',
    image: '/images/retro/footer/TwitterLogo.png',
  },
  // {
  //   link: '#',
  //   image: '/images/retro/footer/InstagramLogo.svg',
  // },
]

const connectLinks = [
  {
    name: 'Discord',
    link: 'https://discord.gg/stabllabs',
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/Retro_finance',
  },
]
const toolsLinks = [
  // {
  //   name: 'Analytics',
  //   link: '#',
  // },
  // {
  //   name: 'Github',
  //   link: '#',
  // },
  // {
  //   name: 'Coingecko',
  //   link: '#',
  // },
  {
    name: 'DEX Screener',
    link: 'https://dexscreener.com/polygon/0xb47a07966ce6812702c0567d03725f1b37e27877',
  },
  // {
  //   name: 'DeFi Liama',
  //   link: '#',
  // },
]
const resourcesLinks = [
  {
    name: 'Medium',
    link: 'https://medium.com/@stabl.labs',
  },
  {
    name: 'Docs',
    link: 'https://docs.retro.finance/protocol-overview/welcome-to-retro-finance',
  },
  {
    name: 'Media Kit',
    link: 'https://docs.retro.finance/resources/brand-assets',
  },
  {
    name: 'Audit',
    link: 'https://blog.openzeppelin.com/retro-thena-audit',
  },
]
const helpLinks = [
  {
    name: 'Apply for Token Listing',
    link: 'https://discord.gg/stabllabs',
  },
  {
    name: 'Apply for Gauge',
    link: 'https://discord.gg/stabllabs',
  },
  {
    name: 'Bug Bounty',
    link: 'https://discord.gg/stabllabs',
  },
]

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='socials-container'>
        <img src='/images/retro/footer/retro_logo.svg' alt='logo' className='logo' />
        <h2 className='title'>Join Community</h2>
        <div className='social-links-container'>
          {socialLinks.map((link, idx) => {
            return (
              <a key={idx} href={link.link} target='_blank' rel='noreferrer' className='social-link'>
                <img src={link.image} alt='Discord' className='social-image' />
              </a>
            )
          })}
        </div>
        <p className='copyright'>Copyright © 2023 Retro. All rights reserved.</p>
      </div>
      <div className='containers'>
        <div className='links-container container-type-connect'>
          <h2 className='title'>Connect</h2>
          {connectLinks.map((link, idx) => {
            return (
              <a key={idx} href={link.link} target='_blank' rel='noreferrer' className='link'>
                {link.name}
              </a>
            )
          })}
        </div>
        <div className='links-container container-type-tools'>
          <h2 className='title'>Tools</h2>
          {toolsLinks.map((link, idx) => {
            return (
              <a key={idx} href={link.link} target='_blank' rel='noreferrer' className='link'>
                {link.name}
              </a>
            )
          })}
        </div>
        <div className='links-container container-type-resources'>
          <h2 className='title'>Resources</h2>
          {resourcesLinks.map((link, idx) => {
            return (
              <a key={idx} href={link.link} target='_blank' rel='noreferrer' className='link'>
                {link.name}
              </a>
            )
          })}
        </div>
        <div className='links-container container-type-help'>
          <h2 className='title'>Help</h2>
          {helpLinks.map((link, idx) => {
            return (
              <a key={idx} href={link.link} target='_blank' rel='noreferrer' className='link'>
                {link.name}
              </a>
            )
          })}
        </div>
      </div>
    </footer>
  )
}

export default Footer
