import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { v4 as uuidv4 } from 'uuid'
import { TransactionType } from '../../config/constants'
import { useV3MintActionHandlers } from '../../state/mintV3/hooks'
import useWeb3 from '../useWeb3'
import { completeTransaction, openTransaction, updateTransaction } from '../../state/transactions/actions'
import { getERC20Contract, getIchiVaultContract } from '../../utils/contractHelpers'
import { getAllowance, sendContract } from '../../utils/api'
import { MAX_UINT256, fromWei } from '../../utils/formatNumber'

export const useAddIchiLiquidity = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers()

  const onIchiAdd = useCallback(
    async (amountA, amountB, asset) => {
      if (!amountA && !amountB) return

      const amount = amountA ?? amountB
      const currency = amount.currency

      const currencyAddress = currency.wrapped ? currency.wrapped.address.toLowerCase() : ''
      const ichiVault = asset ? asset.vault : undefined
      const ichiVaultContract = getIchiVaultContract(web3, ichiVault.vaultAddress)

      if (!amount || !asset || !ichiVault) return

      const [token0Amount, token1Amount] =
        ichiVault.depositTokenSymbol === ichiVault.token0
          ? [amount.numerator.toString(), '0']
          : ['0', amount.numerator.toString()]

      const key = uuidv4()
      const approve0uuid = uuidv4()
      const supplyuuid = uuidv4()

      setPending(true)

      dispatch(
        openTransaction({
          key,
          title: `Add ${currency.symbol} liquidity`,
          transactions: {
            [approve0uuid]: {
              desc: `Approve ${currency.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [supplyuuid]: {
              desc: `Deposit tokens in the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true

      const baseTokenContract = getERC20Contract(web3, currencyAddress)
      const baseAllowance = await getAllowance(baseTokenContract, ichiVault.vaultAddress, account)

      if (fromWei(baseAllowance, currency.decimals).lt(amount.toExact())) {
        isApproved = false

        try {
          await sendContract(
            dispatch,
            key,
            approve0uuid,
            baseTokenContract,
            'approve',
            [ichiVault.vaultAddress, MAX_UINT256],
            account,
          )
        } catch (err) {
          console.log('approve 0 error :>> ', err)
          setPending(false)

          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approve0uuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      try {
        const params = [token0Amount, token1Amount, account]

        console.log(params)

        await sendContract(dispatch, key, supplyuuid, ichiVaultContract, 'deposit', params, account)
      } catch (err) {
        console.log('supply error :>> ', err)
        setPending(false)
        return
      }

      onFieldAInput('')
      onFieldBInput('')

      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Added',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { onIchiAdd, pending }
}
