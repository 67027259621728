import React, { useState, useMemo, useEffect, useContext } from 'react'
import TransparentButton from '../../common/Buttons/transparentButton'
import { Tooltip } from 'react-tooltip'
import Pagination from '../../common/Pagination'
import { useWeb3React } from '@web3-react/core'
import Sticky from 'react-stickynode'
import useWalletModal from '../../../hooks/useWalletModal'
import { formatAmount, getLP0Symbol, getLP1Symbol, ZERO_ADDRESS } from '../../../utils/formatNumber'
import { useNavigate } from 'react-router-dom'
// import { useHarvest } from '../../../hooks/useGauge'
import DepositModal from './depositModal'
import { NumberOfRows, defaultChainId } from '../../../config/constants'
import NoFound from '../../common/NoFound'
import MigrateModal from './migrateModal'
import { FusionsContext } from '../../../context/FusionsContext'
import { useGetMerklDataQuery } from 'hooks/queries/useGetMerklDataQuery'

const ItemWithTooltip = ({ usd, content, account, idx, type }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return account || type === 'tvl' ? (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-tooltip-id={`${type}-${idx}`}
        onMouseEnter={() => {
          setArrowReverse(`${type}-${idx}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] flex items-center cursor-pointer space-x-1.5'
      >
        <p>{'$' + formatAmount(usd, true)}</p>
        <button
          className={`${
            arrowReverse === `${type}-${idx}` ? 'rotate-180' : 'rotate-0'
          } transform transition-all duration-300 ease-in-out`}
        >
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <Tooltip
        className='max-w-[180px] !bg-tooltip !text-white !text-base z-50 !py-2 !px-6 !opacity-100'
        id={`${type}-${idx}`}
        place='right'
        effect='solid'
        arrowColor='#232F3C'
      >
        {content}
      </Tooltip>
    </div>
  ) : (
    <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>-</div>
  )
}

const TableRow = ({ item, idx, isMigration }) => {
  const { isLoading: isMerklDataLoading } = useGetMerklDataQuery()

  const [isOpen, setIsOpen] = useState(!item.account.gaugeBalance.isZero())
  const { account, chainId } = useWeb3React()
  const { openWalletModal } = useWalletModal()
  const navigate = useNavigate()
  // const { onHarvest, pending } = useHarvest()
  const [manage, setManage] = useState(false)
  const [migrate, setMigrate] = useState(false)
  const fusions = useContext(FusionsContext)
  const gammas = useMemo(() => {
    return fusions
      .filter((ele) => ele.gauge.address !== ZERO_ADDRESS && ele.isValid)
      .filter(
        (ele) =>
          (ele.token0.address === item.token0.address && ele.token1.address === item.token1.address) ||
          (ele.token0.address === item.token1.address && ele.token1.address === item.token0.address),
      )
  }, [fusions])

  const renderEarned = () => {
    return (
      <div className='flex flex-col gap-2'>
        {Object.values(item.account.gaugeEarned).map((value) => {
          return (
            <div key={value.symbol}>
              {formatAmount(value.unclaimed)} {value.symbol}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div
      key={idx}
      className={`mb-px rounded-lg flex flex-wrap lg:flex-nowrap items-start lg:items-center w-full justify-between  text-[#DEDBF2] p-4 lg:py-5 px-4 xl:px-6 bg-componentPrimary ${
        item.account?.totalUsd?.gt(0) ? 'border-2 border-[#2166fa]' : ''
      }`}
    >
      <div className='w-full  lg:w-[20%]'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-3 mr:0 lg:mr-3'>
            <div className='flex items-center -space-x-2'>
              <img className='relative z-10' alt='' src={item?.token0?.logoURI} width={30} height={30} />
              <img className='relative z-[5]' alt='' src={item?.token1?.logoURI} width={30} height={30} />
            </div>
            <div>
              <div className='flex flex-wrap text-base xl:text-[12px] leading-[30px] font-medium'>
                <span>{getLP0Symbol(item)}</span>/
                <span>
                  {getLP1Symbol(item)} {`(${item.fee / 10000}%)`}
                </span>
              </div>
              {/* <p className='tracking-[0.78px] text-[10px] leading-none'>{item.alm}</p> */}
            </div>
          </div>
          <button
            className='lg:hidden'
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <img alt='' className={`${isOpen ? 'rotate-180' : ''} transform`} src='/images/swap/dropdown-arrow.png' />
          </button>
        </div>
      </div>
      <div className='flex flex-col mt-5 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center'>
        <p className='lg:hidden text-sm font-semibold z-20'>Avg. APR</p>
        <div
          data-tip
          data-tooltip-id={`apr-toolip-${idx}`}
          className='flex flex-col items-start cursor-pointer justify-center text-white px-3 py-1 bg-[#2166fa] rounded-full text-sm'
        >
          {isMerklDataLoading && item.gauge.apr.toNumber() === 0 ? '-' : `${formatAmount(item.gauge.apr, true)}%`}
        </div>
        {Object.keys(item.fullMerklData?.aprs ?? {}).length > 0 && (
          <Tooltip
            className='max-w-[470px] !bg-tooltip !text-base z-50 !opacity-100 after:!bg-tooltip py-[10px]'
            id={`apr-toolip-${idx}`}
            place='right'
            effect='solid'
          >
            <div className='w-full text-dimGray z-50'>
              <h3 className='mb-2'>APR Details:</h3>
              <div className='text-white text-[20px]'>Average: {formatAmount(item.gauge.apr, true)}%</div>
              <div className='mt-2 text-[14px]'>Rewards are boosted up to x2.5 based on your veRETRO balance</div>
            </div>
            <div className='w-full border-b px-[25px] mt-3' />
            <ul className='list-disc px-[25px] text-dimGray mt-3'>
              {Object.entries(item.fullMerklData?.aprs ?? {}).map(([key, value]) => {
                return (
                  key !== 'avg' &&
                  key !== 'min' &&
                  key !== 'max' && (
                    <li className='mt-2' key={key}>
                      <span className='text-white'>{formatAmount(value)}%</span> - {key}
                    </li>
                  )
                )
              })}
            </ul>
          </Tooltip>
        )}
      </div>
      <div className='flex flex-col w-1/2 mt-5 lg:mt-0 items-start lg:w-[11%] justify-center'>
        <p className='lg:hidden text-sm font-semibold z-20'>Total Deposited</p>
        <p>{'$' + formatAmount(item.totalTvl.toString(), true)}</p>
        {/* <ItemWithTooltip
          account={account}
          type={'tvl'}
          usd={item.totalTvl.toString()}
          content={
            <>
              {formatAmount(item.gauge.pooled0)} {item.token0.symbol}
              <br />
              {formatAmount(item.gauge.pooled1)} {item.token1.symbol}
            </>
          }
          idx={idx}
        /> */}
      </div>
      {/* second row */}
      <div
        className={`${
          isOpen ? 'flex' : 'hidden'
        } lg:flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[11%] justify-center`}
      >
        <p className='lg:hidden text-sm font-semibold z-10'>My Pool</p>
        {isMerklDataLoading ? (
          '-'
        ) : (
          <ItemWithTooltip
            account={account}
            type={'pool'}
            usd={item.account.totalUsd ?? 0}
            content={
              <>
                {formatAmount(item.account.total0)} {item?.token0?.symbol}
                <br />
                {formatAmount(item.account.total1)} {item?.token1?.symbol}
              </>
            }
            idx={idx}
          />
        )}
      </div>
      {/* <div
        className={`${
          isOpen ? 'flex' : 'hidden'
        } lg:flex flex-col items-start w-1/2 mt-3 lg:mt-0 lg:w-[11%] justify-center`}
      >
        <p className='lg:hidden text-sm font-semibold'>My Stake</p>
        <ItemWithTooltip
          account={account}
          type={'stake'}
          usd={item.account.stakedUsd}
          content={
            <>
              {formatAmount(item.account.staked0)} {item.token0.symbol}
              <br />
              {formatAmount(item.account.staked1)} {item.token1.symbol}
            </>
          }
          idx={idx}
        />
      </div> */}
      <div className={`${isOpen ? 'block' : 'hidden'} lg:block  w-1/2 lg:w-[8%] mt-2 lg:mt-0`}>
        <p className='lg:hidden text-sm font-semibold'>Earnings</p>
        {isMerklDataLoading ? (
          '-'
        ) : (
          <ItemWithTooltip
            account={account}
            type={'earning'}
            usd={item.account.earnedUsd}
            content={renderEarned()}
            idx={idx}
          />
        )}
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} lg:block w-full lg:w-[25%] mt-3.5 lg:mt-0`}>
        {account && chainId === defaultChainId ? (
          !isMigration ? (
            <div className='space-x-5 xl:space-x-6 w-full flex items-center lg:justify-end'>
              {/* <div>
              {item.account.lpBalance.gt(0) && (
                <div className="flex flex-row items-center px-3 py-1 gap-x-1 rounded-full border bg-black text-white">
                  <div className='w-2 h-2 rounded-full bg-green'/>
                  <span className="text-sm w-full">
                  Rewards
                  </span>
                </div>
              )}
            </div> */}
              <TransparentButton
                content={'Manage'}
                onClickHandler={() => {
                  navigate(
                    `/liquidity/managev3?currency0=${item.token0.address}&currency1=${item.token1.address}&strategy=${item.title}`,
                  )
                }}
                fontWeight={'font-medium'}
                className='h-10 px-[26px] text-white !bg-transparent border-2 border-white border-solid rounded-3xl flex  max-w-[96px] whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[17px] w-full'
              />
              {/* <button
                disabled={item.account.gaugeEarned.isZero() || pending}
                className={`${
                  item.account.gaugeEarned.isZero() || pending
                    ? 'cursor-not-allowed text-dimGray'
                    : 'text-grayDark hover:text-white transition-colors'
                } text-base font-semibold `}
                onClick={() => {
                  onHarvest(item)
                }}
              >
                Claim
              </button> */}
            </div>
          ) : (
            gammas.length > 0 && (
              <div className='space-x-5 xl:space-x-2 w-full flex items-center lg:justify-end'>
                <TransparentButton
                  content={'Migrate'}
                  onClickHandler={() => {
                    setMigrate(true)
                  }}
                  fontWeight={'font-medium'}
                  className='h-10 px-[26px] text-black flex whitespace-nowrap flex-col items-center justify-center text-sm text-[17px] w-1/2'
                />
              </div>
            )
          )
        ) : (
          <div className='w-full  flex  items-center lg:justify-end'>
            <TransparentButton
              onClickHandler={openWalletModal}
              content={'Connect Wallet'}
              className='h-10 px-[26px] !bg-transparent border-2 text-white border-white border-solid rounded-3xl flex lg:max-w-[173px] whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[17px] w-full '
            />
          </div>
        )}
      </div>
      {manage && <DepositModal isOpen={manage} setIsOpen={setManage} pair={item} />}
      {migrate && <MigrateModal isOpen={migrate} setIsOpen={setMigrate} pair={item} gammas={gammas} />}
    </div>
  )
}

const TablePairs = ({
  pairsData,
  sort,
  setSort,
  sortOptions,
  /* active, */ filter,
  searchText,
  isStaked,
  isMigration = false,
}) => {
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)

  const pageCount = useMemo(() => {
    return Math.ceil(pairsData.length / pageSize)
  }, [pairsData, pageSize])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }

  // useEffect(() => {
  //   setCurrentPage(0)
  // }, [pageSize, active, filter])

  useEffect(() => {
    setCurrentPage(0)
  }, [pageSize, filter, searchText, isStaked])

  return (
    <>
      {/* for desktop */}
      <div className='w-full mt-4 xl:mt-11'>
        <div className='w-full'>
          {pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length > 0 ? (
            <>
              <Sticky
                enabled={true}
                innerActiveClass={'bg-componentPrimary border-2 border-white'}
                top={95}
                bottomBoundary={1200}
                activeClass={''}
                innerClass={
                  'px-6 lg:flex rounded-md justify-between hidden py-[0.475rem] lg:!-mb-[19px] xl:!mb-0 lg:!top-[-19px] xl:!top-[0]'
                }
                className={`z-10`}
              >
                <div className='w-[22%] font-medium text-[17px] xl:text-[18px] text-white'></div>
                {sortOptions.map((option, index) => (
                  <div
                    className={`${
                      index === 4 ? 'w-[8%]' : index === 0 ? 'w-[14%]' : 'w-[11%]'
                    } font-medium text-[17px] xl:text-[18px] text-white`}
                    key={`header-${index}`}
                  >
                    <div
                      onClick={() => {
                        setSort({
                          ...option,
                          isDesc: sort.value === option.value ? !sort.isDesc : true,
                        })
                      }}
                      className='flex items-center cursor-pointer space-x-1 -ml-1 relative '
                    >
                      {sort.value === option.value && (
                        <button className={`${sort.isDesc ? '' : 'rotate-180'} transform absolute -left-3.5`}>
                          <img alt='' src='/images/liquidity/arrow-bottom.svg' />
                        </button>
                      )}
                      <p className='flex items-center'>{option.label}</p>
                    </div>
                  </div>
                ))}
                <div className='w-[25%] font-medium text-[17px] xl:text-[18px] text-white'></div>
              </Sticky>
              <div className='flex flex-col gap-y-5'>
                {pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, idx) => {
                  return (
                    <TableRow
                      isLast={idx === pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length - 1}
                      idx={idx}
                      item={item}
                      key={`row-${idx}`}
                      isMigration={isMigration}
                    />
                  )
                })}
              </div>
              <Pagination
                pageSize={pageSize}
                setPageSize={setPageSize}
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
                total={pairsData.length}
              />
            </>
          ) : (
            <NoFound title='No pools found' />
          )}
        </div>
      </div>
    </>
  )
}

export default TablePairs
