import React, { useState } from 'react'
import CommonHollowModal from '../../components/common/CommonHollowModal'
import Tab from '../../components/common/Tab'
import ManageTab from '../../components/pages/lock/manage'
import MergeTab from '../../components/pages/lock/merge'
import SplitTab from '../../components/pages/lock/split'
import TransferTab from '../../components/pages/lock/transfer'

const data = ['Manage', 'Merge', 'Split', 'Transfer']

const ManageModal = ({ isOpen, setIsOpen, selected, theAsset }) => {
  const [tab, setTab] = useState(0)

  return (
    <CommonHollowModal popup={isOpen} width={588} setPopup={setIsOpen} title={`Manage veRETRO #${selected.id}`}>
      <div className='w-full p-[2px] rounded-full bg-white mt-4 md:mt-[29px]'>
        <Tab
          tabClassName={'text-xs md:text-base py-2.5 md:py-4 px-0 !rounded-full flex-1 -mx-2.5 first:ml-0 last:mr-0 text-base bg-white text-componentPrimary'}
          activeTabClassName={'!bg-componentPrimary !text-white'}
          className={'!gap-0'}
          multi={true}
          tab={tab}
          setTab={setTab}
          tabData={data}
        />
      </div>
      {tab === 0 && <ManageTab selected={selected} theAsset={theAsset} />}
      {tab === 1 && <MergeTab selected={selected} />}
      {tab === 2 && <SplitTab selected={selected} />}
      {tab === 3 && <TransferTab selected={selected} />}
    </CommonHollowModal>
  )
}

export default ManageModal
