import React, { useState, useMemo } from 'react'
// import { useNavigate } from 'react-router-dom'
import TransparentButton from '../../common/Buttons/transparentButton'
import StyledButton from '../../common/Buttons/styledButton'
import CommonHollowModal from '../../common/CommonHollowModal'
// import Tab from '../../common/Tab'
import BalanceInput from '../../common/Input/BalanceInput'
// import { useStake, useUnstake } from '../../../hooks/useGauge'
import { getLPSymbol, isInvalidAmount } from '../../../utils/formatNumber'
import { customNotify } from '../../../utils/notify'
// import ArrowRightCircle from '../../../icons/ArrowRightCircle'
import { useGammaRemove } from 'hooks/v3/useGamma'
import BigNumber from 'bignumber.js'

const DepositModal = ({ isOpen, setIsOpen, pair }) => {
  // const [isStake, setIsStake] = useState(true)
  // const [stakeAmount, setStakeAmount] = useState('')
  // const { onStake, pending: stakePending } = useStake()
  const [withdrawAmount, setWithdrawAmount] = useState('')
  // const { onUnstake, pending: unstakePending } = useUnstake()
  // const navigate = useNavigate()

  const { pending, onGammaRemove } = useGammaRemove()

  // const stakeErrorMsg = useMemo(() => {
  //   if (pair) {
  //     if (isInvalidAmount(stakeAmount)) {
  //       return 'Invalid Amount'
  //     }
  //     if (pair.account.lpBalance.lt(stakeAmount)) {
  //       return 'Insufficient ' + pair.symbol + ' Balance'
  //     }
  //   }
  //   return null
  // }, [stakeAmount, pair])

  const unstakeErrorMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(withdrawAmount)) {
        return 'Invalid Amount'
      }
      if (pair.account.lpBalance.lt(withdrawAmount)) {
        return 'Insufficient ' + pair.symbol + ' Balance'
      }
    }
    return null
  }, [withdrawAmount, pair])

  // const onRedirect = useCallback(() => {
  //   if (pair.isGamma) {
  //     navigate(
  //       `/liquidity/managev3?currency0=${pair.token0.address}&currency1=${pair.token1.address}&strategy=${pair.title}`,
  //     )
  //   } else {
  //     navigate(`/liquidity/manage/${pair.address.toLowerCase()}`)
  //   }
  // }, [pair])

  return (
    <CommonHollowModal
      popup={isOpen}
      width={804}
      setPopup={setIsOpen}
      title={`Manage ${getLPSymbol(pair)} LP (${pair.stable ? 'STABLE' : 'VOLATILE'})`}
    >
      <div className='w-full mt-[29px] flex items-center justify-center flex-col'>
        {/* <Tab leftTitle={'Stake'} rightTitle={'Unstake'} isLeft={isStake} setIsLeft={setIsStake} /> */}
        {/* {isStake ? (
          <div className='w-full flex items-center justify-center flex-col mt-5'>
            <BalanceInput
              title='Amount'
              inputAmount={stakeAmount}
              setInputAmount={setStakeAmount}
              symbol={pair.symbol}
              balance={pair.account.lpBalance}
              logoURIs={[pair.token0.logoURI, pair.token1.logoURI]}
            />
            <div
              className='flex items-center space-x-3.5 mt-5 group cursor-pointer'
              href='#'
              onClick={() => onRedirect()}
            >
              <p className='text-[1.17rem] md:text-xl text-green font-medium'>Get {pair.symbol} LP</p>
              <span className='w-6 h-6 text-green'>
                <ArrowRightCircle />
              </span>
            </div>
            <div className='flex items-center mt-[26px] w-full space-x-5'>
              <StyledButton
                disabled={stakePending}
                onClickHandler={() => {
                  if (stakePending) {
                    return
                  }
                  if (stakeErrorMsg) {
                    customNotify(stakeErrorMsg, 'warn')
                    return
                  }
                  onStake(pair, stakeAmount)
                }}
                content={stakePending ? 'Pending...' : 'Stake LP'}
                className='py-[13px] md:py-[14.53px] w-1/2 !bg-grayHover !text-black flex items-center justify-center text-[13px] md:text-base font-bold px-[23px] rounded-[3px]'
              />
              <TransparentButton
                onClickHandler={() => setIsOpen(false)}
                content={'Cancel'}
                className='
                py-[13px] md:py-[14.53px] px-[26px] rounded-lg !bg-componentSecondary !text-white flex items-center justify-center text-[13px] md:text-base w-1/2 font-semibold'
              />
            </div>
          </div>
        ) : ( */}
        <div className='w-full flex items-center justify-center flex-col mt-5'>
          <BalanceInput
            title='Amount'
            inputAmount={withdrawAmount}
            setInputAmount={setWithdrawAmount}
            symbol={pair.symbol}
            balance={pair.account.lpBalance}
            logoURIs={[pair.token0.logoURI, pair.token1.logoURI]}
          />
          <div className='flex items-center mt-[26px] w-full space-x-5'>
            <StyledButton
              disabled={pending}
              onClickHandler={() => {
                if (unstakeErrorMsg) {
                  customNotify(unstakeErrorMsg, 'warn')
                  return
                }
                // onUnstake(pair, withdrawAmount)

                onGammaRemove(pair.address, pair.token0.symbol, pair.token1.symbol, new BigNumber(withdrawAmount))
              }}
              content={pending ? 'Pending...' : 'Unstake LP'}
              className='py-[13px] md:py-[14.53px] w-1/2 !bg-grayHover !text-black flex items-center justify-center text-[13px] md:text-base font-bold px-[23px] rounded-[3px]'
            />
            <TransparentButton
              onClickHandler={() => setIsOpen(false)}
              content={'Cancel'}
              className='
                py-[13px] md:py-[14.53px] px-[26px] rounded-lg !bg-componentSecondary !text-white flex items-center justify-center text-[13px] md:text-base w-1/2 font-semibold'
            />
          </div>
        </div>
        {/* )} */}
      </div>
    </CommonHollowModal>
  )
}

export default DepositModal
