import { useContract } from 'hooks/useContractV3'
import React, { useState } from 'react'
import RetroVeManagerFactory from 'config/abi/v3/RetroVeManagerFactory.json'
import { ADDR_RETRO_VE_MANAGER_FACTORY } from '../constants'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import useWalletModal from 'hooks/useWalletModal'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import { ADDR_VE_RETRO } from '../constants'
import VotingEscrow from 'config/abi/v3/VotingEscrow.json'

export const SelectNft = ({
  isNftModalOpen,
  setIsNftModalOpen,
  selectedVeNFT,
  tokenId,
  veTHEs,
  managerInfo,
  refetchData,
}) => {
  const { account } = useActiveWeb3React()
  const veManagerFactory = useContract(ADDR_RETRO_VE_MANAGER_FACTORY, RetroVeManagerFactory)
  const [withMaxLock, setWithMaxLock] = useState(false)
  const { openWalletModal } = useWalletModal()
  const veNFTContract = useContract(ADDR_VE_RETRO, VotingEscrow)

  const createManager = async () => {
    try {
      const tx = await veManagerFactory.deployManager(tokenId, withMaxLock)

      await tx.wait()
    } catch (e) {
      console.error(e)
    }

    refetchData()
  }

  const approveManager = async () => {
    try {
      const tx = await veNFTContract.approve(managerInfo.address, tokenId)

      await tx.wait()
    } catch (e) {
      console.error(e)
    }

    refetchData()
  }

  if (!account) {
    return <LinearGhostButton onClickHanlder={openWalletModal} className='w-full' title={'Connect Wallet'} />
  }

  return (
    <div className='flex flex-col gap-1 items-center'>
      {/* <VeTHESelect isSelected={Boolean()} setIsSelected={setVeTHE} /> */}
      <div
        className={`w-full flex items-center border-2 border-white rounded-[3px] bg-componentPrimary px-4 min-w-[300px]`}
      >
        <div className='text-white font-medium whitespace-nowrap pr-3 border-r border-white'>SELECT veRETRO:</div>
        <div
          className={`pl-3 w-full relative focus:outline-none py-2 bg-transparent rounded-[3px] text-white flex items-center justify-between cursor-pointer`}
          onClick={() => {
            if (veTHEs.length > 0) {
              setIsNftModalOpen(true)
            }
          }}
        >
          {selectedVeNFT ? (
            <span className='text-white text-lg'>{`#${selectedVeNFT.id}`}</span>
          ) : (
            <div className='text-dimGray text-lg'>Not found</div>
          )}
          <img
            className={`${
              isNftModalOpen ? 'rotate-180' : 'rotate-0'
            } transform transition-all duration-300 ease-in-out`}
            alt=''
            src='/images/swap/dropdown-arrow.png'
          />
        </div>
      </div>
      {managerInfo && selectedVeNFT && !managerInfo.approved && (
        <div className='flex flex-col items-center'>
          <p className='mt-2'>
            You need to approve the usage of your veNFT by the manager for vote, claim rebase & auto max lock to work.
          </p>
          <LinearGhostButton onClickHanlder={approveManager} className='px-[10px] w-full mt-3' title='Approve' />
        </div>
      )}

      {!managerInfo && selectedVeNFT && (
        <>
          <p className='mb-2 mt-2'>This veNFT has no manager</p>
          <LinearGhostButton
            onClickHanlder={createManager}
            className='px-[10px] mb-2'
            smallCase
            title='Create Manager'
          />

          <div className='flex flex-row gap-2'>
            <input
              className='w-[24px] h-[24px]'
              title='with-maxlock'
              type='checkbox'
              checked={withMaxLock}
              onChange={(event) => setWithMaxLock(Boolean(event.target.checked))}
            />
            <label htmlFor='with-maxlock' className='cursor-pointer' onClick={() => setWithMaxLock(!withMaxLock)}>
              With auto max lock ? (can be disabled later)
            </label>
          </div>
        </>
      )}
    </div>
  )
}
