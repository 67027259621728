import { useState } from 'react'
import './lead.scss'
import bgVideo from '../background-images/lead/Monitorcenterpiece.mp4'
import { useEffect } from 'react'
import { formatAmount } from 'utils/formatNumber'
import { useEpochTimer, useOneDayVolume, useV3Tvl } from 'hooks/useGeneral'
import { formatDistanceStrict } from 'date-fns'
import { ethers, BigNumber } from 'ethers'
import useWeb3 from 'hooks/useWeb3'
import { getTHEAddress, getVeTHEAddress } from 'utils/addressHelpers'
import { getERC20Contract } from 'utils/contractHelpers'

const Lead = () => {
  const volume = useOneDayVolume()
  const { nextEpoch } = useEpochTimer()
  const [retroPrice, setRetroPrice] = useState()
  const [circulatingSupply, setCirculatingSupply] = useState()
  const [lockedSupply, setLockedSupply] = useState()
  const web3 = useWeb3()
  const tvl = useV3Tvl()

  useEffect(() => {
    fetch('https://api.coingecko.com/api/v3/coins/retro-finance')
      .then((res) => res.json())
      .then((data) => {
        setRetroPrice(data.market_data.current_price.usd)
      })
    fetch('https://retro-helper-api.vercel.app/api/circulating-supply')
      .then((res) => res.text())
      .then((data) => {
        setCirculatingSupply(BigNumber.from(data))
      })

    const tokenContract = getERC20Contract(web3, getTHEAddress())
    const veTokenContract = getERC20Contract(web3, getVeTHEAddress())

    tokenContract.methods
      .balanceOf(veTokenContract._address)
      .call()
      .then((res) => {
        setLockedSupply(BigNumber.from(res))
      })
  }, [])

  const numbersLeft = [
    {
      value: tvl ? formatAmount(tvl, true) : '-',
      name: 'TOTAL VALUE LOCKED',
    },
    {
      value: volume ? formatAmount(volume, true) : '-',
      name: 'TRADING VOLUME (24H)',
    },
    // {
    //   value: epoch || '-',
    //   name: 'EPOCH',
    // },
    {
      value: nextEpoch ? 'In ' + formatDistanceStrict(new Date(nextEpoch * 1000), new Date()) : '-',
      name: 'NEXT EPOCH',
    },
  ]

  const numbersRight = [
    {
      value: retroPrice ? '$' + formatAmount(retroPrice, true, 3) : '-',
      name: 'RETRO Price',
    },
    {
      value:
        retroPrice && circulatingSupply
          ? '$' + formatAmount(retroPrice * parseFloat(ethers.utils.formatUnits(circulatingSupply)), true)
          : '-',
      name: 'MARKET CAP',
    },
    {
      value:
        lockedSupply && circulatingSupply
          ? (
              (parseInt(lockedSupply.toString()) / parseInt(circulatingSupply.add(lockedSupply).toString())) *
              100
            ).toFixed(2) + '% '
          : '-',
      name: '% LOCKED',
    },
    // {
    //   value:
    //     lockedSupply && veSupply
    //       ? formatDuration(
    //         intervalToDuration({start: new Date(0), end: new Date(parseInt(veSupply.mul(BigNumber.from('63072000')).div(lockedSupply).mul(1000).toString()))}),
    //           { format: ['days'] },
    //         )
    //       : '-',
    //   name: 'AVERAGE UNLOCK DATETIME',
    // },
  ]
  // const numbersRight = [
  //   {
  //     value: '100',
  //     ammount: 'K',
  //     name: 'TOTAL VALUE LOCKED',
  //   },
  //   {
  //     value: '30',
  //     ammount: 'K',
  //     name: 'CIRCULATING MARKET CAP',
  //   },
  //   {
  //     value: '30',
  //     ammount: 'K',
  //     name: 'CIRCULATING MARKET CAP',
  //   },
  // ]

  return (
    <div className='lead flex flex-col items-center'>
      <div className='video-container'>
        <video playsInline src={bgVideo} preload='true' autoPlay loop muted width='100%' height='100%'></video>
      </div>
      <div className='numbers-container'>
        <div className='numbers'>
          {numbersLeft.map((number, idx) => {
            return (
              <div key={idx} className='number-container'>
                <div className='number'>
                  <p className='value'>
                    {number.value}
                    <span className='number-span'>{number.ammount}</span>
                  </p>
                </div>
                <p className='number-description'>{number.name}</p>
              </div>
            )
          })}
        </div>
        <div className='numbers numbers-right'>
          {numbersRight.map((number, idx) => {
            return (
              <div key={idx} className='number-container number-container-right'>
                <div className='number'>
                  <p className='value'>
                    {number.value}
                    <span className='number-span'>{number.ammount}</span>
                  </p>
                </div>
                <p className='number-description'>{number.name}</p>
              </div>
            )
          })}
        </div>
      </div>
      <img src='/images/retro/background/background-cloud1.svg' alt='' className='background-cloud' />
      <svg className='game-machine-image' viewBox='0 0 1021 899' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M973.154 758.229C853.113 564.598 891.081 63.7976 891.081 63.7976L877.966 -91H124.462L111.347 63.7976C111.347 63.7976 149.315 564.598 29.2743 758.229L26 786.683V898.034H976.428V786.683L973.154 758.229Z'
          fill='#010808'
        />
        <path d='M827.717 89.9062H177.703V165.168H827.717V89.9062Z' fill='url(#paint0_linear_360_9972)' />
        <path d='M829.676 165H173.324L127 681H876L829.676 165Z' fill='#27292D' />
        <path
          d='M151.437 631.726L189.011 208.752C189.663 201.423 192.744 194.625 197.654 189.679C202.564 184.733 208.954 181.992 215.581 181.988H789.803C796.43 181.992 802.819 184.733 807.73 189.679C812.64 194.625 815.72 201.423 816.373 208.752L853.947 631.726C854.314 635.849 853.901 640.012 852.732 643.946C851.564 647.88 849.668 651.499 847.164 654.569C844.661 657.639 841.608 660.093 838.199 661.771C834.791 663.45 831.105 664.317 827.377 664.316H178.007C174.279 664.317 170.592 663.45 167.184 661.771C163.776 660.093 160.722 657.639 158.219 654.569C155.716 651.499 153.819 647.88 152.651 643.946C151.483 640.012 151.069 635.849 151.437 631.726Z'
          fill='black'
          stroke='#616161'
          strokeWidth='1.06'
          strokeMiterlimit='10'
        />
        <foreignObject x='195px' y='200px' width='615px' height='450px'>
          <div>
            <video src={bgVideo} autoPlay loop muted width='100%' height='100%'></video>
          </div>
        </foreignObject>
        <path d='M946 787H60V898H946V787Z' fill='url(#paint1_linear_360_9972)' />
        <path d='M943 787H60L126.319 681H876.699L943 787Z' fill='url(#paint2_linear_360_9972)' />
        <path
          opacity='0.32'
          d='M96.6322 752.661L131.397 696.628C132.756 694.443 134.571 692.658 136.687 691.425C138.803 690.192 141.157 689.548 143.546 689.549H861.874C864.261 689.546 866.613 690.189 868.726 691.422C870.84 692.656 872.651 694.442 874.005 696.628L908.787 752.661C910.309 755.123 911.196 757.999 911.354 760.981C911.513 763.962 910.936 766.935 909.685 769.578C908.435 772.222 906.559 774.436 904.26 775.982C901.961 777.528 899.326 778.347 896.639 778.351H108.781C106.091 778.354 103.452 777.539 101.149 775.995C98.8462 774.45 96.9673 772.235 95.716 769.589C94.4646 766.943 93.8885 763.967 94.05 760.983C94.2115 757.999 95.1045 755.121 96.6322 752.661Z'
          stroke='#616161'
          strokeWidth='1.06'
          strokeMiterlimit='10'
        />
        <path
          d='M304.829 735.84C319.335 735.84 331.095 730.258 331.095 723.373C331.095 716.487 319.335 710.905 304.829 710.905C290.322 710.905 278.562 716.487 278.562 723.373C278.562 730.258 290.322 735.84 304.829 735.84Z'
          fill='#171417'
        />
        <path
          d='M304.828 712.217C318.874 712.217 330.307 717.447 331.023 724.028C331.033 723.81 331.033 723.591 331.023 723.372C331.023 716.492 319.268 710.925 304.757 710.925C290.246 710.925 278.491 716.492 278.491 723.372C278.491 723.611 278.491 723.81 278.491 724.028C279.35 717.447 290.783 712.217 304.828 712.217Z'
          fill='#231F23'
        />
        <path d='M329.216 716.73H280.423V722.596H329.216V716.73Z' fill='#006360' />
        <path
          d='M304.829 734.169C318.298 734.169 329.216 728.987 329.216 722.596C329.216 716.205 318.298 711.023 304.829 711.023C291.36 711.023 280.441 716.205 280.441 722.596C280.441 728.987 291.36 734.169 304.829 734.169Z'
          fill='#006360'
        />
        <path
          d='M304.829 727.926C318.298 727.926 329.216 722.745 329.216 716.354C329.216 709.962 318.298 704.781 304.829 704.781C291.36 704.781 280.441 709.962 280.441 716.354C280.441 722.745 291.36 727.926 304.829 727.926Z'
          fill='black'
        />
        <path
          d='M304.829 727.031C292.536 727.031 282.374 722.756 280.513 717.089C281.372 723.054 291.91 727.866 304.829 727.866C317.747 727.866 328.285 723.173 329.073 717.089C327.284 722.716 317.121 727.031 304.829 727.031Z'
          fill='#E0F7FA'
        />
        <path
          d='M326.389 713.65C326.389 719.297 316.745 723.89 304.828 723.89C292.912 723.89 283.25 719.297 283.25 713.65C283.25 708.002 292.912 703.409 304.828 703.409C316.745 703.409 326.389 708.002 326.389 713.65Z'
          fill='#006064'
        />
        <path
          d='M304.828 722.896C316.538 722.896 326.031 718.392 326.031 712.835C326.031 707.278 316.538 702.773 304.828 702.773C293.119 702.773 283.626 707.278 283.626 712.835C283.626 718.392 293.119 722.896 304.828 722.896Z'
          fill='#8D8D8D'
        />
        <path d='M326.031 708.002H283.626V712.834H326.031V708.002Z' fill='#8D8D8D' />
        <path
          d='M304.828 718.064C316.538 718.064 326.031 713.559 326.031 708.003C326.031 702.446 316.538 697.941 304.828 697.941C293.119 697.941 283.626 702.446 283.626 708.003C283.626 713.559 293.119 718.064 304.828 718.064Z'
          fill='#E1E1E1'
        />
        <path
          d='M304.828 715.995C297.439 715.995 290.908 714.245 287.025 711.541C290.604 714.643 297.224 716.731 304.828 716.731C312.433 716.731 319.142 714.643 322.721 711.541C318.731 714.245 312.218 715.995 304.828 715.995Z'
          fill='black'
        />
        <path
          d='M304.972 699.193C312.826 699.193 319.554 701.341 322.864 704.443C320.234 700.923 313.238 698.478 304.972 698.478C296.705 698.478 289.674 701.003 287.079 704.443C290.389 701.182 297.117 699.193 304.972 699.193Z'
          fill='black'
        />
        <path
          d='M304.829 717.267C293.395 717.267 284.109 712.972 283.662 707.604C283.653 707.736 283.653 707.869 283.662 708.001C283.662 713.569 293.145 718.063 304.864 718.063C316.584 718.063 326.067 713.569 326.067 708.001C326.067 707.862 326.067 707.743 326.067 707.604C325.494 712.972 316.262 717.267 304.829 717.267Z'
          fill='#E0F7FA'
        />
        <path
          d='M362.084 735.84C376.591 735.84 388.351 730.258 388.351 723.373C388.351 716.487 376.591 710.905 362.084 710.905C347.578 710.905 335.818 716.487 335.818 723.373C335.818 730.258 347.578 735.84 362.084 735.84Z'
          fill='#171417'
        />
        <path
          d='M362.084 712.217C376.13 712.217 387.563 717.447 388.297 724.028C388.307 723.81 388.307 723.591 388.297 723.372C388.297 716.492 376.523 710.925 362.013 710.925C347.502 710.925 335.747 716.492 335.747 723.372C335.737 723.591 335.737 723.81 335.747 724.028C336.605 717.447 348.057 712.217 362.084 712.217Z'
          fill='#231F23'
        />
        <path
          d='M362.084 734.169C375.553 734.169 386.472 728.987 386.472 722.596C386.472 716.205 375.553 711.023 362.084 711.023C348.615 711.023 337.697 716.205 337.697 722.596C337.697 728.987 348.615 734.169 362.084 734.169Z'
          fill='#006360'
        />
        <path d='M386.489 716.73H337.697V722.596H386.489V716.73Z' fill='#006360' />
        <path
          d='M362.084 727.926C375.553 727.926 386.472 722.745 386.472 716.354C386.472 709.962 375.553 704.781 362.084 704.781C348.615 704.781 337.697 709.962 337.697 716.354C337.697 722.745 348.615 727.926 362.084 727.926Z'
          fill='black'
        />
        <path
          d='M362.084 727.031C349.81 727.031 339.647 722.756 337.786 717.089C338.627 723.054 349.184 727.866 362.084 727.866C374.985 727.866 385.541 723.173 386.328 717.089C384.539 722.716 374.376 727.031 362.084 727.031Z'
          fill='#E0F7FA'
        />
        <path
          d='M383.662 713.65C383.662 719.297 374.001 723.89 362.084 723.89C350.168 723.89 340.613 719.297 340.613 713.65C340.613 708.002 350.257 703.409 362.084 703.409C373.911 703.409 383.662 708.002 383.662 713.65Z'
          fill='#006064'
        />
        <path
          d='M362.084 722.896C373.794 722.896 383.286 718.392 383.286 712.835C383.286 707.278 373.794 702.773 362.084 702.773C350.374 702.773 340.881 707.278 340.881 712.835C340.881 718.392 350.374 722.896 362.084 722.896Z'
          fill='#8D8D8D'
        />
        <path d='M383.286 708.002H340.881V712.834H383.286V708.002Z' fill='#8D8D8D' />
        <path
          d='M362.084 718.064C373.794 718.064 383.286 713.559 383.286 708.003C383.286 702.446 373.794 697.941 362.084 697.941C350.374 697.941 340.881 702.446 340.881 708.003C340.881 713.559 350.374 718.064 362.084 718.064Z'
          fill='#E1E1E1'
        />
        <path
          d='M362.084 715.995C354.694 715.995 348.181 714.245 344.299 711.541C347.877 714.643 354.498 716.731 362.084 716.731C369.67 716.731 376.308 714.643 379.887 711.541C376.004 714.245 369.491 715.995 362.084 715.995Z'
          fill='black'
        />
        <path
          d='M362.245 699.193C370.1 699.193 376.827 701.341 380.137 704.443C377.507 700.923 370.511 698.478 362.245 698.478C353.979 698.478 346.947 701.003 344.353 704.443C347.645 701.182 354.39 699.193 362.245 699.193Z'
          fill='black'
        />
        <path
          d='M362.084 717.267C350.669 717.267 341.365 712.972 340.935 707.604C340.927 707.736 340.927 707.869 340.935 708.001C340.935 713.569 350.436 718.063 362.138 718.063C373.839 718.063 383.358 713.569 383.358 708.001C383.367 707.869 383.367 707.736 383.358 707.604C382.75 712.972 373.517 717.267 362.084 717.267Z'
          fill='#E0F7FA'
        />
        <path
          d='M419.358 735.84C433.864 735.84 445.624 730.258 445.624 723.373C445.624 716.487 433.864 710.905 419.358 710.905C404.852 710.905 393.092 716.487 393.092 723.373C393.092 730.258 404.852 735.84 419.358 735.84Z'
          fill='#171417'
        />
        <path
          d='M419.357 712.217C433.403 712.217 444.836 717.447 445.552 724.028C445.571 723.81 445.571 723.59 445.552 723.372C445.552 716.492 433.797 710.925 419.286 710.925C404.775 710.925 393.02 716.492 393.02 723.372C393.001 723.59 393.001 723.81 393.02 724.028C393.879 717.447 405.312 712.217 419.357 712.217Z'
          fill='#231F23'
        />
        <path
          d='M419.358 734.169C432.826 734.169 443.745 728.987 443.745 722.596C443.745 716.205 432.826 711.023 419.358 711.023C405.889 711.023 394.97 716.205 394.97 722.596C394.97 728.987 405.889 734.169 419.358 734.169Z'
          fill='#006360'
        />
        <path d='M443.763 716.73H394.97V722.596H443.763V716.73Z' fill='#006360' />
        <path
          d='M419.358 727.926C432.826 727.926 443.745 722.745 443.745 716.354C443.745 709.962 432.826 704.781 419.358 704.781C405.889 704.781 394.97 709.962 394.97 716.354C394.97 722.745 405.889 727.926 419.358 727.926Z'
          fill='black'
        />
        <path
          d='M419.358 727.031C407.066 727.031 396.921 722.756 395.042 717.089C395.901 723.054 406.439 727.866 419.358 727.866C432.276 727.866 442.815 723.114 443.674 717.089C441.795 722.716 431.65 727.031 419.358 727.031Z'
          fill='#E0F7FA'
        />
        <path
          d='M440.936 713.65C440.936 719.297 431.274 723.89 419.358 723.89C407.442 723.89 397.78 719.297 397.78 713.65C397.78 708.002 407.442 703.409 419.358 703.409C431.274 703.409 440.936 708.002 440.936 713.65Z'
          fill='#006064'
        />
        <path
          d='M419.358 722.896C431.068 722.896 440.561 718.392 440.561 712.835C440.561 707.278 431.068 702.773 419.358 702.773C407.648 702.773 398.156 707.278 398.156 712.835C398.156 718.392 407.648 722.896 419.358 722.896Z'
          fill='#8D8D8D'
        />
        <path d='M440.561 708.002H398.156V712.834H440.561V708.002Z' fill='#8D8D8D' />
        <path
          d='M419.358 718.064C431.068 718.064 440.561 713.559 440.561 708.003C440.561 702.446 431.068 697.941 419.358 697.941C407.648 697.941 398.156 702.446 398.156 708.003C398.156 713.559 407.648 718.064 419.358 718.064Z'
          fill='#E1E1E1'
        />
        <path
          d='M419.358 715.995C411.968 715.995 405.455 714.245 401.555 711.541C405.133 714.643 411.753 716.731 419.358 716.731C426.962 716.731 433.672 714.643 437.25 711.541C433.278 714.245 426.747 715.995 419.358 715.995Z'
          fill='black'
        />
        <path
          d='M419.501 699.193C427.356 699.193 434.101 701.341 437.393 704.443C434.745 700.923 427.749 698.478 419.501 698.478C411.252 698.478 404.203 701.003 401.608 704.443C404.918 701.182 411.646 699.193 419.501 699.193Z'
          fill='black'
        />
        <path
          d='M419.358 717.267C407.925 717.267 398.639 712.972 398.191 707.604C398.191 707.743 398.191 707.862 398.191 708.001C398.191 713.569 407.674 718.063 419.394 718.063C431.113 718.063 440.596 713.569 440.596 708.001C440.596 707.862 440.596 707.743 440.596 707.604C440.006 712.972 430.791 717.267 419.358 717.267Z'
          fill='#E0F7FA'
        />
        <path
          d='M296.902 757.812C311.408 757.812 323.168 752.23 323.168 745.344C323.168 738.459 311.408 732.877 296.902 732.877C282.395 732.877 270.636 738.459 270.636 745.344C270.636 752.23 282.395 757.812 296.902 757.812Z'
          fill='#171417'
        />
        <path
          d='M296.866 734.189C310.912 734.189 322.345 739.419 323.061 746C323.078 745.782 323.078 745.563 323.061 745.344C323.061 738.464 311.306 732.877 296.795 732.877C282.284 732.877 270.529 738.464 270.529 745.344C270.511 745.563 270.511 745.782 270.529 746C271.423 739.419 282.857 734.189 296.866 734.189Z'
          fill='#231F23'
        />
        <path
          d='M296.902 756.141C310.371 756.141 321.289 750.96 321.289 744.569C321.289 738.177 310.371 732.996 296.902 732.996C283.433 732.996 272.515 738.177 272.515 744.569C272.515 750.96 283.433 756.141 296.902 756.141Z'
          fill='#006360'
        />
        <path d='M321.307 738.703H272.515V744.569H321.307V738.703Z' fill='#006360' />
        <path
          d='M296.902 749.897C310.371 749.897 321.289 744.716 321.289 738.325C321.289 731.933 310.371 726.752 296.902 726.752C283.433 726.752 272.515 731.933 272.515 738.325C272.515 744.716 283.433 749.897 296.902 749.897Z'
          fill='black'
        />
        <path
          d='M296.866 748.903C284.574 748.903 274.429 744.628 272.55 738.961C273.409 744.926 283.948 749.758 296.866 749.758C309.784 749.758 320.323 744.986 321.182 738.961C319.357 744.688 309.194 748.903 296.866 748.903Z'
          fill='#E0F7FA'
        />
        <path
          d='M318.48 735.621C318.48 741.268 308.819 745.861 296.902 745.861C284.986 745.861 275.396 741.268 275.396 735.621C275.396 729.974 285.057 725.381 296.974 725.381C308.89 725.381 318.48 729.974 318.48 735.621Z'
          fill='#006064'
        />
        <path
          d='M296.902 744.867C308.612 744.867 318.105 740.362 318.105 734.805C318.105 729.249 308.612 724.744 296.902 724.744C285.192 724.744 275.7 729.249 275.7 734.805C275.7 740.362 285.192 744.867 296.902 744.867Z'
          fill='#8D8D8D'
        />
        <path d='M318.105 729.974H275.7V734.805H318.105V729.974Z' fill='#8D8D8D' />
        <path
          d='M296.902 740.035C308.612 740.035 318.105 735.53 318.105 729.973C318.105 724.417 308.612 719.912 296.902 719.912C285.192 719.912 275.7 724.417 275.7 729.973C275.7 735.53 285.192 740.035 296.902 740.035Z'
          fill='#E1E1E1'
        />
        <path
          d='M296.866 737.968C289.476 737.968 282.964 736.198 278.974 733.514C282.552 736.616 289.172 738.703 296.866 738.703C304.56 738.703 311.091 736.715 314.669 733.514C310.822 736.198 304.291 737.968 296.866 737.968Z'
          fill='black'
        />
        <path
          d='M297.045 721.065C304.9 721.065 311.645 723.213 314.937 726.334C312.289 722.815 305.311 720.369 297.045 720.369C288.778 720.369 281.747 722.894 279.152 726.334C282.552 723.193 289.19 721.065 297.045 721.065Z'
          fill='black'
        />
        <path
          d='M296.866 739.24C285.433 739.24 276.147 734.945 275.7 729.576C275.7 729.715 275.7 729.835 275.7 729.974C275.7 735.521 285.201 740.035 296.902 740.035C308.604 740.035 318.105 735.521 318.105 729.974C318.114 729.841 318.114 729.709 318.105 729.576C317.622 734.985 308.336 739.24 296.866 739.24Z'
          fill='#E0F7FA'
        />
        <path
          d='M354.175 757.812C368.682 757.812 380.441 752.23 380.441 745.344C380.441 738.459 368.682 732.877 354.175 732.877C339.669 732.877 327.909 738.459 327.909 745.344C327.909 752.23 339.669 757.812 354.175 757.812Z'
          fill='#171417'
        />
        <path
          d='M354.122 734.189C368.167 734.189 379.601 739.419 380.316 746C380.316 745.782 380.316 745.563 380.316 745.344C380.316 738.464 368.561 732.877 354.05 732.877C339.54 732.877 327.784 738.464 327.784 745.344C327.784 745.563 327.784 745.782 327.784 746C328.697 739.419 340.13 734.189 354.122 734.189Z'
          fill='#231F23'
        />
        <path
          d='M354.176 756.141C367.645 756.141 378.563 750.96 378.563 744.569C378.563 738.177 367.645 732.996 354.176 732.996C340.707 732.996 329.789 738.177 329.789 744.569C329.789 750.96 340.707 756.141 354.176 756.141Z'
          fill='#006360'
        />
        <path d='M378.563 738.703H329.771V744.569H378.563V738.703Z' fill='#006360' />
        <path
          d='M354.176 749.897C367.645 749.897 378.563 744.716 378.563 738.325C378.563 731.933 367.645 726.752 354.176 726.752C340.707 726.752 329.789 731.933 329.789 738.325C329.789 744.716 340.707 749.897 354.176 749.897Z'
          fill='black'
        />
        <path
          d='M354.122 748.903C341.83 748.903 331.667 744.628 329.806 738.961C330.665 744.926 341.204 749.758 354.122 749.758C367.04 749.758 377.579 744.986 378.438 738.961C376.613 744.688 366.45 748.903 354.122 748.903Z'
          fill='#E0F7FA'
        />
        <path
          d='M375.736 735.621C375.736 741.268 366.092 745.861 354.265 745.861C342.439 745.861 332.651 741.268 332.651 735.621C332.651 729.974 342.313 725.381 354.23 725.381C366.146 725.381 375.736 729.974 375.736 735.621Z'
          fill='#006064'
        />
        <path
          d='M354.176 744.867C365.886 744.867 375.378 740.362 375.378 734.805C375.378 729.249 365.886 724.744 354.176 724.744C342.466 724.744 332.973 729.249 332.973 734.805C332.973 740.362 342.466 744.867 354.176 744.867Z'
          fill='#8D8D8D'
        />
        <path d='M375.378 729.974H332.973V734.805H375.378V729.974Z' fill='#8D8D8D' />
        <path
          d='M354.176 740.035C365.886 740.035 375.378 735.53 375.378 729.973C375.378 724.417 365.886 719.912 354.176 719.912C342.466 719.912 332.973 724.417 332.973 729.973C332.973 735.53 342.466 740.035 354.176 740.035Z'
          fill='#E1E1E1'
        />
        <path
          d='M354.122 737.968C346.733 737.968 340.202 736.198 336.319 733.514C339.898 736.616 346.518 738.703 354.122 738.703C361.727 738.703 368.436 736.715 372.015 733.514C368.078 736.198 361.566 737.968 354.122 737.968Z'
          fill='black'
        />
        <path
          d='M354.319 721.065C362.174 721.065 368.901 723.213 372.212 726.334C369.581 722.815 362.585 720.369 354.319 720.369C346.053 720.369 339.021 722.894 336.427 726.334C339.808 723.193 346.464 721.065 354.319 721.065Z'
          fill='black'
        />
        <path
          d='M354.122 739.24C342.688 739.24 333.402 734.945 332.955 729.576C332.946 729.709 332.946 729.841 332.955 729.974C332.955 735.521 342.438 740.035 354.157 740.035C365.877 740.035 375.36 735.521 375.36 729.974C375.36 729.835 375.36 729.715 375.36 729.576C374.895 734.985 365.591 739.24 354.122 739.24Z'
          fill='#E0F7FA'
        />
        <path
          d='M411.432 757.812C425.938 757.812 437.698 752.23 437.698 745.344C437.698 738.459 425.938 732.877 411.432 732.877C396.925 732.877 385.166 738.459 385.166 745.344C385.166 752.23 396.925 757.812 411.432 757.812Z'
          fill='#171417'
        />
        <path
          d='M411.378 734.189C425.423 734.189 436.857 739.419 437.59 746C437.59 745.782 437.59 745.563 437.59 745.344C437.59 738.464 425.817 732.877 411.306 732.877C396.795 732.877 385.04 738.464 385.04 745.344C385.04 745.563 385.04 745.782 385.04 746C385.953 739.419 397.404 734.189 411.378 734.189Z'
          fill='#231F23'
        />
        <path
          d='M411.431 756.141C424.9 756.141 435.819 750.96 435.819 744.569C435.819 738.177 424.9 732.996 411.431 732.996C397.963 732.996 387.044 738.177 387.044 744.569C387.044 750.96 397.963 756.141 411.431 756.141Z'
          fill='#006360'
        />
        <path d='M435.837 738.703H387.044V744.569H435.837V738.703Z' fill='#006360' />
        <path
          d='M411.431 749.897C424.9 749.897 435.819 744.716 435.819 738.325C435.819 731.933 424.9 726.752 411.431 726.752C397.963 726.752 387.044 731.933 387.044 738.325C387.044 744.716 397.963 749.897 411.431 749.897Z'
          fill='black'
        />
        <path
          d='M411.378 748.903C399.104 748.903 388.941 744.628 387.08 738.961C387.921 744.926 398.478 749.758 411.378 749.758C424.278 749.758 434.835 744.986 435.694 738.961C433.887 744.688 423.724 748.903 411.378 748.903Z'
          fill='#E0F7FA'
        />
        <path
          d='M433.01 735.621C433.01 741.268 423.348 745.861 411.431 745.861C399.515 745.861 389.96 741.268 389.96 735.621C389.96 729.974 399.604 725.381 411.431 725.381C423.258 725.381 433.01 729.974 433.01 735.621Z'
          fill='#006064'
        />
        <path
          d='M411.432 744.867C423.141 744.867 432.634 740.362 432.634 734.805C432.634 729.249 423.141 724.744 411.432 724.744C399.722 724.744 390.229 729.249 390.229 734.805C390.229 740.362 399.722 744.867 411.432 744.867Z'
          fill='#8D8D8D'
        />
        <path d='M432.634 729.974H390.229V734.805H432.634V729.974Z' fill='#8D8D8D' />
        <path
          d='M411.432 740.035C423.141 740.035 432.634 735.53 432.634 729.973C432.634 724.417 423.141 719.912 411.432 719.912C399.722 719.912 390.229 724.417 390.229 729.973C390.229 735.53 399.722 740.035 411.432 740.035Z'
          fill='#E1E1E1'
        />
        <path
          d='M411.378 737.968C403.988 737.968 397.475 736.198 393.593 733.514C397.171 736.616 403.791 738.703 411.378 738.703C418.964 738.703 425.692 736.715 429.27 733.514C425.352 736.198 418.839 737.968 411.378 737.968Z'
          fill='black'
        />
        <path
          d='M411.593 721.065C419.447 721.065 426.175 723.213 429.485 726.334C426.855 722.815 419.859 720.369 411.593 720.369C403.326 720.369 396.295 722.894 393.7 726.334C397.064 723.193 403.738 721.065 411.593 721.065Z'
          fill='black'
        />
        <path
          d='M411.378 739.24C399.963 739.24 390.659 734.945 390.229 729.576C390.22 729.709 390.22 729.841 390.229 729.974C390.229 735.521 399.73 740.035 411.432 740.035C423.133 740.035 432.652 735.521 432.652 729.974C432.661 729.841 432.661 729.709 432.652 729.576C432.151 734.985 422.865 739.24 411.378 739.24Z'
          fill='#E0F7FA'
        />
        <path
          d='M196.866 753.179C216.362 753.179 232.167 745.674 232.167 736.417C232.167 727.159 216.362 719.654 196.866 719.654C177.369 719.654 161.564 727.159 161.564 736.417C161.564 745.674 177.369 753.179 196.866 753.179Z'
          fill='#171417'
        />
        <path
          d='M196.866 721.423C215.742 721.423 231.112 728.462 232.078 737.33C232.126 737.041 232.156 736.749 232.167 736.455C232.167 727.209 216.368 719.713 196.866 719.713C177.363 719.713 161.564 727.209 161.564 736.455C161.575 736.749 161.605 737.041 161.653 737.33C162.673 728.462 177.989 721.423 196.866 721.423Z'
          fill='#231F23'
        />
        <path
          d='M196.866 751.668C214.613 751.668 229.001 744.84 229.001 736.417C229.001 727.994 214.613 721.166 196.866 721.166C179.118 721.166 164.731 727.994 164.731 736.417C164.731 744.84 179.118 751.668 196.866 751.668Z'
          fill='url(#paint3_linear_360_9972)'
        />
        <path
          d='M227.086 737.47C227.086 728.86 212.271 721.9 193.985 721.9C192.858 721.9 191.748 721.9 190.657 721.9C192.446 721.761 194.235 721.682 196.024 721.682C213.774 721.682 228.231 728.303 228.231 736.475C228.137 737.836 227.766 739.155 227.146 740.333C226.526 741.511 225.673 742.518 224.652 743.276C226.116 741.769 226.99 739.684 227.086 737.47Z'
          fill='#2F3135'
        />
        <path
          d='M166.681 735.383C166.681 743.973 181.496 750.932 199.782 750.932C200.909 750.932 202.001 750.932 203.092 750.932C201.303 751.071 199.514 751.171 197.724 751.171C179.975 751.171 165.518 744.529 165.518 736.357C165.615 734.997 165.987 733.679 166.606 732.501C167.226 731.324 168.077 730.317 169.097 729.557C167.642 731.077 166.775 733.166 166.681 735.383Z'
          fill='#2F3135'
        />
        <g opacity='0.25'>
          <path
            d='M220.108 734.984C220.108 728.383 208.693 723.054 194.612 723.054C193.753 723.054 192.912 723.054 192.071 723.054C193.431 722.935 194.808 722.875 196.24 722.875C209.91 722.875 220.985 727.985 220.985 734.289C220.907 735.336 220.617 736.35 220.137 737.256C219.656 738.161 218.998 738.935 218.212 739.518C219.353 738.34 220.033 736.713 220.108 734.984Z'
            fill='#59535E'
          />
          <path
            d='M173.605 733.354C173.605 739.975 185.021 745.284 199.102 745.284C199.979 745.284 200.891 745.284 201.661 745.284C200.301 745.404 198.905 745.463 197.492 745.463C183.822 745.463 172.747 740.353 172.747 734.07C172.819 733.018 173.107 731.999 173.588 731.089C174.068 730.179 174.729 729.404 175.52 728.82C174.375 729.997 173.689 731.623 173.605 733.354Z'
            fill='#59535E'
          />
        </g>
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M196.865 711.203C217.834 711.203 234.833 692.313 234.833 669.01C234.833 645.706 217.834 626.815 196.865 626.815C175.896 626.815 158.897 645.706 158.897 669.01C158.897 692.313 175.896 711.203 196.865 711.203Z'
          fill='url(#paint4_radial_360_9972)'
        />
        <path
          d='M196.866 736.159C194.583 736.159 192.394 735.151 190.78 733.357C189.166 731.564 188.259 729.131 188.259 726.594V692.215C188.259 689.678 189.166 687.245 190.78 685.452C192.394 683.658 194.583 682.65 196.866 682.65C197.995 682.65 199.113 682.898 200.156 683.379C201.199 683.86 202.147 684.565 202.945 685.453C203.743 686.341 204.375 687.396 204.805 688.556C205.236 689.716 205.456 690.96 205.454 692.215V726.594C205.456 727.849 205.236 729.093 204.805 730.253C204.375 731.413 203.743 732.468 202.945 733.356C202.147 734.244 201.199 734.949 200.156 735.43C199.113 735.911 197.995 736.159 196.866 736.159Z'
          fill='#656B71'
        />
        <path
          d='M192.571 730.69C191.772 730.69 191.006 730.338 190.439 729.713C189.873 729.087 189.552 728.237 189.547 727.349V695.216C189.552 694.329 189.873 693.479 190.439 692.853C191.006 692.227 191.772 691.876 192.571 691.876C193.368 691.876 194.133 692.228 194.697 692.854C195.26 693.481 195.577 694.331 195.577 695.216V727.29C195.584 727.733 195.512 728.174 195.364 728.586C195.216 728.998 194.996 729.374 194.716 729.69C194.436 730.007 194.102 730.258 193.734 730.43C193.366 730.601 192.97 730.69 192.571 730.69Z'
          fill='#70767C'
        />
        <path
          d='M196.866 682.65C195.99 682.665 195.122 682.826 194.289 683.128C196.028 683.739 197.548 684.948 198.629 686.581C199.709 688.215 200.295 690.187 200.301 692.215V726.594C200.299 728.62 199.715 730.593 198.634 732.224C197.552 733.855 196.03 735.06 194.289 735.661C195.118 735.979 195.988 736.147 196.866 736.159C197.995 736.159 199.113 735.911 200.156 735.43C201.199 734.949 202.147 734.244 202.945 733.356C203.743 732.468 204.375 731.413 204.805 730.253C205.236 729.093 205.456 727.849 205.454 726.594V692.215C205.456 690.96 205.236 689.716 204.805 688.556C204.375 687.396 203.743 686.341 202.945 685.453C202.147 684.565 201.199 683.86 200.156 683.379C199.113 682.898 197.995 682.65 196.866 682.65Z'
          fill='#53585E'
        />
        <path
          d='M196.865 697.404C210.976 697.404 222.415 684.692 222.415 669.01C222.415 653.328 210.976 640.615 196.865 640.615C182.754 640.615 171.314 653.328 171.314 669.01C171.314 684.692 182.754 697.404 196.865 697.404Z'
          fill='#28FFFB'
        />
        <path
          d='M219.732 669.665C219.831 668.728 219.885 667.785 219.893 666.842C219.894 661.191 218.047 655.732 214.696 651.482C211.346 647.231 206.719 644.479 201.679 643.736C206.545 645.79 210.807 649.299 213.993 653.874C217.178 658.45 219.165 663.914 219.732 669.665Z'
          fill='white'
        />
        <path
          d='M199.925 682.57C194.658 682.567 189.482 681.054 184.896 678.177C180.31 675.299 176.47 671.155 173.748 666.146C173.748 666.344 173.748 666.543 173.748 666.742C173.76 672.55 175.576 678.176 178.89 682.668C182.203 687.16 186.811 690.243 191.934 691.396C197.056 692.548 202.38 691.7 207.005 688.994C211.63 686.288 215.272 681.891 217.316 676.545C212.205 680.472 206.138 682.574 199.925 682.57Z'
          fill='white'
        />
        <path
          opacity='0.61'
          d='M198.583 676.367C206.402 676.367 213.237 673.304 217.012 668.751C217.012 668.751 217.012 668.93 217.012 669.029C217.012 675.472 209.855 680.701 201.034 680.701C194.432 680.701 188.742 677.758 186.327 673.563C190.185 675.458 194.364 676.414 198.583 676.367Z'
          fill='#E0F7FA'
        />
        <path
          d='M196.293 642.186C195.881 642.186 195.47 642.186 195.058 642.186C189.866 642.188 184.864 644.351 181.035 648.248C177.207 652.146 174.831 657.495 174.375 663.243C175.72 657.703 178.527 652.737 182.433 648.985C186.339 645.232 191.166 642.864 196.293 642.186Z'
          fill='#E0F7FA'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M296.902 745.364C312.229 745.364 324.653 738.652 324.653 730.371C324.653 722.091 312.229 715.379 296.902 715.379C281.576 715.379 269.151 722.091 269.151 730.371C269.151 738.652 281.576 745.364 296.902 745.364Z'
          fill='url(#paint5_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M354.319 745.364C369.645 745.364 382.07 738.652 382.07 730.371C382.07 722.091 369.645 715.379 354.319 715.379C338.992 715.379 326.567 722.091 326.567 730.371C326.567 738.652 338.992 745.364 354.319 745.364Z'
          fill='url(#paint6_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M411.736 745.364C427.062 745.364 439.487 738.652 439.487 730.371C439.487 722.091 427.062 715.379 411.736 715.379C396.409 715.379 383.984 722.091 383.984 730.371C383.984 738.652 396.409 745.364 411.736 745.364Z'
          fill='url(#paint7_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M304.828 723.393C320.155 723.393 332.579 716.681 332.579 708.401C332.579 700.121 320.155 693.408 304.828 693.408C289.502 693.408 277.077 700.121 277.077 708.401C277.077 716.681 289.502 723.393 304.828 723.393Z'
          fill='url(#paint8_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M362.245 723.393C377.571 723.393 389.996 716.681 389.996 708.401C389.996 700.121 377.571 693.408 362.245 693.408C346.918 693.408 334.494 700.121 334.494 708.401C334.494 716.681 346.918 723.393 362.245 723.393Z'
          fill='url(#paint9_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M419.662 723.393C434.988 723.393 447.413 716.681 447.413 708.401C447.413 700.121 434.988 693.408 419.662 693.408C404.335 693.408 391.911 700.121 391.911 708.401C391.911 716.681 404.335 723.393 419.662 723.393Z'
          fill='url(#paint10_radial_360_9972)'
        />
        <path
          d='M812.633 735.84C827.14 735.84 838.899 730.258 838.899 723.373C838.899 716.487 827.14 710.905 812.633 710.905C798.127 710.905 786.367 716.487 786.367 723.373C786.367 730.258 798.127 735.84 812.633 735.84Z'
          fill='#171417'
        />
        <path
          d='M812.634 712.217C798.588 712.217 787.155 717.447 786.439 724.028C786.429 723.81 786.429 723.591 786.439 723.372C786.439 716.492 798.194 710.925 812.705 710.925C827.216 710.925 838.971 716.492 838.971 723.372C838.99 723.59 838.99 723.81 838.971 724.028C838.112 717.447 826.679 712.217 812.634 712.217Z'
          fill='#231F23'
        />
        <path d='M837.039 716.73H788.246V722.596H837.039V716.73Z' fill='#006360' />
        <path
          d='M812.633 734.169C826.102 734.169 837.021 728.987 837.021 722.596C837.021 716.205 826.102 711.023 812.633 711.023C799.165 711.023 788.246 716.205 788.246 722.596C788.246 728.987 799.165 734.169 812.633 734.169Z'
          fill='#006360'
        />
        <path
          d='M812.633 727.926C826.102 727.926 837.021 722.745 837.021 716.354C837.021 709.962 826.102 704.781 812.633 704.781C799.165 704.781 788.246 709.962 788.246 716.354C788.246 722.745 799.165 727.926 812.633 727.926Z'
          fill='black'
        />
        <path
          d='M812.633 727.031C824.925 727.031 835.088 722.756 836.949 717.089C836.09 723.054 825.552 727.866 812.633 727.866C799.715 727.866 789.176 723.114 788.317 717.089C790.196 722.716 800.341 727.031 812.633 727.031Z'
          fill='#E0F7FA'
        />
        <path
          d='M791.056 713.65C791.056 719.297 800.718 723.89 812.634 723.89C824.55 723.89 834.212 719.297 834.212 713.65C834.212 708.002 824.55 703.409 812.634 703.409C800.718 703.409 791.056 708.002 791.056 713.65Z'
          fill='#006064'
        />
        <path
          d='M812.634 722.896C824.344 722.896 833.836 718.392 833.836 712.835C833.836 707.278 824.344 702.773 812.634 702.773C800.924 702.773 791.431 707.278 791.431 712.835C791.431 718.392 800.924 722.896 812.634 722.896Z'
          fill='#8D8D8D'
        />
        <path d='M833.836 708.002H791.431V712.834H833.836V708.002Z' fill='#8D8D8D' />
        <path
          d='M812.634 718.064C824.344 718.064 833.836 713.559 833.836 708.003C833.836 702.446 824.344 697.941 812.634 697.941C800.924 697.941 791.431 702.446 791.431 708.003C791.431 713.559 800.924 718.064 812.634 718.064Z'
          fill='#E1E1E1'
        />
        <path
          d='M812.634 715.995C820.024 715.995 826.554 714.245 830.526 711.541C826.948 714.643 820.328 716.731 812.634 716.731C804.94 716.731 798.41 714.643 794.831 711.541C798.732 714.245 805.244 715.995 812.634 715.995Z'
          fill='black'
        />
        <path
          d='M812.49 699.193C804.635 699.193 797.89 701.341 794.598 704.443C797.228 700.923 804.224 698.478 812.49 698.478C820.756 698.478 827.788 701.003 830.383 704.443C827.072 701.182 820.345 699.193 812.49 699.193Z'
          fill='black'
        />
        <path
          d='M812.634 717.267C824.067 717.267 833.353 712.972 833.801 707.604C833.809 707.736 833.809 707.869 833.801 708.001C833.801 713.569 824.318 718.063 812.598 718.063C800.878 718.063 791.396 713.569 791.396 708.001C791.396 707.862 791.396 707.743 791.396 707.604C791.914 712.972 801.201 717.267 812.634 717.267Z'
          fill='#E0F7FA'
        />
        <path
          d='M755.377 735.84C769.884 735.84 781.644 730.258 781.644 723.373C781.644 716.487 769.884 710.905 755.377 710.905C740.871 710.905 729.111 716.487 729.111 723.373C729.111 730.258 740.871 735.84 755.377 735.84Z'
          fill='#171417'
        />
        <path
          d='M755.377 712.217C741.331 712.217 729.898 717.447 729.165 724.028C729.155 723.81 729.155 723.591 729.165 723.372C729.165 716.492 740.938 710.925 755.449 710.925C769.959 710.925 781.715 716.492 781.715 723.372C781.725 723.591 781.725 723.81 781.715 724.028C780.856 717.447 769.405 712.217 755.377 712.217Z'
          fill='#231F23'
        />
        <path
          d='M755.378 734.169C768.846 734.169 779.765 728.987 779.765 722.596C779.765 716.205 768.846 711.023 755.378 711.023C741.909 711.023 730.99 716.205 730.99 722.596C730.99 728.987 741.909 734.169 755.378 734.169Z'
          fill='#006360'
        />
        <path d='M779.764 716.73H730.972V722.596H779.764V716.73Z' fill='#006360' />
        <path
          d='M755.378 727.926C768.846 727.926 779.765 722.745 779.765 716.354C779.765 709.962 768.846 704.781 755.378 704.781C741.909 704.781 730.99 709.962 730.99 716.354C730.99 722.745 741.909 727.926 755.378 727.926Z'
          fill='black'
        />
        <path
          d='M755.378 727.031C767.652 727.031 777.815 722.756 779.676 717.089C778.835 723.054 768.278 727.866 755.378 727.866C742.477 727.866 731.921 723.114 731.062 717.089C732.923 722.716 743.086 727.031 755.378 727.031Z'
          fill='#E0F7FA'
        />
        <path
          d='M733.799 713.65C733.799 719.297 743.461 723.89 755.378 723.89C767.294 723.89 776.938 719.297 776.938 713.65C776.938 708.002 767.276 703.409 755.378 703.409C743.479 703.409 733.799 708.002 733.799 713.65Z'
          fill='#006064'
        />
        <path
          d='M755.377 722.896C767.087 722.896 776.58 718.392 776.58 712.835C776.58 707.278 767.087 702.773 755.377 702.773C743.668 702.773 734.175 707.278 734.175 712.835C734.175 718.392 743.668 722.896 755.377 722.896Z'
          fill='#8D8D8D'
        />
        <path d='M776.563 708.002H734.158V712.834H776.563V708.002Z' fill='#8D8D8D' />
        <path
          d='M755.377 718.064C767.087 718.064 776.58 713.559 776.58 708.003C776.58 702.446 767.087 697.941 755.377 697.941C743.668 697.941 734.175 702.446 734.175 708.003C734.175 713.559 743.668 718.064 755.377 718.064Z'
          fill='#E1E1E1'
        />
        <path
          d='M755.378 715.995C762.768 715.995 769.281 714.245 773.163 711.541C769.585 714.643 762.965 716.731 755.378 716.731C747.792 716.731 741.154 714.643 737.575 711.541C741.458 714.245 747.971 715.995 755.378 715.995Z'
          fill='black'
        />
        <path
          d='M755.217 699.193C747.362 699.193 740.634 701.341 737.324 704.443C739.954 700.923 746.95 698.478 755.217 698.478C763.483 698.478 770.515 701.003 773.109 704.443C769.817 701.182 763.071 699.193 755.217 699.193Z'
          fill='black'
        />
        <path
          d='M755.378 717.267C766.793 717.267 776.097 712.972 776.527 707.604C776.535 707.736 776.535 707.869 776.527 708.001C776.527 713.569 767.026 718.063 755.324 718.063C743.622 718.063 734.104 713.569 734.104 708.001C734.095 707.869 734.095 707.736 734.104 707.604C734.658 712.972 743.945 717.267 755.378 717.267Z'
          fill='#E0F7FA'
        />
        <path
          d='M698.104 735.84C712.61 735.84 724.37 730.258 724.37 723.373C724.37 716.487 712.61 710.905 698.104 710.905C683.598 710.905 671.838 716.487 671.838 723.373C671.838 730.258 683.598 735.84 698.104 735.84Z'
          fill='#171417'
        />
        <path
          d='M698.104 712.217C684.058 712.217 672.625 717.447 671.909 724.028C671.891 723.81 671.891 723.59 671.909 723.372C671.909 716.492 683.665 710.925 698.176 710.925C712.686 710.925 724.442 716.492 724.442 723.372C724.46 723.59 724.46 723.81 724.442 724.028C723.583 717.447 712.15 712.217 698.104 712.217Z'
          fill='#231F23'
        />
        <path
          d='M698.104 734.169C711.572 734.169 722.491 728.987 722.491 722.596C722.491 716.205 711.572 711.023 698.104 711.023C684.635 711.023 673.716 716.205 673.716 722.596C673.716 728.987 684.635 734.169 698.104 734.169Z'
          fill='#006360'
        />
        <path d='M722.491 716.73H673.698V722.596H722.491V716.73Z' fill='#006360' />
        <path
          d='M698.104 727.926C711.572 727.926 722.491 722.745 722.491 716.354C722.491 709.962 711.572 704.781 698.104 704.781C684.635 704.781 673.716 709.962 673.716 716.354C673.716 722.745 684.635 727.926 698.104 727.926Z'
          fill='black'
        />
        <path
          d='M698.104 727.031C710.396 727.031 720.541 722.756 722.42 717.089C721.561 723.054 711.022 727.866 698.104 727.866C685.186 727.866 674.647 723.114 673.788 717.089C675.649 722.716 685.812 727.031 698.104 727.031Z'
          fill='#E0F7FA'
        />
        <path
          d='M676.525 713.65C676.525 719.297 686.187 723.89 698.104 723.89C710.02 723.89 719.682 719.297 719.682 713.65C719.682 708.002 710.02 703.409 698.104 703.409C686.187 703.409 676.525 708.002 676.525 713.65Z'
          fill='#006064'
        />
        <path
          d='M698.104 722.896C709.814 722.896 719.307 718.392 719.307 712.835C719.307 707.278 709.814 702.773 698.104 702.773C686.395 702.773 676.902 707.278 676.902 712.835C676.902 718.392 686.395 722.896 698.104 722.896Z'
          fill='#8D8D8D'
        />
        <path d='M719.307 708.002H676.902V712.834H719.307V708.002Z' fill='#8D8D8D' />
        <path
          d='M698.104 718.064C709.814 718.064 719.307 713.559 719.307 708.003C719.307 702.446 709.814 697.941 698.104 697.941C686.395 697.941 676.902 702.446 676.902 708.003C676.902 713.559 686.395 718.064 698.104 718.064Z'
          fill='#E1E1E1'
        />
        <path
          d='M698.105 715.995C705.494 715.995 712.007 714.245 715.908 711.541C712.329 714.643 705.709 716.731 698.105 716.731C690.5 716.731 683.88 714.643 680.302 711.541C684.184 714.245 690.715 715.995 698.105 715.995Z'
          fill='black'
        />
        <path
          d='M697.961 699.193C690.106 699.193 683.361 701.341 680.068 704.443C682.716 700.923 689.694 698.478 697.961 698.478C706.227 698.478 713.259 701.003 715.853 704.443C712.543 701.182 705.816 699.193 697.961 699.193Z'
          fill='black'
        />
        <path
          d='M698.104 717.267C709.537 717.267 718.823 712.972 719.271 707.604C719.271 707.743 719.271 707.862 719.271 708.001C719.271 713.569 709.77 718.063 698.068 718.063C686.366 718.063 676.865 713.569 676.865 708.001C676.857 707.869 676.857 707.736 676.865 707.604C677.384 712.972 686.671 717.267 698.104 717.267Z'
          fill='#E0F7FA'
        />
        <path
          d='M820.56 757.812C835.066 757.812 846.826 752.23 846.826 745.344C846.826 738.459 835.066 732.877 820.56 732.877C806.054 732.877 794.294 738.459 794.294 745.344C794.294 752.23 806.054 757.812 820.56 757.812Z'
          fill='#171417'
        />
        <path
          d='M820.56 734.189C806.515 734.189 795.081 739.419 794.366 746C794.348 745.782 794.348 745.563 794.366 745.344C794.366 738.464 806.121 732.877 820.632 732.877C835.142 732.877 846.898 738.464 846.898 745.344C846.898 745.563 846.898 745.782 846.898 746C846.039 739.419 834.606 734.189 820.56 734.189Z'
          fill='#231F23'
        />
        <path
          d='M820.56 756.141C834.029 756.141 844.947 750.96 844.947 744.569C844.947 738.177 834.029 732.996 820.56 732.996C807.091 732.996 796.172 738.177 796.172 744.569C796.172 750.96 807.091 756.141 820.56 756.141Z'
          fill='#006360'
        />
        <path d='M844.947 738.703H796.155V744.569H844.947V738.703Z' fill='#006360' />
        <path
          d='M820.56 749.897C834.029 749.897 844.947 744.716 844.947 738.325C844.947 731.933 834.029 726.752 820.56 726.752C807.091 726.752 796.172 731.933 796.172 738.325C796.172 744.716 807.091 749.897 820.56 749.897Z'
          fill='black'
        />
        <path
          d='M820.56 748.903C832.852 748.903 842.997 744.628 844.876 738.961C844.017 744.926 833.478 749.758 820.56 749.758C807.642 749.758 797.103 744.986 796.244 738.961C798.105 744.688 808.268 748.903 820.56 748.903Z'
          fill='#E0F7FA'
        />
        <path
          d='M798.981 735.621C798.981 741.268 808.643 745.861 820.56 745.861C832.476 745.861 842.138 741.268 842.138 735.621C842.138 729.974 832.476 725.381 820.56 725.381C808.643 725.381 798.981 729.974 798.981 735.621Z'
          fill='#006064'
        />
        <path
          d='M820.56 744.867C832.27 744.867 841.762 740.362 841.762 734.805C841.762 729.249 832.27 724.744 820.56 724.744C808.85 724.744 799.357 729.249 799.357 734.805C799.357 740.362 808.85 744.867 820.56 744.867Z'
          fill='#8D8D8D'
        />
        <path d='M841.762 729.974H799.357V734.805H841.762V729.974Z' fill='#8D8D8D' />
        <path
          d='M820.56 740.035C832.27 740.035 841.762 735.53 841.762 729.973C841.762 724.417 832.27 719.912 820.56 719.912C808.85 719.912 799.357 724.417 799.357 729.973C799.357 735.53 808.85 740.035 820.56 740.035Z'
          fill='#E1E1E1'
        />
        <path
          d='M820.56 737.968C827.949 737.968 834.462 736.198 838.363 733.514C834.784 736.616 828.164 738.703 820.56 738.703C812.956 738.703 806.246 736.715 802.667 733.514C806.64 736.198 813.17 737.968 820.56 737.968Z'
          fill='black'
        />
        <path
          d='M820.417 721.065C812.544 721.065 805.817 723.213 802.524 726.334C805.155 722.815 812.151 720.369 820.417 720.369C828.683 720.369 835.715 722.894 838.309 726.334C834.999 723.193 828.272 721.065 820.417 721.065Z'
          fill='black'
        />
        <path
          d='M820.56 739.24C831.993 739.24 841.279 734.945 841.727 729.576C841.727 729.715 841.727 729.835 841.727 729.974C841.727 735.521 832.226 740.035 820.524 740.035C808.822 740.035 799.322 735.521 799.322 729.974C799.313 729.841 799.313 729.709 799.322 729.576C799.84 734.985 809.127 739.24 820.56 739.24Z'
          fill='#E0F7FA'
        />
        <path
          d='M763.286 757.812C777.792 757.812 789.552 752.23 789.552 745.344C789.552 738.459 777.792 732.877 763.286 732.877C748.78 732.877 737.02 738.459 737.02 745.344C737.02 752.23 748.78 757.812 763.286 757.812Z'
          fill='#171417'
        />
        <path
          d='M763.286 734.189C749.241 734.189 737.807 739.419 737.092 746C737.092 745.782 737.092 745.563 737.092 745.344C737.092 738.464 748.847 732.877 763.358 732.877C777.869 732.877 789.624 738.464 789.624 745.344C789.641 745.563 789.641 745.782 789.624 746C788.765 739.419 777.332 734.189 763.286 734.189Z'
          fill='#231F23'
        />
        <path
          d='M763.286 756.141C776.755 756.141 787.673 750.96 787.673 744.569C787.673 738.177 776.755 732.996 763.286 732.996C749.817 732.996 738.898 738.177 738.898 744.569C738.898 750.96 749.817 756.141 763.286 756.141Z'
          fill='#006360'
        />
        <path d='M787.691 738.703H738.898V744.569H787.691V738.703Z' fill='#006360' />
        <path
          d='M763.286 749.897C776.755 749.897 787.673 744.716 787.673 738.325C787.673 731.933 776.755 726.752 763.286 726.752C749.817 726.752 738.898 731.933 738.898 738.325C738.898 744.716 749.817 749.897 763.286 749.897Z'
          fill='black'
        />
        <path
          d='M763.287 748.903C775.579 748.903 785.742 744.628 787.602 738.961C786.743 744.926 776.205 749.758 763.287 749.758C750.368 749.758 739.83 744.986 738.971 738.961C740.849 744.688 750.994 748.903 763.287 748.903Z'
          fill='#E0F7FA'
        />
        <path
          d='M741.708 735.621C741.708 741.268 751.369 745.861 763.286 745.861C775.202 745.861 784.864 741.268 784.864 735.621C784.864 729.974 775.202 725.381 763.286 725.381C751.369 725.381 741.708 729.974 741.708 735.621Z'
          fill='#006064'
        />
        <path
          d='M763.286 744.867C774.995 744.867 784.488 740.362 784.488 734.805C784.488 729.249 774.995 724.744 763.286 724.744C751.576 724.744 742.083 729.249 742.083 734.805C742.083 740.362 751.576 744.867 763.286 744.867Z'
          fill='#8D8D8D'
        />
        <path d='M784.488 729.974H742.083V734.805H784.488V729.974Z' fill='#8D8D8D' />
        <path
          d='M763.286 740.035C774.995 740.035 784.488 735.53 784.488 729.973C784.488 724.417 774.995 719.912 763.286 719.912C751.576 719.912 742.083 724.417 742.083 729.973C742.083 735.53 751.576 740.035 763.286 740.035Z'
          fill='#E1E1E1'
        />
        <path
          d='M763.286 737.968C770.676 737.968 777.207 736.198 781.089 733.514C777.511 736.616 770.891 738.703 763.286 738.703C755.682 738.703 749.062 736.715 745.483 733.514C749.384 736.198 755.897 737.968 763.286 737.968Z'
          fill='black'
        />
        <path
          d='M763.142 721.065C755.288 721.065 748.542 723.213 745.25 726.334C747.88 722.815 754.876 720.369 763.142 720.369C771.409 720.369 778.44 722.894 781.035 726.334C777.725 723.193 771.015 721.065 763.142 721.065Z'
          fill='black'
        />
        <path
          d='M763.286 739.24C774.719 739.24 784.006 734.945 784.453 729.576C784.462 729.709 784.462 729.841 784.453 729.974C784.453 735.521 774.97 740.035 763.25 740.035C751.531 740.035 742.048 735.521 742.048 729.974C742.048 729.835 742.048 729.715 742.048 729.576C742.567 734.985 751.853 739.24 763.286 739.24Z'
          fill='#E0F7FA'
        />
        <path
          d='M706.03 757.812C720.537 757.812 732.296 752.23 732.296 745.344C732.296 738.459 720.537 732.877 706.03 732.877C691.524 732.877 679.764 738.459 679.764 745.344C679.764 752.23 691.524 757.812 706.03 757.812Z'
          fill='#171417'
        />
        <path
          d='M706.031 734.189C691.985 734.189 680.552 739.419 679.818 746C679.818 745.782 679.818 745.563 679.818 745.344C679.818 738.464 691.592 732.877 706.102 732.877C720.613 732.877 732.368 738.464 732.368 745.344C732.368 745.563 732.368 745.782 732.368 746C731.51 739.419 720.058 734.189 706.031 734.189Z'
          fill='#231F23'
        />
        <path
          d='M706.03 756.141C719.499 756.141 730.418 750.96 730.418 744.569C730.418 738.177 719.499 732.996 706.03 732.996C692.562 732.996 681.643 738.177 681.643 744.569C681.643 750.96 692.562 756.141 706.03 756.141Z'
          fill='#006360'
        />
        <path d='M730.418 738.703H681.625V744.569H730.418V738.703Z' fill='#006360' />
        <path
          d='M706.03 749.897C719.499 749.897 730.418 744.716 730.418 738.325C730.418 731.933 719.499 726.752 706.03 726.752C692.562 726.752 681.643 731.933 681.643 738.325C681.643 744.716 692.562 749.897 706.03 749.897Z'
          fill='black'
        />
        <path
          d='M706.031 748.903C718.305 748.903 728.468 744.628 730.329 738.961C729.488 744.926 718.931 749.758 706.031 749.758C693.13 749.758 682.574 744.986 681.715 738.961C683.576 744.688 693.739 748.903 706.031 748.903Z'
          fill='#E0F7FA'
        />
        <path
          d='M684.452 735.621C684.452 741.268 694.114 745.861 706.03 745.861C717.946 745.861 727.59 741.268 727.59 735.621C727.59 729.974 717.928 725.381 706.03 725.381C694.131 725.381 684.452 729.974 684.452 735.621Z'
          fill='#006064'
        />
        <path
          d='M706.03 744.867C717.74 744.867 727.232 740.362 727.232 734.805C727.232 729.249 717.74 724.744 706.03 724.744C694.32 724.744 684.827 729.249 684.827 734.805C684.827 740.362 694.32 744.867 706.03 744.867Z'
          fill='#8D8D8D'
        />
        <path d='M727.215 729.974H684.81V734.805H727.215V729.974Z' fill='#8D8D8D' />
        <path
          d='M706.03 740.035C717.74 740.035 727.232 735.53 727.232 729.973C727.232 724.417 717.74 719.912 706.03 719.912C694.32 719.912 684.827 724.417 684.827 729.973C684.827 735.53 694.32 740.035 706.03 740.035Z'
          fill='#E1E1E1'
        />
        <path
          d='M706.03 737.968C713.42 737.968 719.932 736.198 723.815 733.514C720.237 736.616 713.616 738.703 706.03 738.703C698.444 738.703 691.806 736.715 688.227 733.514C692.11 736.198 698.623 737.968 706.03 737.968Z'
          fill='black'
        />
        <path
          d='M705.869 721.065C698.015 721.065 691.287 723.213 687.977 726.334C690.607 722.815 697.603 720.369 705.869 720.369C714.136 720.369 721.168 722.894 723.762 726.334C720.47 723.193 713.76 721.065 705.869 721.065Z'
          fill='black'
        />
        <path
          d='M706.03 739.24C717.445 739.24 726.75 734.945 727.179 729.576C727.188 729.709 727.188 729.841 727.179 729.974C727.179 735.521 717.678 740.035 705.976 740.035C694.275 740.035 684.756 735.521 684.756 729.974C684.747 729.841 684.747 729.709 684.756 729.576C685.311 734.985 694.597 739.24 706.03 739.24Z'
          fill='#E0F7FA'
        />
        <path
          d='M597.763 753.179C617.26 753.179 633.065 745.674 633.065 736.417C633.065 727.159 617.26 719.654 597.763 719.654C578.267 719.654 562.461 727.159 562.461 736.417C562.461 745.674 578.267 753.179 597.763 753.179Z'
          fill='#171417'
        />
        <path
          d='M597.763 721.423C616.64 721.423 632.009 728.462 632.976 737.33C632.994 737.039 632.994 736.747 632.976 736.455C632.976 727.209 617.177 719.713 597.674 719.713C578.171 719.713 562.372 727.209 562.372 736.455C562.383 736.749 562.413 737.041 562.462 737.33C563.463 728.462 578.887 721.423 597.763 721.423Z'
          fill='#231F23'
        />
        <path
          d='M597.764 751.668C615.511 751.668 629.899 744.84 629.899 736.417C629.899 727.994 615.511 721.166 597.764 721.166C580.016 721.166 565.629 727.994 565.629 736.417C565.629 744.84 580.016 751.668 597.764 751.668Z'
          fill='url(#paint11_linear_360_9972)'
        />
        <path
          d='M627.876 737.47C627.876 728.86 613.061 721.9 594.775 721.9C593.648 721.9 592.556 721.9 591.465 721.9C593.254 721.761 595.043 721.682 596.833 721.682C614.582 721.682 629.039 728.303 629.039 736.475C628.945 737.836 628.574 739.155 627.954 740.333C627.334 741.511 626.482 742.518 625.46 743.276C626.918 741.766 627.785 739.681 627.876 737.47Z'
          fill='#2F3135'
        />
        <path
          d='M567.579 735.383C567.579 743.973 582.394 750.932 600.68 750.932C601.807 750.932 602.916 750.932 604.008 750.932C602.218 751.071 600.429 751.171 598.64 751.171C580.891 751.171 566.434 744.529 566.434 736.357C566.53 734.997 566.902 733.679 567.522 732.501C568.142 731.324 568.993 730.317 570.012 729.557C568.55 731.073 567.678 733.163 567.579 735.383Z'
          fill='#2F3135'
        />
        <g opacity='0.25'>
          <path
            d='M621.023 734.984C621.023 728.383 609.608 723.054 595.509 723.054C594.65 723.054 593.719 723.054 592.968 723.054C594.328 722.935 595.723 722.875 597.137 722.875C610.807 722.875 621.882 727.985 621.882 734.289C621.808 735.337 621.519 736.353 621.039 737.259C620.558 738.165 619.898 738.938 619.109 739.518C620.253 738.341 620.94 736.715 621.023 734.984Z'
            fill='#59535E'
          />
          <path
            d='M574.52 733.354C574.52 739.975 585.936 745.284 600.017 745.284C600.876 745.284 601.717 745.284 602.558 745.284C601.198 745.404 599.82 745.463 598.389 745.463C584.719 745.463 573.644 740.353 573.644 734.07C573.72 733.019 574.01 732.001 574.49 731.092C574.97 730.183 575.629 729.407 576.417 728.82C575.276 729.998 574.595 731.625 574.52 733.354Z'
            fill='#59535E'
          />
        </g>
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M597.763 711.203C618.732 711.203 635.731 692.313 635.731 669.01C635.731 645.706 618.732 626.815 597.763 626.815C576.794 626.815 559.795 645.706 559.795 669.01C559.795 692.313 576.794 711.203 597.763 711.203Z'
          fill='url(#paint12_radial_360_9972)'
        />
        <path
          d='M597.763 736.159C596.633 736.159 595.515 735.911 594.472 735.43C593.429 734.949 592.481 734.244 591.683 733.356C590.886 732.468 590.254 731.413 589.823 730.253C589.392 729.093 589.172 727.849 589.174 726.594V692.215C589.172 690.96 589.392 689.716 589.823 688.556C590.254 687.396 590.886 686.341 591.683 685.453C592.481 684.565 593.429 683.86 594.472 683.379C595.515 682.898 596.633 682.65 597.763 682.65C600.045 682.65 602.234 683.658 603.848 685.452C605.462 687.245 606.369 689.678 606.369 692.215V726.594C606.369 729.131 605.462 731.564 603.848 733.357C602.234 735.151 600.045 736.159 597.763 736.159Z'
          fill='#656B71'
        />
        <path
          d='M593.469 730.69C592.672 730.69 591.908 730.338 591.344 729.711C590.78 729.085 590.463 728.235 590.463 727.349V695.216C590.463 694.331 590.78 693.481 591.344 692.854C591.908 692.228 592.672 691.876 593.469 691.876C594.267 691.876 595.031 692.228 595.595 692.854C596.159 693.481 596.475 694.331 596.475 695.216V727.29C596.482 727.733 596.41 728.174 596.262 728.586C596.114 728.998 595.894 729.374 595.614 729.69C595.334 730.007 595 730.258 594.632 730.43C594.264 730.601 593.869 730.69 593.469 730.69Z'
          fill='#70767C'
        />
        <path
          d='M597.763 682.65C596.888 682.665 596.019 682.826 595.187 683.128C596.926 683.739 598.445 684.948 599.526 686.581C600.607 688.215 601.192 690.187 601.198 692.215V726.594C601.197 728.62 600.613 730.593 599.531 732.224C598.45 733.855 596.927 735.06 595.187 735.661C596.016 735.979 596.886 736.147 597.763 736.159C600.046 736.159 602.235 735.151 603.849 733.357C605.463 731.564 606.369 729.131 606.369 726.594V692.215C606.369 689.678 605.463 687.245 603.849 685.452C602.235 683.658 600.046 682.65 597.763 682.65Z'
          fill='#53585E'
        />
        <path
          d='M597.763 697.404C611.874 697.404 623.313 684.692 623.313 669.01C623.313 653.328 611.874 640.615 597.763 640.615C583.652 640.615 572.212 653.328 572.212 669.01C572.212 684.692 583.652 697.404 597.763 697.404Z'
          fill='#28FFFB'
        />
        <path
          d='M620.719 669.665C620.818 668.728 620.872 667.785 620.88 666.842C620.881 661.194 619.037 655.738 615.69 651.488C612.343 647.238 607.721 644.483 602.684 643.736C607.548 645.791 611.807 649.3 614.99 653.876C618.172 658.452 620.155 663.916 620.719 669.665Z'
          fill='white'
        />
        <path
          d='M600.822 682.57C595.558 682.569 590.383 681.057 585.8 678.179C581.217 675.302 577.38 671.156 574.664 666.146C574.664 666.344 574.664 666.543 574.664 666.742C574.676 672.55 576.492 678.176 579.805 682.668C583.119 687.16 587.726 690.243 592.849 691.396C597.972 692.548 603.295 691.7 607.92 688.994C612.545 686.288 616.187 681.891 618.232 676.545C613.116 680.476 607.042 682.578 600.822 682.57Z'
          fill='white'
        />
        <path
          opacity='0.61'
          d='M599.481 676.367C607.3 676.367 614.135 673.304 617.911 668.751C617.911 668.751 617.911 668.93 617.911 669.029C617.911 675.472 610.754 680.701 601.951 680.701C595.33 680.701 589.641 677.758 587.225 673.563C591.084 675.458 595.263 676.414 599.481 676.367Z'
          fill='#E0F7FA'
        />
        <path
          d='M597.191 642.186C596.779 642.186 596.386 642.186 595.974 642.186C590.78 642.188 585.775 644.35 581.944 648.247C578.113 652.144 575.733 657.494 575.272 663.243C576.618 657.703 579.424 652.737 583.33 648.985C587.237 645.232 592.064 642.864 597.191 642.186Z'
          fill='#E0F7FA'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M820.56 745.364C835.886 745.364 848.311 738.652 848.311 730.371C848.311 722.091 835.886 715.379 820.56 715.379C805.233 715.379 792.809 722.091 792.809 730.371C792.809 738.652 805.233 745.364 820.56 745.364Z'
          fill='url(#paint13_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M763.143 745.364C778.469 745.364 790.894 738.652 790.894 730.371C790.894 722.091 778.469 715.379 763.143 715.379C747.816 715.379 735.392 722.091 735.392 730.371C735.392 738.652 747.816 745.364 763.143 745.364Z'
          fill='url(#paint14_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M705.727 745.364C721.053 745.364 733.478 738.652 733.478 730.371C733.478 722.091 721.053 715.379 705.727 715.379C690.4 715.379 677.976 722.091 677.976 730.371C677.976 738.652 690.4 745.364 705.727 745.364Z'
          fill='url(#paint15_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M812.634 723.393C827.96 723.393 840.385 716.681 840.385 708.401C840.385 700.121 827.96 693.408 812.634 693.408C797.307 693.408 784.882 700.121 784.882 708.401C784.882 716.681 797.307 723.393 812.634 723.393Z'
          fill='url(#paint16_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M755.216 723.393C770.543 723.393 782.967 716.681 782.967 708.401C782.967 700.121 770.543 693.408 755.216 693.408C739.889 693.408 727.465 700.121 727.465 708.401C727.465 716.681 739.889 723.393 755.216 723.393Z'
          fill='url(#paint17_radial_360_9972)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M697.8 723.393C713.126 723.393 725.551 716.681 725.551 708.401C725.551 700.121 713.126 693.408 697.8 693.408C682.473 693.408 670.049 700.121 670.049 708.401C670.049 716.681 682.473 723.393 697.8 723.393Z'
          fill='url(#paint18_radial_360_9972)'
        />
        <path d='M879.461 -91L892.576 63.7976H860.477L847.38 -91H879.461Z' fill='#E1E1E1' />
        <path d='M974.649 758.229H942.55L945.824 786.762H977.923L974.649 758.229Z' fill='#E1E1E1' />
        <path d='M977.923 786.764H945.824V898.015H977.923V786.764Z' fill='white' />
        <path
          d='M860.478 63.7979H892.577C892.577 63.7979 854.609 564.598 974.649 758.23H942.55C822.528 564.598 860.478 63.7979 860.478 63.7979Z'
          fill='#E1E1E1'
        />
        <path d='M125.958 -91L112.843 63.7976H144.942L158.039 -91H125.958Z' fill='#E1E1E1' />
        <path d='M30.7699 758.229H62.8689L59.5946 786.762H27.4956L30.7699 758.229Z' fill='#E1E1E1' />
        <path d='M59.5946 786.764H27.4956V898.015H59.5946V786.764Z' fill='white' />
        <path
          d='M144.942 63.7979H112.843C112.843 63.7979 150.81 564.598 30.77 758.23H62.869C182.891 564.598 144.942 63.7979 144.942 63.7979Z'
          fill='#E1E1E1'
        />
        <path d='M1021 108.633H0L18.6885 -46.1846H1002.31L1021 108.633Z' fill='url(#paint19_linear_360_9972)' />
        <path d='M1021 108.633H0L46.7468 135.115H974.253L1021 108.633Z' fill='url(#paint20_linear_360_9972)' />
        <defs>
          <linearGradient
            id='paint0_linear_360_9972'
            x1='502.701'
            y1='227.922'
            x2='502.701'
            y2='77.0611'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#17191D' />
            <stop offset='0.4' stopColor='#131519' />
            <stop offset='0.81' stopColor='#08090D' />
            <stop offset='1' stopColor='#000005' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_360_9972'
            x1='503'
            y1='971.087'
            x2='503'
            y2='719.13'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.33' stopColor='#1E2024' />
            <stop offset='0.86' stopColor='#07080D' />
            <stop offset='1' stopColor='#000005' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_360_9972'
            x1='501.5'
            y1='661.784'
            x2='501.5'
            y2='793.765'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='1' stopColor='#1A1C20' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_360_9972'
            x1='196.866'
            y1='714.584'
            x2='196.866'
            y2='748.984'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.56' stopColor='#232529' />
            <stop offset='1' stopColor='#1A1C20' />
          </linearGradient>
          <radialGradient
            id='paint4_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(196.865 669.009) scale(37.9677 42.194)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint5_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(295.972 742.442) scale(27.7165 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint6_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(353.312 742.442) scale(27.7164 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint7_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(410.652 742.442) scale(27.7164 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint8_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(303.874 720.174) scale(27.7165 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint9_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(361.214 720.174) scale(27.7164 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint10_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(418.554 720.174) scale(27.7165 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <linearGradient
            id='paint11_linear_360_9972'
            x1='597.764'
            y1='714.584'
            x2='597.764'
            y2='748.984'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.56' stopColor='#232529' />
            <stop offset='1' stopColor='#1A1C20' />
          </linearGradient>
          <radialGradient
            id='paint12_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(597.763 669.009) scale(37.9677 42.194)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint13_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(817.95 742.442) rotate(180) scale(27.7165 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint14_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(760.61 742.442) rotate(180) scale(27.7164 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint15_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(703.271 742.442) rotate(180) scale(27.7164 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint16_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(810.048 720.174) rotate(180) scale(27.7165 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint17_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(752.707 720.174) rotate(180) scale(27.7164 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint18_radial_360_9972'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(695.368 720.174) rotate(180) scale(27.7164 15.1898)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <linearGradient
            id='paint19_linear_360_9972'
            x1='510.487'
            y1='-37.9515'
            x2='510.487'
            y2='125.298'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.31' stopColor='#232529' />
            <stop offset='0.63' stopColor='#17191D' />
            <stop offset='0.95' stopColor='#040409' />
            <stop offset='1' stopColor='#000005' />
          </linearGradient>
          <linearGradient
            id='paint20_linear_360_9972'
            x1='510.487'
            y1='32.4947'
            x2='510.487'
            y2='162.464'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.31' stopColor='#232529' />
            <stop offset='0.63' stopColor='#17191D' />
            <stop offset='0.95' stopColor='#040409' />
            <stop offset='1' stopColor='#000005' />
          </linearGradient>
        </defs>
      </svg>

      <img src='/images/retro/background/background-plus.svg' alt='' className='background-plus' />
    </div>
  )
}

export default Lead
