import React from 'react'

const LinearGhostButton = ({ title, onClickHanlder, className, smallCase, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={`py-[10.8px] text-black font-semibold leading-5 px-3 ${className} ${smallCase ? '' : 'uppercase'} ${
        disabled ? 'opacity-30' : ''
      } bg-white fonts-semibold text-[15px] md:text-[17px] rounded-[3px] flex items-center justify-center flex-col`}
      onClick={() => {
        onClickHanlder?.()
      }}
    >
      {title}
    </button>
  )
}

export default LinearGhostButton
