import { FusionsContext } from 'context/FusionsContext'
import { useGetMerklDataQuery } from 'hooks/queries/useGetMerklDataQuery'
import { useContext, useMemo } from 'react'
import { ZERO_VALUE } from 'utils/formatNumber'
import { useIchiVaultAssets } from './useIchiVaultAssets'
import BigNumber from 'bignumber.js'
import { getAddress } from 'ethers/lib/utils'
import usePrices from 'hooks/usePrices'
import { useV3ManualPositions } from './useV3ManualPositions'
import { useWeb3React } from '@web3-react/core'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
import { computePoolAddress } from '@uniswap/v3-sdk'
import { V3_CORE_FACTORY_ADDRESSES } from 'config/constants/v3/addresses'
import { ChainId } from 'config/constants'
import { POOL_INIT_CODE_HASH } from 'v3lib/utils'
import { toToken } from 'config/constants/v3/routing'
import { useDefiEdgeStrategiesQuery } from 'hooks/queries/useDefiEdgeStrategiesQuery'

export const useGetAllLiquidityPools = () => {
  const { account } = useWeb3React()
  const gammaPools = useContext(FusionsContext)
  const prices = usePrices()
  const baseAssets = useContext(BaseAssetsConetext)

  const defiEdgeStrategiesQuery = useDefiEdgeStrategiesQuery()

  const { positions: manualPositions } = useV3ManualPositions(account)

  const ichiVaultAssets = useIchiVaultAssets()

  const { merklDataQuery } = useGetMerklDataQuery()

  const ichiAssetsReady = Boolean(ichiVaultAssets?.[0]?.secondAsset)

  const getPoolData = (merklPool) => {
    const apr = merklPool?.meanAPR ?? ZERO_VALUE
    const userTvl = merklPool?.userTVL ?? ZERO_VALUE

    const gaugeEarned = merklPool?.rewardsPerToken ?? {}
    const totalTvl = new BigNumber(merklPool?.tvl ?? ZERO_VALUE)

    const earnedUsd = gaugeEarned
      ? Object.entries(gaugeEarned).reduce((acc, [gaugeAddress, rewardData]) => {
          const price = prices[rewardData.symbol]

          if (price) {
            acc[gaugeAddress] = new BigNumber(price).times(rewardData.unclaimed)
          }

          return acc
        }, {})
      : {}

    const earnedUsdTotal = Object.values(earnedUsd).reduce((acc, earned) => earned.plus(acc), new BigNumber(0))

    const userTotalBalance0 = merklPool?.userTotalBalance0
    const userTotalBalance1 = merklPool?.userTotalBalance1

    return { apr, userTvl, totalTvl, earnedUsd: earnedUsdTotal, userTotalBalance0, gaugeEarned, userTotalBalance1 }
  }

  const ichiPools = useMemo(
    () =>
      (ichiAssetsReady ? ichiVaultAssets : [])
        .map((ichiVaultAsset) => {
          const merklPool = merklDataQuery.data?.pools?.[getAddress(ichiVaultAsset?.vault?.poolAddress)]

          const { apr, userTvl, totalTvl, earnedUsd, gaugeEarned, userTotalBalance0, userTotalBalance1 } =
            getPoolData(merklPool)

          return {
            ...ichiVaultAsset,
            fee: ichiVaultAsset.vault.feeTier * 10000,
            alm: 'ICHI',
            symbol: ichiVaultAsset.name,
            title: ichiVaultAsset.name,
            gauge: {
              apr: new BigNumber(apr),
            },
            token0: {
              name: ichiVaultAsset.name,
              symbol: ichiVaultAsset.symbol,
              price: ichiVaultAsset.price,
              decimals: ichiVaultAsset.decimals,
              address: ichiVaultAsset.address,
              logoURI: ichiVaultAsset.logoURI,
              balance: ichiVaultAsset.balance,
              chainId: ichiVaultAsset.chainId,
            },
            poolAddress: ichiVaultAsset.vault.poolAddress,
            token1: ichiVaultAsset?.secondAsset,
            account: {
              earnedUsd,
              gaugeEarned,
              totalUsd: new BigNumber(userTvl) ?? ZERO_VALUE,
              total0: new BigNumber(userTotalBalance0 ?? 0),
              total1: new BigNumber(userTotalBalance1 ?? 0),
              lpBalance: new BigNumber(userTvl) ?? ZERO_VALUE,
              gaugeBalance: ZERO_VALUE,
            },
            totalTvl: totalTvl,
            fullMerklData: merklPool,
          }
        })
        .filter((asset) => !asset.vault.disabled),
    [ichiVaultAssets],
  )

  const manualPools = useMemo(
    () =>
      (manualPositions ?? [])
        .map((position) => {
          const tokenA = baseAssets?.find((asset) => asset.address.toLowerCase() === position.token0.toLowerCase())
          const tokenB = baseAssets?.find((asset) => asset.address.toLowerCase() === position.token1.toLowerCase())

          if (!tokenA || !tokenB) return

          const poolAddress = computePoolAddress({
            factoryAddress: V3_CORE_FACTORY_ADDRESSES[ChainId.MAINNET],
            tokenA: toToken(tokenA),
            tokenB: toToken(tokenB),
            fee: position.fee,
            initCodeHashManualOverride: POOL_INIT_CODE_HASH,
          })

          const merklPool = merklDataQuery.data?.pools?.[poolAddress]

          const { apr, userTvl, totalTvl, earnedUsd, gaugeEarned, userTotalBalance0, userTotalBalance1 } =
            getPoolData(merklPool)

          // const apr = merklPool?.meanAPR ?? ZERO_VALUE
          // const userTvl = merklPool?.userTVL ?? ZERO_VALUE

          // const gaugeEarned = merklPool?.rewardsPerToken?.[contracts.option[ChainId.MAINNET]]?.unclaimed
          // const totalTvl = new BigNumber(merklPool?.tvl ?? ZERO_VALUE)

          // const earnedUsd = oRETROPrice && gaugeEarned ? new BigNumber(oRETROPrice).times(gaugeEarned) : ZERO_VALUE

          // const userTotalBalance0 = merklPool?.userTotalBalance0
          // const userTotalBalance1 = merklPool?.userTotalBalance1

          const poolName = `${tokenA.symbol}/${tokenB.symbol} (${position.fee / 10000})%`

          return {
            fee: position.fee,
            alm: 'MANUAL',
            symbol: poolName,
            title: poolName,
            gauge: {
              apr: new BigNumber(apr),
            },
            token0: tokenA,
            token1: tokenB,
            poolAddress,
            account: {
              earnedUsd,
              gaugeEarned,
              totalUsd: new BigNumber(userTvl) ?? ZERO_VALUE,
              total0: new BigNumber(userTotalBalance0 ?? 0),
              total1: new BigNumber(userTotalBalance1 ?? 0),
              lpBalance: new BigNumber(userTvl) ?? ZERO_VALUE,
              gaugeBalance: ZERO_VALUE,
            },
            totalTvl: totalTvl,
            fullMerklData: merklPool,
          }
        })
        .filter((pool) => pool !== undefined),
    [manualPositions],
  )

  const defiEdgePools = useMemo(
    () =>
      (defiEdgeStrategiesQuery.data ?? []).map((strategy) => {
        const merklPool = merklDataQuery.data?.pools?.[getAddress(strategy.pool)]

        const { apr, userTvl, totalTvl, earnedUsd, userTotalBalance0, gaugeEarned, userTotalBalance1 } =
          getPoolData(merklPool)

        const poolName = `${strategy.token0.symbol}/${strategy.token1.symbol} (${strategy.feeTier / 10000}%)`

        return {
          fee: Number(strategy.feeTier),
          alm: 'DefiEdge',
          symbol: poolName,
          title: poolName,
          gauge: {
            apr: new BigNumber(apr),
          },
          token0: strategy.token0,
          token1: strategy.token1,
          poolAddress: strategy.pool,
          account: {
            earnedUsd,
            gaugeEarned,
            totalUsd: new BigNumber(userTvl) ?? ZERO_VALUE,
            total0: new BigNumber(userTotalBalance0 ?? 0),
            total1: new BigNumber(userTotalBalance1 ?? 0),
            lpBalance: new BigNumber(userTvl) ?? ZERO_VALUE,
            gaugeBalance: ZERO_VALUE,
          },
          totalTvl: totalTvl,
          fullMerklData: merklPool,
        }
      }),
    [defiEdgeStrategiesQuery.data],
  )

  const pools = [...ichiPools, ...gammaPools, ...manualPools, ...defiEdgePools]

  return pools
}
