import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useERC20 } from './useContract'
import { ethers } from 'ethers'

export const useAllowance = (farm, contractAddress, forceUpdate) => {
  const [allowance, setAllowance] = useState(new BigNumber(-1))
  const { account, chainId } = useWeb3React()
  const fastRefresh = 1
  const lpAddress = farm?.lpAddresses[chainId]
  const contract = useERC20(lpAddress)

  useEffect(() => {
    const fetchAllowance = async () => {
      if (contract === null) setAllowance(new BigNumber(-1))
      else {
        console.log('callled')
        const res = await contract.methods.allowance(account, contractAddress).call()
        setAllowance(new BigNumber(res))
      }
    }
    if (account && lpAddress) {
      fetchAllowance()
    }
    console.log('callled 2')
  }, [account, contract, chainId, contractAddress, lpAddress, fastRefresh, forceUpdate])

  return allowance
}

export const useTokenAllowance = (token, spender, forceUpdate) => {
  const [allowance, setAllowance] = useState(new BigNumber(-1))
  const { account, chainId } = useWeb3React()

  useEffect(() => {
    const fetchAllowance = async () => {
      const tokenContract = new ethers.Contract(
        token,
        ['function allowance(address,address) view returns (uint256)'],
        ethers.getDefaultProvider('https://rpc-mainnet.maticvigil.com/'),
      )
      tokenContract.allowance(account, spender).then((newAllowance) => {
        setAllowance(newAllowance)
      })
    }
    if (account && token) {
      fetchAllowance()
    }
  }, [account, chainId, token, spender, forceUpdate])
  
  return allowance
}
