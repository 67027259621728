import React from 'react'

const VoteIcon = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_412_2291)'>
        <path
          d='M17.3333 12.7597H16.6816L14.7649 14.6763H16.5953L18.2916 16.593H4.87492L6.58075 14.6763H8.54534L6.62867 12.7597H5.83325L2.95825 15.6347V19.468C2.95825 20.5222 3.81117 21.3847 4.86533 21.3847H18.2916C19.3458 21.3847 20.2083 20.5318 20.2083 19.468V15.6347L17.3333 12.7597ZM18.2916 19.468H4.87492V18.5097H18.2916V19.468ZM10.9508 14.6955C11.3245 15.0693 11.9283 15.0693 12.302 14.6955L18.397 8.60051C18.7708 8.22676 18.7708 7.62301 18.397 7.24926L13.6533 2.50551C13.2891 2.12217 12.6853 2.12217 12.3116 2.49592L6.207 8.60051C5.83325 8.97426 5.83325 9.57801 6.207 9.95176L10.9508 14.6955ZM12.9824 4.52759L16.3749 7.92009L11.6312 12.6638L8.23867 9.27134L12.9824 4.52759Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_412_2291'>
          <rect width='100%' height='100%' fill='currentColor' transform='translate(0.083252 0.301758)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VoteIcon
