import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

const MobileDropDown = ({ options, sort, setSort }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={`max-w-[300px] w-full dropdownwrapper`}>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <div
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          className={`w-full flex items-center h-[42px] md:h-[52px] border border-white rounded-[3px] bg-componentSecondary bg-opacity-80 px-4 cursor-pointer`}
        >
          <div className={`w-full relative focus:outline-none py-2 bg-transparent rounded-[3px] text-white flex items-center justify-between`}>
            <p className='text-white'>{sort.label}</p>
            <img
              className={`${isOpen ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}
              alt=''
              src='/images/swap/dropdown-arrow.png'
            />
            {isOpen && (
              <div className='bg-componentPrimary w-full border z-[102] border-white -right-4 top-12 md:top-14 absolute p-3.5 rounded-[3px]'>
                {options.map((item, idx) => {
                  return (
                    <div
                      onClick={() => {
                        setSort(item)
                        setIsOpen(false)
                      }}
                      key={idx}
                      className='z-[10]'
                    >
                      <p className='text-white text-base md:text-lg leading-7 tracking-wide py-1'>{item.label}</p>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default MobileDropDown
