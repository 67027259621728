import { useQuery } from '@tanstack/react-query'
import { useWeb3React } from '@web3-react/core'
import Big from 'big.js'
import { ichiVaultAbi, pairAbi } from 'config/abi'

import { multicallFailSafe } from 'utils/multicall'
import { useIchiVaultAssets } from './useIchiVaultAssets'
import { chunkArr } from 'utils/chunkArray'

export const useUserIchiPositions = ({ enabled } = { enabled: true }) => {
  const { account } = useWeb3React()

  const ichiVaultAssets = useIchiVaultAssets()

  return useQuery(
    ['user-ichi-positions', account],
    async () => {
      try {
        const callsVaults = ichiVaultAssets.flatMap((asset) => [
          { name: 'balanceOf', address: asset.vault.vaultAddress, params: [account] },
          { name: 'totalSupply', address: asset.vault.vaultAddress },
          { name: 'getTotalAmounts', address: asset.vault.vaultAddress },
        ])

        const callsPairs = ichiVaultAssets.flatMap((asset) => [
          { name: 'token0', address: asset.vault.poolAddress },
          { name: 'token1', address: asset.vault.poolAddress },
        ])

        const responseVaults = await multicallFailSafe(ichiVaultAbi, callsVaults)
        const responsePairs = await multicallFailSafe(pairAbi, callsPairs)

        const chunksVaults = chunkArr(responseVaults, 3)

        const chunksPairs = chunkArr(responsePairs, 2)

        const result = ichiVaultAssets.map((asset, index) => {
          const [[share], [totalSupply], { total0, total1 }] = chunksVaults[index]

          const [[token0], [token1]] = chunksPairs[index]

          const balance0 = Big(share.toString()).div(Big(totalSupply.toString())).mul(total0.toString())
          const balance1 = Big(share.toString()).div(Big(totalSupply.toString())).mul(total1.toString())

          return {
            ...asset,
            share: Big(share.toString()),
            balances: {
              [token0?.toLowerCase()]: balance0,
              [token1?.toLowerCase()]: balance1,
            },
          }
        })

        return result.filter((vault) => Object.values(vault.balances)[0].gt(0))
      } catch (error) {
        console.error(error)
      }
    },
    {
      enabled: enabled && Boolean(account) && ichiVaultAssets.length > 0 && Boolean(ichiVaultAssets[0].logoURI),
    },
  )
}
