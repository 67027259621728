import './partners.scss'

const partners = [
  { image: '/images/retro/partners/thena.png' },
  { image: '/images/retro/partners/polygon.png' },
  { image: '/images/retro/partners/angle.png' },
  { image: '/images/retro/partners/gamma.png' },
  { image: '/images/retro/partners/stader.png' },
  { image: '/images/retro/partners/qidao.png' },
  { image: '/images/retro/partners/ichi.png' },
  { image: '/images/retro/partners/tetu.png' },
  { image: '/images/retro/partners/paintswap.png' },
  { image: '/images/retro/partners/frax.png' },
  { image: '/images/retro/partners/fbomb.png' },
  { image: '/images/retro/partners/ethos.png' },
  { image: '/images/retro/partners/liquiddriver.png' },
  { image: '/images/retro/partners/okse.png' },
  { image: '/images/retro/partners/sphere.png' },
  { image: '/images/retro/partners/lido.png' },
  { image: '/images/retro/partners/stablefi.png' },
  { image: '/images/retro/partners/beefy.png' },
  { image: '/images/retro/partners/coin98.png' },
  { image: '/images/retro/partners/jarvis.png' },
  { image: '/images/retro/partners/kyber.png' },
]

const Partners = () => {
  return (
    <div className='partners'>
      <div className='heading'>
        <h2 className='title'>OUR PARTNERS</h2>
        <p className='subtitle'>Control RETRO's destiny by locking into veRETRO.</p>
      </div>
      <div className='pb-14 flex flex-row max-w-3xl justify-center mx-auto flex-wrap gap-8'>
        {partners.map((partner, idx) => {
          return <img key={idx} src={partner.image} alt='' className='w-24 h-24' />
        })}
      </div>
      <div className='background'></div>
    </div>
  )
}

export default Partners
