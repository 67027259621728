import React from 'react'
import Spinner from '../../../../../common/Spinner'
import { PartnerItem } from './PartnerItem'
import { v3LiquidityRangeType } from 'v3lib/entities/constants'
import { useGetAllLiquidityPools } from 'hooks/v3/useGetAllLiquidityPools'
import { useDefiEdgeStrategiesQuery } from 'hooks/queries/useDefiEdgeStrategiesQuery'

const PartnersList = ({
  gammaPairs,
  ichiAsset,
  loading,
  baseCurrency,
  quoteCurrency,
  selectRangeType,
  selectedLiquidityRangeType,
}) => {
  const gammaPair = gammaPairs?.[0]

  const gammaPoolDisabled = !gammaPair?.gammaEnabled

  const allPools = useGetAllLiquidityPools()

  const pool = allPools?.find(
    (pool) =>
      (pool?.token0?.symbol.toLowerCase() === baseCurrency?.symbol.toLowerCase() &&
        pool?.token1?.symbol.toLowerCase() === quoteCurrency?.symbol.toLowerCase()) ||
      (pool?.token1?.symbol.toLowerCase() === quoteCurrency?.symbol.toLowerCase() &&
        pool?.token0?.symbol.toLowerCase() === baseCurrency?.symbol.toLowerCase()),
  )

  const baseCurrencyAddress = baseCurrency?.address?.toLowerCase()
  const quoteCurrencyAddress = quoteCurrency?.address?.toLowerCase()

  const defiEdgeStategies = useDefiEdgeStrategiesQuery()

  const defiEdgeStrategy = defiEdgeStategies.data?.find((strategy) => {
    return (
      (strategy?.token0?.address?.toLowerCase() === baseCurrencyAddress &&
        strategy?.token1?.address?.toLowerCase() === quoteCurrencyAddress) ||
      (strategy?.token0?.address?.toLowerCase() === quoteCurrencyAddress &&
        strategy?.token1?.address?.toLowerCase() === baseCurrencyAddress)
    )
  })

  const getStatsData = (alm) => {
    const aprKeys = Object.keys(pool?.fullMerklData?.aprs ?? {}).filter((key) => {
      const almKey = key?.split(' ')[0]?.toLowerCase()

      return almKey === alm?.toLowerCase()
    })

    const getApr = () => {
      switch (alm) {
        case 'ICHI': {
          const aprs = aprKeys.reduce((acc, aprKey) => {
            const [alm, vaultAddress] = aprKey?.split(' ')

            acc[vaultAddress] = { alm, vaultAddress, apr: pool?.fullMerklData?.aprs?.[aprKey] }

            return acc
          }, {})

          return aprs?.[ichiAsset?.vault?.vaultAddress?.toLowerCase() ?? '']?.apr
        }

        default:
          return pool?.fullMerklData?.aprs[aprKeys[0]]
      }
    }

    return { apr: getApr() }
  }

  if (loading)
    return (
      <div className='flex justify-center items-center'>
        <Spinner />
      </div>
    )

  return (
    <>
      <h3 className='text-center w-full text-[25px] font-semibold text-white m-0 mb-9 mt-8'>
        Step 2 - Select ALM Partner
      </h3>
      <ul className='list-none m-0 p-0 grid grid-cols-1 2xl:grid-cols-2 gap-2.5'>
        <PartnerItem
          description='Single Deposit Positions'
          onSelect={() => selectRangeType(v3LiquidityRangeType.ICHI_RANGE)}
          active={selectedLiquidityRangeType === v3LiquidityRangeType.ICHI_RANGE}
          logoUrl='/images/liquidity/ichi.svg'
          url='https://ichi.org/'
          apr={getStatsData('ICHI').apr}
          tvl={0}
        />
        <PartnerItem
          description='Gamma Managed Positions'
          disabled={gammaPoolDisabled}
          onSelect={() => selectRangeType(v3LiquidityRangeType.GAMMA_RANGE)}
          active={selectedLiquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE}
          logoUrl='/images/liquidity/gamma-red.png'
          url='https://www.gamma.xyz/'
          apr={getStatsData('GAMMA').apr}
          tvl={0}
        />
        <PartnerItem
          description='DefiEdge Managed Positions'
          disabled={!defiEdgeStrategy}
          onSelect={() => selectRangeType(v3LiquidityRangeType.DEFI_EDGE)}
          active={selectedLiquidityRangeType === v3LiquidityRangeType.DEFI_EDGE}
          logoUrl='/images/liquidity/defiedge-logo.png'
          url='https://retro.defiedge.io/'
          apr={getStatsData('DEFIEDGE').apr}
          tvl={0}
        />
      </ul>

      <div className='mt-6 flex items-center'>
        <a href='https://merkl.angle.money/' target='_blank' rel='noreferrer'>
          <img alt='Powered By' src='/images/liquidity/powered-by-merkl.png' />
        </a>
      </div>
    </>
  )
}

export default PartnersList
