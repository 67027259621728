import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { v4 as uuidv4 } from 'uuid'
import { TransactionType } from '../../config/constants'
import { useV3MintActionHandlers } from '../../state/mintV3/hooks'
import useWeb3 from '../useWeb3'
import { completeTransaction, openTransaction, updateTransaction } from '../../state/transactions/actions'
import { getERC20Contract, getWBNBContract } from '../../utils/contractHelpers'
import { getAllowance, sendContract } from '../../utils/api'
import { formatAmount, fromWei } from '../../utils/formatNumber'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import { approveStrategyToken, depositLP } from '@defiedge/sdk'
import { useDefiEdgeStrategiesQuery } from 'hooks/queries/useDefiEdgeStrategiesQuery'
import { useDefiEdgeLiquidityRatio } from 'hooks/queries/useDefiEdgeLiquidityRatio'
import Big from 'big.js'
import { customNotify } from 'utils/notify'

const useDefiEdgeAdd = (baseCurrency, quoteCurrency) => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const { library } = useActiveWeb3React()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers()

  const defiEdgeStrategiesQuery = useDefiEdgeStrategiesQuery()

  const baseCurrencyAddress = baseCurrency?.address ? baseCurrency?.address?.toLowerCase() : ''
  const quoteCurrencyAddress = quoteCurrency?.address ? quoteCurrency?.address?.toLowerCase() : ''

  const defiEdgeStrategy = defiEdgeStrategiesQuery.data?.find((strategy) => {
    return (
      (strategy?.token0?.address?.toLowerCase() === baseCurrencyAddress &&
        strategy?.token1?.address?.toLowerCase() === quoteCurrencyAddress) ||
      (strategy?.token0?.address?.toLowerCase() === quoteCurrencyAddress &&
        strategy?.token1?.address?.toLowerCase() === baseCurrencyAddress)
    )
  })

  const strategyAddress = defiEdgeStrategy?.id
  const liquidityRatioQuery = useDefiEdgeLiquidityRatio(strategyAddress)
  const liquidityRatio = liquidityRatioQuery.data

  const handleDefiEdgeAdd = async (amountA, amountB, amountToWrap) => {
    if ((!amountA && !amountB) || !strategyAddress || liquidityRatio === undefined) return

    const key = uuidv4()
    const wrapuuid = uuidv4()
    const approve0uuid = uuidv4()
    const approve1uuid = uuidv4()
    const supplyuuid = uuidv4()
    setPending(true)

    dispatch(
      openTransaction({
        key,
        title: `Add ${baseCurrency.symbol}/${quoteCurrency.symbol} liquidity`,
        transactions: {
          ...(amountToWrap
            ? {
                [wrapuuid]: {
                  desc: `Wrap ${formatAmount(fromWei(amountToWrap.toString(10)))} MATIC for WMATIC`,
                  status: TransactionType.WAITING,
                  hash: null,
                },
              }
            : {}),
          [approve0uuid]: {
            desc: `Approve ${baseCurrency.symbol}`,
            status: amountToWrap ? TransactionType.START : TransactionType.WAITING,
            hash: null,
          },
          [approve1uuid]: {
            desc: `Approve ${quoteCurrency.symbol}`,
            status: TransactionType.START,
            hash: null,
          },
          [supplyuuid]: {
            desc: `Deposit tokens in the pool`,
            status: TransactionType.START,
            hash: null,
          },
        },
      }),
    )

    if (amountToWrap) {
      const wbnbContract = getWBNBContract(web3)
      try {
        await sendContract(dispatch, key, wrapuuid, wbnbContract, 'deposit', [], account, amountToWrap.toString(10))
      } catch (err) {
        console.log('wrap error :>> ', err)
        setPending(false)
        return
      }
    }

    let isApproved = true
    const baseTokenContract = getERC20Contract(web3, baseCurrencyAddress)
    const baseAllowance = await getAllowance(baseTokenContract, strategyAddress, account)

    if (amountA && fromWei(baseAllowance, baseCurrency.decimals).lt(amountA.toExact())) {
      isApproved = false

      try {
        const txnDetails = await approveStrategyToken(
          account,
          0, // token idx can be 0 or 1
          strategyAddress,
          library,
        )

        await txnDetails.wait()
      } catch (err) {
        console.log('approve 0 error :>> ', err)
        setPending(false)
        return
      }
    }
    if (isApproved) {
      dispatch(
        updateTransaction({
          key,
          uuid: approve0uuid,
          status: TransactionType.SUCCESS,
        }),
      )
    }
    isApproved = true

    const quoteTokenContract = getERC20Contract(web3, quoteCurrencyAddress)
    const quoteAllowance = await getAllowance(quoteTokenContract, strategyAddress, account)

    if (amountB && fromWei(quoteAllowance, quoteCurrency.decimals).lt(amountB.toExact())) {
      isApproved = false
      try {
        const txnDetails = await approveStrategyToken(account, 1, strategyAddress, library)

        await txnDetails.wait()
      } catch (err) {
        console.log('approve 0 error :>> ', err)
        setPending(false)
        return
      }
    }
    if (isApproved) {
      dispatch(
        updateTransaction({
          key,
          uuid: approve1uuid,
          status: TransactionType.SUCCESS,
        }),
      )
    }

    let hash
    try {
      const amount0 = amountA?.numerator
        ? Big(amountA.numerator.toString())
            .div(10 ** baseCurrency.decimals)
            .toString()
        : 0

      const amount1 = amountB?.numerator
        ? Big(amountB.numerator.toString())
            .div(10 ** baseCurrency.decimals)
            .toString()
        : 0

      dispatch(
        updateTransaction({
          key,
          supplyuuid,
          status: TransactionType.WAITING,
        }),
      )

      const txnDetails = await depositLP(account, amount0, amount1, strategyAddress, library)
      hash = txnDetails.hash

      dispatch(
        updateTransaction({
          key,
          supplyuuid,
          status: TransactionType.PENDING,
          hash,
        }),
      )

      await txnDetails.wait()

      dispatch(
        updateTransaction({
          key,
          supplyuuid,
          status: TransactionType.SUCCESS,
          hash,
        }),
      )

      customNotify('Transaction Successful!', 'success', hash)
    } catch (err) {
      console.error('supply error :>> ', err)

      dispatch(
        updateTransaction({
          key,
          supplyuuid,
          status: TransactionType.FAILED,
          hash,
        }),
      )
      customNotify(err.message, 'error')

      setPending(false)
      return
    }

    onFieldAInput('')
    onFieldBInput('')
    dispatch(
      completeTransaction({
        key,
        final: 'Liquidity Added',
      }),
    )
    setPending(false)
  }

  return { handleDefiEdgeAdd, pending }
}

export { useDefiEdgeAdd }
