import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import TokenInput from '../../common/Input/TokenInput'
// import Toggle from '../../common/Toggle'
import StyledButton from '../../common/Buttons/styledButton'
import { formatAmount, isInvalidAmount } from '../../../utils/formatNumber'
import { customNotify } from '../../../utils/notify'
import { useAddLiquidity } from '../../../hooks/useLiquidity'
import BigNumber from 'bignumber.js'
import LiquidityDetails from './liquidityDetails'
import useWalletModal from '../../../hooks/useWalletModal'
import { getWBNBAddress } from '../../../utils/addressHelpers'
import { defaultChainId } from 'config/constants'

const getAddress = (asset) => {
  if (asset.address === 'MATIC') {
    return getWBNBAddress().toLowerCase()
  }
  return asset.address.toLowerCase()
}

let previousPair

const AddLiquidity = ({ slippage, deadline, pairAddress, pairs }) => {
  const [firstAmount, setFirstAmount] = useState('')
  const [firstAsset, setFirstAsset] = useState()
  const [secondAmount, setSecondAmount] = useState('')
  const [secondAsset, setSecondAsset] = useState()
  const [stable, setStable] = useState(false)
  const [init, setInit] = useState(false)
  // const [options, setoptions] = useState(false)
  const { account, chainId } = useWeb3React()
  const { openWalletModal } = useWalletModal()
  const { onAdd } = useAddLiquidity()
  const baseAssets = useContext(BaseAssetsConetext)

  useEffect(() => {
    if (!pairAddress) {
      if (!firstAsset) {
        setFirstAsset(baseAssets.find((asset) => asset.symbol === 'MATIC'))
      }
      if (!secondAsset) {
        setSecondAsset(baseAssets.find((asset) => asset.symbol === 'RETRO'))
      }
    } else if (pairs && pairs.length > 0 && !init) {
      const item = pairs.find((ele) => ele.address.toLowerCase() === pairAddress)
      setFirstAsset(
        baseAssets.find((asset) =>
          item.token0.address.toLowerCase() === getWBNBAddress().toLowerCase()
            ? asset.symbol === 'MATIC'
            : asset.address.toLowerCase() === item.token0.address.toLowerCase(),
        ),
      )
      setSecondAsset(
        baseAssets.find((asset) =>
          item.token1.address.toLowerCase() === getWBNBAddress().toLowerCase()
            ? asset.symbol === 'MATIC'
            : asset.address.toLowerCase() === item.token1.address.toLowerCase(),
        ),
      )
      setStable(item.stable)
      setInit(true)
    }
  }, [baseAssets, pairAddress, pairs])

  useEffect(() => {
    if (firstAsset) {
      setFirstAsset(baseAssets.find((asset) => asset.symbol === firstAsset.symbol))
    }
    if (secondAsset) {
      setSecondAsset(baseAssets.find((asset) => asset.symbol === secondAsset.symbol))
    }
  }, [baseAssets, firstAsset, secondAsset])

  const pair = useMemo(() => {
    if (pairs && pairs.length > 0 && firstAsset && secondAsset) {
      return pairs.find(
        (item) =>
          [getAddress(firstAsset), getAddress(secondAsset)].includes(getAddress(item.token0)) &&
          [getAddress(firstAsset), getAddress(secondAsset)].includes(getAddress(item.token1)) &&
          item.stable === stable &&
          !item.isGamma,
      )
    } else {
      return null
    }
  }, [firstAsset, secondAsset, pairs, stable])

  const isReverse = useMemo(() => {
    if (pair && firstAsset) {
      return getAddress(pair.token1) === getAddress(firstAsset)
    }
    return false
  }, [pair, firstAsset])

  const onFirstChange = useCallback(
    (val) => {
      setFirstAmount(new BigNumber(val).dp(firstAsset?.decimals || 18))
      if (pair) {
        const firstReserve = isReverse ? pair.token1.reserve : pair.token0.reserve
        const secondReserve = isReverse ? pair.token0.reserve : pair.token1.reserve
        setSecondAmount(val ? secondReserve.times(val).div(firstReserve).dp(secondAsset.decimals).toString(10) : '')
      }
    },
    [isReverse, pair, setFirstAmount, setSecondAmount],
  )

  const onSecondChange = useCallback(
    (val) => {
      setSecondAmount(new BigNumber(val).dp(secondAsset?.decimals || 18))
      if (pair) {
        const firstReserve = isReverse ? pair.token1.reserve : pair.token0.reserve
        const secondReserve = isReverse ? pair.token0.reserve : pair.token1.reserve
        setFirstAmount(val ? firstReserve.times(val).div(secondReserve).dp(firstAsset.decimals).toString(10) : '')
      }
    },
    [isReverse, pair, setFirstAmount, setSecondAmount],
  )

  const errorMsg = useMemo(() => {
    if (!firstAsset || !secondAsset) {
      return `Invalid Asset`
    }
    if (isInvalidAmount(firstAmount) || isInvalidAmount(secondAmount)) {
      return `Invalid Amount`
    }
    if (firstAsset.balance.lt(firstAmount)) {
      return 'Insufficient ' + firstAsset.symbol + ' Balance'
    }
    if (secondAsset.balance.lt(secondAmount)) {
      return 'Insufficient ' + secondAsset.symbol + ' Balance'
    }
    return null
  }, [firstAmount, secondAmount, firstAsset, secondAsset])

  // const onAddAndStakeLiquidity = useCallback(() => {
  //   if (errorMsg) {
  //     customNotify(errorMsg, 'warn')
  //     return
  //   }
  //   onAddAndStake(pair, firstAsset, secondAsset, firstAmount, secondAmount, stable, slippage, deadline)
  // }, [pair, firstAsset, secondAsset, firstAmount, secondAmount, stable, slippage, deadline])

  const onAddLqiduity = useCallback(() => {
    if (errorMsg) {
      customNotify(errorMsg, 'warn')
      return
    }
    onAdd(firstAsset, secondAsset, firstAmount, secondAmount, stable, slippage, deadline)
  }, [firstAsset, secondAsset, firstAmount, secondAmount, stable, slippage, deadline])

  useEffect(() => {
    if (pair) {
      if (previousPair !== pair.address) {
        previousPair = pair.address
        const firstReserve = isReverse ? pair.token1.reserve : pair.token0.reserve
        const secondReserve = isReverse ? pair.token0.reserve : pair.token1.reserve
        if (firstAmount && secondAmount) {
          setSecondAmount(secondReserve.times(firstAmount).div(firstReserve).dp(secondAsset.decimals).toString(10))
        }

        if (!firstAmount && secondAmount) {
          setFirstAmount(firstReserve.times(secondAmount).div(secondReserve).dp(firstAsset.decimals).toString(10))
        }

        if (firstAmount && !secondAmount) {
          setSecondAmount(secondReserve.times(firstAmount).div(firstReserve).dp(secondAsset.decimals).toString(10))
        }
      } else {
        previousPair = pair.address
      }
    } else {
      previousPair = undefined
    }
  }, [pair, isReverse])

  return (
    <>
      <div className='mt-7 mx-auto mb-7 border border-white rounded-[10px] max-w-[263px] w-full f-f-fg text-[13px] md:text-sm tracking-[0.56px] h-[34px] md:h-[38px]'>
        <button
          onClick={() => {
            setStable(true)
          }}
          className={`${
            stable ? 'bg-white text-black font-medium' : 'text-dimGray'
          } w-1/2 rounded-[10px] transition-all h-full`}
        >
          STABLE
        </button>
        <button
          onClick={() => {
            setStable(false)
          }}
          className={`${
            !stable ? 'bg-white text-black font-medium' : 'text-dimGray'
          } w-1/2 rounded-[10px] transition-all h-full uppercase`}
        >
          Volatile
        </button>
      </div>
      <div className='mt-3 md:mt-[14px]'>
        <div className={`flex flex-col w-full items-center justify-center `}>
          <TokenInput
            title='Input'
            asset={firstAsset}
            setAsset={setFirstAsset}
            otherAsset={secondAsset}
            setOtherAsset={setSecondAsset}
            amount={firstAmount}
            onInputChange={(e) => onFirstChange(e)}
          />
          <div className='my-5 z-[8]'>
            <img src='/images/liquidity/plus.svg' />
          </div>
          <TokenInput
            title='Input'
            asset={secondAsset}
            setAsset={setSecondAsset}
            otherAsset={firstAsset}
            setOtherAsset={setFirstAsset}
            amount={secondAmount}
            onInputChange={(e) => onSecondChange(e)}
          />
        </div>
      </div>
      {pair ? (
        <LiquidityDetails pair={pair} slippage={slippage} />
      ) : (
        firstAsset &&
        secondAsset && (
          <div className='mt-4'>
            <div className='text-white text-sm md:text-base font-medium pb-1 border-b border-[#757384]'>
              Starting Liquidity Info
            </div>
            <div className='flex justify-around mt-4 w-full'>
              <div className='flex flex-col items-center justify-between'>
                <p className='text-white text-sm md:text-base leading-5 font-medium'>
                  {firstAmount && secondAmount && !new BigNumber(secondAmount).isZero()
                    ? formatAmount(firstAmount / secondAmount)
                    : '0'}
                </p>
                <p className='text-white text-sm md:text-base leading-5'>
                  {firstAsset.symbol} per {secondAsset.symbol}
                </p>
              </div>
              <div className='flex flex-col items-center justify-between'>
                <p className='text-white text-sm md:text-base leading-5 font-medium'>
                  {firstAmount && secondAmount && !new BigNumber(firstAmount).isZero()
                    ? formatAmount(secondAmount / firstAmount)
                    : '0'}
                </p>
                <p className='text-white text-sm md:text-base leading-5'>
                  {secondAsset.symbol} per {firstAsset.symbol}
                </p>
              </div>
            </div>
          </div>
        )
      )}
      {/* <div className='flex items-center justify-end space-x-2 mt-5 font-light'>
      <p className='text-white text-sm md:text-base leading-5'>More Options</p>
      <Toggle
        toggleId='options'
        rounded={false}
        onChange={(e) => {
          setoptions(e.target.checked)
        }}
        small={true}
      />
    </div> */}
      {account && chainId === defaultChainId ? (
        <div className='mt-8'>
          {/* {pair && pair.gauge.address !== ZERO_ADDRESS && (
            <StyledButton
              onClickHandler={onAddAndStakeLiquidity}
              content={'ADD LIQUIDITY & STAKE LP'}
              className='py-[13px] md:py-[14.53px] mb-7 text-base md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-[3px]'
            />
          )} */}
          <StyledButton
            onClickHandler={onAddLqiduity}
            content={'ADD LIQUIDITY'}
            className='py-[13px] md:py-[14.53px] text-base md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-[3px]'
          />
        </div>
      ) : (
        <StyledButton
          onClickHandler={openWalletModal}
          content={'CONNECT WALLET'}
          className='py-[13px] md:py-[14.53px] mt-4 text-base md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-[3px]'
        />
      )}
    </>
  )
}

export default AddLiquidity
