import React from 'react'

const RewardIcon = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.8258 7.51855H3.63175C3.14181 7.51855 2.74463 7.96538 2.74463 8.51657V10.5126C2.74463 11.0638 3.14181 11.5106 3.63175 11.5106H17.8258C18.3157 11.5106 18.7129 11.0638 18.7129 10.5126V8.51657C18.7129 7.96538 18.3157 7.51855 17.8258 7.51855Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10.7288 7.51855V19.4948' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M17.3821 11.5107V17.7206C17.3821 18.1912 17.1818 18.6425 16.8253 18.9752C16.4688 19.3079 15.9853 19.4949 15.4811 19.4949H5.97618C5.47201 19.4949 4.98848 19.3079 4.63198 18.9752C4.27548 18.6425 4.0752 18.1912 4.0752 17.7206V11.5107'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.45142 7.51843C5.82121 7.51843 5.21681 7.30816 4.77118 6.93386C4.32555 6.55956 4.0752 6.05191 4.0752 5.52257C4.0752 4.99324 4.32555 4.48559 4.77118 4.11129C5.21681 3.73699 5.82121 3.52672 6.45142 3.52672C7.36835 3.5133 8.26689 3.88698 9.02986 4.59902C9.79283 5.31106 10.3848 6.32842 10.7286 7.51843C11.0725 6.32842 11.6644 5.31106 12.4274 4.59902C13.1904 3.88698 14.0889 3.5133 15.0059 3.52672C15.6361 3.52672 16.2405 3.73699 16.6861 4.11129C17.1317 4.48559 17.3821 4.99324 17.3821 5.52257C17.3821 6.05191 17.1317 6.55956 16.6861 6.93386C16.2405 7.30816 15.6361 7.51843 15.0059 7.51843'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default RewardIcon
