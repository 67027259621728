import React from 'react'

const CloseModalIcon = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='100%' height='100%' rx='16' fill='#2A2A2A' />
      <path d='M22 10L10 22' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 10L22 22' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default CloseModalIcon
