import Header from '../retro/header/index.js'
import Lead from '../retro/lead/index.js'
import Content from '../retro/content/index.js'
import Footer from '../retro/footer/index.js'

import './home.scss'

const Home = () => {
  return (
    <>
      <Header />
      <Lead />
      <Content />
      <Footer />
    </>
  )
}

export default Home
