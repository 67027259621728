import React from 'react'

const LockIcon = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_412_2283)'>
        <path
          d='M16.375 11.1357H6.79167C5.73312 11.1357 4.875 11.9939 4.875 13.0524V18.8024C4.875 19.861 5.73312 20.7191 6.79167 20.7191H16.375C17.4335 20.7191 18.2917 19.861 18.2917 18.8024V13.0524C18.2917 11.9939 17.4335 11.1357 16.375 11.1357Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.5833 16.8854C12.1126 16.8854 12.5417 16.4564 12.5417 15.9271C12.5417 15.3978 12.1126 14.9688 11.5833 14.9688C11.0541 14.9688 10.625 15.3978 10.625 15.9271C10.625 16.4564 11.0541 16.8854 11.5833 16.8854Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.75 11.1354V7.30208C7.75 6.28542 8.15387 5.3104 8.87276 4.59151C9.59165 3.87262 10.5667 3.46875 11.5833 3.46875C12.6 3.46875 13.575 3.87262 14.2939 4.59151C15.0128 5.3104 15.4167 6.28542 15.4167 7.30208V11.1354'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_412_2283'>
          <rect width='100%' height='100%' fill='currentColor' transform='translate(0.083252 0.59375)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LockIcon
