import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getTHEAddress } from '../../../utils/addressHelpers'
import { formatAmount } from '../../../utils/formatNumber'
import Modal from '../Modal'
import NoFound from '../NoFound'
import { useTokenBalances } from 'hooks/useTokenBalance'
import { ethers } from 'ethers'

const usdcAddress = '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
const usdtAddress = '0x55d398326f99059fF775485246999027B3197955'
const ethAddress = '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
const btcAddress = '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
const busdAddress = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
const fraxAddress = '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40'
const cashAddress = '0x5D066D022EDE10eFa2717eD3D79f22F949F8C175'
const theAddress = getTHEAddress()

const SearchTokenPopup = ({
  popup,
  setPopup,
  selectedAsset,
  setSelectedAsset,
  otherAsset,
  setOtherAsset,
  baseAssets,
  hideCommon,
  onAssetSelect = () => {},
}) => {
  const balanceAssets = useMemo(() => baseAssets.map((item) => item.address), [baseAssets])

  const balances = useTokenBalances(balanceAssets)

  const [manage, setManage] = useState(false)
  const [searchText, setSearchText] = useState('')
  const { account } = useWeb3React()
  const inputRef = useRef()

  const filteredAssets = searchText
    ? baseAssets.filter((asset) => {
        return (
          asset?.name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          asset?.symbol?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          asset?.address?.toLowerCase()?.includes(searchText.toLowerCase())
        )
      })
    : baseAssets

  const commonAssets = useMemo(() => {
    return baseAssets.filter(
      (asset) =>
        asset.address === 'MATIC' ||
        [
          theAddress?.toLowerCase(),
          usdcAddress.toLowerCase(),
          usdtAddress.toLowerCase(),
          ethAddress.toLowerCase(),
          fraxAddress.toLowerCase(),
          btcAddress.toLowerCase(),
          busdAddress.toLowerCase(),
          cashAddress.toLowerCase(),
        ].includes(asset?.address?.toLowerCase()),
    )
  }, [baseAssets])

  useEffect(() => {
    if (!popup) {
      setSearchText('')
    } else {
      setTimeout(() => {
        inputRef.current && inputRef.current.focus()
      }, 300)
    }
  }, [popup])

  return (
    <Modal
      popup={popup}
      setPopup={setPopup}
      title={manage ? 'Manage Tokens' : 'Select a Token'}
      isBack={manage}
      setIsBack={setManage}
      width={540}
      isToken={true}
    >
      <>
        <div className='w-full'>
          <div className='px-3 md:px-6'>
            <div className='border-2 border-solid border-white w-full mt-3 rounded-[3px]'>
              <input
                ref={inputRef}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder='Search by name, symbol or address'
                className='bg-black placeholder-placeholder h-14 w-full text-white text-base md:text-lg px-4 py-[18px] rounded-[3px]'
              />
            </div>
          </div>

          {!hideCommon && (
            <div className='w-full mt-3.5 md:mt-[18px] px-3 md:px-6'>
              <p className='text-[13px] md:text-sm tracking-[0.52px] md:tracking-[0.56px] f-f-fg text-dimGray mb-1'>
                COMMON TOKENS
              </p>
              <div className='flex flex-wrap md:-mx-3'>
                {commonAssets.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`px-2 md:px-3 cursor-pointer py-1 md:py-1.5 border m-[5px] flex items-center gap-x-2.5 border-white rounded-xl hover:bg-componentSecondary`}
                      onClick={() => {
                        if (otherAsset && otherAsset.address === item.address) {
                          const temp = selectedAsset
                          setSelectedAsset(otherAsset)
                          setOtherAsset(temp)
                        } else {
                          setSelectedAsset(item)
                        }
                        onAssetSelect()
                        setPopup(false)
                      }}
                    >
                      <img alt='' src={item.logoURI} width={28} height={28} loading='lazy' />
                      <p className='text-sm md:text-base text-white f-f-fg'>{item.symbol}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <div className='w-full mt-3'>
          <div className='flex justify-between text-[13px] md:text-sm tracking-[0.52px] md:tracking-[0.56px] f-f-fg text-dimGray mb-1 px-3 md:px-6'>
            <span>TOKEN NAME</span>
            {account && <span>BALANCE</span>}
          </div>
          <div className='w-full mt-3 md:mt-[13px] max-h-[340px] overflow-auto'>
            {filteredAssets.length > 0 ? (
              filteredAssets.map((asset, idx) => {
                return (
                  <div
                    key={`asset-${idx}`}
                    className={`flex items-center justify-between py-[6px] px-3 md:px-6 cursor-pointer hover:bg-componentSecondary`}
                    onClick={() => {
                      if (otherAsset && otherAsset.address === asset.address) {
                        const temp = selectedAsset
                        setSelectedAsset(otherAsset)
                        setOtherAsset(temp)
                      } else {
                        setSelectedAsset(asset)
                      }
                      onAssetSelect()
                      setPopup(false)
                    }}
                  >
                    <div className='flex items-center space-x-2.5 md:space-x-3'>
                      <img alt='' src={asset.logoURI} className='flex-shrink-0' width={28} height={28} loading='lazy' />
                      <div className=''>
                        <p className='text-white text-sm md:text-base f-f-fg'>{asset.symbol}</p>
                        <p className='text-[13px] md:text-sm tracking-[0.52px] text-dimGray'>{asset.name}</p>
                      </div>
                    </div>
                    {account && balances && balances[asset.address] && (
                      <p className='text-sm md:text-base text-white'>
                        {formatAmount(ethers.utils.formatUnits(balances[asset.address], asset.decimals)) || ''}
                      </p>
                    )}
                  </div>
                )
              })
            ) : (
              <NoFound title='No tokens found' />
            )}
          </div>
          {/* <div className='flex items-center justify-center w-full pt-[0.775rem]'>
              <button
                onClick={() => {
                  setManage(true)
                }}
                className='text-sm md:text-[17px] text-green text-center'
              >
                Manage Tokens
              </button>
            </div> */}
        </div>
      </>
    </Modal>
  )
}

export default SearchTokenPopup
