import { useContext, useMemo } from 'react'
import { Token } from '@uniswap/sdk-core'
import { ChainId } from 'config/constants'
import { STABLE_TOKENS } from '../../config/constants'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'

export const useStableAssets = () => {
  const baseAssets = useContext(BaseAssetsConetext)
  return useMemo(() => {
    return baseAssets.length > 0
      ? baseAssets
          .filter((item) => Object.values(STABLE_TOKENS).find((asset) => asset.toLowerCase() === item.address.toLowerCase()))
          .map((stable) => new Token(ChainId.MAINNET, stable.address, stable.decimals, stable.symbol, stable.name))
      : []
  }, [baseAssets])
}
