import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background-color: ${({ disabled }) => (disabled ? 'unset' : '#E1E1E1')};
`
const StyledButton = ({ content, className, onClickHandler = null, disabled, pending = false }) => {
  return (
    <Button
      disabled={disabled}
      role={'button'}
      aria-disabled={disabled}
      onClick={(e) => {
        if (!disabled && !pending && onClickHandler) {
          onClickHandler(e.target.value)
        }
      }}
      className={`transition-all duration-300 ease-in-out !font-semibold ${className ? ' ' + className : ''}${
        disabled ? ' !bg-white !bg-opacity-[0.33] !text-black cursor-not-allowed hover:bg-grayHover' : ''
      } `}
    >
      {pending ? 'PENDING...' : content}
    </Button>
  )
}

export default StyledButton
