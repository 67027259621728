import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import getNodeUrl from './getRpcUrl'
import { WalletConnectConnectorV2 } from './walletConnectConnectorV2'

const rpcUrl = getNodeUrl()
const defaultChainId = process.env.REACT_APP_CHAIN_ID

const supportedChainIds = [Number(defaultChainId), 1, 250, 10, 42161, 56, 43114]

export const injected = new InjectedConnector({
  supportedChainIds,
})

// const walletconnect = new WalletConnectConnector({
//   rpc: { [defaultChainId]: rpcUrl },
//   qrcode: true,
//   pollingInterval: POLLING_INTERVAL,
// })

const walletconnectV2 = new WalletConnectConnectorV2({
  chains: [Number(defaultChainId)],
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
  showQrModal: true,
  qrModalOptions: {
    themeVariables: {
      '--wcm-z-index': '9999',
    },
  },
})

const walletlink = new WalletLinkConnector({
  url: rpcUrl,
  appName: 'Thena',
  supportedChainIds: supportedChainIds,
})

const binanceChainWalletConnector = new BscConnector({ supportedChainIds })

export const ConnectorNames = {
  MetaMask: 'MetaMask',
  TrustWallet: 'TrustWallet',
  WalletConnect: 'WalletConnect',
  Coinbase: 'Coinbase',
  BinanceChainWallet: 'BinanceChainWallet',
  Coin98Wallet: 'Coin98Wallet',
}

export const connectorsByName = {
  [ConnectorNames.MetaMask]: injected,
  [ConnectorNames.TrustWallet]: injected,
  [ConnectorNames.WalletConnect]: walletconnectV2,
  [ConnectorNames.Coinbase]: walletlink,
  [ConnectorNames.BinanceChainWallet]: binanceChainWalletConnector,
  [ConnectorNames.Coin98Wallet]: injected,
}
