import { useQuery } from '@tanstack/react-query'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'

import RetroVeManagerFactory from 'config/abi/v3/RetroVeManagerFactory.json'
import VotingEscrow from 'config/abi/v3/VotingEscrow.json'
import RetroVeManager from 'config/abi/v3/RetroVeManager.json'

import ERC20 from 'config/abi/erc20.json'
import { ADDR_RETRO_VE_MANAGER_FACTORY, ADDR_VE_RETRO, ADDR_ZERO } from '../constants'
import { getContract } from 'utils/contract'
import { multicallFailSafe } from 'utils/multicall'
import { useContext } from 'react'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
import { isAddress } from 'web3-utils'

const range = (count) => Array.from(new Array(Number(count)).keys())

export const useVeNftsQuery = () => {
  const { account, library } = useActiveWeb3React()
  const tokens = useContext(BaseAssetsConetext)

  const loadVeNFTs = async () => {
    try {
      const veRETRO = getContract(ADDR_VE_RETRO, VotingEscrow, library, account)

      const retroVeManagerFactory = getContract(ADDR_RETRO_VE_MANAGER_FACTORY, RetroVeManagerFactory, library, account)

      const veBalance = await veRETRO.balanceOf(account)

      const veNFTs = await Promise.all(
        range(veBalance).map(async (index) => {
          const tokenId = await veRETRO.tokenOfOwnerByIndex(account, index)
          const managerAddress = await retroVeManagerFactory.tokenIdForManager(tokenId)

          const managerInfo = await (async () => {
            if (managerAddress === ADDR_ZERO) {
              return undefined
            }

            const manager = getContract(managerAddress, RetroVeManager, library, account)

            const shouldMaxLock = await manager.shouldMaxLock()
            const approved = await veRETRO.isApprovedOrOwner(managerAddress, tokenId)
            const automationActive = await manager.automationActive()
            const currentTaskId = await manager.currentTaskId()
            const balance = await library.getBalance(managerAddress)

            const contractCalls = tokens
              .map((token) => {
                return {
                  address: token.address,
                  name: 'balanceOf',
                  params: [managerAddress],
                }
              })
              .filter((call) => isAddress(call.address))

            const callsResult = await multicallFailSafe(ERC20, contractCalls)

            const tokenBalance = Object.fromEntries(tokens.map((token, index) => [token.address, callsResult[index]]))

            return {
              address: managerAddress,
              shouldMaxLock,
              approved,
              automationActive,
              currentTaskId,
              balance,
              tokenBalance,
            }
          })()

          return {
            tokenId: tokenId.toString(),
            managerInfo,
          }
        }),
      )

      return veNFTs
    } catch (error) {
      console.error(error)
    }
  }

  return useQuery(
    ['ve-manager', 'nfts-list', account],
    async () => {
      const result = loadVeNFTs()

      return result
    },
    { enabled: tokens.length > 0 },
  )
}
