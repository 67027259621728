import { BigNumber } from '@ethersproject/bignumber'
import { useQuery } from '@tanstack/react-query'
import { useV3NFTPositionManagerContract } from 'hooks/useContractV3'
import { useMemo } from 'react'

import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import { multicallFailSafe } from 'utils/multicall'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from 'config/constants/v3/addresses'
import { ChainId } from 'config/constants'

const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson

function useV3PositionsFromTokenIds(tokenIds) {
  const inputs = useMemo(
    () =>
      tokenIds
        ? tokenIds.map((tokenId) => {
            return [BigNumber.from(tokenId)]
          })
        : [],
    [tokenIds],
  )

  const positionsQuery = useQuery(
    ['manual-positions', [...tokenIds].sort()],
    async () => {
      const calls = tokenIds.map((tokenId) => {
        return {
          address: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[ChainId.MAINNET],
          name: 'positions',
          params: [BigNumber.from(tokenId)],
        }
      })

      const response = await multicallFailSafe(NFTPositionManagerABI, calls)

      return response
    },
    { enabled: tokenIds.length > 0 },
  )

  const loading = positionsQuery.isLoading
  const error = positionsQuery.isError

  const results = positionsQuery.data

  const positions = useMemo(() => {
    if (!loading && !error && tokenIds) {
      return results.map((result, i) => {
        const tokenId = tokenIds[i]
        return {
          tokenId,
          fee: result.fee,
          feeGrowthInside0LastX128: result.feeGrowthInside0LastX128,
          feeGrowthInside1LastX128: result.feeGrowthInside1LastX128,
          liquidity: result.liquidity,
          nonce: result.nonce,
          operator: result.operator,
          tickLower: result.tickLower,
          tickUpper: result.tickUpper,
          token0: result.token0,
          token1: result.token1,
          tokensOwed0: result.tokensOwed0,
          tokensOwed1: result.tokensOwed1,
        }
      })
    }
    return undefined
  }, [loading, error, results, tokenIds])

  return {
    loading,
    positions: positions?.map((position, i) => ({ ...position, tokenId: inputs[i][0] })),
  }
}

export function useV3PositionFromTokenId(tokenId) {
  const position = useV3PositionsFromTokenIds(tokenId ? [tokenId] : undefined)
  return {
    loading: position.loading,
    position: position.positions?.[0],
  }
}

export function useV3ManualPositions(account) {
  const positionManager = useV3NFTPositionManagerContract()

  const balanceQuery = useQuery(
    ['position-balance', account],
    async () => {
      const response = await positionManager.balanceOf(account)

      return response
    },
    { enabled: Boolean(account) },
  )

  const balanceResult = balanceQuery.data
  const balanceLoading = balanceQuery.isLoading

  // const { loading: balanceLoading, result: balanceResult } = useSingleCallResult(positionManager, 'balanceOf', [account ?? undefined])

  // we don't expect any account balance to ever exceed the bounds of max safe int
  const accountBalance = balanceResult?.toNumber()

  const tokenIdsArgs = useMemo(() => {
    if (accountBalance && account) {
      const tokenRequests = []
      for (let i = 0; i < accountBalance; i++) {
        tokenRequests.push([account, i])
      }
      return tokenRequests
    }
    return []
  }, [account, accountBalance])

  const tokenIdResultsQuery = useQuery(
    ['token-ids', account, [...tokenIdsArgs].sort()],
    async () => {
      const calls = Array.from(Array(accountBalance)).map((_, i) => {
        return {
          address: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[ChainId.MAINNET],
          name: 'tokenOfOwnerByIndex',
          params: [account, i],
        }
      })

      const response = await multicallFailSafe(NFTPositionManagerABI, calls)

      return response ?? []
    },
    { enabled: Boolean(accountBalance) },
  )

  const tokenIdResults = tokenIdResultsQuery.data ?? []

  // const tokenIdResults = useSingleContractMultipleData(positionManager, 'tokenOfOwnerByIndex', tokenIdsArgs)
  const someTokenIdsLoading = tokenIdResultsQuery.isLoading

  const tokenIds = useMemo(() => {
    if (account) {
      return tokenIdResults.map((result) => BigNumber.from(result[0]))
    }

    return []
  }, [account, tokenIdResults])

  const { positions, loading: positionsLoading } = useV3PositionsFromTokenIds(tokenIds)

  return {
    loading: someTokenIdsLoading || balanceLoading || positionsLoading,
    positions,
  }
}
