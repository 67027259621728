import { Token } from '@uniswap/sdk-core'
import { ChainId } from 'config/constants'
import { BNB } from './bnb'

export const WBNB_EXTENDED = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped MATIC',
  ),
}

export class ExtendedEther extends BNB {
  static _cachedEther = {}

  get wrapped() {
    if (this.chainId in WBNB_EXTENDED) return WBNB_EXTENDED[this.chainId]
    throw new Error('Unsupported chain ID')
  }

  static onChain(chainId) {
    return this._cachedEther[chainId] ?? (this._cachedEther[chainId] = new ExtendedEther(chainId))
  }
}

export const v3LiquidityRangeType = {
  MANUAL_RANGE: 'MANUAL_RANGE',
  GAMMA_RANGE: 'GAMMA_RANGE',
  ICHI_RANGE: 'ICHI_RANGE',
  DEFI_EDGE: 'DEFI_EDGE',
}
