import React, { useState } from 'react'
import { ArcadeMachine } from './ArcadeMachine'
import { SelectNft } from './SelectNft'
import VeTHEPopup from 'components/common/VeTHEPopup'

export const ManagerHeader = ({
  setSelectedVeNft,
  selectedVeNFT,
  tokenId,
  isManagerLoading,
  veTHEs,
  nfts,
  managerInfo,
  refetchData,
}) => {
  const [isNftModalOpen, setIsNftModalOpen] = useState(false)

  return (
    <div className='w-full flex justify-center px-[20px]'>
      <div className='flex flex-col justify-center max-w-[650px]'>
        <h1 className='font-semibold f-f-fg text-[34px] md:text-[42px]'>Manage your veRETRO</h1>
        <p className='text-[16px] mb-[30px]'>
          veRETRO Manager is a tool to automate weekly tasks with your veRETRO NFT. Gelato network will run your
          automation each week. You can stop it or replace it anytime.
        </p>
        <div>
          <a href='https://www.gelato.network/' target='_blank' rel='noreferrer'>
            <img src={'/images/retro/powered-by-gelato.png'} />
          </a>
        </div>
      </div>
      <ArcadeMachine>
        {isManagerLoading ? (
          'LOADING...'
        ) : (
          <SelectNft
            setSelectedVeNft={setSelectedVeNft}
            selectedVeNFT={selectedVeNFT}
            nfts={nfts}
            managerInfo={managerInfo}
            refetchData={refetchData}
            tokenId={tokenId}
            veTHEs={veTHEs}
            isNftModalOpen={isNftModalOpen}
            setIsNftModalOpen={setIsNftModalOpen}
          />
        )}
      </ArcadeMachine>

      <VeTHEPopup
        popup={isNftModalOpen}
        setPopup={setIsNftModalOpen}
        setSelectedVeTHE={setSelectedVeNft}
        veTHEs={veTHEs}
      />
    </div>
  )
}
