import React from 'react'

const PoolWarning = ({ text, setFullRangeWarningShown, isFull = false }) => {
  return (
    <div className='pl-3.5 pr-4 py-2.5 flex space-x-[11.5px] max-w-fit border border-white bg-componentSecondary rounded-[5px] my-3.5'>
      <img alt='' src='/images/svgs/warning.svg' />
      <div className={`w-px bg-white`} />
      <div className='flex items-center space-x-2'>
        <p className=' text-white font-normal'>{text}</p>
        {isFull && (
          <button
            onClick={() => {
              setFullRangeWarningShown(false)
            }}
          >
            <img alt='' src='/images/svgs/close-button.svg' />
          </button>
        )}
      </div>
    </div>
  )
}

export default PoolWarning
