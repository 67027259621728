import clsx from 'clsx'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import NumericalInput from 'components/common/Input/NumericalInput'
import { toToken } from 'config/constants/v3/routing'
import { formatUnits } from 'ethers/lib/utils'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import { useAddIchiLiquidity } from 'hooks/v3/useAddIchiLiquidity'
import { useIchiRemove } from 'hooks/v3/useIchiRemove'
import React, { useState } from 'react'
import Slider from 'react-rangeslider'
import { formatAmount, formatNumber, fromWei } from 'utils/formatNumber'
import { tryParseAmount } from 'v3lib/utils/utils'

const percentArray = ['25', '50', '75', '100']

export const VaultCard = (props) => {
  const { account } = useActiveWeb3React()
  const [typedValue, setTypedValue] = useState('')
  const [activeTab, setActiveTab] = useState('deposit')
  const [removePercent, setRemovePercent] = useState(0)

  const apr = props.pool?.fullMerklData?.aprs[`Ichi ${props.asset.vault.vaultAddress.toLowerCase()}`]

  const { onIchiAdd, pending: pendingAdd } = useAddIchiLiquidity()
  const { pending: pendingRemove, onIchiRemove } = useIchiRemove()

  const onRemove = () => {
    if (removePercent > 0) {
      onIchiRemove(props.position.share.mul(removePercent).div(100).toFixed(0), props.position)

      props.refetchPositions()
    }
  }

  const handleClick = () => {
    switch (activeTab) {
      case 'deposit': {
        onIchiAdd(undefined, tryParseAmount(typedValue, toToken(props.depositToken)), props.asset)

        props.refetchPositions()

        return
      }

      case 'withdraw': {
        onRemove()

        return
      }

      default:
        return
    }
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'deposit': {
        return (
          <>
            <div className='flex items-center justify-between w-full'>
              <div className={`flex flex-wrap items-center text-sm lg:text-base gap-2 justify-between w-full`}>
                <div className='flex items-center gap-2.5'>
                  {percentArray.map((percent, index) => {
                    return (
                      <div
                        disabled={!props.balance}
                        className={`flex items-center justify-center font-montSerrat bg-black round-[3px] min-w-[62px] text-white text-[13px] md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                        onClick={() => {
                          props.balance &&
                            setTypedValue(
                              formatUnits(
                                props.balance?.div('100')?.mul(percent)?.toString(),
                                props.depositToken.decimals,
                              ) ?? '',
                            )
                        }}
                        key={`percent-${index}`}
                      >
                        {percent}%
                      </div>
                    )
                  })}
                </div>
                <p className='text-white'>
                  Balance:{' '}
                  {props.balance
                    ? formatNumber(formatUnits(props.balance.toString(), props.depositToken.decimals), 5)
                    : '-'}
                </p>
              </div>
            </div>
            <div className='mt-3 mb-3 h-[45px]'>
              <NumericalInput value={typedValue} onUserInput={setTypedValue} className='bg-black h-full text-white' />
            </div>
          </>
        )
      }

      case 'withdraw': {
        return (
          <>
            <div className='mt-5 flex items-center justify-between'>
              <div className='flex items-center space-x-2.5'>
                {percentArray.map((perc, index) => {
                  return (
                    <div
                      className={`flex items-center justify-center font-montSerrat bg-black round-[3px] min-w-[62px] text-white text-[13px] md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                      onClick={() => setRemovePercent(perc)}
                      key={`percent-${index}`}
                    >
                      {perc}%
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='w-full px-2.5 md:px-4 pt-4 md:pt-5 bg-body rounded-[3px] pb-5 md:pb-[22px] mt-1 md:mt-[7px]'>
              <div className='text-lg md:text-2xl leading-5 md:leading-[29px] text-white'>{removePercent}%</div>
              <Slider
                min={0}
                max={100}
                className='!mt-3 mb-0'
                value={Number(removePercent)}
                onChange={setRemovePercent}
              />
            </div>

            {props.position?.balances && (
              <div className='mt-3 w-full'>
                <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px]'>
                  <div className='flex items-center space-x-[5px]'>
                    <img alt='' src={props.asset.firstAsset.logoURI} className={'w-4 md:w-5'} />
                    <span>{props.asset.firstAsset.symbol}</span>
                  </div>
                  <span>
                    {formatAmount(
                      fromWei(
                        props.position.balances[props.asset.firstAsset.address.toLowerCase()].toString(),
                        props.asset.firstAsset.decimals,
                      )
                        .times(removePercent)
                        .div(100),
                    )}
                  </span>
                </div>

                <div className='mt-2 flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px]'>
                  <div className='flex items-center space-x-[5px]'>
                    <img alt='' src={props.asset.secondAsset.logoURI} className={'w-4 md:w-5'} />
                    <span>{props.asset.secondAsset.symbol}</span>
                  </div>
                  <span>
                    {formatAmount(
                      fromWei(
                        props.position.balances[props.asset.secondAsset.address.toLowerCase()].toString(),
                        props.position.secondAsset.decimals,
                      )
                        .times(removePercent)
                        .div(100),
                    )}
                  </span>
                </div>
              </div>
            )}
          </>
        )
      }

      default:
        break
    }
  }

  const renderEarnedInfo = () => {
    if (props.isEarnedDataLoading) {
      return '-'
    }

    return props.position ? (
      <div className='text-dimGray'>
        <div className='flex justify-between mb-2'>
          <div className='text-white'>Position:</div>
          {props.position?.balances && (
            <div>
              <div className='flex min-w-[100px]'>
                <div>{props.asset.firstAsset.symbol}</div>
                <div className='ml-auto'>
                  {formatAmount(
                    fromWei(
                      props.position.balances[props.asset.firstAsset.address.toLowerCase()].toString(),
                      props.asset.firstAsset.decimals,
                    ),
                  )}
                </div>
              </div>

              <div className='flex mt-1 min-w-[100px]'>
                <div>{props.asset.secondAsset.symbol}</div>
                <div className='ml-auto'>
                  {formatAmount(
                    fromWei(
                      props.position.balances[props.asset.secondAsset.address.toLowerCase()].toString(),
                      props.position.secondAsset.decimals,
                    ),
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='flex justify-between'>
          <div className='text-white'>Earned:</div>
          <div className='flex flex-col gap-2'>
            {props.earnedData
              ? Object.values(props.earnedData.gaugeEarned).map((value) => {
                  return (
                    <div key={value.symbol}>
                      {formatAmount(value.unclaimed)} {value.symbol} (
                      {'$' + formatAmount(props.earnedData.earnedUsd, true)})
                    </div>
                  )
                })
              : '-'}
          </div>
        </div>
      </div>
    ) : (
      'No position'
    )
  }

  return (
    <div className='py-[20px] px-[20px] border-2 flex flex-col gap-3 border-white rounded-[10px] bg-[#141B22] w-full md:max-w-[320px] relative'>
      <img alt='' className='w-[72px] h-[72px] absolute top-[-35px] right-[20px]' src={props.depositToken.logoURI} />
      <h2 className='text-white text-[20px] font-bold'>{props.asset.vault.depositTokenSymbol}</h2>
      <div className='flex items-center space-x-3'>
        <div className='flex items-center'>
          <img alt='' className='w-[20px]  relative shadow' src={props.asset.firstAsset.logoURI} />
          <img alt='' className='w-[20px]' src={props.asset.secondAsset.logoURI} />
        </div>
        <p className='text-[12px] font-semibold text-white'>
          {props.asset.firstAsset.symbol}/{props.asset.secondAsset.symbol}
        </p>
      </div>
      <div className='text-white text-[12px]'>APR:</div>
      <div className='text-[#00FFDB] text-[24px] font-bold'>{apr ? `${formatAmount(apr, true)}%` : '-'}</div>

      <div
        className={clsx('bg-componentSecondary  py-[4px] px-[8px] rounded-[5px] text-[14px]', {
          ['text-[#b8b6cb]']: !props.earnedData,
          ['text-white']: Boolean(props.earnedData),
        })}
      >
        {renderEarnedInfo()}
      </div>

      <div className='mt-4'>
        <div className='flex justify-between px-[40px] mb-4'>
          <button
            className={clsx([
              'block text-dimGray font-semibold',
              activeTab === 'deposit' && 'border-b-2 border-white text-white',
            ])}
            onClick={() => setActiveTab('deposit')}
          >
            DEPOSIT
          </button>
          <button
            disabled={!props.position}
            className={clsx([
              'block text-dimGray font-semibold',
              activeTab === 'withdraw' && 'border-b-2 border-white text-white',
              !props.position && 'opacity-20',
            ])}
            onClick={() => setActiveTab('withdraw')}
          >
            WITHDRAW
          </button>
        </div>

        {renderContent()}

        <LinearGhostButton
          disabled={
            !account ||
            pendingRemove ||
            pendingAdd ||
            (activeTab === 'deposit' && !typedValue) ||
            (activeTab === 'withdraw' && !removePercent)
          }
          onClickHanlder={handleClick}
          className='w-full mt-3'
          title={activeTab === 'deposit' ? 'Deposit' : 'Withdraw'}
        />
      </div>
    </div>
  )
}
