import React, { useState, useEffect, useMemo } from 'react'
import { useSplit } from '../../../hooks/useLock'
import { formatAmount, isInvalidAmount } from '../../../utils/formatNumber'
import StyledButton from '../../common/Buttons/styledButton'

const validNumber = (val) => {
  return val === '' ? 0 : Number(val)
}
const arrayPercent = [2, 3, 4]

const SplitTab = ({ selected }) => {
  const [numberOfInputs, setNumberOfInputs] = useState(2)
  const [customInput, setCustomInput] = useState('')
  const [percentArr, setPercentArr] = useState([])

  const { onSplit, pending } = useSplit()

  useEffect(() => {
    const fixedArr = []
    const target = customInput !== '' ? customInput : numberOfInputs
    for (let i = 0; i < target; i++) {
      fixedArr.push('')
    }
    setPercentArr(fixedArr)
  }, [numberOfInputs, customInput])

  const total = useMemo(() => {
    return percentArr.reduce((sum, cur) => {
      return sum + validNumber(cur)
    }, 0)
  }, [percentArr])

  const errorMsg = useMemo(() => {
    let isError = false
    for (let i = 0; i < percentArr.length; i++) {
      if (validNumber(percentArr[i]) === 0) {
        isError = true
        break
      }
    }
    if (isError) {
      return 'ALL THE PERCENTAGES SHOULD BE FILLED.'
    }
    if (total !== 100) {
      return 'TOTAL PERCENT SHOULD BE 100%.'
    }
    return null
  }, [percentArr, total])

  return (
    <>
      <div className='mt-5 flex items-center justify-between'>
        <span className='text-base lg:text-xl text-white font-light'>Token #{selected.id} veRETRO to:</span>
        <p className='text-[#B8B6CB] text-[13px] md:text-base'>
          veRETRO Balance: {formatAmount(selected.voting_amount)}
        </p>
      </div>
      <div className='md:mt-2.5 -mt-1  flex items-center flex-wrap w-full text-white text-sm md:text-lg'>
        {arrayPercent.map((item, index) => {
          return (
            <div
              className={`h-[30px] px-10 sm:px-[19px] flex-grow sm:flex-grow-0 mt-3 md:mt-0  flex-shrink-0 font-medium ${
                index === 1 ? 'ml-[11px] sm:mr-[11px]' : index === 0 ? '' : 'mr-[11px]'
              }  ${
                item === numberOfInputs && customInput === '' ? 'bg-white text-body' : ' bg-body'
              } flex items-center justify-center cursor-pointer`}
              key={`level-${index}`}
              onClick={() => {
                setCustomInput('')
                setNumberOfInputs(item)
              }}
            >
              {item} Tokens
            </div>
          )
        })}
        <div className='mt-3 w-full relative'>
          <input
            className='placeholder-dimGray  flex-shrink-0  font-normal bg-body w-full h-10 md:h-[48px] rounded-[3px] text-white pl-2.5 pr-2 text-sm md:text-lg !border !border-white focus:!border-[2px] block focus-visible:!outline-none'
            type='number'
            min={5}
            max={10}
            value={customInput}
            onChange={(e) => {
              if (!isInvalidAmount(e.target.value)) {
                const nums = Number(e.target.value)
                setCustomInput(Math.max(Math.min(10, nums), 5))
              } else {
                setCustomInput('')
              }
            }}
            placeholder='Enter Amount'
          />
          {customInput !== '' && (
            <span className='absolute z-10 text-white text-base md:text-lg top-2.5 font-light right-[14px]'>
              Tokens
            </span>
          )}
        </div>

        <div className='  flex justify-between  w-full mt-5'>
          <div className='flex items-center w-[75%]'>
            <div className='w-[35%] font-medium text-[13px] md:text-[17px] text-white f-f-fg pl-[15px]'>No</div>
            <div className='w-[65%] font-medium text-[13px] md:text-[17px] text-white f-f-fg'>veRETRO Amount</div>
          </div>
          <div className='w-[25%] font-medium text-[13px] md:text-[17px] text-white f-f-fg'>Percentage</div>
        </div>
        <div className='w-full max-h-[260px] overflow-auto'>
          {percentArr.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`bg-componentPrimary p-px w-full space-y-2.5 lg:space-y-0 ${
                  idx === 0 ? 'mt-[7px]' : 'mt-4'
                } rounded-[3px]`}
              >
                <div className=' flex flex-row justify-between items-center rounded-[3px] border border-white'>
                  <div className='flex items-center border-r border-white bg-[#232F3B] w-[75%]'>
                    <div className='w-[35%] py-[11px] pl-2 md:py-5 lg:pl-[15px]  text-[#fff]'>
                      <div className='text-base lg:text-xl font-medium'>{idx + 1}</div>
                    </div>
                    <div className='w-[65%]  text-[#fff]'>
                      <div className='text-base lg:text-xl font-medium'>
                        {formatAmount(selected.voting_amount.times(validNumber(item)).div(100))}
                      </div>
                    </div>
                  </div>
                  <div className='pl-px rounded-tr-[3px] rounded-br-[3px] w-[25%] bg-body'>
                    <div className=' w-full relative text-[#fff]  rounded-tr-[3px] rounded-br-[3px] bg-componentPrimary'>
                      <input
                        type={'number'}
                        value={item}
                        onChange={(e) => {
                          const val = validNumber(e.target.value)
                          let temp = [...percentArr]
                          if (val > 0) {
                            const newVal =
                              total - validNumber(percentArr[idx]) + val > 100
                                ? 100 - total + validNumber(percentArr[idx])
                                : val
                            temp[idx] = newVal > 0 ? newVal : ''
                            setPercentArr(temp)
                          } else {
                            temp[idx] = ''
                            setPercentArr(temp)
                          }
                        }}
                        className=' py-[11px] px-3.5 w-[90%]  md:py-5 lg:pl-[15px] text-white font-medium text-base lg:text-xl bg-transparent'
                      />
                      <span className='text-white font-medium text-base lg:text-xl absolute right-[10px] md:right-[14px] z-10 mt-[11px] md:mt-5'>
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className='mt-5 flex items-center w-full justify-between'>
          <span className='text-base lg:text-xl text-white font-light'>
            Total Split Amount: <span className='text-lg lg:text-[22px] text-white font-medium'>{total}%</span>
          </span>
          <button
            className='text-lg font-medium text-dimGray'
            onClick={() => {
              const fixedArr = []
              for (let i = 0; i < percentArr.length; i++) {
                fixedArr.push('')
              }
              setPercentArr(fixedArr)
            }}
          >
            Reset
          </button>
        </div>
        <StyledButton
          disabled={errorMsg || pending}
          pending={pending}
          onClickHandler={() => {
            onSplit(selected, percentArr)
          }}
          content={errorMsg || 'SPLIT'}
          className='text-black py-[13px] md:py-[14.53px] mt-4 text-base  md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-[3px]'
        />
      </div>
    </>
  )
}

export default SplitTab
