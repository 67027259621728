import { useQuery } from '@tanstack/react-query'
import { useWeb3React } from '@web3-react/core'
import { ERC20Abi, pairAbi } from 'config/abi'

import { multicallFailSafe } from 'utils/multicall'
import { useDefiEdgeStrategiesQuery } from './useDefiEdgeStrategiesQuery'
import Big from 'big.js'
import BigNumber from 'bignumber.js'

import { chunkArr } from 'utils/chunkArray'

export const useDefiEdgePositionsQuery = () => {
  const { account } = useWeb3React()

  const defiEdgeStrategies = useDefiEdgeStrategiesQuery()

  return useQuery(
    ['defi-edge-positions', account],
    async () => {
      const calls = defiEdgeStrategies.data.map((strategy) => {
        return { name: 'balanceOf', address: strategy.id, params: [account] }
      })

      const callsPairs = defiEdgeStrategies.data.flatMap((strategy) => [
        { name: 'token0', address: strategy.pool },
        { name: 'token1', address: strategy.pool },
      ])

      const responsePairs = await multicallFailSafe(pairAbi, callsPairs)

      const chunksPairs = chunkArr(responsePairs, 2)

      try {
        const response = await multicallFailSafe(ERC20Abi, calls)

        return response.map((item, index) => {
          const strategy = defiEdgeStrategies.data[index]
          const { totalSupply } = strategy.liquidityDetails

          //   const token0 = toToken(strategy.token0)
          //   const token1 = toToken(strategy.token1)

          const [[token0], [token1]] = chunksPairs[index]

          //   const [strategyToken0, strategyToken1] = token0.sortsBefore(token1)
          //     ? [strategy.token0, strategy.token1]
          //     : [strategy.token1, strategy.token0]

          const amount0 = Big(strategy.amount0).mul(10 ** strategy.token0.decimals)
          const amount1 = Big(strategy.amount1).mul(10 ** strategy.token1.decimals)

          const balance0 = totalSupply
            ? Big(item.balance.toString()).div(new BigNumber(totalSupply.hex).toString()).mul(amount0)
            : Big(0)

          const balance1 = totalSupply
            ? Big(item.balance.toString()).div(new BigNumber(totalSupply.hex).toString()).mul(amount1)
            : Big(0)

          return {
            shareBalance: Big(item.balance.toString()),
            // balances: {
            //   [strategyToken0.address.toLowerCase()]: balance0,
            //   [strategyToken1.address.toLowerCase()]: balance1,
            // },
            balances: {
              [token0?.toLowerCase()]: balance0,
              [token1?.toLowerCase()]: balance1,
            },
            ...strategy,
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    { enabled: Boolean(account) && Boolean(defiEdgeStrategies.data) },
  )
}
