import React from 'react'

const FilterIcon = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 66 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='100%' height='100%' rx='3' fill='#141B22' />
      <path
        d='M19.0714 19.6735H46.9286C47.5203 19.6735 48 19.308 48 18.8571V16.8163C48 16.3655 47.5203 16 46.9286 16H19.0714C18.4797 16 18 16.3655 18 16.8163V18.8571C18 19.308 18.4797 19.6735 19.0714 19.6735ZM19.0714 27.8367H46.9286C47.5203 27.8367 48 27.4713 48 27.0204V24.9796C48 24.5287 47.5203 24.1633 46.9286 24.1633H19.0714C18.4797 24.1633 18 24.5287 18 24.9796V27.0204C18 27.4713 18.4797 27.8367 19.0714 27.8367ZM19.0714 36H46.9286C47.5203 36 48 35.6345 48 35.1837V33.1429C48 32.692 47.5203 32.3265 46.9286 32.3265H19.0714C18.4797 32.3265 18 32.692 18 33.1429V35.1837C18 35.6345 18.4797 36 19.0714 36Z'
        fill='white'
      />
    </svg>
  )
}

export default FilterIcon
