import React, { useContext, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import GammaPosition from './gammaPosition'
import useWalletModal from '../../../../../hooks/useWalletModal'
import StyledButton from '../../../../common/Buttons/styledButton'
import { FusionsContext } from '../../../../../context/FusionsContext'
import TabFilter from 'components/common/TabFilter'
import { TabSizes, defaultChainId } from 'config/constants'
import { useUserIchiPositions } from 'hooks/v3/useUserIchiPositions'
import { IchiPosition } from './components/IchiPosition'
import Spinner from 'components/common/Spinner'
import { useDefiEdgePositionsQuery } from 'hooks/queries/useDefiEdgePositionsQuery'
import { DefiEdgePosition } from './components/DefiEdgePosition'

export const MyAutoPoolType = {
  GAMMA: 'GAMMA',
  ICHI: 'ICHI',
  DEFI_EDGE: 'DEFI EDGE',
}

const MyAutoPools = () => {
  const [openedPositionIndex, setOpenedPositionIndex] = useState(-1)
  const [filter, setFilter] = useState(MyAutoPoolType.GAMMA)
  const { account, chainId } = useWeb3React()
  const { openWalletModal } = useWalletModal()
  const fusions = useContext(FusionsContext)

  const gammaPositions = fusions.filter((pos) => pos.isGamma && pos.account.totalLp.gt(0))
  const ichiPositionsQuery = useUserIchiPositions({ enabled: filter === MyAutoPoolType.ICHI })
  const defiEdgePostionsQuery = useDefiEdgePositionsQuery()

  useEffect(() => {
    setOpenedPositionIndex(-1)
  }, [filter])

  const renderPositions = () => {
    switch (filter) {
      case MyAutoPoolType.GAMMA: {
        return gammaPositions.length > 0 ? (
          <div className=' mt-2.5 md:mt-[9px]'>
            {gammaPositions.map((position, id) => {
              return (
                <GammaPosition
                  openedPositionIndex={openedPositionIndex}
                  setOpenedPositionIndex={setOpenedPositionIndex}
                  id={id}
                  key={id}
                  position={position}
                />
              )
            })}
          </div>
        ) : (
          <p className='my-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>
            You do not have any liquidity positions.
          </p>
        )
      }

      case MyAutoPoolType.ICHI: {
        if (ichiPositionsQuery.isLoading) {
          return (
            <div className='flex justify-center mt-2'>
              <Spinner />
            </div>
          )
        }

        if (ichiPositionsQuery.data?.length > 0) {
          return (
            <div className='mt-2.5 md:mt-[9px]'>
              {ichiPositionsQuery.data?.map((position, id) => {
                return <IchiPosition key={position.vault.vaultAddress} id={id} position={position} />
              })}
            </div>
          )
        }

        return (
          <p className='my-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>
            You do not have any liquidity positions.
          </p>
        )
      }

      case MyAutoPoolType.DEFI_EDGE: {
        if (defiEdgePostionsQuery.isLoading) {
          return (
            <div className='flex justify-center mt-2'>
              <Spinner />
            </div>
          )
        }

        if (defiEdgePostionsQuery.data?.length > 0) {
          return (
            <div className='mt-2.5 md:mt-[9px]'>
              {defiEdgePostionsQuery.data
                ?.filter((position) => position.shareBalance.gt(0))
                .map((position, index) => {
                  return (
                    <DefiEdgePosition
                      openedPositionIndex={openedPositionIndex}
                      setOpenedPositionIndex={setOpenedPositionIndex}
                      key={position.id}
                      index={index}
                      position={position}
                    />
                  )
                })}
            </div>
          )
        }

        return (
          <p className='my-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>
            You do not have any liquidity positions.
          </p>
        )
      }

      default:
        return null
    }
  }

  return (
    <div
      className={`max-h-[550px] overflow-auto w-full  rounded-[5px] bg-componentPrimary border-4 border-white py-3 px-4 lg:px-6 relative`}
    >
      <p className='text-lg lg:text-[22px] leading-[22px] lg:leading-[27px] font-semibold text-white'>
        Your Automatic LP
      </p>
      <div className='mt-[10px]'>
        <TabFilter data={Object.values(MyAutoPoolType)} filter={filter} setFilter={setFilter} size={TabSizes.SMALL} />
      </div>

      {!account || chainId !== defaultChainId ? (
        <StyledButton
          onClickHandler={openWalletModal}
          content={'CONNECT WALLET'}
          className='my-3 py-[13px] md:py-[14.53px] text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
        />
      ) : (
        renderPositions()
      )}
    </div>
  )
}

export default MyAutoPools
