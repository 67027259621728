// import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import { useState } from 'react'

import DefiEdgeRemoveModal from './DefiEdgeRemoveModal'

import { formatAmount } from 'utils/formatNumber'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'

export const DefiEdgePosition = ({ openedPositionIndex, setOpenedPositionIndex, index, position }) => {
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)

  const { token0, token1 } = position

  const lpPosition = position.shareBalance.div(10 ** 18)
  const lpUsd = lpPosition.mul(position.liquidityDetails.sharePrice)

  // const token0Amount = position.balances[position.token0.address.toLowerCase()].div(10 ** position.token0.decimals)
  // const token1Amount = position.balances[position.token1.address.toLowerCase()].div(10 ** position.token1.decimals)

  // const token0Usd = token0Amount.mul(position.liquidityDetails.token0Price)
  // const token1Usd = token1Amount.mul(position.liquidityDetails.token1Price)

  return (
    <div
      onClick={() => {
        openedPositionIndex === index ? setOpenedPositionIndex(-1) : setOpenedPositionIndex(index)
      }}
      className={`${index > 0 ? 'mt-[9px]' : ''} w-full px-5 py-[19.2px] bg-body cursor-pointer`}
    >
      {isWithdrawModalOpen && (
        <DefiEdgeRemoveModal
          lpPosition={lpPosition}
          lpUsd={lpUsd}
          isOpen={isWithdrawModalOpen}
          setIsOpen={setIsWithdrawModalOpen}
          position={position}
        />
      )}

      <div className='flex items-start md:items-center justify-between'>
        <div className='flex items-start md:items-center space-x-3'>
          <div className='flex items-center space-x-3 '>
            <div className='flex items-center'>
              <img alt='' className='w-6 lg:w-[30px] relative shadow' src={token0.logoURI} />
              <img alt='' className='w-6 lg:w-[30px] -ml-3' src={token1.logoURI} />
            </div>
            <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>
              {token0.symbol}/{token1.symbol}
            </p>
          </div>
          {/* <div className='md:flex-row flex flex-col-reverse items-start md:items-center md:space-x-2'>
            <div className='bg-componentPrimary py-1 pl-3 mt-2 md:mt-0 rounded-[13px] w-full flex items-center space-x-[5px] pr-4 flex-shrink-0'>
              <span className='text-[15px] font-semibold text-white whitespace-nowrap'>{type} range</span>
            </div>
          </div> */}
        </div>
        <button className={`${openedPositionIndex === index ? 'rotate-180' : 'rotate-0'} mt-2 md:mt-0 w-3 md:w-auto`}>
          <img alt='' src='/images/svgs/dropdown.svg' />
        </button>
        {/* <div className='text-white w-[90px]'>
          <LinearGhostButton onClickHanlder={() => setIsWithdrawModalOpen(true)} smallCase title='Withdraw' />
        </div> */}
      </div>
      {openedPositionIndex === index ? (
        <div className='w-full mt-5'>
          <div className='flex items-center justify-between'>
            <span className='text-sm md:text-base leading-4 md:leading-5 font-medium text-white'>Pooled Liquidity</span>
            <span className='text-sm md:text-base leading-4 md:leading-5 font-light  text-white'>{`$${formatAmount(
              lpUsd.toNumber(),
            )} (${formatAmount(lpPosition.toString(), 4)} LP)`}</span>
          </div>
          {/* <div className='mt-3.5'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center space-x-[5px]'>
                <img alt='' src={token0.logoURI} className={'w-6 md:w-7'} />
                <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>
                  {token0.symbol}
                </span>
              </div>
              <div className='flex items-center space-x-2'>
                <span className='text-[#DEDBF2] leading-5 font-light'>{formatAmount(token0Amount.toString())}</span>
                <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-componentPrimary rounded-md'>
                  ${formatAmount(token0Usd.toNumber(), 5)}
                </div>
              </div>
            </div>
            <div className='flex items-center justify-between mt-2'>
              <div className='flex items-center space-x-[5px]'>
                <img alt='' src={token1.logoURI} className={'w-6 md:w-7'} />
                <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>
                  {token1.symbol}
                </span>
              </div>
              <div className='flex items-center space-x-2'>
                <span className='text-[#DEDBF2] leading-5 font-light'>{formatAmount(token1Amount.toString())}</span>
                <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-componentPrimary rounded-md'>
                  ${formatAmount(token1Usd.toNumber())}
                </div>
              </div>
            </div>
          </div> */}

          <div className='flex items-center space-x-3 mt-4'>
            <LinearGhostButton
              onClickHanlder={() => {
                setIsWithdrawModalOpen(true)
              }}
              smallCase={true}
              title={'Withdraw'}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
