import React from 'react'
import { TabSizes } from '../../../config/constants'

const TabFilter = ({ data, filter, setFilter, className, size = TabSizes.MEDIUM }) => {
  return (
    <div className={`flex items-center w-full lg:w-fit rounded-[10px] p-px bg-black lg:bg-componentPrimary ${className ? ' ' + className : ''}`}>
      {data.map((item, idx) => {
        return (
          <div
            onClick={() => setFilter(item)}
            key={`tab-${idx}`}
            className={`cursor-pointer ${
              size === TabSizes.LARGE ? 'w-[200px]' : size === TabSizes.MEDIUM ? 'w-[100px]' : 'w-[80px]'
            } h-[34px] lg:h-10 rounded-[10px] flex items-center justify-center transition-all ${
              filter === item ? 'bg-grayHover text-black font-medium' : 'text-dimGray'
            } ${idx == data.length - 1 ? '' : ''} lg:tracking-[0.6px] text-[13px] lg:text-sm lg:text-[15px] flex-grow`}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

export default TabFilter
