import { BigNumber } from '@ethersproject/bignumber'
import { useQuery } from '@tanstack/react-query'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useV3NFTPositionManagerContract } from 'hooks/useContractV3'

import { unwrappedToken } from 'v3lib/utils/utils'

const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1)

// compute current + counterfactual fees for a v3 position
export function useV3ManualPositionFees(pool, tokenId, asWETH = false) {
  const positionManager = useV3NFTPositionManagerContract()
  const { account } = useWeb3React()

  const owner = account

  // const owner = useSingleCallResult(tokenId ? positionManager : null, 'ownerOf', [tokenId]).result?.[0]

  const tokenIdHexString = tokenId?.toHexString()

  // we can't use multicall for this because we need to simulate the call from a specific address
  // latestBlockNumber is included to ensure data stays up-to-date every block

  const feesQuery = useQuery(
    ['manual-position-fees', tokenIdHexString],
    async () => {
      const results = await positionManager.callStatic.collect(
        {
          tokenId: tokenIdHexString,
          recipient: owner, // some tokens might fail if transferred to address(0)
          amount0Max: MAX_UINT128,
          amount1Max: MAX_UINT128,
        },
        { from: owner }, // need to simulate the call as the owner
      )

      return [results.amount0, results.amount1]
    },
    {
      enabled: Boolean(positionManager) && Boolean(tokenIdHexString) && Boolean(owner),
      refetchOnMount: false,
    },
  )

  // useEffect(() => {
  //   ;(async function getFees() {
  //     if (positionManager && tokenIdHexString && owner) {
  //       try {
  //         const results = await positionManager.callStatic.collect(
  //           {
  //             tokenId: tokenIdHexString,
  //             recipient: owner, // some tokens might fail if transferred to address(0)
  //             amount0Max: MAX_UINT128,
  //             amount1Max: MAX_UINT128,
  //           },
  //           { from: owner }, // need to simulate the call as the owner
  //         )
  //         setAmounts([results.amount0, results.amount1])
  //       } catch {
  //         // If the static call fails, the default state will remain for `amounts`.
  //         // This case is handled by returning unclaimed fees as empty.
  //         // TODO(WEB-2283): Look into why we have failures with call data being 0x.
  //       }
  //     }
  //   })()
  // }, [tokenIdHexString, latestBlockNumber, owner])

  const amounts = feesQuery.data

  if (pool && amounts) {
    return [
      CurrencyAmount.fromRawAmount(asWETH ? pool.token0 : unwrappedToken(pool.token0), amounts[0].toString()),
      CurrencyAmount.fromRawAmount(asWETH ? pool.token1 : unwrappedToken(pool.token1), amounts[1].toString()),
    ]
  } else {
    return [undefined, undefined]
  }
}
