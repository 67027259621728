import React, { useContext, useEffect, useMemo, useState } from 'react'
// import { Link } from 'react-router-dom'
import V3AddLiquidity from '../../components/pages/liquidity/v3/addLiquidity'
import MyAutoPools from '../../components/pages/liquidity/v3/MyAutoPools'
import MyManualPools from '../../components/pages/liquidity/v3/MyManualPools'
import { EnterAmounts } from 'components/pages/liquidity/v3/addLiquidity/containers/EnterAmounts'
import { useCurrency } from 'hooks/v3/Tokens'
import { useV3DerivedMintInfo, useV3MintActionHandlers, useV3MintState } from 'state/mintV3/hooks'
import { AddLiquidityButton } from 'components/pages/liquidity/v3/addLiquidity/containers/AddLiquidityButton'
import { FusionsContext } from 'context/FusionsContext'
import { useIchiVaultAssets } from 'hooks/v3/useIchiVaultAssets'
import { v3LiquidityRangeType } from 'v3lib/entities/constants'
import { EnterIchiAmount } from 'components/pages/liquidity/v3/addLiquidity/containers/EnterIchiAmount'
// import { Link } from 'react-router-dom'

const ManageLiquidity = () => {
  const [firstAsset, setFirstAsset] = useState()
  const [secondAsset, setSecondAsset] = useState()

  const baseCurrency = useCurrency(firstAsset ? firstAsset.address : undefined)
  const currencyB = useCurrency(secondAsset ? secondAsset.address : undefined)
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  const mintInfo = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    baseCurrency ?? undefined,
    undefined,
  )
  const { liquidityRangeType } = useV3MintState()
  const { onChangeLiquidityRangeType } = useV3MintActionHandlers()

  const [settings, setSettings] = useState(false)
  const [slippage, setSlippage] = useState(0.5)
  const [deadline, setDeadline] = useState(20)

  const fusions = useContext(FusionsContext)

  const currencyAAddress = baseCurrency && baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : ''
  const currencyBAddress = quoteCurrency && quoteCurrency.wrapped ? quoteCurrency.wrapped.address.toLowerCase() : ''
  const gammaPairs = useMemo(() => {
    const gammas = fusions.filter((ele) => ele.isGamma)
    const result = gammas.filter(
      (ele) =>
        (ele.token0.address.toLowerCase() === currencyAAddress &&
          ele.token1.address.toLowerCase() === currencyBAddress) ||
        (ele.token1.address.toLowerCase() === currencyAAddress &&
          ele.token0.address.toLowerCase() === currencyBAddress),
    )
    return result.length > 0 ? result : null
  }, [fusions, currencyAAddress, currencyBAddress])

  const ichiVaultAssets = useIchiVaultAssets()

  const [ichiAsset, setIchiAsset] = useState()

  useEffect(() => {
    if (!gammaPairs?.[0].gammaEnabled) {
      onChangeLiquidityRangeType(v3LiquidityRangeType.ICHI_RANGE)
    }
  }, [firstAsset, secondAsset])

  return (
    <div className='w-full max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 pt-5 pb-10 xl:pt-20 md:pt-14 mx-auto'>
      <div className='max-w-[1200px] mx-auto w-full'>
        {/* <div className='flex justify-center'>
          <div className='flex h-11'>
            <Link
              to={'/liquidity/manage'}
              className={`w-[100px] h-full flex flex-col items-center justify-center cursor-pointer text-[#A2A0B7] font-normal border-[#555367] border-l border-t border-b rounded-l-[5px] -mr-[3px] -ml-px`}
            >
              V1
            </Link>
            <Link
              to={'/liquidity/manageV3'}
              className={`w-[100px] h-full flex flex-col items-center justify-center cursor-pointer text-black font-semibold bg-grayHover rounded-[5px] -mr-px`}
            >
              FUSION
            </Link>
          </div>
        </div> */}
        <div className='lg:flex items-start lg:space-x-6'>
          <V3AddLiquidity
            mintInfo={mintInfo}
            firstAsset={firstAsset}
            setFirstAsset={setFirstAsset}
            secondAsset={secondAsset}
            setSecondAsset={setSecondAsset}
            settings={settings}
            setSettings={setSettings}
            slippage={slippage}
            setSlippage={setSlippage}
            deadline={deadline}
            setDeadline={setDeadline}
            ichiVaultAssets={ichiVaultAssets}
            gammaPairs={gammaPairs}
            ichiAsset={ichiAsset}
          />
          <div className='lg:max-w-[550px] w-full mt-5 lg:mt-0'>
            <div className='mb-5 rounded-[5px] bg-componentPrimary border-4 border-white py-3 px-4'>
              <h3 className='text-center w-full text-[25px] font-semibold text-white m-0 mb-[15px] mt-[30px]'>
                Step 3 - Select Deposit Amounts
              </h3>
              {liquidityRangeType === v3LiquidityRangeType.ICHI_RANGE && (
                <EnterIchiAmount
                  mintInfo={mintInfo}
                  liquidityRangeType={liquidityRangeType}
                  firstAsset={firstAsset}
                  secondAsset={secondAsset}
                  ichiAsset={ichiAsset}
                  setIchiAsset={setIchiAsset}
                />
              )}
              {liquidityRangeType !== v3LiquidityRangeType.ICHI_RANGE && (
                <EnterAmounts
                  currencyA={baseCurrency ?? undefined}
                  currencyB={currencyB ?? undefined}
                  mintInfo={mintInfo}
                />
              )}
              <AddLiquidityButton
                baseCurrency={baseCurrency ?? undefined}
                quoteCurrency={quoteCurrency ?? undefined}
                firstAsset={firstAsset}
                mintInfo={mintInfo}
                slippage={slippage}
                deadline={deadline}
                gammaPairs={gammaPairs}
                ichiVaultAssets={ichiVaultAssets}
                ichiAsset={ichiAsset}
              />
            </div>
            <MyAutoPools />
            <MyManualPools />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageLiquidity
