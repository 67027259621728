import BigNumber from 'bignumber.js'
import { useContext, useMemo, useState, useEffect } from 'react'
import { BaseAssetsConetext } from '../context/BaseAssetsConetext'
import { getTHEAddress } from '../utils/addressHelpers'
import { ZERO_VALUE, fromWei } from '../utils/formatNumber'
import { useExtraRewarder, useMinter, useV3Voter } from './useContract'
import useRefresh from './useRefresh'
import { TOTAL_VOLUME_DATA, FUSION_TOTAL_TVL } from '../apollo/queries'
import { fusionClient, v3Client } from '../apollo/client'
import useWeb3 from './useWeb3'
import { useWeb3React } from '@web3-react/core'

const useTHEAsset = () => {
  const baseAssets = useContext(BaseAssetsConetext)
  const theAsset = useMemo(() => {
    return baseAssets.length > 0
      ? baseAssets.find((item) => item.address.toLowerCase() === getTHEAddress().toLowerCase())
      : null
  }, [baseAssets])

  return theAsset
}

const useTVL = () => {
  const [tvl, setTvl] = useState(0)

  const fetchTvl = async () => {
    const res = await fetch('https://api.llama.fi/tvl/retro')
    const json = await res.text()
    setTvl(Number(json))
  }

  useEffect(() => {
    fetchTvl()
  }, [])

  return tvl
}

const useVoteEmissions = () => {
  const [voteEmssions, setVoteEmissions] = useState(null)
  const [lpEmission, setLpEmission] = useState(new BigNumber(0))
  const voterContract = useV3Voter()
  const minterContract = useMinter()
  const theAsset = useTHEAsset()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchSupply = async () => {
      const [totalWeight, weekly_emission] = await Promise.all([
        voterContract.methods.totalWeight().call(),
        minterContract.methods.weekly_emission().call(),
      ])
      const lpEmissionRes = fromWei(weekly_emission).times(0.675)
      setLpEmission(lpEmissionRes)
      setVoteEmissions(Number(totalWeight) > 0 ? lpEmissionRes.times(theAsset.price).div(100) : new BigNumber(0))
    }
    if (voterContract && minterContract && theAsset) {
      fetchSupply()
    }
  }, [voterContract, minterContract, theAsset, fastRefresh])
  return { voteEmssions, lpEmission }
}

const useEpochTimer = () => {
  const [epochInfo, setEpochInfo] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    epoch: 0,
    nextEpoch: 0,
  })
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const curTime = new Date().getTime() / 1000
    const epoch5 = 1690416000
    const epoch = Math.floor((curTime - epoch5) / 604800)
    const nextEpoch = Math.ceil(curTime / (86400 * 7)) * (86400 * 7)
    const days = Math.floor((nextEpoch - curTime) / 86400)
    const hours = Math.floor((nextEpoch - curTime - days * 86400) / 3600)
    const mins = Math.floor((nextEpoch - curTime - days * 86400 - hours * 3600) / 60)
    setEpochInfo({
      days,
      hours: hours < 10 ? '0' + hours : hours,
      mins: mins < 10 ? '0' + mins : mins,
      epoch,
      nextEpoch,
    })
  }, [fastRefresh])

  return epochInfo
}

const useOneDayVolume = () => {
  const [oneDayVolume, setOneDayVolume] = useState(0)
  const { fastRefresh } = useRefresh()
  const web3 = useWeb3()

  useEffect(() => {
    const fetchVolume = async () => {
      const curblockNumber = await web3.eth.getBlockNumber()
      const last = curblockNumber - 28800

      const [todayFusionVolume, yesterdayFusionVolume] = await Promise.all([
        fusionClient.query({
          query: TOTAL_VOLUME_DATA(),
          fetchPolicy: 'cache-first',
        }),
        fusionClient.query({
          query: TOTAL_VOLUME_DATA(last),
          fetchPolicy: 'cache-first',
        }),
      ])
      let v1Volume = 0
      let fusionVolume = 0

      if (
        todayFusionVolume?.data?.factories[0]?.totalVolumeUSD &&
        yesterdayFusionVolume?.data?.factories[0]?.totalVolumeUSD
      ) {
        fusionVolume =
          Number(todayFusionVolume?.data?.factories[0]?.totalVolumeUSD) -
          Number(yesterdayFusionVolume?.data?.factories[0]?.totalVolumeUSD)
      }
      setOneDayVolume(v1Volume + fusionVolume)
    }
    fetchVolume()
  }, [fastRefresh, web3])

  return oneDayVolume
}

const useV3Tvl = () => {
  const [v3Tvl, setV3Tvl] = useState(0)
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchTvl = async () => {
      const result = await v3Client.query({
        query: FUSION_TOTAL_TVL(),
        fetchPolicy: 'cache-first',
      })
      if (result?.data?.factories[0]?.totalValueLockedUSD) {
        setV3Tvl(Number(result?.data?.factories[0]?.totalValueLockedUSD))
      }
    }
    fetchTvl()
  }, [fastRefresh])

  return v3Tvl
}

const useExtraRewardsInfo = () => {
  const [rewardPerSecond, setRewardPerSecond] = useState(ZERO_VALUE)
  const [pendingRewards, setPendingRewards] = useState(ZERO_VALUE)
  const rewarderContract = useExtraRewarder()
  const { fastRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const fetchInfo = async () => {
      const rewardPerSecondRes = await rewarderContract.methods.rewardPerSecond().call()
      setRewardPerSecond(fromWei(rewardPerSecondRes))
      if (account) {
        const pendingRewardRes = await rewarderContract.methods.pendingReward(account).call()
        setPendingRewards(fromWei(pendingRewardRes))
      } else {
        setPendingRewards(ZERO_VALUE)
      }
    }
    fetchInfo()
  }, [account, fastRefresh])
  return { pendingRewards, rewardPerSecond }
}

export { useTHEAsset, useTVL, useVoteEmissions, useEpochTimer, useOneDayVolume, useV3Tvl, useExtraRewardsInfo }
