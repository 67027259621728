import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { formatAmount } from '../../../utils/formatNumber'

const percentArray = [25, 50, 75, 100]

const BalanceInput = ({ title, inputAmount, setInputAmount, symbol = '', logoURIs, balance = null }) => {
  const { account } = useWeb3React()
  return (
    <div className='w-full'>
      <div className='flex flex-col gap-y-1 md:flex-row md:items-center justify-center w-full gap-x-5 font-montSerrat'>
        {title && <p className='text-white text-sm md:text-base leading-10 flex-grow-1 text-left'>{title}</p>}
        {account && balance && (
          <div className={`md:ml-auto flex flex-col gap-y-1 md:flex-row md:items-center text-sm lg:text-base md:space-x-3 ${!title && 'justify-between w-full'}`}>
            <div className='flex items-center gap-y-2.5 md:gap-0 md:space-x-2.5'>
              {percentArray.map((percent, index) => {
                return (
                  <div
                    className={`flex items-center justify-center min-w-[62px] bg-black round-[3px] text-white text-[13px] md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                    onClick={() => setInputAmount(balance.times(percent).div(100).toString(10) || 0)}
                    key={`percent-${index}`}
                  >
                    {percent}%
                  </div>
                )
              })}
            </div>
            <p className='text-white mt-2 md:mt-0'>Balance: {formatAmount(balance)}</p>
          </div>
        )}
      </div>
      <div className='mt-1.5 lg:mt-4 p-px w-full'>
        <div className='bg-black px-4 rounded-[3px] flex items-center justify-between font-montSerrat'>
          <input
            className={`bg-transparent ${
              logoURIs.length > 1 ? 'w-[35%] md:w-[50%]' : 'w-[80%]'
            } py-2.5 lg:py-4 text-xl lg:text-2xl leading-10 placeholder-placeholder text-white`}
            value={inputAmount}
            onChange={(e) => {
              setInputAmount(Number(e.target.value) < 0 ? '' : e.target.value)
            }}
            placeholder='0.00'
            type={'number'}
            min={0}
          />
          {logoURIs.length > 0 && (
            <div className='flex items-center justify-between space-x-2'>
              {logoURIs.length > 1 ? (
                <div className='flex items-center -space-x-2'>
                  <img className='relative z-10' width={28} height={28} alt='' src={logoURIs[0]} />
                  <img className='relative z-[5]' width={28} height={28} alt='' src={logoURIs[1]} />
                </div>
              ) : (
                <img alt='' width={28} height={28} src={logoURIs[0]} />
              )}
              <p className='font-semibold text-sm lg:text-lg leading-6 text-white'>{symbol}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BalanceInput
