import React from 'react'

export const ArcadeMachine = ({ children }) => {
  return (
    <div>
      <svg className='w-[590px] h-[534px]' viewBox='0 0 681 606' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M648.276 534.551C568.411 399.901 593.672 51.6458 593.672 51.6458L584.946 -56H83.6224L74.8965 51.6458C74.8965 51.6458 100.157 399.901 20.2918 534.551L18.1133 554.338V631.771H650.455V554.338L648.276 534.551Z'
          fill='#010808'
        />
        <path d='M551.512 69.8018H119.043V122.138H551.512V69.8018Z' fill='url(#paint0_linear_3746_5487)' />
        <path d='M552.815 122.021H116.129L85.3086 480.846H583.635L552.815 122.021Z' fill='#27292D' />
        <path
          d='M101.567 446.582L126.566 152.448C127 147.351 129.05 142.624 132.316 139.184C135.583 135.745 139.834 133.838 144.244 133.836H526.287C530.696 133.838 534.947 135.745 538.214 139.184C541.481 142.624 543.53 147.351 543.964 152.448L568.963 446.582C569.208 449.449 568.932 452.344 568.155 455.08C567.378 457.816 566.116 460.332 564.451 462.467C562.785 464.602 560.754 466.308 558.486 467.476C556.219 468.643 553.766 469.246 551.285 469.245H119.245C116.764 469.246 114.312 468.643 112.044 467.476C109.777 466.308 107.745 464.602 106.079 462.467C104.414 460.332 103.152 457.816 102.375 455.08C101.598 452.344 101.323 449.449 101.567 446.582Z'
          fill='black'
          stroke='#616161'
          strokeWidth='1.06'
          strokeMiterlimit='10'
        />
        <foreignObject x='135px' y='135px' width='400px' height='310px'>
          <div className='flex justify-center items-center w-full h-full'>{children}</div>
        </foreignObject>
        <path d='M630.21 554.559H40.7344V631.748H630.21V554.559Z' fill='url(#paint1_linear_3746_5487)' />
        <path
          d='M628.215 554.559H40.7344L84.858 480.847H584.103L628.215 554.559Z'
          fill='url(#paint2_linear_3746_5487)'
        />
        <path
          opacity='0.32'
          d='M65.1068 530.679L88.2367 491.714C89.1404 490.194 90.3481 488.953 91.756 488.095C93.1639 487.238 94.73 486.79 96.3197 486.791H574.239C575.828 486.789 577.392 487.236 578.799 488.094C580.205 488.951 581.41 490.194 582.31 491.714L605.452 530.679C606.464 532.391 607.055 534.391 607.16 536.464C607.265 538.538 606.882 540.605 606.05 542.443C605.218 544.282 603.97 545.821 602.44 546.896C600.911 547.971 599.157 548.541 597.369 548.544H73.1897C71.4001 548.546 69.6442 547.979 68.112 546.905C66.5798 545.831 65.3297 544.291 64.4972 542.451C63.6646 540.611 63.2813 538.541 63.3888 536.466C63.4962 534.391 64.0903 532.39 65.1068 530.679Z'
          stroke='#616161'
          strokeWidth='1.06'
          strokeMiterlimit='10'
        />
        <path
          d='M203.624 518.982C213.275 518.982 221.099 515.1 221.099 510.312C221.099 505.524 213.275 501.643 203.624 501.643C193.972 501.643 186.148 505.524 186.148 510.312C186.148 515.1 193.972 518.982 203.624 518.982Z'
          fill='#171417'
        />
        <path
          d='M203.625 502.556C212.969 502.556 220.576 506.193 221.052 510.769C221.059 510.617 221.059 510.465 221.052 510.313C221.052 505.529 213.231 501.657 203.577 501.657C193.923 501.657 186.102 505.529 186.102 510.313C186.102 510.479 186.102 510.617 186.102 510.769C186.673 506.193 194.28 502.556 203.625 502.556Z'
          fill='#231F23'
        />
        <path d='M219.85 505.693H187.387V509.772H219.85V505.693Z' fill='#006360' />
        <path
          d='M203.624 517.82C212.585 517.82 219.849 514.217 219.849 509.772C219.849 505.328 212.585 501.725 203.624 501.725C194.663 501.725 187.398 505.328 187.398 509.772C187.398 514.217 194.663 517.82 203.624 517.82Z'
          fill='#006360'
        />
        <path
          d='M203.624 513.479C212.585 513.479 219.849 509.876 219.849 505.431C219.849 500.987 212.585 497.384 203.624 497.384C194.663 497.384 187.398 500.987 187.398 505.431C187.398 509.876 194.663 513.479 203.624 513.479Z'
          fill='black'
        />
        <path
          d='M203.623 512.856C195.445 512.856 188.683 509.883 187.445 505.942C188.017 510.091 195.028 513.437 203.623 513.437C212.218 513.437 219.23 510.174 219.753 505.942C218.563 509.856 211.801 512.856 203.623 512.856Z'
          fill='#E0F7FA'
        />
        <path
          d='M217.967 503.551C217.967 507.478 211.55 510.672 203.622 510.672C195.694 510.672 189.266 507.478 189.266 503.551C189.266 499.624 195.694 496.43 203.622 496.43C211.55 496.43 217.967 499.624 217.967 503.551Z'
          fill='#006064'
        />
        <path
          d='M203.622 509.982C211.413 509.982 217.729 506.849 217.729 502.985C217.729 499.121 211.413 495.988 203.622 495.988C195.831 495.988 189.516 499.121 189.516 502.985C189.516 506.849 195.831 509.982 203.622 509.982Z'
          fill='#8D8D8D'
        />
        <path d='M217.729 499.624H189.516V502.984H217.729V499.624Z' fill='#8D8D8D' />
        <path
          d='M203.622 506.621C211.413 506.621 217.729 503.489 217.729 499.625C217.729 495.76 211.413 492.628 203.622 492.628C195.831 492.628 189.516 495.76 189.516 499.625C189.516 503.489 195.831 506.621 203.622 506.621Z'
          fill='#E1E1E1'
        />
        <path
          d='M203.622 505.181C198.706 505.181 194.361 503.965 191.777 502.084C194.158 504.241 198.563 505.693 203.622 505.693C208.681 505.693 213.145 504.241 215.526 502.084C212.872 503.965 208.539 505.181 203.622 505.181Z'
          fill='black'
        />
        <path
          d='M203.721 493.499C208.947 493.499 213.423 494.992 215.625 497.149C213.875 494.702 209.22 493.001 203.721 493.001C198.221 493.001 193.543 494.757 191.816 497.149C194.019 494.881 198.495 493.499 203.721 493.499Z'
          fill='black'
        />
        <path
          d='M203.622 506.067C196.015 506.067 189.837 503.08 189.54 499.347C189.534 499.439 189.534 499.531 189.54 499.623C189.54 503.495 195.849 506.62 203.646 506.62C211.443 506.62 217.753 503.495 217.753 499.623C217.753 499.526 217.753 499.443 217.753 499.347C217.372 503.08 211.229 506.067 203.622 506.067Z'
          fill='#E0F7FA'
        />
        <path
          d='M241.718 518.982C251.369 518.982 259.193 515.1 259.193 510.312C259.193 505.524 251.369 501.643 241.718 501.643C232.066 501.643 224.242 505.524 224.242 510.312C224.242 515.1 232.066 518.982 241.718 518.982Z'
          fill='#171417'
        />
        <path
          d='M241.719 502.556C251.064 502.556 258.671 506.193 259.159 510.769C259.166 510.617 259.166 510.465 259.159 510.313C259.159 505.529 251.326 501.657 241.672 501.657C232.017 501.657 224.196 505.529 224.196 510.313C224.19 510.465 224.19 510.617 224.196 510.769C224.768 506.193 232.386 502.556 241.719 502.556Z'
          fill='#231F23'
        />
        <path
          d='M241.718 517.82C250.679 517.82 257.943 514.217 257.943 509.772C257.943 505.328 250.679 501.725 241.718 501.725C232.757 501.725 225.492 505.328 225.492 509.772C225.492 514.217 232.757 517.82 241.718 517.82Z'
          fill='#006360'
        />
        <path d='M257.955 505.693H225.492V509.772H257.955V505.693Z' fill='#006360' />
        <path
          d='M241.718 513.479C250.679 513.479 257.943 509.876 257.943 505.431C257.943 500.987 250.679 497.384 241.718 497.384C232.757 497.384 225.492 500.987 225.492 505.431C225.492 509.876 232.757 513.479 241.718 513.479Z'
          fill='black'
        />
        <path
          d='M241.717 512.856C233.55 512.856 226.789 509.883 225.551 505.942C226.11 510.091 233.134 513.437 241.717 513.437C250.3 513.437 257.323 510.174 257.847 505.942C256.657 509.856 249.895 512.856 241.717 512.856Z'
          fill='#E0F7FA'
        />
        <path
          d='M256.075 503.551C256.075 507.478 249.647 510.672 241.719 510.672C233.79 510.672 227.434 507.478 227.434 503.551C227.434 499.624 233.85 496.43 241.719 496.43C249.587 496.43 256.075 499.624 256.075 503.551Z'
          fill='#006064'
        />
        <path
          d='M241.716 509.982C249.507 509.982 255.822 506.849 255.822 502.985C255.822 499.121 249.507 495.988 241.716 495.988C233.925 495.988 227.609 499.121 227.609 502.985C227.609 506.849 233.925 509.982 241.716 509.982Z'
          fill='#8D8D8D'
        />
        <path d='M255.822 499.624H227.609V502.984H255.822V499.624Z' fill='#8D8D8D' />
        <path
          d='M241.716 506.621C249.507 506.621 255.822 503.489 255.822 499.625C255.822 495.76 249.507 492.628 241.716 492.628C233.925 492.628 227.609 495.76 227.609 499.625C227.609 503.489 233.925 506.621 241.716 506.621Z'
          fill='#E1E1E1'
        />
        <path
          d='M241.716 505.181C236.799 505.181 232.466 503.965 229.883 502.084C232.264 504.241 236.668 505.693 241.716 505.693C246.763 505.693 251.18 504.241 253.56 502.084C250.977 503.965 246.644 505.181 241.716 505.181Z'
          fill='black'
        />
        <path
          d='M241.826 493.499C247.052 493.499 251.528 494.992 253.73 497.149C251.98 494.702 247.326 493.001 241.826 493.001C236.326 493.001 231.648 494.757 229.922 497.149C232.112 494.881 236.6 493.499 241.826 493.499Z'
          fill='black'
        />
        <path
          d='M241.716 506.067C234.121 506.067 227.931 503.08 227.645 499.347C227.639 499.439 227.639 499.531 227.645 499.623C227.645 503.495 233.966 506.62 241.751 506.62C249.537 506.62 255.87 503.495 255.87 499.623C255.876 499.531 255.876 499.439 255.87 499.347C255.465 503.08 249.323 506.067 241.716 506.067Z'
          fill='#E0F7FA'
        />
        <path
          d='M279.823 518.982C289.474 518.982 297.299 515.1 297.299 510.312C297.299 505.524 289.474 501.643 279.823 501.643C270.172 501.643 262.348 505.524 262.348 510.312C262.348 515.1 270.172 518.982 279.823 518.982Z'
          fill='#171417'
        />
        <path
          d='M279.821 502.556C289.166 502.556 296.773 506.193 297.249 510.769C297.262 510.618 297.262 510.465 297.249 510.313C297.249 505.529 289.428 501.657 279.774 501.657C270.119 501.657 262.298 505.529 262.298 510.313C262.286 510.465 262.286 510.618 262.298 510.769C262.87 506.193 270.477 502.556 279.821 502.556Z'
          fill='#231F23'
        />
        <path
          d='M279.823 517.82C288.784 517.82 296.049 514.217 296.049 509.772C296.049 505.328 288.784 501.725 279.823 501.725C270.862 501.725 263.598 505.328 263.598 509.772C263.598 514.217 270.862 517.82 279.823 517.82Z'
          fill='#006360'
        />
        <path d='M296.061 505.693H263.598V509.772H296.061V505.693Z' fill='#006360' />
        <path
          d='M279.823 513.479C288.784 513.479 296.049 509.876 296.049 505.431C296.049 500.987 288.784 497.384 279.823 497.384C270.862 497.384 263.598 500.987 263.598 505.431C263.598 509.876 270.862 513.479 279.823 513.479Z'
          fill='black'
        />
        <path
          d='M279.822 512.856C271.644 512.856 264.894 509.883 263.645 505.942C264.216 510.091 271.228 513.437 279.822 513.437C288.417 513.437 295.429 510.132 296 505.942C294.75 509.856 288.001 512.856 279.822 512.856Z'
          fill='#E0F7FA'
        />
        <path
          d='M294.178 503.551C294.178 507.478 287.75 510.672 279.821 510.672C271.893 510.672 265.465 507.478 265.465 503.551C265.465 499.624 271.893 496.43 279.821 496.43C287.75 496.43 294.178 499.624 294.178 503.551Z'
          fill='#006064'
        />
        <path
          d='M279.821 509.982C287.612 509.982 293.928 506.849 293.928 502.985C293.928 499.121 287.612 495.988 279.821 495.988C272.031 495.988 265.715 499.121 265.715 502.985C265.715 506.849 272.031 509.982 279.821 509.982Z'
          fill='#8D8D8D'
        />
        <path d='M293.928 499.624H265.715V502.984H293.928V499.624Z' fill='#8D8D8D' />
        <path
          d='M279.821 506.621C287.612 506.621 293.928 503.489 293.928 499.625C293.928 495.76 287.612 492.628 279.821 492.628C272.031 492.628 265.715 495.76 265.715 499.625C265.715 503.489 272.031 506.621 279.821 506.621Z'
          fill='#E1E1E1'
        />
        <path
          d='M279.821 505.181C274.905 505.181 270.572 503.965 267.977 502.084C270.357 504.241 274.762 505.693 279.821 505.693C284.881 505.693 289.345 504.241 291.726 502.084C289.083 503.965 284.738 505.181 279.821 505.181Z'
          fill='black'
        />
        <path
          d='M279.916 493.499C285.142 493.499 289.63 494.992 291.82 497.149C290.058 494.702 285.404 493.001 279.916 493.001C274.428 493.001 269.738 494.757 268.012 497.149C270.214 494.881 274.69 493.499 279.916 493.499Z'
          fill='black'
        />
        <path
          d='M279.825 506.067C272.218 506.067 266.04 503.08 265.742 499.347C265.742 499.443 265.742 499.526 265.742 499.623C265.742 503.495 272.051 506.62 279.849 506.62C287.646 506.62 293.955 503.495 293.955 499.623C293.955 499.526 293.955 499.443 293.955 499.347C293.562 503.08 287.432 506.067 279.825 506.067Z'
          fill='#E0F7FA'
        />
        <path
          d='M198.35 534.261C208.002 534.261 215.826 530.38 215.826 525.592C215.826 520.803 208.002 516.922 198.35 516.922C188.699 516.922 180.875 520.803 180.875 525.592C180.875 530.38 188.699 534.261 198.35 534.261Z'
          fill='#171417'
        />
        <path
          d='M198.325 517.834C207.67 517.834 215.276 521.471 215.753 526.048C215.764 525.896 215.764 525.743 215.753 525.592C215.753 520.807 207.931 516.922 198.277 516.922C188.623 516.922 180.802 520.807 180.802 525.592C180.79 525.743 180.79 525.896 180.802 526.048C181.397 521.471 189.004 517.834 198.325 517.834Z'
          fill='#231F23'
        />
        <path
          d='M198.35 533.1C207.312 533.1 214.576 529.497 214.576 525.052C214.576 520.608 207.312 517.005 198.35 517.005C189.389 517.005 182.125 520.608 182.125 525.052C182.125 529.497 189.389 533.1 198.35 533.1Z'
          fill='#006360'
        />
        <path d='M214.588 520.973H182.125V525.052H214.588V520.973Z' fill='#006360' />
        <path
          d='M198.35 528.757C207.312 528.757 214.576 525.154 214.576 520.71C214.576 516.265 207.312 512.662 198.35 512.662C189.389 512.662 182.125 516.265 182.125 520.71C182.125 525.154 189.389 528.757 198.35 528.757Z'
          fill='black'
        />
        <path
          d='M198.326 528.066C190.148 528.066 183.398 525.093 182.148 521.152C182.72 525.301 189.731 528.661 198.326 528.661C206.921 528.661 213.933 525.342 214.504 521.152C213.29 525.135 206.528 528.066 198.326 528.066Z'
          fill='#E0F7FA'
        />
        <path
          d='M212.708 518.83C212.708 522.757 206.28 525.951 198.352 525.951C190.424 525.951 184.043 522.757 184.043 518.83C184.043 514.903 190.471 511.709 198.399 511.709C206.328 511.709 212.708 514.903 212.708 518.83Z'
          fill='#006064'
        />
        <path
          d='M198.349 525.259C206.14 525.259 212.455 522.126 212.455 518.262C212.455 514.398 206.14 511.266 198.349 511.266C190.558 511.266 184.242 514.398 184.242 518.262C184.242 522.126 190.558 525.259 198.349 525.259Z'
          fill='#8D8D8D'
        />
        <path d='M212.455 514.902H184.242V518.262H212.455V514.902Z' fill='#8D8D8D' />
        <path
          d='M198.349 521.899C206.14 521.899 212.455 518.767 212.455 514.903C212.455 511.039 206.14 507.906 198.349 507.906C190.558 507.906 184.242 511.039 184.242 514.903C184.242 518.767 190.558 521.899 198.349 521.899Z'
          fill='#E1E1E1'
        />
        <path
          d='M198.326 520.462C193.41 520.462 189.077 519.231 186.422 517.364C188.803 519.521 193.207 520.973 198.326 520.973C203.445 520.973 207.79 519.59 210.171 517.364C207.611 519.231 203.266 520.462 198.326 520.462Z'
          fill='black'
        />
        <path
          d='M198.443 508.708C203.669 508.708 208.157 510.201 210.348 512.372C208.586 509.924 203.943 508.224 198.443 508.224C192.944 508.224 188.265 509.98 186.539 512.372C188.801 510.187 193.217 508.708 198.443 508.708Z'
          fill='black'
        />
        <path
          d='M198.325 521.347C190.718 521.347 184.54 518.36 184.242 514.627C184.242 514.724 184.242 514.807 184.242 514.903C184.242 518.761 190.563 521.9 198.349 521.9C206.134 521.9 212.455 518.761 212.455 514.903C212.461 514.811 212.461 514.719 212.455 514.627C212.134 518.388 205.956 521.347 198.325 521.347Z'
          fill='#E0F7FA'
        />
        <path
          d='M236.456 534.261C246.107 534.261 253.931 530.38 253.931 525.592C253.931 520.803 246.107 516.922 236.456 516.922C226.804 516.922 218.98 520.803 218.98 525.592C218.98 530.38 226.804 534.261 236.456 534.261Z'
          fill='#171417'
        />
        <path
          d='M236.421 517.834C245.766 517.834 253.373 521.471 253.849 526.048C253.849 525.896 253.849 525.744 253.849 525.592C253.849 520.807 246.028 516.922 236.374 516.922C226.72 516.922 218.898 520.807 218.898 525.592C218.898 525.744 218.898 525.896 218.898 526.048C219.506 521.471 227.112 517.834 236.421 517.834Z'
          fill='#231F23'
        />
        <path
          d='M236.456 533.1C245.417 533.1 252.681 529.497 252.681 525.052C252.681 520.608 245.417 517.005 236.456 517.005C227.495 517.005 220.23 520.608 220.23 525.052C220.23 529.497 227.495 533.1 236.456 533.1Z'
          fill='#006360'
        />
        <path d='M252.682 520.973H220.219V525.052H252.682V520.973Z' fill='#006360' />
        <path
          d='M236.456 528.757C245.417 528.757 252.681 525.154 252.681 520.71C252.681 516.265 245.417 512.662 236.456 512.662C227.495 512.662 220.23 516.265 220.23 520.71C220.23 525.154 227.495 528.757 236.456 528.757Z'
          fill='black'
        />
        <path
          d='M236.42 528.066C228.242 528.066 221.48 525.093 220.242 521.152C220.814 525.301 227.825 528.661 236.42 528.661C245.015 528.661 252.027 525.342 252.598 521.152C251.384 525.135 244.622 528.066 236.42 528.066Z'
          fill='#E0F7FA'
        />
        <path
          d='M250.798 518.83C250.798 522.757 244.382 525.951 236.513 525.951C228.644 525.951 222.133 522.757 222.133 518.83C222.133 514.903 228.561 511.709 236.489 511.709C244.418 511.709 250.798 514.903 250.798 518.83Z'
          fill='#006064'
        />
        <path
          d='M236.454 525.259C244.245 525.259 250.561 522.126 250.561 518.262C250.561 514.398 244.245 511.266 236.454 511.266C228.663 511.266 222.348 514.398 222.348 518.262C222.348 522.126 228.663 525.259 236.454 525.259Z'
          fill='#8D8D8D'
        />
        <path d='M250.561 514.902H222.348V518.262H250.561V514.902Z' fill='#8D8D8D' />
        <path
          d='M236.454 521.899C244.245 521.899 250.561 518.767 250.561 514.903C250.561 511.039 244.245 507.906 236.454 507.906C228.663 507.906 222.348 511.039 222.348 514.903C222.348 518.767 228.663 521.899 236.454 521.899Z'
          fill='#E1E1E1'
        />
        <path
          d='M236.419 520.462C231.502 520.462 227.157 519.231 224.574 517.364C226.955 519.521 231.36 520.973 236.419 520.973C241.478 520.973 245.942 519.59 248.323 517.364C245.704 519.231 241.371 520.462 236.419 520.462Z'
          fill='black'
        />
        <path
          d='M236.553 508.708C241.779 508.708 246.255 510.201 248.457 512.372C246.707 509.924 242.052 508.224 236.553 508.224C231.053 508.224 226.375 509.98 224.648 512.372C226.898 510.187 231.327 508.708 236.553 508.708Z'
          fill='black'
        />
        <path
          d='M236.419 521.347C228.812 521.347 222.634 518.36 222.336 514.627C222.331 514.719 222.331 514.811 222.336 514.903C222.336 518.761 228.646 521.9 236.443 521.9C244.24 521.9 250.549 518.761 250.549 514.903C250.549 514.807 250.549 514.724 250.549 514.627C250.24 518.388 244.05 521.347 236.419 521.347Z'
          fill='#E0F7FA'
        />
        <path
          d='M274.55 534.261C284.201 534.261 292.025 530.38 292.025 525.592C292.025 520.803 284.201 516.922 274.55 516.922C264.898 516.922 257.074 520.803 257.074 525.592C257.074 530.38 264.898 534.261 274.55 534.261Z'
          fill='#171417'
        />
        <path
          d='M274.515 517.834C283.86 517.834 291.467 521.471 291.955 526.048C291.955 525.896 291.955 525.744 291.955 525.592C291.955 520.807 284.122 516.922 274.468 516.922C264.813 516.922 256.992 520.807 256.992 525.592C256.992 525.744 256.992 525.896 256.992 526.048C257.599 521.471 265.218 517.834 274.515 517.834Z'
          fill='#231F23'
        />
        <path
          d='M274.55 533.1C283.511 533.1 290.775 529.497 290.775 525.052C290.775 520.608 283.511 517.005 274.55 517.005C265.589 517.005 258.324 520.608 258.324 525.052C258.324 529.497 265.589 533.1 274.55 533.1Z'
          fill='#006360'
        />
        <path d='M290.787 520.973H258.324V525.052H290.787V520.973Z' fill='#006360' />
        <path
          d='M274.55 528.757C283.511 528.757 290.775 525.154 290.775 520.71C290.775 516.265 283.511 512.662 274.55 512.662C265.589 512.662 258.324 516.265 258.324 520.71C258.324 525.154 265.589 528.757 274.55 528.757Z'
          fill='black'
        />
        <path
          d='M274.514 528.066C266.347 528.066 259.586 525.093 258.348 521.152C258.907 525.301 265.931 528.661 274.514 528.661C283.097 528.661 290.12 525.342 290.691 521.152C289.489 525.135 282.728 528.066 274.514 528.066Z'
          fill='#E0F7FA'
        />
        <path
          d='M288.907 518.83C288.907 522.757 282.479 525.951 274.551 525.951C266.622 525.951 260.266 522.757 260.266 518.83C260.266 514.903 266.682 511.709 274.551 511.709C282.419 511.709 288.907 514.903 288.907 518.83Z'
          fill='#006064'
        />
        <path
          d='M274.548 525.259C282.339 525.259 288.654 522.126 288.654 518.262C288.654 514.398 282.339 511.266 274.548 511.266C266.757 511.266 260.441 514.398 260.441 518.262C260.441 522.126 266.757 525.259 274.548 525.259Z'
          fill='#8D8D8D'
        />
        <path d='M288.654 514.902H260.441V518.262H288.654V514.902Z' fill='#8D8D8D' />
        <path
          d='M274.548 521.899C282.339 521.899 288.654 518.767 288.654 514.903C288.654 511.039 282.339 507.906 274.548 507.906C266.757 507.906 260.441 511.039 260.441 514.903C260.441 518.767 266.757 521.899 274.548 521.899Z'
          fill='#E1E1E1'
        />
        <path
          d='M274.513 520.462C269.596 520.462 265.263 519.231 262.68 517.364C265.061 519.521 269.465 520.973 274.513 520.973C279.56 520.973 284.036 519.59 286.417 517.364C283.81 519.231 279.477 520.462 274.513 520.462Z'
          fill='black'
        />
        <path
          d='M274.658 508.708C279.884 508.708 284.36 510.201 286.562 512.372C284.812 509.924 280.158 508.224 274.658 508.224C269.158 508.224 264.48 509.98 262.754 512.372C264.992 510.187 269.432 508.708 274.658 508.708Z'
          fill='black'
        />
        <path
          d='M274.513 521.347C266.918 521.347 260.728 518.36 260.442 514.627C260.436 514.719 260.436 514.811 260.442 514.903C260.442 518.761 266.763 521.9 274.548 521.9C282.334 521.9 288.667 518.761 288.667 514.903C288.673 514.811 288.673 514.719 288.667 514.627C288.333 518.388 282.155 521.347 274.513 521.347Z'
          fill='#E0F7FA'
        />
        <path
          d='M131.796 531.04C144.767 531.04 155.283 525.822 155.283 519.384C155.283 512.946 144.767 507.728 131.796 507.728C118.824 507.728 108.309 512.946 108.309 519.384C108.309 525.822 118.824 531.04 131.796 531.04Z'
          fill='#171417'
        />
        <path
          d='M131.796 508.957C144.355 508.957 154.58 513.852 155.223 520.019C155.255 519.818 155.275 519.614 155.283 519.41C155.283 512.98 144.771 507.768 131.796 507.768C118.82 507.768 108.309 512.98 108.309 519.41C108.316 519.614 108.336 519.818 108.368 520.019C109.047 513.852 119.237 508.957 131.796 508.957Z'
          fill='#231F23'
        />
        <path
          d='M131.794 529.989C143.602 529.989 153.174 525.241 153.174 519.384C153.174 513.527 143.602 508.778 131.794 508.778C119.986 508.778 110.414 513.527 110.414 519.384C110.414 525.241 119.986 529.989 131.794 529.989Z'
          fill='url(#paint3_linear_3746_5487)'
        />
        <path
          d='M151.901 520.116C151.901 514.128 142.044 509.289 129.878 509.289C129.128 509.289 128.39 509.289 127.664 509.289C128.854 509.192 130.045 509.137 131.235 509.137C143.044 509.137 152.663 513.741 152.663 519.424C152.6 520.37 152.354 521.288 151.941 522.107C151.529 522.926 150.961 523.626 150.282 524.153C151.256 523.105 151.837 521.656 151.901 520.116Z'
          fill='#2F3135'
        />
        <path
          d='M111.711 518.665C111.711 524.638 121.568 529.478 133.734 529.478C134.484 529.478 135.21 529.478 135.936 529.478C134.746 529.574 133.556 529.644 132.365 529.644C120.556 529.644 110.938 525.025 110.938 519.342C111.002 518.396 111.249 517.48 111.662 516.661C112.074 515.842 112.64 515.142 113.318 514.613C112.35 515.67 111.774 517.123 111.711 518.665Z'
          fill='#2F3135'
        />
        <g opacity='0.25'>
          <path
            d='M147.256 518.389C147.256 513.798 139.661 510.092 130.292 510.092C129.721 510.092 129.161 510.092 128.602 510.092C129.506 510.009 130.423 509.968 131.375 509.968C140.47 509.968 147.839 513.521 147.839 517.905C147.787 518.633 147.594 519.339 147.274 519.968C146.955 520.598 146.517 521.136 145.994 521.541C146.753 520.722 147.206 519.591 147.256 518.389Z'
            fill='#59535E'
          />
          <path
            d='M116.317 517.255C116.317 521.86 123.912 525.552 133.281 525.552C133.864 525.552 134.471 525.552 134.983 525.552C134.079 525.634 133.15 525.676 132.21 525.676C123.115 525.676 115.746 522.122 115.746 517.753C115.795 517.022 115.986 516.313 116.306 515.68C116.625 515.048 117.065 514.508 117.591 514.103C116.83 514.921 116.373 516.052 116.317 517.255Z'
            fill='#59535E'
          />
        </g>
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M131.792 501.85C145.743 501.85 157.053 488.714 157.053 472.509C157.053 456.304 145.743 443.167 131.792 443.167C117.841 443.167 106.531 456.304 106.531 472.509C106.531 488.714 117.841 501.85 131.792 501.85Z'
          fill='url(#paint4_radial_3746_5487)'
        />
        <path
          d='M131.792 519.204C130.274 519.204 128.817 518.503 127.743 517.256C126.67 516.008 126.066 514.317 126.066 512.553V488.645C126.066 486.881 126.67 485.189 127.743 483.942C128.817 482.695 130.274 481.994 131.792 481.994C132.544 481.994 133.288 482.166 133.982 482.501C134.676 482.835 135.306 483.325 135.837 483.943C136.368 484.561 136.788 485.294 137.075 486.101C137.361 486.908 137.508 487.772 137.506 488.645V512.553C137.508 513.425 137.361 514.29 137.075 515.097C136.788 515.904 136.368 516.637 135.837 517.255C135.306 517.872 134.676 518.363 133.982 518.697C133.288 519.031 132.544 519.204 131.792 519.204Z'
          fill='#656B71'
        />
        <path
          d='M128.938 515.401C128.406 515.401 127.896 515.157 127.519 514.722C127.142 514.286 126.929 513.695 126.926 513.078V490.733C126.929 490.116 127.142 489.525 127.519 489.09C127.896 488.654 128.406 488.41 128.938 488.41C129.468 488.41 129.977 488.655 130.352 489.091C130.727 489.526 130.938 490.117 130.938 490.733V513.037C130.942 513.345 130.894 513.652 130.796 513.938C130.697 514.225 130.551 514.486 130.365 514.706C130.178 514.926 129.956 515.101 129.711 515.22C129.466 515.34 129.203 515.401 128.938 515.401Z'
          fill='#70767C'
        />
        <path
          d='M131.792 481.994C131.21 482.004 130.632 482.116 130.078 482.326C131.235 482.751 132.246 483.592 132.965 484.728C133.684 485.864 134.074 487.235 134.078 488.645V512.553C134.077 513.962 133.688 515.333 132.969 516.467C132.249 517.602 131.236 518.439 130.078 518.858C130.63 519.079 131.209 519.196 131.792 519.204C132.544 519.204 133.288 519.031 133.982 518.697C134.676 518.363 135.306 517.872 135.837 517.255C136.368 516.637 136.788 515.904 137.075 515.097C137.361 514.29 137.508 513.425 137.506 512.553V488.645C137.508 487.772 137.361 486.908 137.075 486.101C136.788 485.294 136.368 484.561 135.837 483.943C135.306 483.325 134.676 482.835 133.982 482.501C133.288 482.166 132.544 481.994 131.792 481.994Z'
          fill='#53585E'
        />
        <path
          d='M131.792 492.254C141.181 492.254 148.791 483.413 148.791 472.508C148.791 461.603 141.181 452.763 131.792 452.763C122.404 452.763 114.793 461.603 114.793 472.508C114.793 483.413 122.404 492.254 131.792 492.254Z'
          fill='#28FFFB'
        />
        <path
          d='M147.007 472.964C147.073 472.313 147.109 471.657 147.115 471.001C147.115 467.071 145.886 463.275 143.657 460.32C141.428 457.364 138.35 455.45 134.996 454.934C138.234 456.362 141.069 458.802 143.189 461.983C145.308 465.165 146.63 468.965 147.007 472.964Z'
          fill='white'
        />
        <path
          d='M133.83 481.938C130.326 481.936 126.882 480.884 123.831 478.883C120.78 476.882 118.225 474 116.414 470.517C116.414 470.655 116.414 470.793 116.414 470.931C116.422 474.971 117.63 478.883 119.835 482.006C122.04 485.13 125.105 487.274 128.513 488.076C131.922 488.877 135.464 488.287 138.541 486.405C141.618 484.524 144.041 481.466 145.401 477.748C142.001 480.479 137.964 481.941 133.83 481.938Z'
          fill='white'
        />
        <path
          opacity='0.61'
          d='M132.936 477.625C138.138 477.625 142.685 475.496 145.197 472.329C145.197 472.329 145.197 472.454 145.197 472.523C145.197 477.003 140.435 480.639 134.567 480.639C130.174 480.639 126.388 478.593 124.781 475.675C127.349 476.993 130.129 477.658 132.936 477.625Z'
          fill='#E0F7FA'
        />
        <path
          d='M131.415 453.854C131.141 453.854 130.867 453.854 130.593 453.854C127.139 453.856 123.811 455.36 121.264 458.071C118.716 460.781 117.136 464.501 116.832 468.498C117.727 464.645 119.594 461.192 122.193 458.583C124.792 455.973 128.004 454.326 131.415 453.854Z'
          fill='#E0F7FA'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M198.35 525.605C208.547 525.605 216.814 520.938 216.814 515.18C216.814 509.422 208.547 504.754 198.35 504.754C188.153 504.754 179.887 509.422 179.887 515.18C179.887 520.938 188.153 525.605 198.35 525.605Z'
          fill='url(#paint5_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M236.549 525.605C246.747 525.605 255.013 520.938 255.013 515.18C255.013 509.422 246.747 504.754 236.549 504.754C226.352 504.754 218.086 509.422 218.086 515.18C218.086 520.938 226.352 525.605 236.549 525.605Z'
          fill='url(#paint6_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M274.753 525.605C284.95 525.605 293.216 520.938 293.216 515.18C293.216 509.422 284.95 504.754 274.753 504.754C264.555 504.754 256.289 509.422 256.289 515.18C256.289 520.938 264.555 525.605 274.753 525.605Z'
          fill='url(#paint7_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M203.624 510.326C213.821 510.326 222.087 505.658 222.087 499.9C222.087 494.142 213.821 489.475 203.624 489.475C193.427 489.475 185.16 494.142 185.16 499.9C185.16 505.658 193.427 510.326 203.624 510.326Z'
          fill='url(#paint8_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M241.823 510.326C252.02 510.326 260.286 505.658 260.286 499.9C260.286 494.142 252.02 489.475 241.823 489.475C231.626 489.475 223.359 494.142 223.359 499.9C223.359 505.658 231.626 510.326 241.823 510.326Z'
          fill='url(#paint9_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M280.026 510.326C290.223 510.326 298.489 505.658 298.489 499.9C298.489 494.142 290.223 489.475 280.026 489.475C269.829 489.475 261.562 494.142 261.562 499.9C261.562 505.658 269.829 510.326 280.026 510.326Z'
          fill='url(#paint10_radial_3746_5487)'
        />
        <path
          d='M541.479 518.982C551.131 518.982 558.955 515.1 558.955 510.312C558.955 505.524 551.131 501.643 541.479 501.643C531.828 501.643 524.004 505.524 524.004 510.312C524.004 515.1 531.828 518.982 541.479 518.982Z'
          fill='#171417'
        />
        <path
          d='M541.48 502.556C532.135 502.556 524.528 506.193 524.052 510.769C524.045 510.617 524.045 510.465 524.052 510.313C524.052 505.529 531.873 501.657 541.527 501.657C551.182 501.657 559.003 505.529 559.003 510.313C559.015 510.465 559.015 510.618 559.003 510.769C558.431 506.193 550.824 502.556 541.48 502.556Z'
          fill='#231F23'
        />
        <path d='M557.717 505.693H525.254V509.772H557.717V505.693Z' fill='#006360' />
        <path
          d='M541.479 517.82C550.44 517.82 557.705 514.217 557.705 509.772C557.705 505.328 550.44 501.725 541.479 501.725C532.518 501.725 525.254 505.328 525.254 509.772C525.254 514.217 532.518 517.82 541.479 517.82Z'
          fill='#006360'
        />
        <path
          d='M541.479 513.479C550.44 513.479 557.705 509.876 557.705 505.431C557.705 500.987 550.44 497.384 541.479 497.384C532.518 497.384 525.254 500.987 525.254 505.431C525.254 509.876 532.518 513.479 541.479 513.479Z'
          fill='black'
        />
        <path
          d='M541.479 512.856C549.657 512.856 556.418 509.883 557.656 505.942C557.085 510.091 550.074 513.437 541.479 513.437C532.884 513.437 525.872 510.132 525.301 505.942C526.551 509.856 533.3 512.856 541.479 512.856Z'
          fill='#E0F7FA'
        />
        <path
          d='M527.121 503.551C527.121 507.478 533.549 510.672 541.478 510.672C549.406 510.672 555.834 507.478 555.834 503.551C555.834 499.624 549.406 496.43 541.478 496.43C533.549 496.43 527.121 499.624 527.121 503.551Z'
          fill='#006064'
        />
        <path
          d='M541.478 509.982C549.268 509.982 555.584 506.849 555.584 502.985C555.584 499.121 549.268 495.988 541.478 495.988C533.687 495.988 527.371 499.121 527.371 502.985C527.371 506.849 533.687 509.982 541.478 509.982Z'
          fill='#8D8D8D'
        />
        <path d='M555.584 499.624H527.371V502.984H555.584V499.624Z' fill='#8D8D8D' />
        <path
          d='M541.478 506.621C549.268 506.621 555.584 503.489 555.584 499.625C555.584 495.76 549.268 492.628 541.478 492.628C533.687 492.628 527.371 495.76 527.371 499.625C527.371 503.489 533.687 506.621 541.478 506.621Z'
          fill='#E1E1E1'
        />
        <path
          d='M541.478 505.181C546.394 505.181 550.739 503.965 553.382 502.084C551.001 504.241 546.596 505.693 541.478 505.693C536.359 505.693 532.014 504.241 529.633 502.084C532.228 503.965 536.561 505.181 541.478 505.181Z'
          fill='black'
        />
        <path
          d='M541.381 493.499C536.155 493.499 531.667 494.992 529.477 497.149C531.226 494.702 535.881 493.001 541.381 493.001C546.881 493.001 551.559 494.757 553.285 497.149C551.083 494.881 546.607 493.499 541.381 493.499Z'
          fill='black'
        />
        <path
          d='M541.478 506.067C549.085 506.067 555.263 503.08 555.561 499.347C555.567 499.439 555.567 499.531 555.561 499.623C555.561 503.495 549.251 506.62 541.454 506.62C533.657 506.62 527.348 503.495 527.348 499.623C527.348 499.526 527.348 499.443 527.348 499.347C527.693 503.08 533.871 506.067 541.478 506.067Z'
          fill='#E0F7FA'
        />
        <path
          d='M503.386 518.982C513.037 518.982 520.861 515.1 520.861 510.312C520.861 505.524 513.037 501.643 503.386 501.643C493.734 501.643 485.91 505.524 485.91 510.312C485.91 515.1 493.734 518.982 503.386 518.982Z'
          fill='#171417'
        />
        <path
          d='M503.386 502.556C494.041 502.556 486.434 506.193 485.946 510.769C485.94 510.617 485.94 510.465 485.946 510.313C485.946 505.529 493.779 501.657 503.434 501.657C513.088 501.657 520.909 505.529 520.909 510.313C520.916 510.465 520.916 510.617 520.909 510.769C520.338 506.193 512.719 502.556 503.386 502.556Z'
          fill='#231F23'
        />
        <path
          d='M503.386 517.82C512.347 517.82 519.611 514.217 519.611 509.772C519.611 505.328 512.347 501.725 503.386 501.725C494.425 501.725 487.16 505.328 487.16 509.772C487.16 514.217 494.425 517.82 503.386 517.82Z'
          fill='#006360'
        />
        <path d='M519.611 505.693H487.148V509.772H519.611V505.693Z' fill='#006360' />
        <path
          d='M503.386 513.479C512.347 513.479 519.611 509.876 519.611 505.431C519.611 500.987 512.347 497.384 503.386 497.384C494.425 497.384 487.16 500.987 487.16 505.431C487.16 509.876 494.425 513.479 503.386 513.479Z'
          fill='black'
        />
        <path
          d='M503.385 512.856C511.551 512.856 518.313 509.883 519.551 505.942C518.991 510.091 511.968 513.437 503.385 513.437C494.802 513.437 487.778 510.132 487.207 505.942C488.445 509.856 495.207 512.856 503.385 512.856Z'
          fill='#E0F7FA'
        />
        <path
          d='M489.027 503.551C489.027 507.478 495.456 510.672 503.384 510.672C511.312 510.672 517.728 507.478 517.728 503.551C517.728 499.624 511.3 496.43 503.384 496.43C495.468 496.43 489.027 499.624 489.027 503.551Z'
          fill='#006064'
        />
        <path
          d='M503.384 509.982C511.175 509.982 517.49 506.849 517.49 502.985C517.49 499.121 511.175 495.988 503.384 495.988C495.593 495.988 489.277 499.121 489.277 502.985C489.277 506.849 495.593 509.982 503.384 509.982Z'
          fill='#8D8D8D'
        />
        <path d='M517.479 499.624H489.266V502.984H517.479V499.624Z' fill='#8D8D8D' />
        <path
          d='M503.384 506.621C511.175 506.621 517.49 503.489 517.49 499.625C517.49 495.76 511.175 492.628 503.384 492.628C495.593 492.628 489.277 495.76 489.277 499.625C489.277 503.489 495.593 506.621 503.384 506.621Z'
          fill='#E1E1E1'
        />
        <path
          d='M503.384 505.181C508.3 505.181 512.633 503.965 515.217 502.084C512.836 504.241 508.431 505.693 503.384 505.693C498.336 505.693 493.92 504.241 491.539 502.084C494.122 503.965 498.455 505.181 503.384 505.181Z'
          fill='black'
        />
        <path
          d='M503.279 493.499C498.053 493.499 493.577 494.992 491.375 497.149C493.125 494.702 497.779 493.001 503.279 493.001C508.779 493.001 513.457 494.757 515.183 497.149C512.993 494.881 508.505 493.499 503.279 493.499Z'
          fill='black'
        />
        <path
          d='M503.385 506.067C510.98 506.067 517.17 503.08 517.456 499.347C517.462 499.439 517.462 499.531 517.456 499.623C517.456 503.495 511.135 506.62 503.349 506.62C495.564 506.62 489.231 503.495 489.231 499.623C489.225 499.531 489.225 499.439 489.231 499.347C489.6 503.08 495.778 506.067 503.385 506.067Z'
          fill='#E0F7FA'
        />
        <path
          d='M465.28 518.982C474.932 518.982 482.756 515.1 482.756 510.312C482.756 505.524 474.932 501.643 465.28 501.643C455.629 501.643 447.805 505.524 447.805 510.312C447.805 515.1 455.629 518.982 465.28 518.982Z'
          fill='#171417'
        />
        <path
          d='M465.281 502.556C455.936 502.556 448.329 506.193 447.853 510.769C447.841 510.618 447.841 510.465 447.853 510.313C447.853 505.529 455.674 501.657 465.328 501.657C474.983 501.657 482.804 505.529 482.804 510.313C482.816 510.465 482.816 510.618 482.804 510.769C482.233 506.193 474.626 502.556 465.281 502.556Z'
          fill='#231F23'
        />
        <path
          d='M465.28 517.82C474.241 517.82 481.506 514.217 481.506 509.772C481.506 505.328 474.241 501.725 465.28 501.725C456.319 501.725 449.055 505.328 449.055 509.772C449.055 514.217 456.319 517.82 465.28 517.82Z'
          fill='#006360'
        />
        <path d='M481.502 505.693H449.039V509.772H481.502V505.693Z' fill='#006360' />
        <path
          d='M465.28 513.479C474.241 513.479 481.506 509.876 481.506 505.431C481.506 500.987 474.241 497.384 465.28 497.384C456.319 497.384 449.055 500.987 449.055 505.431C449.055 509.876 456.319 513.479 465.28 513.479Z'
          fill='black'
        />
        <path
          d='M465.279 512.856C473.458 512.856 480.207 509.883 481.457 505.942C480.886 510.091 473.874 513.437 465.279 513.437C456.685 513.437 449.673 510.132 449.102 505.942C450.34 509.856 457.101 512.856 465.279 512.856Z'
          fill='#E0F7FA'
        />
        <path
          d='M450.922 503.551C450.922 507.478 457.35 510.672 465.278 510.672C473.207 510.672 479.635 507.478 479.635 503.551C479.635 499.624 473.207 496.43 465.278 496.43C457.35 496.43 450.922 499.624 450.922 503.551Z'
          fill='#006064'
        />
        <path
          d='M465.278 509.982C473.069 509.982 479.385 506.849 479.385 502.985C479.385 499.121 473.069 495.988 465.278 495.988C457.488 495.988 451.172 499.121 451.172 502.985C451.172 506.849 457.488 509.982 465.278 509.982Z'
          fill='#8D8D8D'
        />
        <path d='M479.385 499.624H451.172V502.984H479.385V499.624Z' fill='#8D8D8D' />
        <path
          d='M465.278 506.621C473.069 506.621 479.385 503.489 479.385 499.625C479.385 495.76 473.069 492.628 465.278 492.628C457.488 492.628 451.172 495.76 451.172 499.625C451.172 503.489 457.488 506.621 465.278 506.621Z'
          fill='#E1E1E1'
        />
        <path
          d='M465.278 505.181C470.195 505.181 474.528 503.965 477.123 502.084C474.742 504.241 470.338 505.693 465.278 505.693C460.219 505.693 455.814 504.241 453.434 502.084C456.017 503.965 460.362 505.181 465.278 505.181Z'
          fill='black'
        />
        <path
          d='M465.185 493.499C459.96 493.499 455.472 494.992 453.281 497.149C455.043 494.702 459.686 493.001 465.185 493.001C470.685 493.001 475.364 494.757 477.09 497.149C474.887 494.881 470.411 493.499 465.185 493.499Z'
          fill='black'
        />
        <path
          d='M465.279 506.067C472.886 506.067 479.064 503.08 479.362 499.347C479.362 499.443 479.362 499.526 479.362 499.623C479.362 503.495 473.041 506.62 465.255 506.62C457.47 506.62 451.149 503.495 451.149 499.623C451.143 499.531 451.143 499.439 451.149 499.347C451.494 503.08 457.672 506.067 465.279 506.067Z'
          fill='#E0F7FA'
        />
        <path
          d='M546.753 534.261C556.404 534.261 564.228 530.38 564.228 525.592C564.228 520.803 556.404 516.922 546.753 516.922C537.101 516.922 529.277 520.803 529.277 525.592C529.277 530.38 537.101 534.261 546.753 534.261Z'
          fill='#171417'
        />
        <path
          d='M546.753 517.834C537.408 517.834 529.801 521.471 529.325 526.048C529.313 525.896 529.313 525.743 529.325 525.592C529.325 520.807 537.146 516.922 546.801 516.922C556.455 516.922 564.276 520.807 564.276 525.592C564.276 525.744 564.276 525.896 564.276 526.048C563.705 521.471 556.098 517.834 546.753 517.834Z'
          fill='#231F23'
        />
        <path
          d='M546.753 533.1C555.714 533.1 562.978 529.497 562.978 525.052C562.978 520.608 555.714 517.005 546.753 517.005C537.792 517.005 530.527 520.608 530.527 525.052C530.527 529.497 537.792 533.1 546.753 533.1Z'
          fill='#006360'
        />
        <path d='M562.979 520.973H530.516V525.052H562.979V520.973Z' fill='#006360' />
        <path
          d='M546.753 528.757C555.714 528.757 562.978 525.154 562.978 520.71C562.978 516.265 555.714 512.662 546.753 512.662C537.792 512.662 530.527 516.265 530.527 520.71C530.527 525.154 537.792 528.757 546.753 528.757Z'
          fill='black'
        />
        <path
          d='M546.752 528.066C554.93 528.066 561.68 525.093 562.93 521.152C562.359 525.301 555.347 528.661 546.752 528.661C538.157 528.661 531.146 525.342 530.574 521.152C531.812 525.135 538.574 528.066 546.752 528.066Z'
          fill='#E0F7FA'
        />
        <path
          d='M532.395 518.83C532.395 522.757 538.823 525.951 546.751 525.951C554.679 525.951 561.108 522.757 561.108 518.83C561.108 514.903 554.679 511.709 546.751 511.709C538.823 511.709 532.395 514.903 532.395 518.83Z'
          fill='#006064'
        />
        <path
          d='M546.751 525.259C554.542 525.259 560.858 522.126 560.858 518.262C560.858 514.398 554.542 511.266 546.751 511.266C538.96 511.266 532.645 514.398 532.645 518.262C532.645 522.126 538.96 525.259 546.751 525.259Z'
          fill='#8D8D8D'
        />
        <path d='M560.858 514.902H532.645V518.262H560.858V514.902Z' fill='#8D8D8D' />
        <path
          d='M546.751 521.899C554.542 521.899 560.858 518.767 560.858 514.903C560.858 511.039 554.542 507.906 546.751 507.906C538.96 507.906 532.645 511.039 532.645 514.903C532.645 518.767 538.96 521.899 546.751 521.899Z'
          fill='#E1E1E1'
        />
        <path
          d='M546.752 520.462C551.668 520.462 556.002 519.231 558.597 517.364C556.216 519.521 551.811 520.973 546.752 520.973C541.693 520.973 537.229 519.59 534.848 517.364C537.49 519.231 541.835 520.462 546.752 520.462Z'
          fill='black'
        />
        <path
          d='M546.654 508.708C541.416 508.708 536.94 510.201 534.75 512.372C536.5 509.924 541.154 508.224 546.654 508.224C552.154 508.224 556.832 509.98 558.558 512.372C556.356 510.187 551.88 508.708 546.654 508.708Z'
          fill='black'
        />
        <path
          d='M546.752 521.347C554.359 521.347 560.537 518.36 560.835 514.627C560.835 514.724 560.835 514.807 560.835 514.903C560.835 518.761 554.513 521.9 546.728 521.9C538.943 521.9 532.622 518.761 532.622 514.903C532.616 514.811 532.616 514.719 532.622 514.627C532.967 518.388 539.145 521.347 546.752 521.347Z'
          fill='#E0F7FA'
        />
        <path
          d='M508.647 534.261C518.299 534.261 526.123 530.38 526.123 525.592C526.123 520.803 518.299 516.922 508.647 516.922C498.996 516.922 491.172 520.803 491.172 525.592C491.172 530.38 498.996 534.261 508.647 534.261Z'
          fill='#171417'
        />
        <path
          d='M508.647 517.834C499.302 517.834 491.695 521.471 491.219 526.048C491.219 525.896 491.219 525.744 491.219 525.592C491.219 520.807 499.04 516.922 508.694 516.922C518.349 516.922 526.17 520.807 526.17 525.592C526.181 525.743 526.181 525.896 526.17 526.048C525.598 521.471 517.991 517.834 508.647 517.834Z'
          fill='#231F23'
        />
        <path
          d='M508.647 533.1C517.608 533.1 524.873 529.497 524.873 525.052C524.873 520.608 517.608 517.005 508.647 517.005C499.686 517.005 492.422 520.608 492.422 525.052C492.422 529.497 499.686 533.1 508.647 533.1Z'
          fill='#006360'
        />
        <path d='M524.885 520.973H492.422V525.052H524.885V520.973Z' fill='#006360' />
        <path
          d='M508.647 528.757C517.608 528.757 524.873 525.154 524.873 520.71C524.873 516.265 517.608 512.662 508.647 512.662C499.686 512.662 492.422 516.265 492.422 520.71C492.422 525.154 499.686 528.757 508.647 528.757Z'
          fill='black'
        />
        <path
          d='M508.647 528.066C516.825 528.066 523.586 525.093 524.824 521.152C524.253 525.301 517.241 528.661 508.647 528.661C500.052 528.661 493.04 525.342 492.469 521.152C493.719 525.135 500.468 528.066 508.647 528.066Z'
          fill='#E0F7FA'
        />
        <path
          d='M494.289 518.83C494.289 522.757 500.717 525.951 508.646 525.951C516.574 525.951 523.002 522.757 523.002 518.83C523.002 514.903 516.574 511.709 508.646 511.709C500.717 511.709 494.289 514.903 494.289 518.83Z'
          fill='#006064'
        />
        <path
          d='M508.646 525.259C516.436 525.259 522.752 522.126 522.752 518.262C522.752 514.398 516.436 511.266 508.646 511.266C500.855 511.266 494.539 514.398 494.539 518.262C494.539 522.126 500.855 525.259 508.646 525.259Z'
          fill='#8D8D8D'
        />
        <path d='M522.752 514.902H494.539V518.262H522.752V514.902Z' fill='#8D8D8D' />
        <path
          d='M508.646 521.899C516.436 521.899 522.752 518.767 522.752 514.903C522.752 511.039 516.436 507.906 508.646 507.906C500.855 507.906 494.539 511.039 494.539 514.903C494.539 518.767 500.855 521.899 508.646 521.899Z'
          fill='#E1E1E1'
        />
        <path
          d='M508.645 520.462C513.562 520.462 517.907 519.231 520.49 517.364C518.109 519.521 513.705 520.973 508.645 520.973C503.586 520.973 499.182 519.59 496.801 517.364C499.396 519.231 503.729 520.462 508.645 520.462Z'
          fill='black'
        />
        <path
          d='M508.549 508.708C503.323 508.708 498.835 510.201 496.645 512.372C498.394 509.924 503.049 508.224 508.549 508.224C514.049 508.224 518.727 509.98 520.453 512.372C518.251 510.187 513.787 508.708 508.549 508.708Z'
          fill='black'
        />
        <path
          d='M508.646 521.347C516.253 521.347 522.431 518.36 522.729 514.627C522.735 514.719 522.735 514.811 522.729 514.903C522.729 518.761 516.419 521.9 508.622 521.9C500.825 521.9 494.516 518.761 494.516 514.903C494.516 514.807 494.516 514.724 494.516 514.627C494.861 518.388 501.039 521.347 508.646 521.347Z'
          fill='#E0F7FA'
        />
        <path
          d='M470.554 534.261C480.205 534.261 488.029 530.38 488.029 525.592C488.029 520.803 480.205 516.922 470.554 516.922C460.902 516.922 453.078 520.803 453.078 525.592C453.078 530.38 460.902 534.261 470.554 534.261Z'
          fill='#171417'
        />
        <path
          d='M470.553 517.834C461.208 517.834 453.601 521.471 453.113 526.048C453.113 525.896 453.113 525.744 453.113 525.592C453.113 520.807 460.946 516.922 470.601 516.922C480.255 516.922 488.076 520.807 488.076 525.592C488.076 525.744 488.076 525.896 488.076 526.048C487.505 521.471 479.886 517.834 470.553 517.834Z'
          fill='#231F23'
        />
        <path
          d='M470.554 533.1C479.515 533.1 486.779 529.497 486.779 525.052C486.779 520.608 479.515 517.005 470.554 517.005C461.593 517.005 454.328 520.608 454.328 525.052C454.328 529.497 461.593 533.1 470.554 533.1Z'
          fill='#006360'
        />
        <path d='M486.779 520.973H454.316V525.052H486.779V520.973Z' fill='#006360' />
        <path
          d='M470.554 528.757C479.515 528.757 486.779 525.154 486.779 520.71C486.779 516.265 479.515 512.662 470.554 512.662C461.593 512.662 454.328 516.265 454.328 520.71C454.328 525.154 461.593 528.757 470.554 528.757Z'
          fill='black'
        />
        <path
          d='M470.553 528.066C478.719 528.066 485.481 525.093 486.719 521.152C486.159 525.301 479.136 528.661 470.553 528.661C461.97 528.661 454.946 525.342 454.375 521.152C455.613 525.135 462.375 528.066 470.553 528.066Z'
          fill='#E0F7FA'
        />
        <path
          d='M456.195 518.83C456.195 522.757 462.624 525.951 470.552 525.951C478.48 525.951 484.896 522.757 484.896 518.83C484.896 514.903 478.468 511.709 470.552 511.709C462.636 511.709 456.195 514.903 456.195 518.83Z'
          fill='#006064'
        />
        <path
          d='M470.552 525.259C478.343 525.259 484.658 522.126 484.658 518.262C484.658 514.398 478.343 511.266 470.552 511.266C462.761 511.266 456.445 514.398 456.445 518.262C456.445 522.126 462.761 525.259 470.552 525.259Z'
          fill='#8D8D8D'
        />
        <path d='M484.647 514.902H456.434V518.262H484.647V514.902Z' fill='#8D8D8D' />
        <path
          d='M470.552 521.899C478.343 521.899 484.658 518.767 484.658 514.903C484.658 511.039 478.343 507.906 470.552 507.906C462.761 507.906 456.445 511.039 456.445 514.903C456.445 518.767 462.761 521.899 470.552 521.899Z'
          fill='#E1E1E1'
        />
        <path
          d='M470.552 520.462C475.468 520.462 479.801 519.231 482.385 517.364C480.004 519.521 475.599 520.973 470.552 520.973C465.504 520.973 461.088 519.59 458.707 517.364C461.29 519.231 465.623 520.462 470.552 520.462Z'
          fill='black'
        />
        <path
          d='M470.447 508.708C465.221 508.708 460.745 510.201 458.543 512.372C460.293 509.924 464.947 508.224 470.447 508.224C475.947 508.224 480.625 509.98 482.351 512.372C480.161 510.187 475.697 508.708 470.447 508.708Z'
          fill='black'
        />
        <path
          d='M470.553 521.347C478.148 521.347 484.338 518.36 484.624 514.627C484.63 514.719 484.63 514.811 484.624 514.903C484.624 518.761 478.303 521.9 470.517 521.9C462.732 521.9 456.399 518.761 456.399 514.903C456.393 514.811 456.393 514.719 456.399 514.627C456.768 518.388 462.946 521.347 470.553 521.347Z'
          fill='#E0F7FA'
        />
        <path
          d='M398.518 531.04C411.49 531.04 422.005 525.822 422.005 519.384C422.005 512.946 411.49 507.728 398.518 507.728C385.547 507.728 375.031 512.946 375.031 519.384C375.031 525.822 385.547 531.04 398.518 531.04Z'
          fill='#171417'
        />
        <path
          d='M398.519 508.957C411.078 508.957 421.304 513.852 421.947 520.019C421.959 519.816 421.959 519.613 421.947 519.41C421.947 512.98 411.435 507.768 398.46 507.768C385.484 507.768 374.973 512.98 374.973 519.41C374.98 519.614 375 519.818 375.032 520.019C375.699 513.852 385.96 508.957 398.519 508.957Z'
          fill='#231F23'
        />
        <path
          d='M398.521 529.989C410.329 529.989 419.901 525.241 419.901 519.384C419.901 513.527 410.329 508.778 398.521 508.778C386.713 508.778 377.141 513.527 377.141 519.384C377.141 525.241 386.713 529.989 398.521 529.989Z'
          fill='url(#paint11_linear_3746_5487)'
        />
        <path
          d='M418.553 520.116C418.553 514.128 408.697 509.289 396.53 509.289C395.78 509.289 395.054 509.289 394.328 509.289C395.519 509.192 396.709 509.137 397.899 509.137C409.708 509.137 419.327 513.741 419.327 519.424C419.265 520.37 419.018 521.288 418.605 522.107C418.193 522.926 417.626 523.626 416.946 524.153C417.916 523.103 418.492 521.654 418.553 520.116Z'
          fill='#2F3135'
        />
        <path
          d='M378.438 518.665C378.438 524.638 388.294 529.478 400.46 529.478C401.21 529.478 401.949 529.478 402.675 529.478C401.484 529.574 400.294 529.644 399.103 529.644C387.294 529.644 377.676 525.025 377.676 519.342C377.74 518.396 377.988 517.48 378.4 516.661C378.812 515.842 379.379 515.142 380.057 514.613C379.084 515.668 378.503 517.121 378.438 518.665Z'
          fill='#2F3135'
        />
        <g opacity='0.25'>
          <path
            d='M413.994 518.389C413.994 513.798 406.399 510.092 397.019 510.092C396.447 510.092 395.828 510.092 395.328 510.092C396.233 510.009 397.161 509.968 398.102 509.968C407.197 509.968 414.565 513.521 414.565 517.905C414.516 518.634 414.324 519.34 414.004 519.971C413.685 520.601 413.246 521.138 412.72 521.541C413.482 520.723 413.939 519.592 413.994 518.389Z'
            fill='#59535E'
          />
          <path
            d='M383.056 517.254C383.056 521.859 390.651 525.551 400.02 525.551C400.591 525.551 401.15 525.551 401.71 525.551C400.805 525.634 399.889 525.675 398.936 525.675C389.841 525.675 382.473 522.121 382.473 517.752C382.524 517.021 382.716 516.314 383.036 515.682C383.355 515.049 383.793 514.509 384.318 514.102C383.559 514.921 383.106 516.052 383.056 517.254Z'
            fill='#59535E'
          />
        </g>
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M398.519 501.85C412.47 501.85 423.779 488.714 423.779 472.509C423.779 456.304 412.47 443.167 398.519 443.167C384.567 443.167 373.258 456.304 373.258 472.509C373.258 488.714 384.567 501.85 398.519 501.85Z'
          fill='url(#paint12_radial_3746_5487)'
        />
        <path
          d='M398.519 519.204C397.767 519.204 397.023 519.031 396.329 518.697C395.635 518.363 395.005 517.872 394.474 517.255C393.943 516.637 393.523 515.904 393.236 515.097C392.95 514.29 392.803 513.425 392.805 512.553V488.645C392.803 487.772 392.95 486.908 393.236 486.101C393.523 485.294 393.943 484.561 394.474 483.943C395.005 483.325 395.635 482.835 396.329 482.501C397.023 482.166 397.767 481.994 398.519 481.994C400.037 481.994 401.494 482.695 402.568 483.942C403.641 485.189 404.245 486.881 404.245 488.645V512.553C404.245 514.317 403.641 516.008 402.568 517.256C401.494 518.503 400.037 519.204 398.519 519.204Z'
          fill='#656B71'
        />
        <path
          d='M395.664 515.401C395.134 515.401 394.625 515.156 394.25 514.721C393.875 514.285 393.664 513.694 393.664 513.078V490.733C393.664 490.117 393.875 489.526 394.25 489.091C394.625 488.655 395.134 488.41 395.664 488.41C396.194 488.41 396.703 488.655 397.078 489.091C397.453 489.526 397.664 490.117 397.664 490.733V513.037C397.669 513.345 397.62 513.652 397.522 513.938C397.424 514.225 397.277 514.486 397.091 514.706C396.905 514.926 396.683 515.101 396.438 515.22C396.193 515.34 395.93 515.401 395.664 515.401Z'
          fill='#70767C'
        />
        <path
          d='M398.519 481.994C397.936 482.004 397.359 482.116 396.805 482.326C397.962 482.751 398.973 483.592 399.692 484.728C400.411 485.864 400.8 487.235 400.804 488.645V512.553C400.803 513.962 400.415 515.333 399.695 516.467C398.976 517.602 397.963 518.439 396.805 518.858C397.356 519.079 397.935 519.196 398.519 519.204C400.038 519.204 401.494 518.503 402.568 517.256C403.642 516.008 404.245 514.317 404.245 512.553V488.645C404.245 486.881 403.642 485.189 402.568 483.942C401.494 482.695 400.038 481.994 398.519 481.994Z'
          fill='#53585E'
        />
        <path
          d='M398.519 492.254C407.907 492.254 415.518 483.413 415.518 472.508C415.518 461.603 407.907 452.763 398.519 452.763C389.13 452.763 381.52 461.603 381.52 472.508C381.52 483.413 389.13 492.254 398.519 492.254Z'
          fill='#28FFFB'
        />
        <path
          d='M413.792 472.964C413.858 472.313 413.894 471.657 413.9 471.001C413.9 467.073 412.673 463.279 410.446 460.324C408.22 457.369 405.145 455.453 401.793 454.934C405.029 456.363 407.863 458.803 409.98 461.985C412.098 465.167 413.417 468.966 413.792 472.964Z'
          fill='white'
        />
        <path
          d='M400.556 481.938C397.054 481.938 393.611 480.886 390.562 478.885C387.512 476.884 384.96 474.001 383.152 470.517C383.152 470.655 383.152 470.793 383.152 470.931C383.16 474.971 384.369 478.883 386.573 482.006C388.778 485.13 391.843 487.274 395.252 488.076C398.66 488.877 402.202 488.287 405.279 486.405C408.356 484.524 410.779 481.466 412.139 477.748C408.736 480.482 404.694 481.944 400.556 481.938Z'
          fill='white'
        />
        <path
          opacity='0.61'
          d='M399.662 477.625C404.864 477.625 409.412 475.496 411.924 472.329C411.924 472.329 411.924 472.454 411.924 472.523C411.924 477.003 407.162 480.639 401.305 480.639C396.9 480.639 393.115 478.593 391.508 475.675C394.075 476.993 396.855 477.658 399.662 477.625Z'
          fill='#E0F7FA'
        />
        <path
          d='M398.137 453.854C397.864 453.854 397.602 453.854 397.328 453.854C393.872 453.856 390.542 455.36 387.993 458.07C385.444 460.78 383.861 464.5 383.555 468.498C384.45 464.645 386.317 461.192 388.916 458.583C391.515 455.973 394.726 454.326 398.137 453.854Z'
          fill='#E0F7FA'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M546.753 525.605C556.95 525.605 565.216 520.938 565.216 515.18C565.216 509.422 556.95 504.754 546.753 504.754C536.555 504.754 528.289 509.422 528.289 515.18C528.289 520.938 536.555 525.605 546.753 525.605Z'
          fill='url(#paint13_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M508.549 525.605C518.747 525.605 527.013 520.938 527.013 515.18C527.013 509.422 518.747 504.754 508.549 504.754C498.352 504.754 490.086 509.422 490.086 515.18C490.086 520.938 498.352 525.605 508.549 525.605Z'
          fill='url(#paint14_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M470.35 525.605C480.547 525.605 488.814 520.938 488.814 515.18C488.814 509.422 480.547 504.754 470.35 504.754C460.153 504.754 451.887 509.422 451.887 515.18C451.887 520.938 460.153 525.605 470.35 525.605Z'
          fill='url(#paint15_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M541.479 510.326C551.676 510.326 559.943 505.658 559.943 499.9C559.943 494.142 551.676 489.475 541.479 489.475C531.282 489.475 523.016 494.142 523.016 499.9C523.016 505.658 531.282 510.326 541.479 510.326Z'
          fill='url(#paint16_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M503.276 510.326C513.473 510.326 521.739 505.658 521.739 499.9C521.739 494.142 513.473 489.475 503.276 489.475C493.079 489.475 484.812 494.142 484.812 499.9C484.812 505.658 493.079 510.326 503.276 510.326Z'
          fill='url(#paint17_radial_3746_5487)'
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d='M465.077 510.326C475.274 510.326 483.54 505.658 483.54 499.9C483.54 494.142 475.274 489.475 465.077 489.475C454.88 489.475 446.613 494.142 446.613 499.9C446.613 505.658 454.88 510.326 465.077 510.326Z'
          fill='url(#paint18_radial_3746_5487)'
        />
        <path d='M585.942 -56L594.668 51.6457H573.312L564.598 -56H585.942Z' fill='#E1E1E1' />
        <path d='M649.27 534.552H627.914L630.093 554.394H651.449L649.27 534.552Z' fill='#E1E1E1' />
        <path d='M651.45 554.395H630.094V631.758H651.45V554.395Z' fill='white' />
        <path d='M84.6164 -56L75.8906 51.6457H97.2468L105.961 -56H84.6164Z' fill='#E1E1E1' />
        <path d='M21.2859 534.552H42.6421L40.4636 554.394H19.1074L21.2859 534.552Z' fill='#E1E1E1' />
        <path d='M40.4636 554.395H19.1074V631.758H40.4636V554.395Z' fill='white' />
        <path
          d='M680.109 82.8247H0.814453L13.2484 -24.835H667.675L680.109 82.8247Z'
          fill='url(#paint19_linear_3746_5487)'
        />
        <path
          d='M680.109 82.8252H0.814453L31.9162 101.241H649.008L680.109 82.8252Z'
          fill='url(#paint20_linear_3746_5487)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_3746_5487'
            x1='335.272'
            y1='165.777'
            x2='335.272'
            y2='60.8693'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#17191D' />
            <stop offset='0.4' stopColor='#131519' />
            <stop offset='0.81' stopColor='#08090D' />
            <stop offset='1' stopColor='#000005' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_3746_5487'
            x1='335.472'
            y1='682.572'
            x2='335.472'
            y2='507.362'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.33' stopColor='#1E2024' />
            <stop offset='0.86' stopColor='#07080D' />
            <stop offset='1' stopColor='#000005' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_3746_5487'
            x1='334.474'
            y1='467.484'
            x2='334.474'
            y2='559.263'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='1' stopColor='#1A1C20' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_3746_5487'
            x1='131.794'
            y1='504.201'
            x2='131.794'
            y2='528.123'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.56' stopColor='#232529' />
            <stop offset='1' stopColor='#1A1C20' />
          </linearGradient>
          <radialGradient
            id='paint4_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(131.792 472.509) scale(25.2608 29.3416)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint5_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(197.732 523.573) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint6_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(235.88 523.573) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint7_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(274.032 523.573) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint8_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(202.989 508.087) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint9_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(241.137 508.087) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint10_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(279.289 508.087) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <linearGradient
            id='paint11_linear_3746_5487'
            x1='398.521'
            y1='504.201'
            x2='398.521'
            y2='528.123'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.56' stopColor='#232529' />
            <stop offset='1' stopColor='#1A1C20' />
          </linearGradient>
          <radialGradient
            id='paint12_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(398.519 472.509) scale(25.2608 29.3416)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint13_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(545.016 523.573) rotate(180) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint14_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(506.864 523.573) rotate(180) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint15_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(468.716 523.573) rotate(180) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint16_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(539.759 508.087) rotate(180) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint17_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(501.607 508.087) rotate(180) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='paint18_radial_3746_5487'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(463.459 508.087) rotate(180) scale(18.4404 10.5629)'
          >
            <stop offset='0.59' stopColor='#02D9D4' />
            <stop offset='0.63' stopColor='#02C1BD' stopOpacity='0.89' />
            <stop offset='0.73' stopColor='#017D7C' stopOpacity='0.58' />
            <stop offset='0.82' stopColor='#014749' stopOpacity='0.33' />
            <stop offset='0.9' stopColor='#002124' stopOpacity='0.15' />
            <stop offset='0.96' stopColor='#00090D' stopOpacity='0.04' />
            <stop offset='1' stopColor='#000005' stopOpacity='0' />
          </radialGradient>
          <linearGradient
            id='paint19_linear_3746_5487'
            x1='340.453'
            y1='-19.1097'
            x2='340.453'
            y2='94.4135'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.31' stopColor='#232529' />
            <stop offset='0.63' stopColor='#17191D' />
            <stop offset='0.95' stopColor='#040409' />
            <stop offset='1' stopColor='#000005' />
          </linearGradient>
          <linearGradient
            id='paint20_linear_3746_5487'
            x1='340.453'
            y1='29.879'
            x2='340.453'
            y2='120.259'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#27292D' />
            <stop offset='0.31' stopColor='#232529' />
            <stop offset='0.63' stopColor='#17191D' />
            <stop offset='0.95' stopColor='#040409' />
            <stop offset='1' stopColor='#000005' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
