import React, { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'

const Menu = ({ item, idx }) => {
  const route = useLocation()
  const [more, setMore] = useState(false)

  return (
    <li key={`main-${idx}`} className={`w-full`}>
      {item.dropdown ? (
        <div className='relative w-full'>
          <div
            onClick={() => {
              setMore(!more)
            }}
            className='flex items-center space-x-2 px-20 py-5 cursor-pointer font-semibold text-light hover:text-white transition-colors'
          >
            <img
              alt='dropdown'
              src='/images/header/dropdown-arrow.svg'
              className={`${!more ? 'rotate-180' : 'rotate-0'} transition-all duration-150 ease-in-out`}
            />
            {item.icon && <div className='w-6 h-6'>{item.icon}</div>}
            <span>{item.name}</span>
          </div>
          {more && (
            <div
              onClick={() => {
                setMore(!more)
              }}
              className='text-left flex flex-col font-light text-white pl-3'
            >
              {item.link.map((_item, j) => {
                return _item.external ? (
                  <div
                    onClick={() => {
                      window.open(_item.link, '_blank')
                    }}
                    key={`more-${j}`}
                    className='px-20 py-2 cursor-pointer hover:opacity-70'
                  >
                    {_item.name}
                  </div>
                ) : (
                  <Link
                    key={`more-${j}`}
                    onClick={() => setMore(false)}
                    className={`flex hover:opacity-70 items-center gap-y-2.5 py-2 px-20 font-light text-white ${
                      route.pathname.includes(item.link) ? 'text-white' : ''
                    }`}
                    to={_item.link}
                  >
                    {_item.name}
                  </Link>
                )
              })}
            </div>
          )}
        </div>
      ) : (
        <Link
          className={`flex items-center gap-2.5 py-5 px-20 font-semibold text-light hover:text-white transition-colors ${
            route.pathname.includes(item.link) ? 'text-white' : ''
          }`}
          to={item.link}
          target={item.external && '_blank'}
        >
          {item.icon && <div className={item.iconOnly ? 'w-[123px] h-[24px]' : 'w-6 h-6'}>{item.icon}</div>}
          {!item.iconOnly && item.name}
        </Link>
      )}
    </li>
  )
}

export default Menu
