import SwapIcon from '../../../icons/SwapIcon'
import LiquidityIcon from '../../../icons/LiquidityIcon'
import LockIcon from '../../../icons/LockIcon'
import VoteIcon from '../../../icons/VoteIcon'
import RewardIcon from '../../../icons/RewardIcon'
// import GaugesIcon from '../../../icons/GaugesIcon'
import { ORetroIcon } from 'icons/ORetroIcon'
import { ArcadeIcon } from 'icons/ArcadeIcon'
import { SingleDepositIcon } from 'icons/SingleDepositIcon'
// import OptionsIcon from '../../../icons/OptionsIcon'

// import NftIcon from '../../../icons/NftIcon'
// import MoreIcon from '../../../icons/MoreIcon'

const navLinks = [
  {
    name: 'SWAP',
    link: '/swap',
    icon: <SwapIcon />,
  },
  {
    name: 'SINGLE DEPOSIT',
    icon: <SingleDepositIcon />,
    link: '/single-deposit',
  },
  {
    name: 'LIQUIDITY',
    icon: <LiquidityIcon />,
    link: '/liquidity',
  },
  {
    name: 'LOCK',
    link: '/lock',
    icon: <LockIcon />,
  },
  {
    name: 'VOTE',
    link: '/vote',
    icon: <VoteIcon />,
  },
  {
    name: 'REWARDS',
    link: '/rewards',
    icon: <RewardIcon />,
  },
  {
    name: 'oRETRO',
    link: '/options',
    icon: <ORetroIcon />,
  },
  {
    name: 'ARCADE',
    link: 'https://arcade.retro.finance/',
    icon: <ArcadeIcon />,
    iconOnly: true,
    external: true,
  },
  {
    dropdown: true,
    name: 'MORE',
    link: [
      {
        name: 'Bribe',
        link: '/bribes',
        // icon: <GaugesIcon />,
      },
      {
        name: 'Dashboard',
        link: '/dashboard',
      },
      {
        name: 'veNFT Manager',
        link: '/ve-manager',
      },
      {
        name: 'Docs',
        link: 'https://docs.retro.finance/',
        external: true,
      },
      {
        name: 'Analytics',
        link: 'https://info.retro.finance/',
        external: true,
      },
    ],
    external: true,
  },
  // {
  //   name: 'theNFT',
  //   link: '/theNFT',
  //   icon: <NftIcon />,
  // },
  // {
  //   name: 'REFERRAL',
  //   link: '/referral',
  //   icon: <NftIcon />,
  // },
  // {
  //   name: 'MORE',
  //   dropdown: true,
  //   icon: <MoreIcon />,
  //   link: [
  //     {
  //       name: 'Analytics',
  //       link: 'https://info.thena.fi/',
  //       external: true,
  //     },
  //     {
  //       name: 'Docs',
  //       link: 'https://thena.gitbook.io/',
  //       external: true,
  //     },
  //   ],
  // },
]

export default navLinks
