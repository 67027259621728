import React, { memo, useState } from 'react'
import AlgebraPosition from './algebraPosition'
import { useWeb3React } from '@web3-react/core'

import useWalletModal from '../../../../../hooks/useWalletModal'
import StyledButton from '../../../../common/Buttons/styledButton'
import { useV3ManualPositions } from 'hooks/v3/useV3ManualPositions'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import AddModal from './components/AddModal'
import RemoveModal from './components/RemoveModal'
import CollectModal from './components/CollectModal'
import { defaultChainId } from 'config/constants'
import Toggle from 'components/common/Toggle'

// const itemKey = (index, data) => Number(data.positions[index].tokenId)

// eslint-disable-next-line react/display-name
const Row = memo((props) => {
  const { data, style, index } = props

  const setManualLP = (id) => {
    data.listRef.current?.resetAfterIndex(index)

    data.setManualLP(id)
  }

  return (
    <AlgebraPosition
      style={style}
      item={data}
      position={data.positions[index]}
      id={index}
      key={`position-${index}`}
      manualLP={data.manualLP}
      setManualLP={setManualLP}
      setIsLiquidity={data.setIsLiquidity}
      setIsWithDraw={data.setIsWithDraw}
      setCollectFees={data.setCollectFees}
    />
  )
})

const MyManualPools = () => {
  const [addLiquidity, setIsLiquidity] = useState(false)
  const [withDraw, setIsWithDraw] = useState(false)
  const [fees, setCollectFees] = useState(false)

  const [manualLP, setManualLP] = useState(-1)
  const { account, chainId } = useWeb3React()
  const { positions } = useV3ManualPositions(account)
  const listRef = React.createRef()
  const { openWalletModal } = useWalletModal()
  const [hideClosed, setHideClosed] = useState(true)

  const filteredPositions = positions?.filter((position) => (hideClosed ? !position.liquidity?.eq(0) : true))

  const data = {
    positions: filteredPositions,
    manualLP,
    setManualLP,
    listRef,
    setIsLiquidity,
    setIsWithDraw,
    setCollectFees,
  }

  return (
    <div
      className={`h-full w-full rounded-[5px] bg-componentPrimary border-4 border-white py-3 px-4 lg:px-6 relative mt-5 md:mt-6`}
    >
      {addLiquidity && (
        <AddModal
          isOpen={addLiquidity}
          setIsOpen={setIsLiquidity}
          positionDetails={filteredPositions[manualLP]}
          position={filteredPositions[manualLP]}
        />
      )}
      {withDraw && manualLP !== -1 && (
        <RemoveModal isOpen={withDraw} setIsOpen={setIsWithDraw} position={filteredPositions[manualLP]} />
      )}
      {fees && manualLP !== -1 && (
        <CollectModal isOpen={fees} setIsOpen={setCollectFees} position={filteredPositions[manualLP]} />
      )}
      <p className='mb-4 text-lg lg:text-[22px] flex justify-between leading-[22px] lg:leading-[27px] f-f-fg font-semibold text-white'>
        <div>Your Manual LP </div>
        <div className='flex'>
          <Toggle
            checked={hideClosed}
            onChange={() => setHideClosed(!hideClosed)}
            small
            toggleId='close-manual-positions'
          />
          <div className='ml-3'>Hide closed</div>
        </div>
      </p>
      {!account || chainId !== defaultChainId ? (
        <StyledButton
          onClickHandler={openWalletModal}
          content={'CONNECT WALLET'}
          className='my-3 py-[13px] md:py-[14.53px] text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
        />
      ) : filteredPositions?.length > 0 ? (
        <div className='h-[550px]'>
          <AutoSizer disableWidth>
            {({ height }) => {
              return (
                <List
                  ref={listRef}
                  height={height}
                  itemCount={filteredPositions.length}
                  itemSize={(index) => (index === manualLP ? 800 : 130)}
                  width='100%'
                  itemData={data}
                  // itemKey={itemKey}
                >
                  {Row}
                </List>
              )
            }}
          </AutoSizer>
        </div>
      ) : (
        <p className='my-4 text-lg leading-[22px] text-[#DEDBF2] font-light text-center'>
          You do not have any liquidity positions.
        </p>
      )}
    </div>
  )
}

export default MyManualPools
