import React from 'react'
import CloseModalIcon from '../../../icons/CloseModalIcon'
import OutsideClickHandler from 'react-outside-click-handler'

const CommonHollowModal = ({ popup, setPopup, title, width = 588, children, disableOutside }) => {
  return (
    <>
      <div
        onClick={() => {
          setPopup(false)
        }}
        className={`fixed ${
          popup ? 'visible z-[202] opacity-100' : 'z-0 invisible opacity-0'
        } inset-0 w-full h-full transition-all duration-300 ease-in-out bg-opacity-[0.88] bg-black`}
      ></div>

      <div
        className={`${
          popup ? 'z-[203] visible opacity-100' : 'z-0 invisible opacity-0'
        } w-full xl:w-mobileModal 2xl:w-modal xl:ml-auto inset-0 fixed transition-all duration-300 ease-in-out flex items-center min-h-screen justify-center flex-col paraent`}
      >
        <OutsideClickHandler
          onOutsideClick={() => {
            if (!disableOutside) {
              setPopup(false)
            }
          }}
        >
          <div
            className={`max-w-[92%] ${width === 588 ? ' sm:min-w-[500px] sm:max-w-[500px] md:min-w-[588px] md:max-w-[588px]' : ''} ${
              width === 540 ? ' md:min-w-[540px] max-w-[540px]' : ''
            } mx-auto w-full bg-componentPrimary rounded-lg border-4 border-solid border-white max-h-[80%] overflow-y-auto`}
          >
            <div className='px-3 py-3 rounded-[5px] md:px-6 md:py-5'>
              <div className='flex gap-x-5 items-center justify-between'>
                <p className='text-[20px] md:text-[25px] font-montSerrat text-white font-bold'>{title}</p>
                <button onClick={() => setPopup(false)} className='focus:outline-none ml-auto'>
                  <span className='block w-8 h-8'>
                    <CloseModalIcon />
                  </span>
                </button>
              </div>
              {children}
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

export default CommonHollowModal
