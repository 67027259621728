import { useState } from 'react'
import { ADDR_ZERO } from './constants'
import { formatNumber } from 'utils/formatNumber'
import NumericalInput from 'components/common/Input/NumericalInput'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import useTokenBalance from 'hooks/useTokenBalance'
import Big from 'big.js'

export const DepositGas = ({ managerInfo, withdrawToken, depositToken }) => {
  const [maticToDeposit, setMaticToDeposit] = useState('')
  const userBalance = useTokenBalance('MATIC')

  return (
    <div className='py-5 flex flex-col items-center gap-5 bg-componentPrimary max-w-[866px]'>
      <h1 className='font-semibold f-f-fg text-[34px] md:text-[42px]'>Deposit Gas for Automation</h1>
      <div className='flex flex-col items-center'>
        <p>You need to deposit MATIC on the manager to pay for automation fees. You can withdraw anytime.</p>
        <p>
          You can also deposit when creating the automation, see {'"'}Automation{'"'} section.
        </p>
      </div>
      <div className='flex justify-center h-[56px]'>
        <div
          onClick={() => setMaticToDeposit(userBalance.toString())}
          className='flex items-center mr-2 cursor-pointer'
        >
          Balance: {formatNumber(userBalance.toString(), 5)}
        </div>
        <div className='w-[133px] mr-3'>
          <NumericalInput className=' bg-black h-full' value={maticToDeposit} onUserInput={setMaticToDeposit} />
        </div>
        <LinearGhostButton
          disabled={!maticToDeposit || userBalance.lt(maticToDeposit)}
          smallCase
          title={userBalance.lt(maticToDeposit) ? 'Not enough balance' : 'Deposit'}
          className='text-black inline px-[10px] w-[250px]'
          onClickHanlder={() => {
            depositToken(
              ADDR_ZERO,
              Big(maticToDeposit)
                .mul(10 ** 18)
                .toString(),
            )
          }}
        />
      </div>
      <div className='flex justify-center gap-2 w-full h-[56px] px-[50px]'>
        <p className='h-full w-[250px] text-[24px] font-semibold flex justify-center items-center'>Manager balance: </p>
        <div className='bg-[#0C111D] h-full rounded-[3px] w-[250px] flex justify-center items-center'>
          {formatNumber(
            Big(managerInfo.balance.toString())
              .div(10 ** 18)
              .toString(),
            5,
          )}{' '}
          MATIC
        </div>
        <LinearGhostButton
          disabled={managerInfo.balance.lte(0)}
          smallCase
          title='Withdraw'
          className='text-black px-[10px] w-[250px]'
          onClickHanlder={() => withdrawToken(ADDR_ZERO)}
        />
      </div>
    </div>
  )
}
