import React from 'react'
import 'react-rangeslider/lib/index.css'
import Modal from '../../../../../common/Modal'
import StyledButton from '../../../../../common/Buttons/styledButton'
import { useCurrencyLogo } from '../../../../../../hooks/v3/useCurrencyLogo'
import { useAlgebraClaim } from '../../../../../../hooks/v3/useAlgebra'
import { useV3ManualPositionFees } from 'hooks/v3/useV3ManualPositionsFees'
import { usePool } from 'hooks/v3/usePools'
import { unwrappedToken } from 'v3lib/utils/utils'
import { useToken } from 'hooks/v3/Tokens'

const CollectModal = ({ isOpen, setIsOpen, position }) => {
  const { token0: _token0Address, token1: _token1Address, fee } = position

  const token0 = useToken(_token0Address)
  const token1 = useToken(_token1Address)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  // construct Position from details returned
  const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, fee)

  const [feeValue0, feeValue1] = useV3ManualPositionFees(pool ?? undefined, position?.tokenId)

  const logoURI0 = useCurrencyLogo(feeValue0?.currency)
  const logoURI1 = useCurrencyLogo(feeValue1?.currency)
  const { pending, onAlgebraClaim } = useAlgebraClaim()

  return (
    <Modal popup={isOpen} setPopup={setIsOpen} title={'Collect Fees'} width={588}>
      <div className='px-4 pt-5 pb-3 rounded-[5px] bg-componentSecondary border border-white mt-[13px]'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-[5px]'>
            <img alt='' src={logoURI0} className={'w-[22px] md:w-6'} />
            <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-semibold'>
              {feeValue0?.currency.symbol}
            </span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className='text-[#DEDBF2] leading-5'>{feeValue0?.toSignificant()}</span>
          </div>
        </div>
        <div className='flex items-center justify-between mt-2'>
          <div className='flex items-center space-x-[5px]'>
            <img alt='' src={logoURI1} className={'w-[22px] md:w-6'} />
            <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-semibold'>
              {feeValue1?.currency.symbol}
            </span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className='text-[#DEDBF2] leading-5'>{feeValue1?.toSignificant()}</span>
          </div>
        </div>
        <StyledButton
          pending={pending}
          onClickHandler={() => onAlgebraClaim(position.tokenId, feeValue0, feeValue1)}
          content={'Claim'}
          className='mt-[15px]  py-[13px] md:py-[14.53px] text-black text-sm md:text-lg leading-4 flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
        />
        <p className='mt-2 md:mt-1.5 text-[#DEDBF2] leading-5 text-sm md:text-base font-light text-center'>
          Collecting fees will withdraw currently available fees for you.
        </p>
      </div>
    </Modal>
  )
}

export default CollectModal
