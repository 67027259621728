import React, { useMemo } from 'react'
import {
  // Fraction,
  Percent,
} from '@uniswap/sdk-core'
import LinearGhostButton from '../../../../common/Buttons/linearGhostButton'
import { STABLE_TOKENS } from '../../../../../config/constants'
import { useToken } from '../../../../../hooks/v3/Tokens'
import { WBNB_EXTENDED } from '../../../../../v3lib/entities/constants'
import usePrevious from '../../../../../hooks/usePrevious'
import { unwrappedToken } from '../../../../../v3lib/utils/utils'
import { usePool } from '../../../../../hooks/v3/usePools'
import { Position } from '../../../../../v3lib/entities'
import useIsTickAtLimit from '../../../../../hooks/v3/useIsTickAtLimit'
import { formatCurrencyAmount, formatTickPrice, getRatio } from '../../../../../v3lib/utils/formatTickPrice'
import { Bound } from '../../../../../state/mintV3/actions'
import { useCurrencyLogo } from '../../../../../hooks/v3/useCurrencyLogo'

import useUSDCPrice from '../../../../../hooks/v3/useUSDCPrice'
import { useV3ManualPositionFees } from 'hooks/v3/useV3ManualPositionsFees'
import { Fraction } from 'thena-sdk'
import SelectedRange from './components/SelectedRange'

export const getPriceOrderingFromPositionForUI = (position) => {
  // if token0 is a dollar-stable asset, set it as the quote token
  const USDC = useToken(STABLE_TOKENS.USDC)
  const USDT = useToken(STABLE_TOKENS.USDT)
  // const BUSD = useToken(STABLE_TOKENS.BUSD)

  if (!position) {
    return {}
  }

  const token0 = position.amount0.currency
  const token1 = position.amount1.currency
  const stables = [USDC, USDT]
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if token1 is an WBNB, set it as the base token
  //TODO
  const bases = [...Object.values(WBNB_EXTENDED)]
  if (bases.some((base) => base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  }
}

const AlgebraPosition = ({
  setIsLiquidity,
  setIsWithDraw,
  setCollectFees,
  style,
  position: positionDetails,
  id,
  manualLP,
  setManualLP,
}) => {
  // const prevPositionDetails = usePrevious({ ...positionDetails })
  // const _positionDetails = useMemo(() => {
  //   if (!positionDetails && prevPositionDetails && prevPositionDetails.liquidity) {
  //     return { ...prevPositionDetails }
  //   }
  //   return { ...positionDetails }
  // }, [positionDetails, prevPositionDetails])
  const {
    token0: _token0Address,
    token1: _token1Address,
    liquidity: _liquidity,
    tickLower: _tickLower,
    tickUpper: _tickUpper,
  } = positionDetails

  const token0 = useToken(_token0Address)
  const token1 = useToken(_token1Address)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined
  const logoURI0 = useCurrencyLogo(currency0)
  const logoURI1 = useCurrencyLogo(currency1)

  // construct Position from details returned
  const [, _pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, positionDetails?.fee)
  // const [prevPoolState, prevPool] = usePrevious([poolState, pool]) || []
  // const [, _pool] = useMemo(() => {
  //   if (!pool && prevPool && prevPoolState) {
  //     return [prevPoolState, prevPool]
  //   }
  //   return [poolState, pool]
  // }, [pool, poolState, prevPool, prevPoolState])

  const position = useMemo(() => {
    if (_pool) {
      return new Position({
        pool: _pool,
        liquidity: _liquidity.toString(),
        tickLower: _tickLower,
        tickUpper: _tickUpper,
      })
    }
    return undefined
  }, [_liquidity, _pool, _tickLower, _tickUpper])

  const tickAtLimit = useIsTickAtLimit(position?.pool?.fee, _tickLower, _tickUpper)

  // prices
  const priceTickInfo = getPriceOrderingFromPositionForUI(position)
  const { priceLower, priceUpper, quote, base } = priceTickInfo
  // check if price is within range
  const outOfRange = _pool ? _pool.tickCurrent < _tickLower || _pool.tickCurrent >= _tickUpper : false
  const currencyQuote = quote && unwrappedToken(quote)
  const currencyBase = base && unwrappedToken(base)

  const ratio = useMemo(() => {
    return priceLower && _pool && priceUpper
      ? getRatio(position.token0PriceLower, _pool.token0Price, position.token0PriceUpper)
      : undefined
  }, [_pool, priceLower, priceUpper])

  // fees
  const [feeValue0, feeValue1] = useV3ManualPositionFees(_pool ?? undefined, positionDetails?.tokenId)
  const price0 = useUSDCPrice(token0 ?? undefined)
  const price1 = useUSDCPrice(token1 ?? undefined)
  //console.log(price0?.toFixed(0), price1?.toFixed(0))
  const fiatValueOfFees = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return null

    // we wrap because it doesn't matter, the quote returns a USDC amount
    const feeValue0Wrapped = feeValue0?.wrapped
    const feeValue1Wrapped = feeValue1?.wrapped

    if (!feeValue0Wrapped || !feeValue1Wrapped) return null

    const amount0 = price0.quote(feeValue0Wrapped)
    const amount1 = price1.quote(feeValue1Wrapped)
    return amount0.add(amount1)
  }, [price0, price1, feeValue0, feeValue1])

  const prevFiatValueOfFees = usePrevious(fiatValueOfFees)
  const _fiatValueOfFees = useMemo(() => {
    if (!fiatValueOfFees && prevFiatValueOfFees) {
      return prevFiatValueOfFees
    }
    return fiatValueOfFees
  }, [fiatValueOfFees, prevFiatValueOfFees])

  const fiatValueOfLiquidity = useMemo(() => {
    if (!price0 || !price1 || !position) return
    const amount0 = price0.quote(position.amount0)
    const amount1 = price1.quote(position.amount1)
    return amount0.add(amount1)
  }, [price0, price1, position])

  // const showCollectAsWbnb = Boolean(
  //   (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) &&
  //     currency0 &&
  //     currency1 &&
  //     (currency0.isNative || currency1.isNative),
  // )

  return (
    <>
      <div style={style} className={`w-full px-5 py-[19.2px] bg-body`}>
        <div
          onClick={() => {
            manualLP === id ? setManualLP(-1) : setManualLP(id)
          }}
          className='md:flex justify-start items-center space-x-2.5 cursor-pointer w-full'
        >
          <div className='md:w-[50px] w-11 h-11 md:h-[50px] rounded-full flex flex-col items-center justify-center nftBox flex-shrink-0'>
            <p className='text-[9px] md:text-[10px] font-semibold leading-[14px] text-[#090333] '>NFT ID:</p>
            <p className='text-xs md:text-[13px] font-semibold leading-[18px] text-[#090333] '>
              {Number(positionDetails?.tokenId)}
            </p>
          </div>
          <div className='flex items-start md:items-center justify-between cursor-pointer  w-full mt-2 md:mt-0'>
            <div className='flex items-start md:items-center space-x-2'>
              <div className='flex items-center space-x-2 '>
                <div className='flex items-center'>
                  <img alt='' className='w-6 lg:w-[30px] relative shadow' src={logoURI0} />
                  <img alt='' className='w-6 lg:w-[30px] -ml-3' src={logoURI1} />
                </div>
                <div>
                  <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>
                    {currency0?.symbol}-{currency1?.symbol}
                  </p>
                </div>
              </div>
              <div className='md:flex-row flex flex-col-reverse items-start md:items-center md:space-x-2'>
                <div className='bg-componentPrimary py-1 pl-3 mt-2 md:mt-0 rounded-[13px] flex items-center space-x-[5px] pr-4 flex-shrink-0'>
                  <div
                    className={`${
                      _liquidity?.eq(0) ? 'bg-red-700' : outOfRange ? 'bg-red-700' : 'bg-[#55A361]'
                    } w-2 h-2  rounded-full`}
                  />
                  <span className='text-[15px] fonts-medium text-white whitespace-nowrap'>
                    {_liquidity?.eq(0) ? 'Closed' : outOfRange ? 'Out of Range' : 'In Range'}
                  </span>
                </div>
                <div className='bg-componentPrimary  py-1  rounded-[13px]   px-2.5 flex-shrink-0'>
                  <span className='text-[15px] fonts-medium text-white whitespace-nowrap'>{`${new Percent(
                    _pool?.fee || 100,
                    1_000_000,
                  ).toSignificant()}%`}</span>
                </div>
              </div>
            </div>
            <button className={`${manualLP === id ? 'rotate-180' : 'rotate-0'} mt-2 md:mt-0 w-3 md:w-auto`}>
              <img alt='' src='/images/svgs/dropdown.svg' />
            </button>
          </div>
        </div>
        {manualLP === id ? (
          <div className='w-full mt-3 md:mt-5'>
            <div className='flex items-center justify-between'>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-medium text-white'>Liquidity</span>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-light  text-white'>
                ${formatCurrencyAmount(fiatValueOfLiquidity, 4)}
              </span>
            </div>
            <div className='mt-3.5'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={logoURI0} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>
                    {currency0?.symbol}
                  </span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5 font-light'>
                    {formatCurrencyAmount(position?.amount0, 4)}
                  </span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-componentPrimary rounded-md'>{`${ratio}%`}</div>
                </div>
              </div>
              <div className='flex items-center justify-between mt-2'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={logoURI1} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>
                    {currency1?.symbol}
                  </span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5 font-light'>
                    {formatCurrencyAmount(position?.amount1, 4)}
                  </span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-componentPrimary rounded-md'>{`${
                    100 - ratio
                  }%`}</div>
                </div>
              </div>
            </div>

            <div className='flex items-center space-x-3 mt-4'>
              <LinearGhostButton
                onClickHanlder={() => {
                  setIsLiquidity(true)
                }}
                className='w-full'
                smallCase={true}
                title={'Add Liquidity'}
              />
              <LinearGhostButton
                onClickHanlder={() => {
                  setIsWithDraw(true)
                }}
                className='w-full'
                smallCase={true}
                title={'Withdraw'}
              />
            </div>
            <div className='w-full'>
              <div className='mt-2 flex items-center justify-between'>
                <span className='text-sm md:text-base leading-4 md:leading-5 font-medium text-white'>
                  Unclaimed Fees
                </span>
                <span className='text-sm md:text-base leading-4 md:leading-5 font-light  text-white'>
                  $
                  {_fiatValueOfFees?.greaterThan(new Fraction(1, 100))
                    ? +_fiatValueOfFees.toFixed(2, { groupSeparator: ',' }) < 0.01
                      ? '<0.01'
                      : _fiatValueOfFees?.toFixed(2, {
                          groupSeparator: ',',
                        })
                    : '-'}
                </span>
              </div>
              <div className='mt-3.5'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center space-x-[5px]'>
                    <img alt='' src={logoURI0} className={'w-6 md:w-7'} />
                    <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>
                      {currency0?.symbol}
                    </span>
                  </div>
                  <span className='text-[#DEDBF2] leading-5 font-light'>
                    {feeValue0 ? formatCurrencyAmount(feeValue0, 4) : '-'}
                  </span>
                </div>
                <div className='flex items-center justify-between mt-2'>
                  <div className='flex items-center space-x-[5px]'>
                    <img alt='' src={logoURI1} className={'w-6 md:w-7'} />
                    <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>
                      {currency1?.symbol}
                    </span>
                  </div>
                  <span className='text-[#DEDBF2] leading-5 font-light'>
                    {feeValue1 ? formatCurrencyAmount(feeValue1, 4) : '-'}
                  </span>
                </div>
              </div>
              {(feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) && (
                <LinearGhostButton
                  onClickHanlder={() => {
                    setCollectFees(true)
                  }}
                  title={'Claim'}
                  className='mt-[13px] w-full'
                />
              )}
              {/* {showCollectAsWbnb && (
                <div className=' space-x-1.5 md:space-x-2 flex items-center mt-3.5'>
                  <Toggle
                    checked={receiveWBNB}
                    onChange={() => setReceiveWBNB(!receiveWBNB)}
                    small
                    rounded
                    toggleId='collect'
                  />
                  <span className='text-sm md:text-base text-white'>Collect as WBNB</span>
                </div>
              )} */}
              <SelectedRange
                pool={_pool}
                currency0={currency0}
                currency1={currency1}
                positionDetails={positionDetails}
              />
            </div>
          </div>
        ) : (
          <div className='md:flex-row flex flex-col items-center  space-y-1 md:space-y-0  md:space-x-[5px] text-sm font-light text-white mt-[15px] leading-4'>
            <p>{`Min ${
              Boolean(priceLower) && Boolean(tickAtLimit) ? formatTickPrice(priceLower, tickAtLimit, Bound.LOWER) : '-'
            } ${currencyQuote?.symbol ?? '-'} per ${currencyBase?.symbol ?? '-'}`}</p>
            <button className=''>
              <img alt='' className='md:block hidden' src='/images/svgs/double.svg' />
              <img alt='' className='md:hidden' src='/images/svgs/double-arrowv.svg' />
            </button>
            <p>{`Max ${
              Boolean(priceUpper) && Boolean(tickAtLimit) ? formatTickPrice(priceUpper, tickAtLimit, Bound.UPPER) : '-'
            } ${currencyQuote?.symbol ?? '-'} per ${currencyBase?.symbol ?? '-'}`}</p>
          </div>
        )}
      </div>
      {/* {addLiquidity && (
        <AddModal
          isOpen={addLiquidity}
          setIsOpen={setIsLiquidity}
          positionDetails={_positionDetails}
          position={position}
          pool={_pool}
        />
      )}
      {withDraw && <RemoveModal isOpen={withDraw} setIsOpen={setIsWithDraw} position={_positionDetails} />}
      {fees && <CollectModal isOpen={fees} setIsOpen={setCollectFees} position={_positionDetails} pool={_pool} />} */}
    </>
  )
}

export default AlgebraPosition
