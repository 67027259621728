import { useContext, useMemo } from 'react'
import { ChainId } from 'config/constants'
import { computePoolAddress } from '@uniswap/v3-sdk'
import { useToken } from './Tokens'
import { Pool } from '../../v3lib/entities/pool'
import { JSBI } from 'thena-sdk/dist'
import IUniswapV3PoolStateJSON from '@uniswap/v3-core/artifacts/contracts/interfaces/pool/IUniswapV3PoolState.sol/IUniswapV3PoolState.json'
import { POOL_INIT_CODE_HASH } from 'v3lib/utils'
import { useQuery } from '@tanstack/react-query'
import { multicallFailSafe } from 'utils/multicall'
import { V3_CORE_FACTORY_ADDRESSES } from 'config/constants/v3/addresses'
import { FusionsContext } from 'context/FusionsContext'
import { useV3SwapPools } from './useV3SwapPools'

export const PoolState = {
  LOADING: 0,
  NOT_EXISTS: 1,
  EXISTS: 2,
  INVALID: 3,
}

// Classes are expensive to instantiate, so this caches the recently instantiated pools.
// This avoids re-instantiating pools as the other pools in the same request are loaded.
class PoolCache {
  // Evict after 128 entries. Empirically, a swap uses 64 entries.
  static MAX_ENTRIES = 128

  // These are FIFOs, using unshift/pop. This makes recent entries faster to find.
  static pools = []
  static addresses = []

  static getPoolAddress(factoryAddress, tokenA, tokenB, fee) {
    if (this.addresses.length > this.MAX_ENTRIES) {
      this.addresses = this.addresses.slice(0, this.MAX_ENTRIES / 2)
    }

    const { address: addressA } = tokenA
    const { address: addressB } = tokenB
    const key = `${factoryAddress}:${addressA}:${addressB}:${fee.toString()}`
    const found = this.addresses.find((address) => address.key === key)
    if (found) return found.address

    const address = {
      key,
      address: computePoolAddress({
        factoryAddress,
        tokenA,
        tokenB,
        fee,
        initCodeHashManualOverride: POOL_INIT_CODE_HASH,
      }),
    }
    this.addresses.unshift(address)
    return address.address
  }

  static getPool(tokenA, tokenB, fee, sqrtPriceX96, liquidity, tick) {
    if (this.pools.length > this.MAX_ENTRIES) {
      this.pools = this.pools.slice(0, this.MAX_ENTRIES / 2)
    }

    const found = this.pools.find(
      (pool) =>
        pool.token0 === tokenA &&
        pool.token1 === tokenB &&
        pool.fee === fee &&
        JSBI.EQ(pool.sqrtRatioX96, sqrtPriceX96) &&
        JSBI.EQ(pool.liquidity, liquidity) &&
        pool.tickCurrent === tick,
    )
    if (found) return found

    // const address = this.getPoolAddress(V3_CORE_FACTORY_ADDRESSES[chainId], tokenA, tokenB, fee)

    const pool = new Pool(tokenA, tokenB, fee, sqrtPriceX96, liquidity, tick)
    this.pools.unshift(pool)
    return pool
  }
}

const chainId = ChainId.MAINNET

export function usePools(poolKeys) {
  const poolTokens = useMemo(() => {
    if (!chainId) return new Array(poolKeys.length)

    return poolKeys.map(([currencyA, currencyB, feeAmount]) => {
      if (currencyA && currencyB && feeAmount) {
        const tokenA = currencyA.wrapped
        const tokenB = currencyB.wrapped
        if (tokenA.equals(tokenB)) return undefined

        return tokenA.sortsBefore(tokenB) ? [tokenA, tokenB, feeAmount] : [tokenB, tokenA, feeAmount]
      }
      return undefined
    })
  }, [chainId, poolKeys])

  const poolAddresses = useMemo(() => {
    const v3CoreFactoryAddress = chainId && V3_CORE_FACTORY_ADDRESSES[chainId]
    if (!v3CoreFactoryAddress) return new Array(poolTokens.length)

    return poolTokens.map((value) => value && PoolCache.getPoolAddress(v3CoreFactoryAddress, ...value))
  }, [chainId, poolTokens])

  const slot0sQuery = useQuery(
    ['slot0', [...poolAddresses].sort()],
    async () => {
      const callsSlot0 = poolAddresses.map((poolAddress) => {
        return { address: poolAddress, name: 'slot0' }
      })

      const callsLiquidity = poolAddresses.map((poolAddress) => {
        return { address: poolAddress, name: 'liquidity' }
      })

      try {
        const allResponse = await multicallFailSafe(IUniswapV3PoolStateJSON.abi, [...callsSlot0, ...callsLiquidity])

        const slot0s = allResponse.slice(0, callsSlot0.length)
        const liquidities = allResponse.slice(callsLiquidity.length)

        return { slot0s: slot0s ?? [], liquidities: liquidities ?? [] }
      } catch (error) {
        console.error(error)
      }
    },
    {
      enabled: poolAddresses.length !== 0 && Boolean(poolAddresses) && Boolean(poolAddresses[0]),
      refetchOnMount: false,
    },
  )

  const { slot0s, liquidities } = slot0sQuery.data ?? {}

  return poolKeys.map((_key, index) => {
    const tokens = poolTokens[index]

    if (!tokens) return [PoolState.INVALID, null]
    const [token0, token1, fee] = tokens

    if (!slot0s?.[index]) return [PoolState.INVALID, null]
    const slot0 = slot0s[index]

    if (!liquidities?.[index]) return [PoolState.INVALID, null]
    const liquidity = liquidities[index]

    const sqrtPriceX96 = slot0[0]
    const tick = slot0[1]

    if (!tokens || !slot0 || !liquidity) return [PoolState.INVALID, null]
    if (slot0sQuery.isLoading) return [PoolState.LOADING, null]
    if (!slot0 || !liquidity) return [PoolState.NOT_EXISTS, null]
    if (!sqrtPriceX96 || sqrtPriceX96.eq(0)) return [PoolState.NOT_EXISTS, null]

    try {
      const pool = PoolCache.getPool(token0, token1, fee, sqrtPriceX96, liquidity[0], tick)

      return [PoolState.EXISTS, pool]
    } catch (error) {
      console.error('Error when constructing the pool', error)
      return [PoolState.NOT_EXISTS, null]
    }
  })
}

export function usePool(currencyA, currencyB, feeAmount) {
  const poolKeys = useMemo(() => [[currencyA, currencyB, feeAmount]], [currencyA, currencyB, feeAmount])

  const pools = usePools(poolKeys)

  return pools[0]
}

export const useGammaPool = (currencyA, currencyB) => {
  const fusions = useContext(FusionsContext)

  const { pools } = useV3SwapPools(currencyA, currencyB)

  const [token0, token1] =
    currencyA && currencyB
      ? currencyA?.wrapped?.sortsBefore(currencyB?.wrapped)
        ? [currencyA?.wrapped, currencyB?.wrapped]
        : [currencyB?.wrapped, currencyA?.wrapped]
      : [undefined, undefined]

  const fusionPool = fusions.find((pool) => {
    return (
      pool?.token0?.address?.toLowerCase() === token0?.address?.toLowerCase() &&
      pool?.token1?.address?.toLowerCase() === token1?.address?.toLowerCase()
    )
  })

  const pool = pools.find(
    (pool) =>
      pool.token0.address?.toLowerCase() === fusionPool?.token0?.address?.toLowerCase() &&
      pool.token1.address?.toLowerCase() === fusionPool?.token1?.address?.toLowerCase() &&
      fusionPool.feeLevel === pool.fee,
  )

  return pool ? [PoolState.EXISTS, pool] : [PoolState.NOT_EXISTS, null]
}

export function useTokensSymbols(token0, token1) {
  const _token0 = useToken(token0)
  const _token1 = useToken(token1)

  return useMemo(() => [_token0, _token1], [_token0, _token1])
}
