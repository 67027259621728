import React, { useState } from 'react'
import LinearGhostButton from '../../../../common/Buttons/linearGhostButton'
import GammaRemoveModal from './components/gammaRemoveModal'
// import GammaAddModal from './components/gammaAddModal'
import { formatAmount } from '../../../../../utils/formatNumber'
import { useTokenPrice } from '../../../../../hooks/usePrices'
import { customNotify } from '../../../../../utils/notify'
import BigNumber from 'bignumber.js'

const GammaPosition = ({ id, openedPositionIndex, setOpenedPositionIndex, position }) => {
  // const [addLiquidity, setIsLiquidity] = useState(false)
  const [withDraw, setIsWithDraw] = useState(false)
  const { token0, token1, account, type } = position
  const token0Price = useTokenPrice(token0.address)
  const token0PooledPercent = account.totalUsd.gt(0)
    ? account.total0.times(token0Price).div(account.totalUsd).times(100).toFixed(2)
    : new BigNumber(0)

  return (
    <>
      <div className={`${id > 0 ? 'mt-[9px]' : ''} w-full px-5 py-[19.2px] bg-body`}>
        <div
          onClick={() => {
            openedPositionIndex === id ? setOpenedPositionIndex(-1) : setOpenedPositionIndex(id)
          }}
          className='flex items-start md:items-center justify-between cursor-pointer'
        >
          <div className='flex items-start md:items-center space-x-3'>
            <div className='flex items-center space-x-3 '>
              <div className='flex items-center'>
                <img alt='' className='w-6 lg:w-[30px] relative shadow' src={token0.logoURI} />
                <img alt='' className='w-6 lg:w-[30px] -ml-3' src={token1.logoURI} />
              </div>
              <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>
                {token0.symbol}/{token1.symbol}
              </p>
            </div>
            <div className='md:flex-row flex flex-col-reverse items-start md:items-center md:space-x-2'>
              <div className='bg-componentPrimary py-1 pl-3 mt-2 md:mt-0 rounded-[13px] w-full flex items-center space-x-[5px] pr-4 flex-shrink-0'>
                <span className='text-[15px] font-semibold text-white whitespace-nowrap'>{type} range</span>
              </div>
            </div>
          </div>
          <button className={`${openedPositionIndex === id ? 'rotate-180' : 'rotate-0'} mt-2 md:mt-0 w-3 md:w-auto`}>
            <img alt='' src='/images/svgs/dropdown.svg' />
          </button>
        </div>
        {openedPositionIndex === id ? (
          <div className='w-full mt-5'>
            <div className='flex items-center justify-between'>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-medium text-white'>
                Pooled Liquidity
              </span>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-light  text-white'>{`$${formatAmount(
                account.totalUsd ?? 0,
              )} (${formatAmount(account.totalLp)} LP)`}</span>
            </div>
            <div className='mt-3.5'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={token0.logoURI} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>
                    {token0.symbol}
                  </span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5 font-light'>{formatAmount(account.total0)}</span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-componentPrimary rounded-md'>
                    {formatAmount(token0PooledPercent)}%
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-between mt-2'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={token1.logoURI} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>
                    {token1.symbol}
                  </span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5 font-light'>{formatAmount(account.total1)}</span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-componentPrimary rounded-md'>
                    {formatAmount(100 - token0PooledPercent)}%
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='flex items-center justify-between mt-3.5 pt-3.5 border-t border-white'>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-medium text-white'>
                Staked Liquidity
              </span>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-light  text-white'>{`$${formatAmount(
                account.stakedUsd,
              )} (${formatAmount(account.gaugeBalance)} LP)`}</span>
            </div> */}
            {/* <div className='mt-3.5'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={token0.logoURI} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white font-medium'>
                    {token0.symbol}
                  </span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5'>{formatAmount(account.staked0)}</span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-componentPrimary rounded-md'>
                    {formatAmount(token0PooledPercent)}%
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-between mt-2'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={token1.logoURI} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white font-medium'>
                    {token1.symbol}
                  </span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5'>{formatAmount(account.staked1)}</span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-componentPrimary rounded-md'>
                    {formatAmount(100 - token0PooledPercent)}%
                  </div>
                </div>
              </div>
            </div> */}

            <div className='flex items-center space-x-3 mt-4'>
              {/* <LinearGhostButton
                onClickHanlder={() => {
                  setIsLiquidity(true)
                }}
                smallCase={true}
                title={'Add Liquidity'}
              /> */}
              <LinearGhostButton
                onClickHanlder={() => {
                  if (account.lpBalance.isZero()) {
                    customNotify('LP is empty in your wallet.', 'warn')
                  } else {
                    setIsWithDraw(true)
                  }
                }}
                smallCase={true}
                title={'Withdraw'}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* {addLiquidity && <GammaAddModal isOpen={addLiquidity} setIsOpen={setIsLiquidity} position={position} />} */}
      {withDraw && <GammaRemoveModal isOpen={withDraw} setIsOpen={setIsWithDraw} position={position} />}
    </>
  )
}

export default GammaPosition
