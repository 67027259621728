import React, { useState, useEffect } from 'react'
import './header.scss'
import { Link } from 'react-router-dom'
import MobileNav from '../../../components/common/MobileNav'
import { useWeb3React } from '@web3-react/core'
import ConnectWallet from '../../../components/connectWallet'
import { connectors, defaultChainId } from '../../../config/constants'
import { useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import useWalletModal from '../../../hooks/useWalletModal'
import useAuth from '../../../hooks/useAuth'
import ChevronIcon from '../../../icons/ChevronIcon'
import LogoutIcon from '../../../icons/LogoutIcon'

const links = [
  // { name: 'Gauges', link: '/whitelist' },
  // { name: 'theNFT', link: '/theNFT' },
  // { name: 'Referal', link: '/swap' },
  // { name: 'More', link: '/swap' },
]
const Header = () => {
  const [scrolled, setScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { isWalletOpen } = useSelector((state) => state.wallet)
  const [connector, setConnector] = useState(null)
  const [selected, setSelected] = useState(false)
  const { account, chainId } = useWeb3React()
  const [secondDrop, setSecondDrop] = useState(false)
  const { openWalletModal } = useWalletModal()
  const { logout } = useAuth()

  const dropDownhandler = () => {
    if (connector) {
      setSecondDrop(!secondDrop)
    } else {
      openWalletModal()
    }
  }

  const onDisconnect = () => {
    logout()
    setConnector(null)
    setSecondDrop(false)
  }

  useEffect(() => {
    if (account) {
      if (!connector && !selected) {
        setConnector(connectors[0])
        setSelected(false)
      }
    } else {
      setConnector(null)
    }
  }, [account, connector, selected, setSelected])

  useEffect(() => {
    function handleScroll() {
      const offset = window.scrollY
      if (offset > 50) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header className={scrolled ? 'header scrolled' : 'header'}>
      <a href='#' className='logo'></a>
      <nav className='desktop-menu'>
        <div className='desktop-menu-container'>
          <ul className='desktop-links'>
            {links.map((link, idx) => {
              return (
                <li key={idx}>
                  <Link to={link.link} className='desktop-link'>
                    {link.name}
                  </Link>
                </li>
              )
            })}
          </ul>
          <Link to='/swap' className='desktop-button'>
            <img src='/images/retro/buttons/rocket-launch.svg' alt='' className='button-image' />
            Launch App
          </Link>
        </div>
      </nav>
      <div className='mobile-menu-container'>
        <OutsideClickHandler
          onOutsideClick={() => {
            setSecondDrop(false)
          }}
        >
          <div className='relative w-full'>
            <button
              onClick={() => {
                dropDownhandler()
              }}
              className={` bg-white w-full min-w-[150px] max-w-[252px] xl:max-w-full xl:w-[252px] flex-shrink-0 py-2 px-4 xl:py-4 xl:px-10 rounded-md items-center gap-x-1 xl:gap-x-2.5 font-montSerrat font-semibold text-black text-sm md:text-sm relative z-20  xl:text-base leading-7`}
            >
              {connector && chainId === defaultChainId ? (
                <div className='flex items-center gap-x-1 xl:gap-x-2.5'>
                  <div className='flex items-center flex-shrink-0 gap-x-1 xl:gap-x-2.5'>
                    <img
                      src={connector.title == 'MetaMask' ? '/images/header/metamask.svg' : connector.logo}
                      className='max-w-[24px] h-6 '
                      alt=''
                    />
                    <p className=''>{account ? `${account.slice(0, 6)}...${account.slice(-4)}` : ''}</p>
                  </div>
                  <span
                    className={`${
                      secondDrop ? ' rotate-180' : ' rotate-0'
                    } transition-all duration-300 ease-in-out transform w-full`}
                  >
                    <div className='w-6 h-6 flex-shrink-0'>
                      <ChevronIcon />
                    </div>
                  </span>
                </div>
              ) : (
                <span className='w-full font-montSerrat'>Connect Wallet</span>
              )}
            </button>

            {secondDrop && (
              <div className='absolute w-full border-2 border-white bg-componentPrimary rounded-[3px] top-[50px] xl:top-[70px] block z-[101]'>
                <button
                  onClick={onDisconnect}
                  className='flex items-center gap-x-2.5 w-full py-[8px] xl:py-[15px] px-5 xl:px-10 text-white'
                >
                  <span className='w-4 xl:w-6 h-4 xl:h-6'>
                    <LogoutIcon />
                  </span>
                  <p className='flex-shrink-0 text-sm xl:text-base text-white'>Logout</p>
                </button>
              </div>
            )}
          </div>
        </OutsideClickHandler>
        <div className='burger-menu-btn' onClick={() => setIsMenuOpen(true)}>
          <img src='/images/retro/header/burger-menu.svg' alt='' className='burger-menu-image' />
        </div>
      </div>
      <MobileNav isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
      {isWalletOpen && <ConnectWallet setConnector={setConnector} setSelected={setSelected} />}
    </header>
  )
}

export default Header
