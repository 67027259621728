import React from 'react'

export const VoteOptimizer = () => {
  return (
    <div className='w-full h-full'>
      <iframe
        src='https://retro-tools.streamlit.app/?embed=true'
        height='100%'
        style={{ width: '100%', border: 'none' }}
      ></iframe>
    </div>
  )
}
