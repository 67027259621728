// a list of tokens by chain
import { Token } from '@uniswap/sdk-core'
import { ChainId } from 'config/constants'
import { ExtendedEther, WBNB_EXTENDED } from '../../../v3lib/entities/constants'

export const toToken = (t) => {
  return new Token(t.chainId, t.address, t.decimals, t.symbol, t.name)
}

const routeAssets = {
  WMATIC: {
    name: 'Wrapped MATIC',
    symbol: 'WMATIC',
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://retro.finance/images/tokens/WMATIC.png',
  },
  USDC: {
    name: 'USD Circle',
    symbol: 'USDC',
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    chainId: 137,
    decimals: 6,
    logoURI: 'https://retro.finance/images/tokens/USDC.png',
  },
  RETRO: {
    name: 'RETRO',
    symbol: 'RETRO',
    address: '0xBFA35599c7AEbb0dAcE9b5aa3ca5f2a79624D8Eb',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://retro.finance/images/tokens/USDC.png',
  },
  USDT: {
    name: 'Tether USD',
    symbol: 'USDT',
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    chainId: 137,
    decimals: 6,
    logoURI: 'https://retro.finance/images/tokens/USDT.png',
  },
  CASH: {
    name: 'CASH',
    symbol: 'CASH',
    address: '0x5D066D022EDE10eFa2717eD3D79f22F949F8C175',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://retro.finance/images/tokens/CASH.png',
  },
  // BNB: {
  //   name: 'Wrapped BNB',
  //   symbol: 'WBNB',
  //   address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // BUSD: {
  //   name: 'BUSD Token',
  //   symbol: 'BUSD',
  //   address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // USDC: {
  //   name: 'USD Coin',
  //   symbol: 'USDC',
  //   address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // USDT: {
  //   name: 'Tether USD',
  //   symbol: 'USDT',
  //   address: '0x55d398326f99059fF775485246999027B3197955',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // THE: {
  //   name: 'THENA',
  //   symbol: 'THE',
  //   address: '0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // FRAX: {
  //   name: 'FRAX',
  //   symbol: 'FRAX',
  //   address: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // BNBx: {
  //   name: 'Liquid Staking BNB',
  //   symbol: 'BNBx',
  //   address: '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // ankrBNB: {
  //   name: 'Ankr Reward Bearing BNB',
  //   symbol: 'ankrBNB',
  //   address: '0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // stkBNB: {
  //   name: 'Staked BNB',
  //   symbol: 'stkBNB',
  //   address: '0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // ETH: {
  //   name: 'Ethereum Token',
  //   symbol: 'ETH',
  //   address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   chainId: 56,
  //   decimals: 18,
  // },
  // 'USD+': {
  //   name: 'USD+',
  //   symbol: 'USD+',
  //   address: '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
  //   chainId: 56,
  //   decimals: 6,
  // },
}

export const USDC = toToken(routeAssets.USDC)
export const USDT = toToken(routeAssets.USDT)
// export const BUSD = toToken(routeAssets.BUSD)
// export const FRAX = toToken(routeAssets.FRAX)
// export const BNBx = toToken(routeAssets.BNBx)
// export const ankrBNB = toToken(routeAssets.ankrBNB)
// export const stkBNB = toToken(routeAssets.stkBNB)
// export const ETH = toToken(routeAssets.ETH)
// export const USDPlus = toToken(routeAssets['USD+'])
const RETRO = toToken(routeAssets.RETRO)

const WBNB_ONLY = Object.fromEntries(Object.entries(WBNB_EXTENDED).map(([key, value]) => [key, [value]]))

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST = {
  ...WBNB_ONLY,
  [ChainId.MAINNET]: [...WBNB_ONLY[ChainId.MAINNET], USDC, USDT, RETRO],
}

export const ADDITIONAL_BASES = {}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES = {}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES = {
  [ChainId.MAINNET]: [ExtendedEther.onChain(ChainId.MAINNET), WBNB_EXTENDED[ChainId.MAINNET], USDT, USDC, RETRO],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR = {
  ...WBNB_ONLY,
  [ChainId.MAINNET]: [...WBNB_ONLY[ChainId.MAINNET], RETRO, USDC, USDT],
}
export const PINNED_PAIRS = {
  [ChainId.MAINNET]: [[USDC, USDT]],
}
