import React, { useCallback, useMemo } from 'react'
import JSBI from 'jsbi'
import { ChainId, defaultChainId } from 'config/constants'
import { Field } from '../../../../../../state/mintV3/actions'
import StyledButton from '../../../../../common/Buttons/styledButton'
import { useProceedSwap } from '../../../../../../hooks/useSwap'
import { WBNB_EXTENDED, v3LiquidityRangeType } from '../../../../../../v3lib/entities/constants'
import { useCurrencyBalance } from '../../../../../../hooks/v3/useCurrencyBalances'
import { customNotify } from '../../../../../../utils/notify'
import { useGammaAdd } from '../../../../../../hooks/v3/useGamma'
import { useActivePreset } from '../../../../../../state/mintV3/hooks'
import { useAlgebraAdd } from 'hooks/v3/useAlgebra'
import { useAddIchiLiquidity } from 'hooks/v3/useAddIchiLiquidity'
import { useWeb3React } from '@web3-react/core'
import useWalletModal from 'hooks/useWalletModal'
import { useDefiEdgeAdd } from 'hooks/v3/useDefiEdgeAdd'

const chainId = ChainId.MAINNET

export const AddLiquidityButton = ({
  baseCurrency,
  quoteCurrency,
  mintInfo,
  slippage,
  deadline,
  gammaPairs,
  ichiAsset,
}) => {
  const { account, chainId: activeChainId } = useWeb3React()
  const { openWalletModal } = useWalletModal()

  const { liquidityRangeType, errorMessage } = mintInfo
  const { onWrap, swapPending: wrappingETH } = useProceedSwap()

  const { onGammaAdd, pending: gammaPending } = useGammaAdd()
  const { onManualAdd, pending: isManualPending } = useAlgebraAdd() // manual
  const { onIchiAdd, pending: isIchiPending } = useAddIchiLiquidity()

  const { handleDefiEdgeAdd, pending: isDefiEdgePending } = useDefiEdgeAdd(baseCurrency, quoteCurrency)

  const amountA = mintInfo.parsedAmounts[Field.CURRENCY_A]
  const amountB = mintInfo.parsedAmounts[Field.CURRENCY_B]
  const wbnbBalance = useCurrencyBalance(WBNB_EXTENDED[chainId])
  const preset = useActivePreset()

  const gammaPair = useMemo(() => {
    return gammaPairs && gammaPairs.find((pair) => pair.title === preset)
  }, [gammaPairs, preset])

  const amountToWrap = useMemo(() => {
    if (
      !baseCurrency ||
      !quoteCurrency ||
      !amountA ||
      !amountB ||
      liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE
    )
      return

    if (
      baseCurrency.isNative ||
      baseCurrency.wrapped.address.toLowerCase() === WBNB_EXTENDED[chainId].address.toLowerCase()
    ) {
      if (wbnbBalance && JSBI.greaterThan(amountA.numerator, wbnbBalance.numerator)) {
        return JSBI.subtract(amountA.numerator, wbnbBalance.numerator)
      }
      return
    } else if (
      quoteCurrency.isNative ||
      quoteCurrency.wrapped.address.toLowerCase() === WBNB_EXTENDED[chainId].address.toLowerCase()
    ) {
      if (wbnbBalance && JSBI.greaterThan(amountB.numerator, wbnbBalance.numerator)) {
        return JSBI.subtract(amountB.numerator, wbnbBalance.numerator)
      }
      return
    }

    return
  }, [amountA, amountB, baseCurrency, quoteCurrency, liquidityRangeType, wbnbBalance])

  const onAddLiquidity = useCallback(() => {
    const checkError = () => {
      if (errorMessage) {
        customNotify(errorMessage, 'warn')
        return
      }
    }

    switch (liquidityRangeType) {
      case v3LiquidityRangeType.GAMMA_RANGE: {
        checkError()

        return onGammaAdd(amountA, amountB, amountToWrap, gammaPair)
      }

      case v3LiquidityRangeType.MANUAL_RANGE: {
        checkError()

        return onManualAdd(amountA, amountB, mintInfo, slippage, deadline)
      }

      case v3LiquidityRangeType.ICHI_RANGE:
        return onIchiAdd(amountA, amountB, ichiAsset)

      case v3LiquidityRangeType.DEFI_EDGE:
        return handleDefiEdgeAdd(amountA, amountB, amountToWrap)

      default:
        return
    }
  }, [
    errorMessage,
    amountToWrap,
    onWrap,
    onGammaAdd,
    baseCurrency,
    quoteCurrency,
    liquidityRangeType,
    amountA,
    amountB,
    mintInfo,
    slippage,
    deadline,
  ])

  // const onAddLiquidityAndStake = useCallback(() => {
  //   if (errorMessage) {
  //     customNotify(errorMessage, 'warn')
  //     return
  //   }
  //   onGammaAddAndStake(amountA, amountB, amountToWrap, gammaPair)
  // }, [errorMessage, amountToWrap, onGammaAddAndStake, amountA, amountB])

  const gammaPoolDisabled = liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE && !gammaPair?.gammaEnabled

  return (
    <>
      {/* {liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE &&
        gammaPair &&
        gammaPair.gauge.address !== ZERO_ADDRESS && (
          <StyledButton
            disabled={wrappingETH || gammaPending || isManualPending}
            onClickHandler={onAddLiquidityAndStake}
            content={'ADD LIQUIDITY AND STAKE'}
            className='py-[13px] md:py-[14.53px] mt-8 text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
          />
        )} */}
      {account && activeChainId === defaultChainId ? (
        <StyledButton
          disabled={
            (!amountA && !amountB) ||
            gammaPoolDisabled ||
            wrappingETH ||
            gammaPending ||
            isManualPending ||
            isIchiPending ||
            isDefiEdgePending
          }
          onClickHandler={onAddLiquidity}
          content={gammaPoolDisabled ? 'Pool is locked' : 'ADD LIQUIDITY'}
          className='py-[13px] md:py-[14.53px] mt-8 text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
        />
      ) : (
        <StyledButton
          onClickHandler={openWalletModal}
          content={'CONNECT WALLET'}
          className='my-3 py-[13px] md:py-[14.53px] text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
        />
      )}
    </>
  )
}
