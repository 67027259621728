import gql from 'graphql-tag'

export const TOTAL_VOLUME_DATA = (block) => {
  const queryString = `query factories {
        factories(
         ${block ? `block: { number: ${block}}` : `first: 1`}) {
          totalVolumeUSD
        }
      }`
  return gql(queryString)
}

export const ACCUMULATIVE_TOKEN_BALANCES = (user) => {
  const queryString = `query accumulativeTokenBalances {
    accumulativeTokenBalances(where: {user: "${user}"}) {
      id
      user
      token
      amount
    }
  }`
  return gql(queryString)
}

export const USER_TICKETS = (user, round) => {
  const queryString = `query UserLotteriesQuery {
    userLotteries(where: {user: "${user}", round: ${round}}) {
      id
      user
      round
      tickets
    }
  }`
  return gql(queryString)
}

export const TOTAL_TICKETS = (round) => {
  const queryString = `query TotalTicketsQuery {
    lotteries(where: {round: ${round}}) {
      id
      totalTikets
    }
  }`
  return gql(queryString)
}

export const DIBS_WEEKLY_DATA = (epoch, skip) => {
  const queryString = `query WeeklyDataQuery {
    weeklyGeneratedVolumes(first: 100, skip: ${skip},  where: {epoch: ${epoch}, amountAsReferrer_gt: 0} orderBy: amountAsReferrer orderDirection: desc) {
      user
      amountAsReferrer
    }
  }`
  return gql(queryString)
}

export const DIBS_DAILY_DATA = (day, skip) => {
  const queryString = `query DailyDataQuery {
    dailyGeneratedVolumes(first: 100, skip: ${skip},  where: {day: ${day}, amountAsReferrer_gt: 0} orderBy: amountAsReferrer orderDirection: desc) {
      user
      amountAsReferrer
    }
  }`
  return gql(queryString)
}

export const FUSION_TOTAL_TVL = () => {
  const queryString = `query factories {
        factories(first: 1) {
          totalValueLockedUSD
        }
      }`
  return gql(queryString)
}

export const DEFE_EDGE_LIST = gql(
  `query strategies {
      strategyList(take: 50, networks: [polygon], page: 1, order: desc, search: "", type: null, dex: Retro, forcedRefresh: false, sortBy: aum, returnInToken: null) { 
        data {      
          id
          network
          createdAt
          title
          subTitle
          aum
          apy
          inception
          token0Url
          token1Url
          isArchived
          sharePrice
          since_inception
          fees
          volume
          manager
          dataFeed
          twitterHandle
          fees_apr
          autoRebalance
          one_day_apy
          seven_day_apy
          lp_apr
          lp_one_day_apy
          lp_seven_day_apy
          lp_since_inception
          strategyLimit
          manager
          performanceFee
          managementFee 
        }   
         page
         totalItems
         totalPages
         pageSize
         returnInToken   
        }
          }`,
)

export const DEFI_EDGE_STRATEGY = ({ network, address }) => {
  //   # network: 'mainnet' / 'kovan' / 'rinkeby' / 'matic' / 'arbitrum' / 'optimism'
  // # address: strategy address
  const queryString = `
    query strategyMetadata {
      strategy(where: {network_address: {address: ${address}, network: ${network}}}) {
        id
        title
        subTitle
        description
        updatedAt
        network
        sharePrice
        address
        aum
        createdAt
        fees_apr {
          USD
          BTC
          MATIC
          ETH
        }
        seven_day_apy {
          USD
          BTC
          MATIC
          ETH
        }
        since_inception {
          USD
          BTC
          MATIC
          ETH
        }
        one_day_apy {
          USD
          BTC
          MATIC
          ETH
        }
      }
    }
  `

  return gql(queryString)
}
