export const ichiVaults = [
  {
    depositTokenSymbol: 'WMATIC',
    observations: 900,
    feeTier: 0.05,
    token0: 'WMATIC',
    token1: 'WETH',
    poolAddress: '0x1a34EaBbe928Bf431B679959379b2225d60D9cdA',
    vaultAddress: '0x38F41FDe5cABC569E808537FdaF390cD7f0dC0f6',
  },
  {
    depositTokenSymbol: 'WETH',
    observations: 900,
    feeTier: 0.05,
    token0: 'WMATIC',
    token1: 'WETH',
    poolAddress: '0x1a34EaBbe928Bf431B679959379b2225d60D9cdA',
    vaultAddress: '0xE9BD439259DE0347DC26B86b3E73437E93858283',
  },
  {
    depositTokenSymbol: 'USDC',
    observations: 900,
    feeTier: 0.05,
    token0: 'WMATIC',
    token1: 'USDC',
    poolAddress: '0xec15624fbb314eb05baad4ca49b7904c0cb6b645',
    vaultAddress: '0x5Ef5630195164956d394fF8093C1B6964cb5814B',
  },
  {
    depositTokenSymbol: 'USDC',
    observations: 900,
    feeTier: 0.05,
    token0: 'USDC',
    token1: 'WETH',
    poolAddress: '0xCE67850420c82dB45eb7fEeCcD2d181300D2BDB3',
    vaultAddress: '0x7c96bE1026A5461418C557f2CDECfD0F50244371',
  },
  {
    depositTokenSymbol: 'WBTC',
    observations: 900,
    feeTier: 0.05,
    token0: 'WBTC',
    token1: 'WETH',
    poolAddress: '0xb694E3bdd4BCdF843510983D257679D1E627C474',
    vaultAddress: '0x88e372aBBD5CD31eA8B5F2442c2110c792f81D33',
  },
  {
    depositTokenSymbol: 'WETH',
    observations: 900,
    feeTier: 0.05,
    token0: 'WBTC',
    token1: 'WETH',
    poolAddress: '0xb694E3bdd4BCdF843510983D257679D1E627C474',
    vaultAddress: '0x0B0302014DD4FB6A77da03bF9034db5FEcB68eA8',
  },
  {
    depositTokenSymbol: 'USDT',
    observations: 900,
    feeTier: 0.05,
    token0: 'WMATIC',
    token1: 'USDT',
    poolAddress: '0xB580229645b240A368768ec3E88cb67A41eBBC5B',
    vaultAddress: '0xEC48a7649FE056C064fFB8bA629afbe3BAf46698',
  },
  {
    depositTokenSymbol: 'CASH',
    observations: 900,
    feeTier: 0.05,
    token0: 'WMATIC',
    token1: 'CASH',
    poolAddress: '0x63ca6ED3D390C725b7FEb617BAdcab78a61038E8',
    vaultAddress: '0xD325e348A5cbd68795a0b7131b14885B917B9399',
  },
  {
    depositTokenSymbol: 'CASH',
    observations: 900,
    feeTier: 0.05,
    token0: 'CASH',
    token1: 'WETH',
    poolAddress: '0x0A46B0712974F201BeD15c2458a023EbED25eF4F',
    vaultAddress: '0x4dBa434EF50027dA754bEbc926aec99CB2D960a5',
  },
  {
    depositTokenSymbol: 'MaticX',
    observations: 900,
    feeTier: 0.05,
    token0: 'CASH',
    token1: 'MaticX',
    poolAddress: '0xb98F8DF0DC6750A3e54540A1e094186C8bED780a',
    vaultAddress: '0x881F069CDE4F12590859b95D91ACF4F3E27b1535',
  },
  {
    depositTokenSymbol: 'frxETH',
    observations: 900,
    feeTier: 0.05,
    token0: 'FRAX',
    token1: 'frxETH',
    poolAddress: '0x38df0c93bf69a9EfB3d3647fF459eF27235A8968',
    vaultAddress: '0x958CB44CFc3444e704DB1d355200A2B5945aD341',
  },
  {
    depositTokenSymbol: 'frxETH',
    observations: 900,
    feeTier: 0.05,
    token0: 'WMATIC',
    token1: 'frxETH',
    poolAddress: '0x44AAE81BE8435b6998A66d9642f66DE57DD03FC2',
    vaultAddress: '0xa4717649c357972A026cd18eD786fc800571Efc3',
  },
  {
    depositTokenSymbol: 'WMATIC',
    observations: 1000,
    feeTier: 0.3,
    token0: 'WMATIC',
    token1: 'USDC',
    poolAddress: '0x8c862d100B94d95a49D91958c9e8C2c348d00F04',
    vaultAddress: '0x28CA8D003A2659962893016b85f776Feb35E9ff6',
    disabled: true,
  },
  {
    depositTokenSymbol: 'ICHI',
    observations: 1200,
    feeTier: 1,
    token0: 'ICHI',
    token1: 'CASH',
    poolAddress: '0x8ceD58A2dE3C2ea5D3cED3eb4331158c8cf985Fb',
    vaultAddress: '0x84ad0952DFd0c543B85936644399867e70dd244d',
  },
  {
    depositTokenSymbol: 'CASH',
    observations: 1200,
    feeTier: 1,
    token0: 'ICHI',
    token1: 'CASH',
    poolAddress: '0x8ceD58A2dE3C2ea5D3cED3eb4331158c8cf985Fb',
    vaultAddress: '0x368622eaEEB043Ab4f686E1FAC7CF29F740204E0',
  },
  {
    depositTokenSymbol: 'WETH',
    observations: 1200,
    feeTier: 1,
    token0: 'WETH',
    token1: 'RETRO',
    poolAddress: '0x35394eED0Be676ec6470fE6531daD809265310ff',
    vaultAddress: '0x3845AE5ab91f3E69B462DFcc6353b0A1fCDB6194',
  },
  {
    depositTokenSymbol: 'CASH',
    observations: 1200,
    feeTier: 1,
    token0: 'CASH',
    token1: 'RETRO',
    poolAddress: '0xb47a07966ce6812702c0567d03725f1b37e27877',
    vaultAddress: '0x9a51B71901584Ca77550D603246b58B1914b999f',
  },
  {
    depositTokenSymbol: 'WMATIC',
    observations: 1200,
    feeTier: 1,
    token0: 'WMATIC',
    token1: 'RETRO',
    poolAddress: '0x547fd24aa2ed09d86dac02e36b8ce84a59fd644f',
    vaultAddress: '0xB4eafB818c6c9F4862c95502075667B7b662c573',
  },
  {
    depositTokenSymbol: 'WBTC',
    observations: 1200,
    feeTier: 1,
    token0: 'WBTC',
    token1: 'RETRO',
    poolAddress: '0x0f73e719a76947752e4b26a2c3fc80a580e68fb4',
    vaultAddress: '0xaa3Bc5aC49ccfDDAb74f87Ca2FB3cfB879B91BC0',
  },
  {
    depositTokenSymbol: 'stMATIC',
    observations: 1200,
    feeTier: 1,
    token0: 'stMATIC',
    token1: 'RETRO',
    poolAddress: '0x0973698bb6399c39d628698f9300430c4e7c9bf9',
    vaultAddress: '0x7376734058CF5C6d397287cf8C80aC6334d0eC78',
  },
  {
    depositTokenSymbol: 'USDC',
    observations: 1200,
    feeTier: 1,
    token0: 'USDC',
    token1: 'RETRO',
    poolAddress: '0xc7d8b9c270d0e31a6a0cf4496fe019766be42e15',
    vaultAddress: '0x42F1dE424b129ffab8Da760641c4DBDA10649108',
  },
  {
    depositTokenSymbol: 'USDT',
    observations: 1200,
    feeTier: 1,
    token0: 'RETRO',
    token1: 'USDT',
    poolAddress: '0x69dcae97a1286b112a8bd75bd9147bd0a3207756',
    vaultAddress: '0xdAb84fd12E8937b4c4931A6Cf21d3E602BD49d8b',
  },
  {
    depositTokenSymbol: 'MAI',
    observations: 1200,
    feeTier: 1,
    token0: 'MAI',
    token1: 'RETRO',
    poolAddress: '0xd98eef295f78dddd104a8645854c7f8714b39b88',
    vaultAddress: '0xD0D10057BD9f1b9e7d4b6182aEe577cc46191B13',
  },
  {
    depositTokenSymbol: 'LINK',
    observations: 1200,
    feeTier: 1,
    token0: 'LINK',
    token1: 'RETRO',
    poolAddress: '0x7761c7233924c3d72f27fae6a81b074c0152e03a',
    vaultAddress: '0xfc2Ab62A4532ac85517121349eE93b73f066696b',
  },
  {
    depositTokenSymbol: 'agEUR',
    observations: 1200,
    feeTier: 1,
    token0: 'RETRO',
    token1: 'agEUR',
    poolAddress: '0x15949526ff03fe6ab5f49da6a86749c20141b3f1',
    vaultAddress: '0xE5EE8985cC45bBb3D34DA861a9BE4930b94B2F49',
  },
  {
    depositTokenSymbol: 'agEUR',
    observations: 1200,
    feeTier: 0.05,
    token0: 'USDC',
    token1: 'agEUR',
    poolAddress: '0xC2CB03a819F785bD8eb8D628BAD41Cd7c1C71F4B',
    vaultAddress: '0xcd4DCd92ef8d535623B9f86b9F857735736722bD',
  },
  {
    depositTokenSymbol: 'USDC',
    observations: 1000,
    feeTier: 0.05,
    token0: 'USDC',
    token1: 'agEUR',
    poolAddress: '0xC2CB03a819F785bD8eb8D628BAD41Cd7c1C71F4B',
    vaultAddress: '0x6af6c0fda96b69e2482e8AF499CD67485CCB79dc',
  },
  {
    depositTokenSymbol: 'ICHI',
    observations: 1200,
    feeTier: 1,
    token0: 'ICHI',
    token1: 'RETRO',
    poolAddress: '0xC26E1a0cDab4Bf5141530C49F3360f162C5Db5Db',
    vaultAddress: '0x8557723ba9772507Baae241cb29f9714D86dCfd6',
  },
  {
    depositTokenSymbol: 'WMATIC',
    observations: 1200,
    feeTier: 0.3,
    token0: 'WMATIC',
    token1: 'ICHI',
    poolAddress: '0x13d5e61838D93F7962C52dc921FD7C0Ccc092E35',
    vaultAddress: '0x9969039D2BBD836c30DAB7103CB945D032f48663',
  },
  {
    depositTokenSymbol: 'oRETRO',
    observations: 1000,
    feeTier: 1,
    token0: 'oRETRO',
    token1: 'RETRO',
    poolAddress: '0x387FBcE5E2933Bd3a7243D0be2aAC8fD9Ab3D55d',
    vaultAddress: '0x8A3bA29Ad7B27DEEFBaAc57ccF946fA6eB40147c',
  },
  {
    depositTokenSymbol: 'WMATIC',
    observations: 1000,
    feeTier: 0.01,
    token0: 'WMATIC',
    token1: 'stMATIC',
    poolAddress: '0x2d623c33673248E95C7a1C26aE7065eb7A7Ac0Ea',
    vaultAddress: '0x4D39B76366A6138C08bC88D64029269644f96f0E',
  },
  {
    depositTokenSymbol: 'stMATIC',
    observations: 1000,
    feeTier: 0.01,
    token0: 'WMATIC',
    token1: 'stMATIC',
    poolAddress: '0x2d623c33673248E95C7a1C26aE7065eb7A7Ac0Ea',
    vaultAddress: '0xEf6774d60d25271F14c447090E3D1C6C7915e9A6',
  },
  {
    depositTokenSymbol: 'WMATIC',
    observations: 1000,
    feeTier: 0.05,
    token0: 'WMATIC',
    token1: 'USDC',
    poolAddress: '0xec15624fbb314eb05baad4ca49b7904c0cb6b645',
    vaultAddress: '0x91f935892355C8CA4468C44D2c4bAE1A23c60c14',
  },
  {
    depositTokenSymbol: 'CASH',
    observations: 1000,
    feeTier: 0.3,
    token0: 'LQDR',
    token1: 'CASH',
    poolAddress: '0x22c10e61A05a03bcd8BF61a7E648f2330ecdA270',
    vaultAddress: '0xF40F87deBe6d2676771266C9f749CC9e8EE0B38b',
  },
  {
    depositTokenSymbol: 'JRT',
    observations: 1000,
    feeTier: 0.3,
    token0: 'JRT',
    token1: 'WETH',
    poolAddress: '0xAE107e52ebdF151E61d9Fb4Bd800C86c31741465',
    vaultAddress: '0x6C18D74050Df1b9cc4199E3e50D8DE2cD927BF80',
  },
  {
    depositTokenSymbol: 'MaticX',
    observations: 1000,
    feeTier: 0.01,
    token0: 'WMATIC',
    token1: 'MaticX',
    poolAddress: '0x9aa18301055091e9da7C57b81633632Fa33B9a80',
    vaultAddress: '0x03509B5351342F549B62a806099bDbf4376739B5',
  },
  {
    depositTokenSymbol: 'WETH',
    observations: 900,
    feeTier: 0.05,
    token0: 'USDC',
    token1: 'WETH',
    poolAddress: '0xCE67850420c82dB45eb7fEeCcD2d181300D2BDB3',
    vaultAddress: '0xBc67C4d8b94B325C894DB954a6F4AA0f6f009Add',
  },
  {
    depositTokenSymbol: 'RETRO',
    observations: 1000,
    feeTier: 1,
    token0: 'RETRO',
    token1: 'liveRETRO',
    poolAddress: '0x6333BB8b6f1DDa6f929d70eDEB9e31c8148dC9ef',
    vaultAddress: '0xcbe563247b85c2c9cf5328EAD23f1D5FEDc142D8',
  },
  {
    depositTokenSymbol: 'WBTC',
    observations: 1500,
    feeTier: 0.3,
    token0: 'WBTC',
    token1: 'USDC',
    poolAddress: '0x459F42C788E6F8faCe4854DDD59C81a6Ad8BB1Aa',
    vaultAddress: '0xceBaF9Bf1de089D0Ce0243E9f19682E9445191df',
  },
]
