import React, { useEffect, useState } from 'react'

import { Widget } from '@kyberswap/widgets'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import { getNetworkLibrary } from 'utils'

const darkTheme = {
  text: '#B8B6CB',
  subText: '#B8B6CB',
  primary: '#141B22',
  dialog: '#141B22',
  secondary: '#000000',
  interactive: 'black',
  stroke: '#E1E1E1',
  accent: '#E1E1E1',
  success: '#189470',
  warning: '#FF9901',
  error: '#FF537B',
  fontFamily: 'Inter, sans-serif',
  borderRadius: '3px',
  buttonRadius: '3px',
  boxShadow: '0',
}

export const KyberWidget = () => {
  const { library } = useActiveWeb3React()

  const [tokens, setTokens] = useState([])

  useEffect(() => {
    fetch('https://retro-backend.stabl.finance/api/v1/assets')
      .then((res) => res.json())
      .then((res) => setTokens(res.data))
  }, [])

  return (
    <Widget
      client='retro'
      theme={darkTheme}
      tokenList={tokens}
      provider={library ?? getNetworkLibrary()}
      defaultTokenIn='0x5d066d022ede10efa2717ed3d79f22f949f8c175' // cash
      defaultTokenOut='0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb' // retro
      enableDexes='retro,retro-v3'
    />
  )
}
