import React from 'react'
import Menu from './Menu'
import navLinks from './NavLinks'
import socialLinks from './SocialLinks'

const SideNav = () => {
  return (
    <aside
      className={`relative z-[1000] hidden xl:flex flex-col flex-shrink-0 flex-grow w-mobileSide xl2:w-sideNav overflow-x-hidden bg-headerBg`}
    >
      {/* <UserProfile /> */}
      <ul className='flex flex-col items-center w-full p-0 m-0 list-style-none gap-y-2.5 px-6 2xl:px-12 py-5'>
        {/* {account && oRETROToRelease && oRETROToRelease > 0 && (
          <Link to="/satin-migration" className='text-xl text-green'>
            SATIN MIGRATION
          </Link>
        )} */}
        {navLinks.map((item, idx) => {
          return <Menu item={item} key={`main-${idx}`} idx={idx} />
        })}
      </ul>

      <div className='mt-auto mb-8 flex items-center justify-center gap-x-2.5'>
        {socialLinks.map((link, idx) => {
          return (
            <a key={idx} href={link.link} className='block no-underline' target='_blank' rel='noreferrer'>
              <img src={link.image} alt={link.name} className='w-8' />
            </a>
          )
        })}
      </div>
    </aside>
  )
}

export default SideNav
