import React from 'react'

export const Dashboard = () => {
  return (
    <div className='h-full'>
      <iframe
        width='100%'
        height='100%'
        src='https://lookerstudio.google.com/embed/reporting/735795dd-5d73-4f31-84f5-15d3a73fb370/page/p_wyh0wpv33c'
        // frameBorder='0'
        style={{ border: 0, height: '100%' }}
        allowfullscreen
      ></iframe>
    </div>
  )
}
