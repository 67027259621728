import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import { useState } from 'react'
import IchiRemoveModal from './IchiRemoveModal'

export const IchiPosition = ({ id, position }) => {
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)

  return (
    <div className={`${id > 0 ? 'mt-[9px]' : ''} w-full px-5 py-[19.2px] bg-body`}>
      {isWithdrawModalOpen && (
        <IchiRemoveModal isOpen={isWithdrawModalOpen} setIsOpen={setIsWithdrawModalOpen} position={position} />
      )}

      <div className='flex items-start md:items-center justify-between'>
        <div className='flex items-start md:items-center space-x-3'>
          <div className='flex items-center space-x-3 '>
            <div className='flex items-center'>
              {/* <img alt='' className='w-6 lg:w-[30px] relative shadow' src={token0logoURI} /> */}
              <img alt='' className='w-6 lg:w-[30px]' src={position.logoURI} />
            </div>
            <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>{position.name}</p>
          </div>
        </div>
        <div className='text-white w-[90px]'>
          <LinearGhostButton onClickHanlder={() => setIsWithdrawModalOpen(true)} smallCase title='Withdraw' />
        </div>
      </div>
    </div>
  )
}
