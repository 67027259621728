import web3NoAccount from './web3'
import {
  getAlgebraAddress,
  getDibsAddress,
  getDibsLotteryAddress,
  getFactoryAddress,
  getGammaUNIProxyAddress,
  getMinterAddress,
  getMultiCallAddress,
  getMuonAddress,
  getPairAPIAddress,
  getPairV3APIAddress,
  getQuoterAddress,
  getRewardsAPIAddress,
  getRewardsV3APIAddress,
  getRouterAddress,
  getRoyaltyAddress,
  getStakingAddress,
  getThenianAddress,
  getV3VoterAddress,
  getVeDistAddress,
  getVeTHEAddress,
  getVeTHEAPIAddress,
  getVeTHEV3APIAddress,
  getVoterAddress,
  getWBNBAddress,
  getOptionAddress,
  getSatinMigrationAddress,
  getTransmutationAddress,
  getBribeOptionAddress,
} from './addressHelpers'
import {
  ERC20Abi,
  factoryAbi,
  gaugeAbi,
  multiCallAbi,
  pairAPIAbi,
  routerAbi,
  ThenianAbi,
  veDistAbi,
  veTHEAbi,
  veTHEAPIAbi,
  voterAbi,
  bribeAbi,
  minterAbi,
  pairAbi,
  stakingAbi,
  royaltyAbi,
  rewardAPIAbi,
  dibsAbi,
  muonAbi,
  wbnbAbi,
  dibsLotteryAbi,
  pairV3APIAbi,
  veTheV3ApiAbi,
  rewardsV3APIAbi,
  v3voterAbi,
  extraRewarderAbi,
  optionAbi,
  ichiVaultAbi,
  satinMigrationAbi,
  transmutationAbi,
  bribeOptionAbi,
} from '../config/abi'
import { algebraAbi, gammaHypervisorAbi, gammaUniProxyAbi, quoterAbi } from '../config/abi/v3'

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount
  return new _web3.eth.Contract(abi, address)
}

export const getIchiVaultContract = (web3, address) => {
  return getContract(ichiVaultAbi, address, web3)
}

export const getERC20Contract = (web3, address) => {
  return getContract(ERC20Abi, address, web3)
}

export const getWBNBContract = (web3) => {
  return getContract(wbnbAbi, getWBNBAddress(), web3)
}

export const getMulticallContract = (web3) => {
  return getContract(multiCallAbi, getMultiCallAddress(), web3)
}

export const getThenianContract = (web3) => {
  return getContract(ThenianAbi, getThenianAddress(), web3)
}

export const getRouterContract = (web3) => {
  return getContract(routerAbi, getRouterAddress(), web3)
}

export const getFactoryContract = (web3) => {
  return getContract(factoryAbi, getFactoryAddress(), web3)
}

export const getVeTHEContract = (web3) => {
  return getContract(veTHEAbi, getVeTHEAddress(), web3)
}

export const getVeDistContract = (web3) => {
  return getContract(veDistAbi, getVeDistAddress(), web3)
}

export const getVoterContract = (web3) => {
  return getContract(voterAbi, getVoterAddress(), web3)
}

export const getMinterContract = (web3) => {
  return getContract(minterAbi, getMinterAddress(), web3)
}

export const getPairAPIContract = (web3) => {
  return getContract(pairAPIAbi, getPairAPIAddress(), web3)
}

export const getVeTHEAPIContract = (web3) => {
  return getContract(veTHEAPIAbi, getVeTHEAPIAddress(), web3)
}

export const getRewardsAPIContract = (web3) => {
  return getContract(rewardAPIAbi, getRewardsAPIAddress(), web3)
}

export const getPairV3APIContract = (web3) => {
  return getContract(pairV3APIAbi, getPairV3APIAddress(), web3)
}

export const getVeTHEV3APIContract = (web3) => {
  return getContract(veTheV3ApiAbi, getVeTHEV3APIAddress(), web3)
}

export const getRewardsV3APIContract = (web3) => {
  return getContract(rewardsV3APIAbi, getRewardsV3APIAddress(), web3)
}

export const getGaugeContract = (web3, address) => {
  return getContract(gaugeAbi, address, web3)
}

export const getBribeContract = (web3, address) => {
  return getContract(bribeAbi, address, web3)
}

export const getPairContract = (web3, address) => {
  return getContract(pairAbi, address, web3)
}

export const getStakingContract = (web3) => {
  return getContract(stakingAbi, getStakingAddress(), web3)
}

export const getRoyaltyContract = (web3) => {
  return getContract(royaltyAbi, getRoyaltyAddress(), web3)
}

export const getDibsContract = (web3) => {
  return getContract(dibsAbi, getDibsAddress(), web3)
}

export const getDibsLotteryContract = (web3) => {
  return getContract(dibsLotteryAbi, getDibsLotteryAddress(), web3)
}

export const getMuonContract = (web3) => {
  return getContract(muonAbi, getMuonAddress(), web3)
}

export const getQuoterContract = (web3) => {
  return getContract(quoterAbi, getQuoterAddress(), web3)
}

export const getGammaUNIProxyContract = (web3) => {
  return getContract(gammaUniProxyAbi, getGammaUNIProxyAddress(), web3)
}

export const getGammaHyperVisorContract = (web3, address) => {
  return getContract(gammaHypervisorAbi, address, web3)
}

export const getAlgebraContract = (web3) => {
  return getContract(algebraAbi, getAlgebraAddress(), web3)
}

export const getV3VoterContract = (web3) => {
  return getContract(v3voterAbi, getV3VoterAddress(), web3)
}

export const getExtraRewarder = (web3) => {
  return getContract(extraRewarderAbi, '0xA7266B2303725F731851dfE944a432f8A2EA5c9c', web3)
}

export const getOptionContract = (web3) => {
  return getContract(optionAbi, getOptionAddress(), web3)
}

export const getBribeOptionContract = (web3) => {
  return getContract(bribeOptionAbi, getBribeOptionAddress(), web3)
}

export const getTransmutationContract = (web3) => {
  return getContract(transmutationAbi, getTransmutationAddress(), web3)
}

export const getSatinMigrationContract = (web3) => {
  return getContract(satinMigrationAbi, getSatinMigrationAddress(), web3)
}
