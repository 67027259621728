const contracts = {
  RETRO: {
    137: '0xBFA35599c7AEbb0dAcE9b5aa3ca5f2a79624D8Eb',
    56: '0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11',
    97: '0x4E5009a313978EFa9732997e93928529F8a84072',
  },
  WBNB: {
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  ETH: {
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    97: '',
  },
  thenian: {
    56: '0x2Af749593978CB79Ed11B9959cD82FD128BA4f8d',
    97: '0x45c85db14Ff99803b59989e91178847e1892Ea02',
  },
  multiCall: {
    137: '0xcA11bde05977b3631167028862bE2a173976CA11',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  router: {
    // 56: '0x20a304a7d126758dfe6B243D0fc515F83bCA8431',
    // 56: '0x9B237893321b2D447E1D1Ae02004ebA30b187D0d',
    137: '0x77F0e98e3F2F3134496C2B769f40c891351524d1',
    56: '0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109',
    97: '0x761636F40307AAEC44367BE82FCD9F75FeA310f5',
  },
  factory: {
    137: '0x1fC46294195aA87F77fAE299A14Bd1728dC1Cca9',
    56: '0xAFD89d21BdB66d00817d4153E055830B1c2B3970',
    97: '0x86D22c4130401a5723fFD6C0c798c205A2Fea1ed',
  },
  veTHE: {
    137: '0xB419cE2ea99f356BaE0caC47282B9409E38200fa',
    56: '0xfBBF371C9B0B994EebFcC977CEf603F7f31c070D',
    97: '0x04106fb6E161D0C5E8E0129F4a8a2cd62dCF6110',
  },
  veDist: {
    137: '0x505d9c8d4f47031871bd8e6b9c5db74289ac9a74',
    56: '0xE9fE83aA430Ace4b703C299701142f9dFdde730E',
    97: '0x934547fee052323F05454c8a2d8b45BBc47812D7',
  },
  voter: {
    137: '0xAcCbA5e852AB85E5E3a84bc8E36795bD8cEC5C73',
    56: '0x62Ee96e6365ab515Ec647C065c2707d1122d7b26',
    97: '0x1344244b3432846d00e1D5cc9FEB825743304539',
  },
  v3voter: {
    137: '0xAcCbA5e852AB85E5E3a84bc8E36795bD8cEC5C73',
    56: '0x3A1D0952809F4948d15EBCe8d345962A282C4fCb',
    97: '',
  },
  minter: {
    137: '0x003D505Aff54FB7856aA6Bcb56a8397F5aF89479',
    56: '0x86069FEb223EE303085a1A505892c9D4BdBEE996',
    97: '',
  },
  pairAPI: {
    56: '0x7419477C03b0FEb9286F216b9d19E42f86B288b3',
    97: '',
  },
  veTHEAPI: {
    137: '0x545e0Af21a89Da6Bd72c3a247f9F6D116F9c21c1',
    56: '0x949d5B4eb1A8fbA23AE7741d02C602bFFFdF813b',
    97: '',
  },
  rewardsAPI: {
    137: '0x7992FB23153452e6215bB0c445982f7954a5935a',
    56: '0x54a3E5ef4Ba0308FC60a5c159a96376cc0bB4787',
    97: '',
  },
  pairV3API: {
    137: '0xDF5ed9869721A37981359fA5a617D53Ef1B7e8b5',
    56: '0xE89080cEb6CAEb9Eba5a0d4Aa13686eFcB78A32E',
    97: '',
  },
  veTHEV3API: {
    137: '0x545e0Af21a89Da6Bd72c3a247f9F6D116F9c21c1',
    56: '0x64039b95537D2904716D1957dD07061Ab94a4549',
    97: '',
  },
  rewardsV3API: {
    137: '0x7992FB23153452e6215bB0c445982f7954a5935a',
    56: '0xa636471A76dd3777e5b101DEA2e07B9656c664e9',
    97: '',
  },
  staking: {
    56: '0xe58E64fb76e3C3246C34Ee596fB8Da300b5Adfbb',
    97: '',
  },
  royalty: {
    56: '0xBB2caf56BF29379e329dFED453cbe60E4d913882',
    97: '',
  },
  dibs: {
    56: '0x664cE330511653cB2744b8eD50DbA31C6c4C08ca',
    97: '',
  },
  dibsLottery: {
    56: '0x287ed50e4c158dac38e1b7e16c50cd1b2551a300',
    97: '',
  },
  muon: {
    56: '0xBa079Ad36E48e75b8b37f17aF1Fc285bceB84391',
    97: '',
  },
  option: {
    137: '0x3A29CAb2E124919d14a6F735b6033a3AaD2B260F',
  },
  satinMigration: {
    137: '0x663F8936DCE51680282Af697aaDa7BEBA36E04a8',
  },
  merklDistributor: {
    137: '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae',
  },
  transmutation: {
    137: '0xe77729AB7FD06AF7DaEC6a2EAF93a011C7c395AF',
  },
  bribeOption: {
    137: '0x86b634eac93e463fcc303e632ddf05cfaadfdad1',
  },
}
export default contracts
