import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import TabFilter from '../../components/common/TabFilter'
import SearchInput from '../../components/common/Input/SearchInput'
import Table from '../../components/pages/vote/table'
import Timer from '../../components/common/Timer'
import MobileFilterModal from '../../components/common/MobileFilterModal'
import { formatAmount, getLPSymbol, ZERO_ADDRESS } from '../../utils/formatNumber'
import VeTHESelect from '../../components/common/VeTHESelect'
import Toggle from '../../components/common/Toggle'
import { useEpochTimer, useVoteEmissions } from '../../hooks/useGeneral'
import { PoolTypes } from '../../config/constants'
import { veTHEsContext } from '../../context/veTHEsConetext'
import usePrices from '../../hooks/usePrices'
import { FusionsContext } from '../../context/FusionsContext'
import FilterIcon from '../../icons/FilterIcon'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import CloseModalIcon from 'icons/CloseModalIcon'
import { useLocalStorage } from 'hooks/useLocalStorage'

const sortOptions = [
  {
    label: 'APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'Total Votes',
    value: 'votes',
    isDesc: true,
  },
  {
    label: 'Rewards',
    value: 'rewards',
    isDesc: true,
  },
  {
    label: 'bve Rewards',
    value: 'bveRewardsUsd',
    isDesc: true,
  },
  {
    label: 'Your Vote',
    value: 'your',
    isDesc: true,
  },
]

const Vote = () => {
  const [filter, setFilter] = useState(PoolTypes.ALL)
  const [sort, setSort] = useState(sortOptions[1])
  const [mobileFilter, setMobileFilter] = useState(false)
  const [isVoted, setIsVoted] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [veTHE, setVeTHE] = useState(null)
  const [percent, setPercent] = useState({})
  const fusions = useContext(FusionsContext)
  const veTHEs = useContext(veTHEsContext)
  const { voteEmssions } = useVoteEmissions()
  const { days, hours, mins, epoch } = useEpochTimer()
  const navigate = useNavigate()
  const prices = usePrices()
  let { veId } = useParams()
  const [didCloseResetLockNotification, setDidCloseResetLockNotification] = useLocalStorage(
    'vote-reset-lock-notification-closed',
    false,
  )

  const avgApr = useMemo(() => {
    if (fusions && fusions.length > 0 && prices) {
      const totalBribeRetro = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.bribeUsd)
      }, new BigNumber(0))

      const totalBribeBveRetro = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.bribeBveUsd)
      }, new BigNumber(0))

      const totalWeight = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.weight)
      }, new BigNumber(0))

      const totalVoteUsdRetro = totalWeight.times(prices['RETRO'])

      const totalVoteUsdBveRetro = totalWeight.times(prices['bveRETRO'])

      const avgRetro = totalVoteUsdRetro?.isZero()
        ? new BigNumber(0)
        : totalBribeRetro.times(52).div(totalVoteUsdRetro).times(100)

      const avgbveRetro =
        totalVoteUsdBveRetro?.isZero() || !totalBribeBveRetro || totalBribeBveRetro.isZero()
          ? new BigNumber(0)
          : totalBribeBveRetro.times(52).div(totalVoteUsdBveRetro).times(100)

      return { avgRetro, avgbveRetro, avgTotal: avgRetro.plus(avgbveRetro) }
    }
  }, [fusions, prices])

  useEffect(() => {
    if (veTHEs && veTHEs.length > 0 && veId) {
      const item = veTHEs.find((ele) => ele.id === veId)
      if (!item) {
        navigate('/404')
        return
      }
      setVeTHE(item)
    }
  }, [veTHEs, veId])

  const bribesUsdAmounts = useMemo(() => {
    const bribeTotalUsd = fusions.reduce((acc, cur) => acc.plus(cur.gauge.bribeOnlyUsd), new BigNumber(0))

    const feeTotalUsd = fusions.reduce((acc, cur) => acc.plus(cur.gauge.feeUsd), new BigNumber(0))

    const bribeBveTotalUsd = fusions.reduce((acc, cur) => acc.plus(cur.gauge.bribeBveUsd), new BigNumber(0))

    const totalRewards = bribeTotalUsd.plus(feeTotalUsd).plus(bribeBveTotalUsd)

    return { feeTotalUsd, bribeTotalUsd, bribeBveTotalUsd, totalRewards }
  }, [fusions])

  const totalInfo = useMemo(() => {
    return [
      {
        title: 'Your veRETRO Balance',
        balance: veTHE ? formatAmount(veTHE.voting_amount) : '-',
      },
      {
        title: 'Emissions / % of Vote',
        balance: '$' + formatAmount(voteEmssions),
      },
      {
        title: 'Average Voting APR',
        balance: formatAmount(avgApr?.avgTotal) + '%',
      },
      {
        title: `Epoch ${epoch} Ends In`,
        balance: `${days}d ${hours}h ${mins}m`,
      },
      {
        title: 'Total Bribes',
        balance: `$ ${formatAmount(bribesUsdAmounts.bribeTotalUsd)}`,
      },
      {
        title: 'Total bveBribes',
        balance: `$ ${formatAmount(bribesUsdAmounts.bribeBveTotalUsd)}`,
      },
      {
        title: 'Total Fees',
        balance: `$ ${formatAmount(bribesUsdAmounts.feeTotalUsd)}`,
      },
    ]
  }, [veTHE, voteEmssions, avgApr, mins])

  const pools = useMemo(() => {
    return fusions
      .filter((pair) => pair.gauge.address !== ZERO_ADDRESS && pair.isValid)
      .filter((pair) => pair.gauge.address !== ZERO_ADDRESS && pair.isValid)
      .map((pair) => {
        let votes = {
          weight: new BigNumber(0),
          weightPercent: new BigNumber(0),
        }
        if (veTHE && veTHE.votes.length > 0) {
          const found = veTHE.votes.find((ele) => ele.address.toLowerCase() === pair.underlyingPool.toLowerCase())
          if (found) {
            votes = found
          }
        }
        return {
          ...pair,
          votes,
        }
      })
  }, [fusions, veTHE])

  useEffect(() => {
    if (veTHE) {
      setVeTHE(veTHEs.find((item) => item.id === veTHE.id))
    }
  }, [veTHEs, veTHE])

  const votedGauges = useMemo(() => {
    const temp = []
    for (let i = 0; i < Object.keys(percent).length; i++) {
      const key = Object.keys(percent)[i]
      if (!isNaN(Number(percent[key])) && Number(percent[key]) !== 0) {
        const found = pools.find((pool) => pool.underlyingPool === key)
        temp.push({
          ...found,
          votes: percent[key],
        })
      }
    }
    return temp
  }, [pools, percent])

  const totalPercent = useMemo(() => {
    return Object.values(percent).reduce((sum, current) => {
      return sum + (!current || current === '' ? 0 : Number(current))
    }, 0)
  }, [percent])

  return (
    <>
      <div className='w-full max-w-7xl px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 pt-5 pb-10 xl:pt-20  md:pt-14 mx-auto'>
        <div className='lg:flex flex-col items-start justify-between gap-8'>
          <div className='lg:flex lg:justify-between justify-center w-full'>
            <div className='w-full lg:w-[30%] xl:w-1/3'>
              <div className='max-w-[450px]'>
                <h1 className='text-[34px] md:text-4xl xl:text-[42px] font-semibold text-white'>Vote</h1>
                <p className='text-dimGray text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
                  Select your veRETRO and use 100% of your votes for one or more pools to earn bribes and trading fees.{' '}
                  {''}
                </p>
              </div>
            </div>

            <div className='flex flex-col justify-center lg:self-end'>
              <div>
                <Link to='/vote-optimizer'>
                  <LinearGhostButton smallCase title='Voting Tools' className='text-black inline px-[10px] w-full' />
                </Link>
              </div>
              <div className='flex justify-center mt-3 items-center py-[17px] px-[23px] bg-[#2166fa] text-white rounded-[5px]'>
                <div className='font-light mr-2'>Total Rewards</div>
                <div className='text-[21px] font-semibold'>$ {formatAmount(bribesUsdAmounts.totalRewards)}</div>
              </div>
            </div>
          </div>
          <Timer arr={totalInfo} className={`w-full mt-4 lg:mt-0`} />
        </div>
        {!didCloseResetLockNotification && (
          <div className='text-[#b8b6cb] border-2 border-white p-3 relative mt-3'>
            <p>
              In the last 4 hours of the epoch, there will be a lock on the ability to reset your votes. This means that
              you will still have the flexibility to adjust and reset your votes anytime leading up to this 4 hour
              threshold. However, once this period begins, any votes cast will become final and cannot be altered.
            </p>
            <button
              onClick={() => setDidCloseResetLockNotification(true)}
              className='focus:outline-none ml-auto absolute top-2 right-2'
            >
              <span className='block w-5 h-5'>
                <CloseModalIcon />
              </span>
            </button>
          </div>
        )}
        <div className='flex flex-col lg:flex-row items-center justify-between w-full mt-8 relative'>
          <div className='w-full flex gap-5'>
            <div className='self-center hidden lg:flex'>
              <TabFilter data={Object.values(PoolTypes)} filter={filter} setFilter={setFilter} />
            </div>
            <div className='items-center space-x-2 hidden lg:flex'>
              <Toggle checked={isVoted} onChange={() => setIsVoted(!isVoted)} toggleId='isVoted' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Voted Only</p>
            </div>

            <div className='flex items-center w-full flex-col-reverse lg:flex-row gap-4 lg:gap-5'>
              <div className='flex lg:items-center w-full gap-2.5 lg:gap-0'>
                <SearchInput
                  className={'w-full'}
                  full={true}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  placeholder='Search LP'
                />
                <button
                  onClick={() => {
                    setMobileFilter(!mobileFilter)
                  }}
                  className='flex w-16 flex-shrink-0 lg:hidden'
                >
                  <FilterIcon />
                </button>
              </div>
              <VeTHESelect className={'w-full flex'} isSelected={veTHE} setIsSelected={setVeTHE} />
            </div>
          </div>
          {/* mobile filters popup */}
          {mobileFilter && (
            <MobileFilterModal
              setMobileFilter={setMobileFilter}
              setFilter={setFilter}
              filter={filter}
              tabs={Object.values(PoolTypes)}
              isVote={true}
              isVoted={isVoted}
              setIsVoted={setIsVoted}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
            />
          )}
        </div>
        <div className='my-4'>
          {votedGauges.length > 0 && (
            <>
              <p className='text-white'>Your Votes:</p>
              <div className='flex flex-col md:flex-row gap-y-3 md:gap-0 md:overflow-auto mb-4 lg:mb-0 pb-2 lg:pb-0 w-full mt-1.5 lg:-mt-2.5 md:space-x-4 lg:space-x-8 xl:space-x-4'>
                {votedGauges.map((pool, idx) => {
                  return (
                    <div
                      key={idx}
                      className='flex items-center bg-componentPrimary lg:my-4 flex-shrink-0 px-6 max-w-full lg:max-w-[337px] xl:max-w-[375px] rounded-md'
                    >
                      <button
                        className='w-8 h-8 rounded-full flex-shrink-0 flex items-center justify-center bg-[#2A2A2A]'
                        onClick={() => {
                          setPercent({
                            ...percent,
                            [pool.underlyingPool]: '',
                          })
                        }}
                      >
                        <img alt='' src='/images/vote/remove-button.svg' className='w-full h-full' />
                      </button>
                      <div className='flex items-center w-full space-x-2 ml-4 py-5'>
                        <div className='flex items-center -space-x-2 flex-shrink-0'>
                          <img className='relative w-6 h-6 z-10' alt='' src={pool.token0.logoURI} />
                          <img className='relative w-6 h-6 z-[5]' alt='' src={pool.token1.logoURI} />
                        </div>
                        <div className=' text-white'>
                          <p className='text-[1rem] font-semibold f-f-fg lg:text-base xl:text-[17px] leading-5'>
                            {getLPSymbol(pool)}
                          </p>
                          {/* <p className='tracking-[0.66px] text-[11px] xl:text-xs leading-none'>{pool.title}</p> */}
                        </div>
                      </div>
                      <div className='flex items-center relative'>
                        <input
                          onChange={(e) => {
                            const val = isNaN(Number(percent[pool.underlyingPool]))
                              ? 0
                              : Number(percent[pool.underlyingPool])
                            const newVal =
                              isNaN(Number(e.target.value)) || Number(e.target.value) < 0
                                ? 0
                                : Math.floor(Number(e.target.value))
                            const maxValue = 100 - totalPercent + val === 0 ? '' : 100 - totalPercent + val
                            let final = newVal === 0 ? '' : totalPercent - val + newVal > 100 ? maxValue : newVal
                            setPercent({
                              ...percent,
                              [pool.underlyingPool]: !e.target.value ? '' : final,
                            })
                          }}
                          type={'number'}
                          className='py-3 pl-1 w-[60px] font-medium text-lg lg:text-xl bg-transparent text-end text-white'
                          value={pool.votes}
                        />
                        <span className='text-white font-medium text-lg lg:text-xl'>%</span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
        <Table
          pools={pools}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          filter={filter}
          searchText={searchText}
          isVoted={isVoted}
          veTHE={veTHE}
          percent={percent}
          setPercent={setPercent}
          totalPercent={totalPercent}
        />
      </div>
    </>
  )
}

export default Vote
