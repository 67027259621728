import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { v4 as uuidv4 } from 'uuid'
import { TransactionType } from '../../config/constants'
import { useV3MintActionHandlers } from '../../state/mintV3/hooks'
import useWeb3 from '../useWeb3'
import { completeTransaction, openTransaction, updateTransaction } from '../../state/transactions/actions'

import { removeLP } from '@defiedge/sdk'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import Big from 'big.js'
import { customNotify } from 'utils/notify'

export const useDefiEdgeRemove = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const { library } = useActiveWeb3React()

  const web3 = useWeb3()

  const { onFieldAInput, onFieldBInput } = useV3MintActionHandlers()

  const handleDefiEdgeRemove = useCallback(
    async (amount, strategy) => {
      const key = uuidv4()
      const removeuuid = uuidv4()

      setPending(true)

      dispatch(
        openTransaction({
          key,
          title: `Withdraw ${strategy.title} liquidity`,
          transactions: {
            [removeuuid]: {
              desc: `Withdraw tokens from the pool`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let hash

      try {
        const txnDetails = await removeLP(
          account,
          Big(amount)
            .div(10 ** 18)
            .toString(), // de shares
          strategy.id,
          library,
        )

        hash = txnDetails.hash

        dispatch(
          updateTransaction({
            key,
            removeuuid,
            status: TransactionType.PENDING,
            hash,
          }),
        )

        await txnDetails.wait()

        dispatch(
          updateTransaction({
            key,
            removeuuid,
            status: TransactionType.SUCCESS,
            hash,
          }),
        )

        customNotify('Transaction Successful!', 'success', hash)
      } catch (err) {
        console.log('remove error :>> ', err)

        dispatch(
          updateTransaction({
            key,
            removeuuid,
            status: TransactionType.FAILED,
            hash,
          }),
        )
        customNotify(err.message, 'error')

        setPending(false)
        return
      }

      onFieldAInput('')
      onFieldBInput('')

      dispatch(
        completeTransaction({
          key,
          final: 'Liquidity Removed',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { handleDefiEdgeRemove, pending }
}
