import React from 'react' // { useContext }

import { KyberWidget } from 'components/pages/swap/KyberWidget'
// import { BaseAssetsConetext } from 'context/BaseAssetsConetext'

const Index = () => {
  return (
    <div className='flex items-center h-full justify-center w-full p-5'>
      <KyberWidget />
    </div>
  )
}

export default Index
