import { useQuery } from '@tanstack/react-query'
import { useWeb3React } from '@web3-react/core'

export const useGetMerklDataQuery = () => {
  const { account } = useWeb3React()

  const generalDataQuery = useQuery(['merkl-general-data'], async () => {
    const generalReponse = await fetch(`https://api.angle.money/v2/merkl?chainIds[]=137&AMMs[]=retro`, {
      method: 'get',
    })

    const generalResult = await generalReponse.json()

    return generalResult[137]
  })

  const userDataQuery = useQuery(
    ['merkl-user-data', account],
    async () => {
      const userResponse = await fetch(
        `https://api.angle.money/v2/merkl?chainIds[]=137&AMMs[]=retro${account ? `&user=${account}` : ''}`,
        {
          method: 'get',
        },
      )

      const userResult = await userResponse.json()

      return userResult[137]
    },
    { enabled: Boolean(generalDataQuery.data) && Boolean(account) },
  )

  return {
    merklDataQuery: userDataQuery?.data ? userDataQuery : generalDataQuery,
    isLoading: userDataQuery.isLoading || generalDataQuery.isLoading,
  }
}
