import ERC20Abi from './erc20.json'
import ThenianAbi from './thenian.json'
import multiCallAbi from './multiCall.json'
import factoryAbi from './factory.json'
import routerAbi from './router.json'
import gaugeAbi from './gauge.json'
import veDistAbi from './veDist.json'
import veTHEAbi from './veTHE.json'
import voterAbi from './voter.json'
import v3voterAbi from './v3voter.json'
import bribeAbi from './bribe.json'
import minterAbi from './minter.json'
import pairAbi from './pair.json'
import pairAPIAbi from './pairAPI.json'
import veTHEAPIAbi from './veTHEAPI.json'
import stakingAbi from './staking.json'
import royaltyAbi from './royalty.json'
import rewardAPIAbi from './rewardsAPI.json'
import dibsAbi from './dibs.json'
import dibsLotteryAbi from './dibsLottery.json'
import muonAbi from './muon.json'
import wbnbAbi from './wbnb.json'
import pairV3APIAbi from './pairV3API.json'
import rewardsV3APIAbi from './rewardsV3API.json'
import veTheV3ApiAbi from './veTHEV3API.json'
import extraRewarderAbi from './extraRewarder.json'
import { default as OptionTokenV2 } from './OptionTokenV2.json'
import ichiVaultAbi from './ichiVaultAbi.json'
import satinMigrationAbi from './satinMigrator.json'
import transmutationAbi from './transmutation.json'
import BribeOptionTokenAbi from './BribeOptionToken.json'

const optionAbi = OptionTokenV2.abi
const bribeOptionAbi = BribeOptionTokenAbi

export {
  ERC20Abi,
  ThenianAbi,
  multiCallAbi,
  factoryAbi,
  routerAbi,
  gaugeAbi,
  veDistAbi,
  veTHEAbi,
  voterAbi,
  v3voterAbi,
  pairAPIAbi,
  veTHEAPIAbi,
  bribeAbi,
  minterAbi,
  pairAbi,
  stakingAbi,
  royaltyAbi,
  rewardAPIAbi,
  dibsAbi,
  dibsLotteryAbi,
  muonAbi,
  wbnbAbi,
  pairV3APIAbi,
  rewardsV3APIAbi,
  veTheV3ApiAbi,
  extraRewarderAbi,
  optionAbi,
  ichiVaultAbi,
  satinMigrationAbi,
  transmutationAbi,
  bribeOptionAbi,
}
