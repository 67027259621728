import React, { useMemo, useState } from 'react'
import { Tooltip } from 'react-tooltip'

import StyledButton from '../../common/Buttons/styledButton'
import CommonHollowModal from '../../common/CommonHollowModal'
import { formatAmount } from '../../../utils/formatNumber'
import { useMigrateToFusion, useMigrateToGamma } from '../../../hooks/useMigration'

const ItemWithTooltip = ({ pair, index }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-tooltip-id={`migrate-${index}`}
        onMouseEnter={() => {
          setArrowReverse(`migrate-${index}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-white font-semibold text-[18px] lg:text-[13px] flex items-center cursor-pointer space-x-1.5'
      >
        <p>{'$' + formatAmount(pair.gauge.tvl, true)}</p>
        <button
          className={`${
            arrowReverse === `migrate-${index}` ? 'rotate-180' : 'rotate-0'
          } transform transition-all duration-300 ease-in-out`}
        >
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <Tooltip
        className='max-w-[180px] !bg-[#090333] !border !border-blue !text-[#E6E6E6] !text-base !py-[9px] !px-6 !opacity-100 after:!bg-body'
        id={`migrate-${index}`}
        place='right'
        effect='solid'
      >
        {formatAmount(pair.gauge.pooled0)} {pair.token0.symbol}
        <br />
        {formatAmount(pair.gauge.pooled1)} {pair.token1.symbol}
      </Tooltip>
    </div>
  )
}

const MigrateModal = ({ isOpen, setIsOpen, pair, gammas }) => {
  const [selected, setSelected] = useState(null)
  const { onMigrateToGamma, pending: gammaPending } = useMigrateToGamma()
  const { onMigrateToFusion, pending: fusionPending } = useMigrateToFusion()

  const message = useMemo(() => {
    if (!selected) {
      return 'SELECT MIGRATION'
    } else {
      return `MIGRATE TO ${selected.token0.symbol}-${selected.token1.symbol} (${
        (selected.isGamma ? 'V3 ' : '') + selected.type
      })`
    }
  }, [selected])

  return (
    <CommonHollowModal
      popup={isOpen}
      width={588}
      setPopup={setIsOpen}
      title={`Migrate ${pair.token0.symbol}-${pair.token1.symbol} (${pair.title})`}
    >
      <div className='w-full mt-[14px]'>
        <div className='flex items-center space-x-3'>
          <div className='text-[17px] lg:text-[20px] text-[#E1E1F2] font-semibold f-f-fg'>
            {pair.token0.symbol}-{pair.token1.symbol} (old)
          </div>
          <img src='/images/common/smaller-arrow.png' alt='' className='w-6 h-4' />
          <div className='text-[17px] lg:text-[20px] text-[#E1E1F2] font-semibold f-f-fg'>
            {pair.token0.symbol}-{pair.token1.symbol} (new)
          </div>
        </div>
        <div className='mt-[7px] text-[15px] lg:text-[17px] color-[#DEDBF2]'>
          Before migrating you have to unstake and withdraw assets from the current pool. After that, you’ll be
          redirected to the new V3 pool where you can add liquidity and stake.
        </div>
        {gammas.map((gamma, index) => {
          return (
            <div
              className='mt-[18px] flex flex-col lg:flex-row py-[18px] px-[16px] border border-[#ED00C9] rounded-[5px] cursor-pointer'
              key={`gamma-${index}`}
              onClick={() => setSelected(gamma)}
            >
              <div className='w-full lg:w-1/2 flex'>
                <div className='flex items-center -space-x-2'>
                  <img className='relative z-10' alt='' src={gamma.token0.logoURI} width={30} height={30} />
                  <img className='relative z-[5]' alt='' src={gamma.token1.logoURI} width={30} height={30} />
                </div>
                <div className='ml-2'>
                  <div className='text-white font-semibold text-[17px] lg:text-[19px] f-f-fg'>
                    {gamma.token0.symbol}-{gamma.token1.symbol}
                  </div>
                  <div className='text-white font-semibold text-[11px] lg:text-[13px]'>
                    {(gamma.isGamma ? 'V3 ' : '') + gamma.type}
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-1/2 flex mt-[10px] lg:mt-0'>
                <div className='w-[50%]'>
                  <div className='text-white font-semibold text-[13px] lg:text-[19px] f-f-fg'>TVL</div>
                  <ItemWithTooltip pair={gamma} index={index} />
                </div>
                <div className='w-[50%]'>
                  <div className='text-white font-semibold text-[13px] lg:text-[19px] f-f-fg'>Projected APR</div>
                  <div className='text-white font-semibold text-[18px] lg:text-[13px]'>
                    {formatAmount(gamma.gauge.apr)}%
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <StyledButton
          disabled={gammaPending || fusionPending || !selected}
          onClickHandler={() => {
            if (selected.isGamma) {
              onMigrateToGamma(pair, selected)
            } else {
              onMigrateToFusion(pair, selected)
            }
          }}
          content={gammaPending || fusionPending ? 'PENDING...' : message}
          className='mt-[18px] py-[13px] md:py-[14.53px] w-full text-black flex items-center justify-center text-[13px] md:text-[17px] font-bold px-[23px] rounded-[3px]'
        />
      </div>
    </CommonHollowModal>
  )
}

export default MigrateModal
