import { Token } from '@uniswap/sdk-core'
import { useContext, useMemo } from 'react'
import { ChainId } from 'config/constants'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'
import { ExtendedEther } from '../../v3lib/entities/constants'

// undefined if invalid or does not exist
// otherwise returns the token
export function useToken(tokenAddress) {
  const assets = useContext(BaseAssetsConetext)

  return useMemo(() => {
    if (!tokenAddress) return undefined
    const asset = assets.find((item) => item.address.toLowerCase() === tokenAddress.toLowerCase())
    if (!asset) return undefined
    return new Token(ChainId.MAINNET, asset.address, asset.decimals, asset.symbol, asset.name)
  }, [assets, tokenAddress])
}

export const useCurrency = (currencyId) => {
  let isBNB = currencyId?.toUpperCase() === 'MATIC'
  const token = useToken(isBNB ? undefined : currencyId)
  return isBNB ? ExtendedEther.onChain(ChainId.MAINNET) : token
}
