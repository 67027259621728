export const defiEdgeVaults = [
  { address: '0x089e16220d5bcbd86e211fb70b2d8c4c2eecacc5', name: 'USDC/USDT' },
  { address: '0x8bb96bdd5c7c963bd4d9488be2b1358abe807898', name: 'WMATIC/MaticX' },
  { address: '0x8617a2e925b88cf38239a72783f35f4f436e89bd', name: 'USDC/miMATIC' },
  { address: '0x33004f86828a4f3928edbe4b1126c543d751b6f9', name: 'WMATIC/USDC' },
  { address: '0x54a3d78b7e6bb517fbc45c11733e14669e90b037', name: 'WMATIC/stMATIC' },
  { address: '0xb0e897d59cdd105fb6a8c3724423d075c3d9c6b8', name: 'USDC/WETH' },
  { address: '0xdf02fd9409a8fa44c0aab21012d11a243a657638', name: 'USDC/CASH' },
  { address: '0xdaca7376ea76e9e942eb28efe205cb3dc445ca0c', name: 'WMATIC/WETH' },
]
