import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import Settings from '../../../../common/Settings'
import PairsDropDown from '../../../../common/PairsDropDown'
import StyledButton from '../../../../common/Buttons/styledButton'
import { BaseAssetsConetext } from '../../../../../context/BaseAssetsConetext'
import { useCurrency } from '../../../../../hooks/v3/Tokens'
import { useV3MintActionHandlers, useV3MintState } from '../../../../../state/mintV3/hooks'
import { v3LiquidityRangeType } from '../../../../../v3lib/entities/constants'
import InitialPrice from './containers/InitialPrice'
import SelectRange from './containers/SelectRange'
import useWalletModal from '../../../../../hooks/useWalletModal'
import {
  Field,
  setInitialTokenPrice,
  setInitialUSDPrices,
  updateSelectedPreset,
} from '../../../../../state/mintV3/actions'
import BackIcon from '../../../../../icons/BackIcon'
// import { IchiTokenSelect } from './components/IchiTokenSelect'
import PartnersList from './components/PartnersList'
import { SelectFeeAmount } from './containers/SelectFeeAmount'
import { defaultChainId } from 'config/constants'

export const PriceFormats = {
  TOKEN: 'TOKEN',
  USD: 'USD',
}

const priceFormat = PriceFormats.TOKEN

const V3AddLiquidity = ({
  mintInfo,
  firstAsset,
  setFirstAsset,
  secondAsset,
  setSecondAsset,
  settings,
  setSettings,
  slippage,
  setSlippage,
  deadline,
  setDeadline,
  ichiAsset,
  gammaPairs,
}) => {
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const baseAssets = useContext(BaseAssetsConetext)
  const baseCurrency = useCurrency(firstAsset ? firstAsset.address : undefined)
  const currencyB = useCurrency(secondAsset ? secondAsset.address : undefined)
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  const { liquidityRangeType } = useV3MintState()
  const { openWalletModal } = useWalletModal()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const [init, setInit] = useState(false)

  useEffect(() => {
    const inputCurrency = searchParams.get('currency0')
    const outputCurrency = searchParams.get('currency1')
    const from = inputCurrency
      ? baseAssets.find((asset) => asset.address.toLowerCase() === inputCurrency.toLowerCase())
      : null
    const to = outputCurrency
      ? baseAssets.find((asset) => asset.address.toLowerCase() === outputCurrency.toLowerCase())
      : null
    if (!from) {
      if (!firstAsset) {
        setFirstAsset(baseAssets.find((asset) => asset.symbol === 'CASH'))
      }
    } else {
      if (inputCurrency && !init) {
        setFirstAsset(from)
        setInit(true)
      }
    }
    if (!to) {
      if (!secondAsset) {
        setSecondAsset(baseAssets.find((asset) => asset.symbol === 'RETRO'))
      }
    } else {
      if (outputCurrency && !init) {
        setSecondAsset(to)
        setInit(true)
      }
    }
  }, [baseAssets, searchParams])

  const { onStartPriceInput, onChangeLiquidityRangeType } = useV3MintActionHandlers(mintInfo.noLiquidity)

  const resetState = useCallback(() => {
    dispatch(updateSelectedPreset({ preset: null }))
    dispatch(setInitialTokenPrice({ typedValue: '' }))
    dispatch(setInitialUSDPrices({ field: Field.CURRENCY_A, typedValue: '' }))
    dispatch(setInitialUSDPrices({ field: Field.CURRENCY_B, typedValue: '' }))
    onStartPriceInput('')
  }, [dispatch, onStartPriceInput])

  const handleCurrencyASelect = useCallback(() => {
    resetState()
  }, [resetState])

  const handleCurrencyBSelect = useCallback(() => {
    resetState()
  }, [resetState])

  const gammaPair = gammaPairs?.[0]

  const defaultFee = gammaPair?.feeLevel

  const manualDisabledPools = [
    '0x439e2d51ba26fa062a1e4f0edaa68f3b830ca6da', // liveRETRO-RETRO
  ]

  return (
    <div className='w-full lg:max-w-[665px] p-px rounded-[5px] relative '>
      <OutsideClickHandler
        onOutsideClick={() => {
          setSettings(false)
        }}
      >
        <div className='w-full bg-componentPrimary border-4 border-white px-3 py-3 rounded-[5px] md:px-6 md:py-5 h-full'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <button
                className='w-5 md:w-auto mr-1.5 md:mr-5'
                onClick={() => {
                  navigate('/liquidity')
                }}
              >
                <span className='block w-[20px] lg:w-[26px] text-white'>
                  <BackIcon />
                </span>
              </button>
              <h4 className='text-[1.3rem] md:text-[22px] text-white font-bold'>Supply Liquidity</h4>
            </div>

            <div className='flex items-center space-x-6'>
              <button className='text-white text-sm md:text-base' onClick={() => resetState()}>
                Clear All
              </button>
              <button
                onClick={() => {
                  setSettings(!settings)
                }}
                className=''
              >
                <img className='w-3/4 sm:w-auto' alt='' src='/images/swap/bar.svg' />
              </button>
            </div>
          </div>
          {account && chainId === defaultChainId ? (
            <>
              {/* {liquidityRangeType === v3LiquidityRangeType.ICHI_RANGE ? (
                <IchiTokenSelect
                  vaultAssets={ichiVaultAssets}
                  firstAsset={firstAsset}
                  secondAsset={secondAsset}
                  setFirstAsset={setFirstAsset}
                  setSecondAsset={setSecondAsset}
                />
              ) : ( */}
              <div className='mt-4 md:mt-6'>
                <h3 className='text-center w-full text-[25px] font-semibold text-white m-0 mb-9 mt-8'>
                  Step 1 - Select Pair
                </h3>
                <div className='flex items-center mt-[6px] md:mt-2 w-full gap-x-[7px] md:gap-x-4'>
                  <PairsDropDown
                    asset={firstAsset}
                    setAsset={setFirstAsset}
                    otherAsset={secondAsset}
                    setOtherAsset={setSecondAsset}
                    onAssetSelect={handleCurrencyASelect}
                  />
                  <button
                    className='flex-shrink-0 w-[30px] md:w-auto'
                    onClick={() => {
                      const temp = firstAsset
                      setFirstAsset(secondAsset)
                      setSecondAsset(temp)
                    }}
                  >
                    <img alt='' src='/images/buttons/swap-tokens.png' className='' />
                  </button>
                  <PairsDropDown
                    asset={secondAsset}
                    setAsset={setSecondAsset}
                    otherAsset={firstAsset}
                    setOtherAsset={setFirstAsset}
                    onAssetSelect={handleCurrencyBSelect}
                  />
                </div>
              </div>
              {/* )} */}

              <div className='mt-5'>
                <p className='text-[13px] md:text-base leading-5 text-[#B8B6CB]'>Select Range</p>
                <div className='flex items-center space-x-8 mt-[9px] md:mt-2.5 tracking-[0.9px] text-white text-lg leading-5 font-light'>
                  <div
                    className={`flex items-center space-x-1.5 md:space-x-2 cursor-pointer`}
                    onClick={() => {
                      gammaPairs?.[0].gammaEnabled
                        ? onChangeLiquidityRangeType(v3LiquidityRangeType.GAMMA_RANGE)
                        : onChangeLiquidityRangeType(v3LiquidityRangeType.ICHI_RANGE)
                    }}
                  >
                    <div className='md:w-5 md:h-5 w-[18px] h-[18px] flex flex-col items-center justify-center border border-[#B8B6CB] rounded-full'>
                      <div
                        className={`md:w-2.5 w-2 h-2 md:h-2.5 ${
                          liquidityRangeType !== v3LiquidityRangeType.MANUAL_RANGE ? 'bg-[#2EF80D]' : 'bg-transparent'
                        }  rounded-full`}
                      />
                    </div>
                    <span className='cursor-pointer text-sm md:text-base'>AUTOMATIC</span>
                  </div>
                  {!manualDisabledPools.includes(gammaPair?.address) && (
                    <div
                      className='flex items-center space-x-1.5 md:space-x-2 cursor-pointer'
                      onClick={() => {
                        onChangeLiquidityRangeType(v3LiquidityRangeType.MANUAL_RANGE)
                      }}
                    >
                      <div className='md:w-5 md:h-5 w-[18px] h-[18px] flex flex-col items-center justify-center border border-[#B8B6CB] rounded-full'>
                        <div
                          className={`md:w-2.5 w-2 h-2 md:h-2.5 ${
                            liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE ? 'bg-[#2EF80D]' : 'bg-transparent'
                          }  rounded-full`}
                        />
                      </div>
                      <span className='cursor-pointer text-sm md:text-base'>MANUAL</span>
                      <a
                        href={`https://cl-management.retro.finance/?token0=${baseCurrency?.wrapped?.address}&token1=${quoteCurrency?.wrapped?.address}&feeTier=${defaultFee}`}
                        target='_blank'
                        rel='noreferrer'
                        className='border-b-2 border-solid border-white'
                        onClick={(e) => e.stopPropagation()}
                      >
                        (Calculator)
                      </a>
                    </div>
                  )}
                </div>
              </div>

              {liquidityRangeType !== v3LiquidityRangeType.MANUAL_RANGE && (
                <PartnersList
                  baseCurrency={baseCurrency}
                  quoteCurrency={quoteCurrency}
                  gammaPairs={gammaPairs}
                  mintInfo={mintInfo}
                  selectRangeType={onChangeLiquidityRangeType}
                  selectedLiquidityRangeType={liquidityRangeType}
                  ichiAsset={ichiAsset}
                />
              )}
              {mintInfo.noLiquidity &&
                baseCurrency &&
                quoteCurrency &&
                liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE && (
                  <InitialPrice
                    currencyA={baseCurrency ?? undefined}
                    currencyB={currencyB ?? undefined}
                    mintInfo={mintInfo}
                  />
                )}

              {liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE && (
                <div className='mt-5'>
                  <SelectFeeAmount gammaPairs={gammaPairs} />
                </div>
              )}

              {liquidityRangeType !== v3LiquidityRangeType.ICHI_RANGE && (
                <SelectRange
                  currencyA={baseCurrency}
                  currencyB={quoteCurrency}
                  mintInfo={mintInfo}
                  priceFormat={priceFormat}
                  gammaPairs={gammaPairs}
                />
              )}
            </>
          ) : (
            <StyledButton
              onClickHandler={openWalletModal}
              content={'CONNECT WALLET'}
              className='mt-8 py-[13px] md:py-[14.53px] text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
            />
          )}
        </div>
        <div className='w-full px-3 md:px-6'>
          {settings && (
            <Settings slippage={slippage} setSlippage={setSlippage} deadline={deadline} setDeadline={setDeadline} />
          )}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default V3AddLiquidity
