import { useElapsedTime } from 'use-elapsed-time'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import contracts from 'config/constants/contracts'
import merklDistributorAbi from 'config/abi/merklDistributorAbi.json'
import { useEffect } from 'react'
import { ORetroIcon } from 'icons/ORetroIcon'
import { getContract } from 'utils/contract'
import { getNetworkLibrary } from 'utils'
import { defaultChainId } from 'config/constants'
import Countdown from 'react-countdown'

export const ElapsedSinceLastDistribution = () => {
  const merklDistributorContract = getContract(
    contracts.merklDistributor[defaultChainId],
    merklDistributorAbi,
    getNetworkLibrary(),
  )

  const lastDistributionQuery = useQuery(['last-merkl-distribution-date'], async () => {
    try {
      const response = await merklDistributorContract.endOfDisputePeriod()

      return response
    } catch (error) {
      console.error(error)
    }
  })

  const elapsedSinceLastDistribution = lastDistributionQuery.data
    ? dayjs().diff(lastDistributionQuery.data * 1000)
    : null

  const { elapsedTime, reset } = useElapsedTime({
    updateInterval: 5,
    isPlaying: true,
    startAt: elapsedSinceLastDistribution / 1000,
  })

  useEffect(() => {
    reset()
  }, [lastDistributionQuery.data])

  const isDisputeBefore = dayjs(lastDistributionQuery.data * 1000).isBefore(dayjs())

  const [hours, minutes] = new Date(elapsedTime * 1000).toISOString().substring(11, 16).split(':')

  const countup = `${hours}h ${minutes}m`

  const renderCounter = () => {
    if (isDisputeBefore) return countup

    return (
      <Countdown
        onComplete={() => lastDistributionQuery.refetch()}
        date={lastDistributionQuery.data * 1000}
        renderer={({ hours, minutes }) => (
          <span>
            {hours}h {minutes}m
          </span>
        )}
      />
    )
  }

  return lastDistributionQuery.data ? (
    <div className='py-[8px] px-[34px]  mt-3 lg:mt-0 mb-4 bg-componentPrimary rounded-lg justify-end items-center inline-flex'>
      <div className='justify-start items-center gap-10 inline-flex'>
        <div className='text-[29px] text-center text-white font-semibold'>
          {lastDistributionQuery.data ? renderCounter() : '-'}
        </div>
        <div className='text-white text-[16px] leading-5 tracking-wider'>
          {isDisputeBefore ? 'Since Last' : 'Until next'} <br />
          <div className='inline-flex relative'>
            <span className='mr-2'>oRETRO</span>
            <div className='w-5 h-5 absolute right-[-17px] top-[2px]'>
              <ORetroIcon />
            </div>
          </div>{' '}
          <br />
          Distribution
        </div>
      </div>
    </div>
  ) : null
}
