import React from 'react'

const DateIcon = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27.7082 5.83366H24.7915V4.37533C24.7915 3.98855 24.6379 3.61762 24.3644 3.34413C24.0909 3.07064 23.7199 2.91699 23.3332 2.91699C22.9464 2.91699 22.5755 3.07064 22.302 3.34413C22.0285 3.61762 21.8748 3.98855 21.8748 4.37533V5.83366H13.1248V4.37533C13.1248 3.98855 12.9712 3.61762 12.6977 3.34413C12.4242 3.07064 12.0533 2.91699 11.6665 2.91699C11.2797 2.91699 10.9088 3.07064 10.6353 3.34413C10.3618 3.61762 10.2082 3.98855 10.2082 4.37533V5.83366H7.2915C6.13118 5.83366 5.01838 6.2946 4.19791 7.11507C3.37744 7.93554 2.9165 9.04834 2.9165 10.2087V27.7087C2.9165 28.869 3.37744 29.9818 4.19791 30.8022C5.01838 31.6227 6.13118 32.0837 7.2915 32.0837H27.7082C28.8685 32.0837 29.9813 31.6227 30.8018 30.8022C31.6222 29.9818 32.0832 28.869 32.0832 27.7087V10.2087C32.0832 9.04834 31.6222 7.93554 30.8018 7.11507C29.9813 6.2946 28.8685 5.83366 27.7082 5.83366ZM29.1665 27.7087C29.1665 28.0954 29.0129 28.4664 28.7394 28.7399C28.4659 29.0133 28.0949 29.167 27.7082 29.167H7.2915C6.90473 29.167 6.5338 29.0133 6.26031 28.7399C5.98682 28.4664 5.83317 28.0954 5.83317 27.7087V17.5003H29.1665V27.7087ZM29.1665 14.5837H5.83317V10.2087C5.83317 9.82188 5.98682 9.45095 6.26031 9.17746C6.5338 8.90397 6.90473 8.75033 7.2915 8.75033H10.2082V10.2087C10.2082 10.5954 10.3618 10.9664 10.6353 11.2399C10.9088 11.5133 11.2797 11.667 11.6665 11.667C12.0533 11.667 12.4242 11.5133 12.6977 11.2399C12.9712 10.9664 13.1248 10.5954 13.1248 10.2087V8.75033H21.8748V10.2087C21.8748 10.5954 22.0285 10.9664 22.302 11.2399C22.5755 11.5133 22.9464 11.667 23.3332 11.667C23.7199 11.667 24.0909 11.5133 24.3644 11.2399C24.6379 10.9664 24.7915 10.5954 24.7915 10.2087V8.75033H27.7082C28.0949 8.75033 28.4659 8.90397 28.7394 9.17746C29.0129 9.45095 29.1665 9.82188 29.1665 10.2087V14.5837Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DateIcon
