import { useQuery } from '@tanstack/react-query'
import { defiEdgeVaults } from 'config/constants/defiEdgeVaults'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
import { gql, request } from 'graphql-request'
import { useContext } from 'react'

export const useDefiEdgeStrategiesQuery = () => {
  const baseAssets = useContext(BaseAssetsConetext)

  return useQuery(
    ['defi-edge-strategies-list'],
    async () => {
      try {
        const getSubgraphQuery = () => {
          return defiEdgeVaults.reduce((acc, strategy) => {
            return `
                  strategy${strategy.address}: strategy(id: "${strategy.address}") {   
                    id   
                    name  
                    hash   
                    pool   
                    createdAtTimestamp   
                    amount0   
                    amount1   
                    unusedAmount0   
                    unusedAmount1   
                    shares   
                    owner   
                    userCount   
                    feeTier   
                    dex   
                    collectedFeesToken0   
                    type   
                    collectedFeesToken1   
                    onHold   
                    token0 {     
                        id     
                        symbol     
                        name     
                        decimals     
                    }   
                    token1 {     
                        id    
                        symbol     
                        name     
                        decimals
                    }  
                }
                  ${acc}
                `
          }, '')
        }

        const resSubgraph = await request(
          'https://api.thegraph.com/subgraphs/name/pranavraut033/defiedge-polygon',
          gql`{
          ${getSubgraphQuery()}
        }
        `,
        )

        const detailsRes = await fetch(
          `https://api.defiedge.io/polygon/details?strategies=${defiEdgeVaults.reduce(
            (acc, strategy) => `${acc}${strategy.address},`,
            '',
          )}`,
        ).then((res) => res.json())

        const details = detailsRes.reduce((acc, details) => {
          acc[details.strategy.address] = details

          return acc
        }, {})

        const result = Object.values(resSubgraph).map((strategy) => {
          const token0 = baseAssets.find((asset) => {
            return asset.address.toLowerCase() === strategy.token0.id.toLowerCase()
          })

          const token1 = baseAssets.find((asset) => asset.address.toLowerCase() === strategy.token1.id.toLowerCase())

          const { sharePrice, aum, totalSupplyBN, token0Price, token1Price } = details[strategy.id]

          return {
            ...strategy,
            token0,
            token1,
            liquidityDetails: {
              sharePrice,
              aum,
              totalSupply: totalSupplyBN,
              token0Price,
              token1Price,
            },
          }
        })

        return result
      } catch (error) {
        console.error(error)
      }
    },
    { enabled: baseAssets.length > 0 },
  )
}
