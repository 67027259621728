import './benefits.scss'

const benefits = [
  {
    logo: '/images/retro/benefits/Isolation_Mode.svg',
    name: 'Unique Yield Farming Opportunities via Concentrated Liquidity',
    description: 'Multiple income streams for liquidity providers and veRETRO holders. Earn more at Retro than anywhere else on Polygon',
  },
  {
    logo: '/images/retro/benefits/Isolation_Mode.svg',
    name: 'Clear Incentives for All',
    description: 'Token economics and incentives built for long-term value creation with users and protocols in mind.',
  },
  {
    logo: '/images/retro/benefits/Isolation_Mode.svg',
    name: 'ALM Marketplace',
    description:
      'Find the ALM partner, strategy and risk appetite that works best for you! ALM Marketplace brings mutliple partners under one roof with the use of Merkl distribution',
  },
]

const Benefits = () => {
  return (
    <div className='benefits'>
      {benefits.map((benefit, idx) => {
        return (
          <div key={idx} className='benefit flex flex-col justify-center'>
            <img src={benefit.logo} alt='logo' className='logo' />
            <h2 className='title'>{benefit.name}</h2>
            <p className='description'>{benefit.description}</p>
          </div>
        )
      })}
    </div>
  )
}

export default Benefits
