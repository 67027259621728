import random from 'lodash/random'

// Array of available nodes to connect to
export const MainNodes = ['https://rpc-mainnet.matic.quiknode.pro']
export const TestNodes = [
  'https://data-seed-prebsc-1-s1.binance.org:8545/',
  'https://data-seed-prebsc-1-s3.binance.org:8545/',
  'https://data-seed-prebsc-2-s3.binance.org:8545/',
]

const getRpcUrl = () => {
  const randomIndex = random(0, MainNodes.length - 1)
  return MainNodes[randomIndex]
}

export default getRpcUrl
