import React, { useState, useMemo, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Tooltip } from 'react-tooltip'
import Pagination from '../../common/Pagination'
import Max from '../../common/Buttons/max'
import Sticky from 'react-stickynode'
import TransparentButton from '../../common/Buttons/transparentButton'
// import useWalletModal from '../../../hooks/useWalletModal'
import { NumberOfRows, PoolTypes, defaultChainId } from '../../../config/constants'
import { formatAmount } from '../../../utils/formatNumber'
import useWalletModal from '../../../hooks/useWalletModal'
import StyledButton from '../../common/Buttons/styledButton'
import NoFound from '../../common/NoFound'
import { useVote } from '../../../hooks/useVote'
import { customNotify } from '../../../utils/notify'
import { useReset } from '../../../hooks/useLock'

const Item = ({ usd, content, idx, type }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return !usd.isZero() ? (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-tooltip-id={`${type}-${idx}`}
        onMouseEnter={() => {
          setArrowReverse(`${type}-${idx}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] flex items-center cursor-pointer space-x-1.5'
      >
        <p>{'$' + formatAmount(usd)}</p>
        <button
          className={`${
            arrowReverse === `${type}-${idx}` ? 'rotate-180' : 'rotate-0'
          } transform transition-all duration-300 ease-in-out`}
        >
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <Tooltip
        className='max-w-[180px] !bg-tooltip !text-white !text-base !py-2 z-50 !px-6 !opacity-100'
        id={`${type}-${idx}`}
        place='right'
        effect='solid'
        arrowColor='#232F3C'
      >
        {content}
      </Tooltip>
    </div>
  ) : (
    <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>$0</div>
  )
}

const Row = ({ idx, isLast, pool, percent, setPercent, totalPercent }) => {
  const { openWalletModal } = useWalletModal()
  const { account, chainId } = useWeb3React()

  return (
    <div
      key={idx}
      className={`${idx === 0 && 'rounded-t-[5px]'} ${
        isLast ? 'rounded-b-[5px]' : ''
      } mb-px flex flex-wrap lg:flex-nowrap items-start lg:items-center w-full justify-between  text-[#DEDBF2] p-4 lg:py-5 lg:px-6 bg-componentPrimary`}
    >
      <div className='flex w-full lg:w-[30%] items-center space-x-3'>
        <div className='flex items-center gap-1'>
          <img className='relative z-10' alt='' src={pool.token0.logoURI} width={30} height={30} />
          <img className='relative z-[5]' alt='' src={pool.token1.logoURI} width={30} height={30} />
        </div>
        <div className=''>
          <p className='text-base leading-[30px] font-medium'>{pool.symbol}</p>
          {/* <p className='tracking-[0.78px] text-[13px] leading-none'>{pool.title}</p> */}
        </div>
      </div>
      <div className='flex flex-col mt-2 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Voting APR</p>
        <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>
          {formatAmount(pool.gauge.voteApr, true)}%
        </div>
      </div>
      <div className='flex flex-col w-1/2 mt-2 lg:mt-0 items-start lg:w-[18%] justify-center'>
        <div className='text-sm xl:text-base flex items-center space-x-1.5'>
          <div className='flex flex-col items-start justify-center'>
            <p className='lg:hidden text-sm f-f-fg font-semibold'>Total Votes</p>
            <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>
              {formatAmount(pool.gauge.weight)}
            </div>
            <p className='leading-[22px] text-dimGray text-[15px]'>{formatAmount(pool.gauge.weightPercent)}%</p>
          </div>
        </div>
      </div>
      {/* second row */}
      <div className='flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[18%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Rewards</p>
        <Item
          type={'rewards'}
          usd={pool.gauge.bribeUsd}
          content={
            <>
              {pool.gauge.bribes && pool.gauge.bribes.bribe && (
                <>
                  <div className='text-green'>Bribes</div>
                  {pool.gauge.bribes.bribe.map((bribe, idx) => {
                    return (
                      <p key={`bribe-${idx}`}>
                        {formatAmount(bribe.amount)} {bribe.symbol}
                      </p>
                    )
                  })}
                </>
              )}
              {pool.gauge.bribes && pool.gauge.bribes.fee && (
                <>
                  <div className='text-green'>Projected Fees</div>
                  {pool.gauge.bribes.fee.map((fee, idx) => {
                    return (
                      <p key={`fee-${idx}`}>
                        {formatAmount(fee.amount)} {fee.symbol}
                      </p>
                    )
                  })}
                </>
              )}
            </>
          }
          idx={idx}
        />
      </div>{' '}
      <div className='flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[18%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>bveRewards</p>
        <Item
          type={'bve-rewards'}
          usd={pool.gauge.bribeBveUsd}
          content={
            <>
              {pool.gauge.bveBribes && pool.gauge.bveBribes && (
                <>
                  <div className='text-green'>Bribes</div>
                  {pool.gauge.bveBribes.map((bribe, idx) => {
                    return (
                      <p key={`bribe-${idx}`}>
                        {formatAmount(bribe.amount)} {bribe.symbol}
                      </p>
                    )
                  })}
                </>
              )}
              {/* {pool.gauge.bribes && pool.gauge.bribes.fee && (
                <>
                  <div className='text-green'>Projected Fees</div>
                  {pool.gauge.bribes.fee.map((fee, idx) => {
                    return (
                      <p key={`fee-${idx}`}>
                        {formatAmount(fee.amount)} {fee.symbol}
                      </p>
                    )
                  })}
                </>
              )} */}
            </>
          }
          idx={idx}
        />
      </div>{' '}
      <div className='flex flex-col items-start w-1/2 mt-3 lg:mt-0 lg:w-[18%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Your Vote</p>
        <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>{formatAmount(pool.votes.weight)}</div>
        <p className='leading-[22px] text-dimGray text-[15px]'>{formatAmount(pool.votes.weightPercent)}%</p>
        {/* <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] flex items-center space-x-1.5'>
          <p>{account ? formatAmount(pool.votes) + '%' : '-'}</p>
        </div> */}
      </div>{' '}
      <div className='w-full lg:w-[24%] mt-3.5 lg:mt-0'>
        {!account || chainId !== defaultChainId ? (
          <div className='w-full  flex  items-center lg:justify-end'>
            <TransparentButton
              onClickHandler={openWalletModal}
              content={'Connect Wallet'}
              className='h-10 px-[26px] !bg-transparent border-2 text-white border-white border-solid rounded-3xl flex lg:max-w-[173px] whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[17px] w-full '
            />
          </div>
        ) : (
          <div className='flex flex-col lg:items-end justify-end w-full'>
            <div className='relative w-full lg:w-auto'>
              <input
                className='placeholder-placeholder w-full lg:max-w-[202.89px] bg-componentPrimary h-[42px] rounded-xl z-[10] text-white pl-5 pr-2 text-lg !border-2 !border-white focus:!placeholder-transparent focus:!border-[2px] block focus-visible:!outline-none'
                placeholder={'Enter Vote'}
                type='number'
                min={0}
                step={1}
                value={percent[pool.underlyingPool] || ''}
                onChange={(e) => {
                  const val = isNaN(Number(percent[pool.underlyingPool])) ? 0 : Number(percent[pool.underlyingPool])
                  const newVal =
                    isNaN(Number(e.target.value)) || Number(e.target.value) < 0 ? 0 : Math.floor(Number(e.target.value))
                  const maxValue = 100 - totalPercent + val === 0 ? '' : 100 - totalPercent + val
                  let final = newVal === 0 ? '' : totalPercent - val + newVal > 100 ? maxValue : newVal
                  setPercent({
                    ...percent,
                    [pool.underlyingPool]: !e.target.value ? '' : final,
                  })
                }}
              />
              {!!percent[pool.underlyingPool] && (
                <p className='flex items-center text-lg leading-none text-white absolute top-0 h-full right-20 z-10 '>
                  %
                </p>
              )}
              <Max
                className={'absolute z-10 right-0 top-0 h-full'}
                onClickHanlder={() => {
                  const val = isNaN(Number(percent[pool.underlyingPool])) ? 0 : Number(percent[pool.underlyingPool])
                  const maxValue = 100 - totalPercent + val
                  setPercent({
                    ...percent,
                    [pool.underlyingPool]: maxValue === 0 ? '' : maxValue,
                  })
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const Table = ({
  pools,
  sort,
  setSort,
  sortOptions,
  filter,
  searchText,
  isVoted,
  veTHE,
  percent,
  setPercent,
  totalPercent,
}) => {
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)
  const { onVote, pending } = useVote()
  const { onReset, pending: resetPending } = useReset()
  const { account } = useWeb3React()

  const filteredPools = useMemo(() => {
    const result = pools.filter((pool) => {
      return (isVoted && !pool.votes.weight.isZero()) || !isVoted
    })
    const res = filter === PoolTypes.ALL ? result : result.filter((item) => item.kind === filter)
    if (!searchText || searchText === '') {
      return res
    }
    return (
      res &&
      res.filter((item) => {
        const withSpace = item.symbol.replace('/', ' ')
        const withComma = item.symbol.replace('/', ',')
        return (
          item.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [pools, filter, searchText, isVoted])

  const sortedPools = useMemo(() => {
    return filteredPools.sort((a, b) => {
      let res
      switch (sort.value) {
        case sortOptions[0].value:
          res = a.gauge.voteApr
            .minus(b.gauge.voteApr)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case sortOptions[1].value:
          res = a.gauge.weight
            .minus(b.gauge.weight)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case sortOptions[2].value: {
          res = a.gauge.bribeUsd
            .minus(b.gauge.bribeUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        }

        case sortOptions[3].value: {
          res = a.gauge.bribeBveUsd
            .minus(b.gauge.bribeBveUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()

          break
        }
        case sortOptions[4].value:
          res = a.votes.weight
            .minus(b.votes.weight)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break

        default:
          break
      }
      return res
    })
  }, [filteredPools, sort])

  const pageCount = useMemo(() => {
    return Math.ceil(sortedPools.length / pageSize)
  }, [sortedPools, pageSize])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [pageSize, filter, isVoted, searchText])

  const errorMsg = useMemo(() => {
    if (!veTHE) {
      return 'veRETRO is not selected'
    }
    if (veTHE.voting_amount.isZero()) {
      return 'Voting power is zero'
    }
    if (totalPercent !== 100) {
      return 'Total should be 100%'
    }
    return null
  }, [totalPercent, veTHE])

  return (
    <>
      <div className='w-full lg:mt-2 xl:mt-0'>
        {sortedPools.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length > 0 ? (
          <div className='w-full'>
            <Sticky
              enabled={true}
              innerActiveClass={'border-2 border-white bg-componentPrimary'}
              top={95}
              activeClass={''}
              innerClass={
                'px-6 lg:flex justify-between hidden z-[100] py-[0.475rem] lg:!-mb-[19px] xl:!mb-0 lg:!top-[-19px] xl:!top-[0]'
              }
              className={`z-[100]`}
            >
              <div className='w-[25%] font-medium text-[17px] xl:text-[18px] lg:w-[30%] text-white'></div>
              {sortOptions.map((option, index) => (
                <div
                  className={`${
                    index === 0 ? 'w-[14%]' : 'w-[18%]'
                  } font-medium text-[17px] xl:text-[18px] text-white f-f-fg`}
                  key={`header-${index}`}
                >
                  <div
                    onClick={() => {
                      setSort({
                        ...option,
                        isDesc: sort.value === option.value ? !sort.isDesc : true,
                      })
                    }}
                    className='flex items-center cursor-pointer space-x-1 -ml-1 relative'
                  >
                    {sort.value === option.value && (
                      <button className={`${sort.isDesc ? '' : 'rotate-180'} transform absolute -left-3.5`}>
                        <img alt='' src='/images/liquidity/arrow-bottom.svg' />
                      </button>
                    )}
                    <p className='flex items-center'>{option.label}</p>
                  </div>
                </div>
              ))}
              <div className='w-[24%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
            </Sticky>
            <div className='flex flex-col gap-3 md:gap-0.5 p-px rounded-[5px]'>
              {sortedPools.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((pool, idx) => {
                return (
                  <Row
                    isLast={idx === sortedPools.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length - 1}
                    pool={pool}
                    idx={idx}
                    key={idx}
                    percent={percent}
                    setPercent={setPercent}
                    totalPercent={totalPercent}
                  />
                )
              })}
            </div>
            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
              total={sortedPools.length}
            />
          </div>
        ) : (
          <NoFound title='No pools found' />
        )}
      </div>
      {account && (
        <div
          className={`bottom-0 md:bottom-4 transition-all duration-300 ease-in-out md:flex items-center  fixed md:max-w-[579px] mx-auto w-full  left-0 right-0 z-[100] border-white border-t md:border-l md:border-r md:border-b md md:rounded-[5px] bg-componentPrimary px-5 py-3 md:py-3.5`}
        >
          <p className=''>
            <span className='text-white text-base md:text-[22px]'>Voting Power Used:</span>{' '}
            <span
              className={`${
                veTHE && veTHE.votedCurrentEpoch ? `text-[#2CBA52]` : 'text-error'
              } text-lg md:text-2xl font-semibold`}
            >
              {veTHE && veTHE.votedCurrentEpoch ? 'Yes' : 'No'}
            </span>
          </p>
          <div className='flex items-center mt-2 md:mt-0 w-full justify-between md:w-auto'>
            <StyledButton
              disabled={pending}
              pending={pending}
              onClickHandler={() => {
                if (errorMsg) {
                  customNotify(errorMsg, 'warn')
                  return
                }
                onVote(veTHE.id, percent)
              }}
              content={'CAST VOTES'}
              className={
                'text-sm md:text-[17px] font-semibold w-full md:w-auto  px-[42px] flex flex-col items-center justify-center h-[42px] md:h-[52px] tracking-[0.84px] rounded-[3px] mr-4 md:ml-3'
              }
            />
            <button
              className='text-green'
              disabled={!veTHE || !veTHE.voted || resetPending}
              onClick={() => {
                if (veTHE.voted) {
                  onReset(veTHE.id)
                }
              }}
            >
              Reset
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Table
