import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import ConnectWallet from '../../connectWallet'
import useAuth from '../../../hooks/useAuth'
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics'
import OutsideClickHandler from 'react-outside-click-handler'
import { connectors, defaultChainId } from '../../../config/constants'
import { useSelector } from 'react-redux'
import useWalletModal from '../../../hooks/useWalletModal'
import usePrices from '../../../hooks/usePrices'
import { formatAmount } from '../../../utils/formatNumber'
import ChevronIcon from '../../../icons/ChevronIcon'
import LogoutIcon from '../../../icons/LogoutIcon'
import MobileNav from '../MobileNav'
import { BigNumber } from 'bignumber.js'
import { FusionsContext } from '../../../context/FusionsContext'
import { useV3Tvl } from 'hooks/useGeneral'
import { Tooltip } from 'react-tooltip'

const Header = () => {
  useGoogleAnalytics()
  const [open, setOpen] = useState(false)
  const [connector, setConnector] = useState(null)
  const [secondDrop, setSecondDrop] = useState(false)
  const { account, chainId } = useWeb3React()
  const { logout } = useAuth()
  const [selected, setSelected] = useState(false)
  const prices = usePrices()
  const fusions = useContext(FusionsContext)
  const tvl = useV3Tvl()

  const avgApr = useMemo(() => {
    if (fusions && fusions.length > 0 && prices) {
      const totalBribeRetro = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.bribeUsd)
      }, new BigNumber(0))

      const totalBribeBveRetro = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.bribeBveUsd)
      }, new BigNumber(0))

      const totalWeight = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.weight)
      }, new BigNumber(0))

      const totalVoteUsdRetro = totalWeight.times(prices['RETRO'])

      const totalVoteUsdBveRetro = totalWeight.times(prices['bveRETRO'])

      const avgRetro = totalVoteUsdRetro?.isZero()
        ? new BigNumber(0)
        : totalBribeRetro.times(52).div(totalVoteUsdRetro).times(100)

      const avgbveRetro =
        totalVoteUsdBveRetro?.isZero() || !totalBribeBveRetro || totalBribeBveRetro.isZero()
          ? new BigNumber(0)
          : totalBribeBveRetro.times(52).div(totalVoteUsdBveRetro).times(100)

      return { avgRetro, avgbveRetro, avgTotal: avgRetro.plus(avgbveRetro) }
    }

    return { avgRetro: new BigNumber(0), avgbveRetro: new BigNumber(0) }
  }, [fusions, prices])

  useEffect(() => {
    if (prices && prices['RETRO']) {
      document.title = `Retro - $${formatAmount(prices['RETRO'])}`
    }
  }, [prices])
  const { isWalletOpen } = useSelector((state) => state.wallet)
  const { openWalletModal } = useWalletModal()

  useEffect(() => {
    if (account) {
      if (!connector && !selected) {
        setConnector(connectors[0])
        setSelected(false)
      }
    } else {
      setConnector(null)
    }
  }, [account, connector, selected, setSelected])

  const dropDownhandler = () => {
    if (connector && chainId === defaultChainId) {
      setSecondDrop(!secondDrop)
    } else {
      openWalletModal()
    }
  }

  const onDisconnect = () => {
    logout()
    setConnector(null)
    setSecondDrop(false)
  }

  return (
    <div className='w-full flex items-center relative z-[100] xl:z-[1000]'>
      <div className='relative z-[100] xl:z-[1000] w-full flex items-center justify-between gap-x-3 p-5 bg-headerBg h-[88px] xl:h-24'>
        <div className='flex items-center justify-center xl:w-full xl:max-w-mobileSideNav 2xl:max-w-sideNav xl:-ml-5'>
          <Link to='/'>
            <img className='logo relative z-10' alt='' src='/images/header/logo.png' />
          </Link>
        </div>
        <div className='flex items-center'>
          <div className='flex flex-row items-center gap-x-6 mr-6'>
            <p className=''>
              <span className='text-white/75 mr-1'>TVL</span>
              <span className='text-white font-semibold'> ${formatAmount(tvl)}</span>
            </p>
            <p className='hidden lg:flex'>
              <span className='text-white/75 mr-1'>RETRO</span>
              <span className='text-white font-semibold'> ${formatAmount(prices['RETRO'])}</span>
            </p>
            <p data-tooltip-id='avg-vote-apr' className='hidden lg:flex cursor-pointer'>
              <span className='text-white/75 mr-1'>Avg Vote APR</span>
              <span className='text-white font-semibold'> {formatAmount(avgApr.avgTotal)}%</span>
              <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] flex items-center cursor-pointer space-x-1.5'>
                <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
              </div>
              <Tooltip
                className='max-w-[180px] !bg-tooltip z-50 !text-white !text-base !py-2 !px-6 !opacity-100'
                id='avg-vote-apr'
                place='right'
                effect='solid'
                arrowColor='#232F3C'
              >
                <div>
                  <div className='text-green'>veRETRO</div>

                  <div>{formatAmount(avgApr.avgRetro)}%</div>
                  <div className='text-green'>bveRETRO</div>

                  <div>{formatAmount(avgApr.avgbveRetro)}%</div>
                </div>
              </Tooltip>
            </p>
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setSecondDrop(false)
            }}
          >
            <div className='relative w-full'>
              <button
                onClick={() => {
                  dropDownhandler()
                }}
                className={` bg-white w-full min-w-[150px] max-w-[252px] xl:max-w-full xl:w-[252px] flex-shrink-0 py-2 px-4 xl:py-4 xl:px-10 rounded-md items-center gap-x-1 xl:gap-x-2.5 font-montSerrat font-semibold text-black text-sm md:text-sm relative z-20  xl:text-base leading-7`}
              >
                {connector && chainId === defaultChainId ? (
                  <div className='flex items-center gap-x-1 xl:gap-x-2.5'>
                    <div className='flex items-center flex-shrink-0 gap-x-1 xl:gap-x-2.5'>
                      <img
                        src={connector.title == 'MetaMask' ? '/images/header/metamask.svg' : connector.logo}
                        className='max-w-[24px] h-6 '
                        alt=''
                      />
                      <p className=''>{account ? `${account.slice(0, 6)}...${account.slice(-4)}` : ''}</p>
                    </div>
                    <span
                      className={`${
                        secondDrop ? ' rotate-180' : ' rotate-0'
                      } transition-all duration-300 ease-in-out transform w-full`}
                    >
                      <div className='w-6 h-6 flex-shrink-0'>
                        <ChevronIcon />
                      </div>
                    </span>
                  </div>
                ) : (
                  <span className='w-full font-montSerrat'>Connect Wallet</span>
                )}
              </button>

              {secondDrop && (
                <div className='absolute w-full border-2 border-white bg-componentPrimary rounded-[3px] top-[50px] xl:top-[70px] block z-[101]'>
                  <button
                    onClick={onDisconnect}
                    className='flex items-center gap-x-2.5 w-full py-[8px] xl:py-[15px] px-5 xl:px-10 text-white'
                  >
                    <span className='w-4 xl:w-6 h-4 xl:h-6'>
                      <LogoutIcon />
                    </span>
                    <p className='flex-shrink-0 text-sm xl:text-base text-white'>Logout</p>
                  </button>
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <button
            onClick={() => {
              setOpen(true)
              setSecondDrop(false)
            }}
            className='bg-transparent w-8 xl:hidden'
          >
            <img alt='' src='/images/header/hamburger-menu.png' />
          </button>
        </div>
      </div>
      {isWalletOpen && <ConnectWallet setConnector={setConnector} setSelected={setSelected} />}

      <MobileNav isOpen={open} setIsOpen={setOpen} />
    </div>
  )
}

export default Header
