import { getLiquidityRatio } from '@defiedge/sdk'
import { useQuery } from '@tanstack/react-query'
import { getNetworkLibrary } from 'utils'

export const useDefiEdgeLiquidityRatio = (strategyAddress) => {
  return useQuery(
    ['defiedge-liquidity-ratio', strategyAddress],
    async () => {
      try {
        const ratio = await getLiquidityRatio(strategyAddress, getNetworkLibrary())

        return ratio
      } catch (error) {
        console.error(error)
      }
    },
    { enabled: Boolean(strategyAddress), refetchInterval: 5000 },
  )
}
