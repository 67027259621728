import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import React, { useEffect, useState } from 'react'
import { WidoWidget } from 'wido-widget'
import { getSupportedTokens } from 'wido'
import useWalletModal from 'hooks/useWalletModal'
import { getNetworkLibrary } from 'utils'

const theme = {
  primary: '#FFF',
  secondary: '#A9A9A9',
  interactive: '#E1E1E1',
  container: '#141B22',
  module: '#000000',
  accent: '#E1E1E1',
  dialog: '#000000',
  fontFamily: 'Inter, sans-serif',
  borderRadius: 3,
}

export const WidoWidgetPage = () => {
  const { library } = useActiveWeb3React()

  const [fromTokens, setFromTokens] = useState([])
  const [toTokens, setToTokens] = useState([])

  useEffect(() => {
    getSupportedTokens({ chainId: [1, 137, 250, 10, 42161, 56, 43114] }).then(setFromTokens)

    getSupportedTokens({ chainId: [137] }).then((tokens) => {
      const result = tokens.filter(
        (token) => token.name.includes('Retro') || token.symbol === 'RETRO' || token.symbol === 'CASH',
      )

      setToTokens(result)
    })
  }, [setFromTokens, setToTokens])

  const { openWalletModal } = useWalletModal()

  return (
    <div className='flex !text-red items-center h-full justify-center w-full p-5'>
      <WidoWidget
        width={500}
        ethProvider={library ?? getNetworkLibrary()}
        fromTokens={fromTokens}
        toTokens={toTokens}
        theme={theme}
        partner='0xa876E99E295f34EF23180B5d0e8eE44FAfbCe9b4'
        presetFromToken={{
          chainId: 1,
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        }}
        presetToToken={{
          chainId: 137,
          address: '0x64e14623CA543b540d0bA80477977f7c2c00a7Ea',
        }} // Retro_GAMMA_xCASH-USDC01
        onConnectWalletClick={openWalletModal}
      />
    </div>
  )
}
