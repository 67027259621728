import { useCallback, useMemo } from 'react'
import { CurrencyAmount, Price, Rounding } from '@uniswap/sdk-core'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { ChainId, JSBI } from 'thena-sdk'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import {
  Bound,
  Field,
  setFeeAmount,
  setFullRange,
  typeInput,
  typeLeftRangeInput,
  typeRightRangeInput,
  typeStartPriceInput,
  updateLiquidityRangeType,
  updatePresetRange,
} from './actions'
import { tryParseTick } from './utils'
import { PoolState, useGammaPool, usePool } from '../../hooks/v3/usePools'
import { Pool } from '../../v3lib/entities/pool'
import { Position } from '../../v3lib/entities'
import { encodeSqrtRatioX96, TickMath, nearestUsableTick, TICK_SPACINGS } from '../../v3lib/utils'
import { priceToClosestTick, tickToPrice } from '../../v3lib/utils/priceTickConversions'
import { getTickToPrice } from '../../v3lib/utils/getTickToPrice'
import { BIG_INT_ZERO } from '../../config/constants/v3/misc'
import { useCurrencyBalance, useCurrencyBalances } from '../../hooks/v3/useCurrencyBalances'
import { v3LiquidityRangeType, WBNB_EXTENDED } from '../../v3lib/entities/constants'
import { maxAmountSpend, tryParseAmount } from '../../v3lib/utils/utils'
import { useCurrency } from '../../hooks/v3/Tokens'
import { useV3GammaUniproxy } from '../../hooks/useContractV3'
import { gammaUniProxyAbi } from '../../config/abi/v3'
import { useQuery } from '@tanstack/react-query'
import { multicallFailSafe } from 'utils/multicall'
import { defaultChainId } from 'config/constants'
import { useDefiEdgeStrategiesQuery } from 'hooks/queries/useDefiEdgeStrategiesQuery'
import { useDefiEdgeLiquidityRatio } from 'hooks/queries/useDefiEdgeLiquidityRatio'
import Big from 'big.js'

export const useV3MintState = () => {
  return useSelector((state) => state.mintV3)
}

export const useV3MintActionHandlers = (noLiquidity) => {
  const dispatch = useDispatch()

  const onFieldAInput = useCallback(
    (typedValue) => {
      dispatch(
        typeInput({
          field: Field.CURRENCY_A,
          typedValue,
          noLiquidity: noLiquidity === true,
        }),
      )
    },
    [dispatch, noLiquidity],
  )

  const onFieldBInput = useCallback(
    (typedValue) => {
      dispatch(
        typeInput({
          field: Field.CURRENCY_B,
          typedValue,
          noLiquidity: noLiquidity === true,
        }),
      )
    },
    [dispatch, noLiquidity],
  )

  const onLeftRangeInput = useCallback(
    (typedValue) => {
      dispatch(typeLeftRangeInput({ typedValue }))
    },
    [dispatch],
  )

  const onRightRangeInput = useCallback(
    (typedValue) => {
      dispatch(typeRightRangeInput({ typedValue }))
    },
    [dispatch],
  )

  const onStartPriceInput = useCallback(
    (typedValue) => {
      dispatch(typeStartPriceInput({ typedValue }))
    },
    [dispatch],
  )

  const onChangeLiquidityRangeType = useCallback(
    (value) => {
      dispatch(updateLiquidityRangeType({ liquidityRangeType: value }))
    },
    [dispatch],
  )

  const onSetFeeAmount = useCallback(
    (value) => {
      dispatch(setFeeAmount({ feeAmount: value }))
    },
    [dispatch],
  )

  const onChangePresetRange = useCallback(
    (value) => {
      dispatch(updatePresetRange({ presetRange: value }))
    },
    [dispatch],
  )

  return {
    onFieldAInput,
    onFieldBInput,
    onLeftRangeInput,
    onRightRangeInput,
    onStartPriceInput,
    onChangeLiquidityRangeType,
    onChangePresetRange,
    onSetFeeAmount,
  }
}

export const useV3DerivedMintInfo = (
  currencyA,
  currencyB,
  baseCurrency,
  // override for existing position
  existingPosition,
) => {
  const { chainId, account } = useWeb3React()

  const {
    independentField,
    typedValue,
    leftRangeTypedValue,
    rightRangeTypedValue,
    startPriceTypedValue,
    liquidityRangeType,
    presetRange,
    feeAmount,
  } = useV3MintState()
  const dependentField = independentField === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A
  const bnb = useCurrency('MATIC')

  const isDoubleCurrencyAlmDeposit =
    liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE || liquidityRangeType === v3LiquidityRangeType.DEFI_EDGE

  // currencies
  const currencies = useMemo(
    () => ({
      [Field.CURRENCY_A]: currencyA,
      [Field.CURRENCY_B]: currencyB,
    }),
    [currencyA, currencyB],
  )

  // formatted with tokens
  const [tokenA, tokenB, baseToken] = useMemo(
    () => [currencyA?.wrapped, currencyB?.wrapped, baseCurrency?.wrapped],
    [currencyA, currencyB, baseCurrency],
  )

  const [token0, token1] = useMemo(
    () =>
      tokenA && tokenB ? (tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]) : [undefined, undefined],
    [tokenA, tokenB],
  )

  const ethBalance = useCurrencyBalance(bnb)
  const wethBalance = useCurrencyBalance(WBNB_EXTENDED[ChainId.MAINNET])
  const maxSpendETH = maxAmountSpend(ethBalance)
  // balances
  const balances = useCurrencyBalances([currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B]])

  const currencyBalances = {
    [Field.CURRENCY_A]:
      isDoubleCurrencyAlmDeposit &&
      currencyA &&
      chainId &&
      currencyA.wrapped.address.toLowerCase() === WBNB_EXTENDED[chainId].address.toLowerCase()
        ? CurrencyAmount.fromRawAmount(
            currencyA,
            JSBI.ADD(
              JSBI.BigInt(wethBalance ? wethBalance.numerator.toString() : '0'),
              JSBI.BigInt(maxSpendETH ? maxSpendETH.numerator.toString() : '0'),
            ),
          )
        : balances[0],
    [Field.CURRENCY_B]:
      isDoubleCurrencyAlmDeposit &&
      currencyB &&
      chainId &&
      currencyB.wrapped.address.toLowerCase() === WBNB_EXTENDED[chainId].address.toLowerCase()
        ? CurrencyAmount.fromRawAmount(
            currencyB,
            JSBI.ADD(
              JSBI.BigInt(wethBalance ? wethBalance.numerator.toString() : '0'),
              JSBI.BigInt(maxSpendETH ? maxSpendETH.numerator.toString() : '0'),
            ),
          )
        : balances[1],
  }

  const [manualPoolState, manualPool] = usePool(currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B], feeAmount)
  const [gammaPoolState, gammaPool] = useGammaPool(currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B])

  const getPool = () => {
    switch (liquidityRangeType) {
      case v3LiquidityRangeType.GAMMA_RANGE:
        return [gammaPoolState, gammaPool]

      case v3LiquidityRangeType.MANUAL_RANGE:
        return [manualPoolState, manualPool]

      default:
        return [gammaPoolState, gammaPool]
    }
  }

  const [poolState, pool] = getPool()

  const noLiquidity = poolState === PoolState.NOT_EXISTS

  const dynamicFee = pool ? pool.fee : 100

  // note to parse inputs in reverse
  const invertPrice = Boolean(baseToken && token0 && !baseToken.equals(token0))

  // always returns the price with 0 as base token
  const price = useMemo(() => {
    // if no liquidity use typed value
    if (noLiquidity) {
      const parsedQuoteAmount = tryParseAmount(startPriceTypedValue, invertPrice ? token0 : token1)
      if (parsedQuoteAmount && token0 && token1) {
        const baseAmount = tryParseAmount('1', invertPrice ? token1 : token0)
        const price =
          baseAmount && parsedQuoteAmount
            ? new Price(
                baseAmount.currency,
                parsedQuoteAmount.currency,
                baseAmount.quotient,
                parsedQuoteAmount.quotient,
              )
            : undefined
        return (invertPrice ? price?.invert() : price) ?? undefined
      }
      return undefined
    } else {
      // get the amount of quote currency
      return pool && token0 ? pool.priceOf(token0) : undefined
    }
  }, [noLiquidity, startPriceTypedValue, invertPrice, token1, token0, pool])

  // check for invalid price input (converts to invalid ratio)
  const invalidPrice = useMemo(() => {
    const sqrtRatioX96 = price ? encodeSqrtRatioX96(price.numerator, price.denominator) : undefined
    const invalid =
      price &&
      sqrtRatioX96 &&
      !(
        JSBI.greaterThanOrEqual(sqrtRatioX96, TickMath.MIN_SQRT_RATIO) &&
        JSBI.lessThan(sqrtRatioX96, TickMath.MAX_SQRT_RATIO)
      )
    return invalid
  }, [price])

  // used for ratio calculation when pool not initialized
  const mockPool = useMemo(() => {
    if (tokenA && tokenB && feeAmount && price && !invalidPrice) {
      const currentTick = priceToClosestTick(price)
      const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick)
      return new Pool(tokenA, tokenB, feeAmount, currentSqrt, JSBI.BigInt(0), currentTick, [])
    } else {
      return undefined
    }
  }, [feeAmount, invalidPrice, price, tokenA, tokenB])

  // if pool exists use it, if not use the mock pool
  const poolForPosition = pool ?? mockPool

  // lower and upper limits in the tick space for `feeAmount`
  const tickSpaceLimits = useMemo(
    () => ({
      [Bound.LOWER]: feeAmount ? nearestUsableTick(TickMath.MIN_TICK, TICK_SPACINGS[feeAmount]) : undefined,
      [Bound.UPPER]: feeAmount ? nearestUsableTick(TickMath.MAX_TICK, TICK_SPACINGS[feeAmount]) : undefined,
    }),
    [feeAmount],
  )

  // parse typed range values and determine closest ticks
  // lower should always be a smaller tick
  const ticks = useMemo(() => {
    return {
      [Bound.LOWER]:
        typeof existingPosition?.tickLower === 'number'
          ? existingPosition.tickLower
          : (invertPrice && typeof rightRangeTypedValue === 'boolean') ||
            (!invertPrice && typeof leftRangeTypedValue === 'boolean')
          ? tickSpaceLimits[Bound.LOWER]
          : invertPrice
          ? tryParseTick(token1, token0, feeAmount, rightRangeTypedValue.toString())
          : tryParseTick(token0, token1, feeAmount, leftRangeTypedValue.toString()),
      [Bound.UPPER]:
        typeof existingPosition?.tickUpper === 'number'
          ? existingPosition.tickUpper
          : (!invertPrice && typeof rightRangeTypedValue === 'boolean') ||
            (invertPrice && typeof leftRangeTypedValue === 'boolean')
          ? tickSpaceLimits[Bound.UPPER]
          : invertPrice
          ? tryParseTick(token1, token0, feeAmount, leftRangeTypedValue.toString())
          : tryParseTick(token0, token1, feeAmount, rightRangeTypedValue.toString()),
    }
  }, [
    existingPosition,
    feeAmount,
    invertPrice,
    leftRangeTypedValue,
    rightRangeTypedValue,
    token0,
    token1,
    tickSpaceLimits,
  ])

  const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks || {}

  // specifies whether the lower and upper ticks is at the exteme bounds
  const ticksAtLimit = useMemo(
    () => ({
      [Bound.LOWER]: feeAmount && tickLower === tickSpaceLimits.LOWER,
      [Bound.UPPER]: feeAmount && tickUpper === tickSpaceLimits.UPPER,
    }),
    [tickSpaceLimits, tickLower, tickUpper, feeAmount],
  )

  // mark invalid range
  const invalidRange = Boolean(typeof tickLower === 'number' && typeof tickUpper === 'number' && tickLower >= tickUpper)

  // always returns the price with 0 as base token
  const pricesAtTicks = useMemo(() => {
    return {
      [Bound.LOWER]: getTickToPrice(token0, token1, ticks[Bound.LOWER]),
      [Bound.UPPER]: getTickToPrice(token0, token1, ticks[Bound.UPPER]),
    }
  }, [token0, token1, ticks])
  const { [Bound.LOWER]: lowerPrice, [Bound.UPPER]: upperPrice } = pricesAtTicks

  // liquidity range warning
  const outOfRange = Boolean(
    !invalidRange && price && lowerPrice && upperPrice && (price.lessThan(lowerPrice) || price.greaterThan(upperPrice)),
  )

  const independentCurrency = currencies[independentField]

  const independentAmount = tryParseAmount(
    typedValue,
    isDoubleCurrencyAlmDeposit && independentCurrency && independentCurrency.isNative
      ? independentCurrency.wrapped
      : independentCurrency,
  )

  const gammaUNIPROXYContract = useV3GammaUniproxy()
  const gammaCurrencies = currencyA && currencyB ? [currencyA, currencyB] : []
  // const depositAmountsData = useSingleContractMultipleData(
  //   presetRange && presetRange.address && gammaCurrencies.length > 0 ? gammaUNIPROXYContract : undefined,
  //   'getDepositAmount',
  //   presetRange && presetRange.address
  //     ? gammaCurrencies.map((currency) => [presetRange?.address, currency?.wrapped.address, parseUnits('1', currency?.wrapped.decimals ?? 0)])
  //     : [],
  // )

  const depositAmountQuery = useQuery(
    ['deposit-amount-gamma', presetRange?.address],
    async () => {
      const calls = gammaCurrencies.map((currency) => {
        return {
          address: gammaUNIPROXYContract.address,
          name: 'getDepositAmount',
          params: [presetRange?.address, currency?.wrapped?.address, parseUnits('1', currency?.wrapped.decimals ?? 0)],
        }
      })

      try {
        const result = await multicallFailSafe(gammaUniProxyAbi, calls)

        return result
      } catch (error) {
        console.error(error)
      }
    },
    {
      enabled:
        Boolean(presetRange?.address) &&
        gammaCurrencies.length > 0 &&
        liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE,
    },
  )

  const depositAmountsData = depositAmountQuery.data

  const quoteDepositAmount = useMemo(() => {
    if (
      depositAmountsData?.length > 0 &&
      !depositAmountQuery.isLoading &&
      depositAmountsData[0] &&
      depositAmountsData[0]?.length > 1 &&
      currencyB
    ) {
      return {
        amountMin: Number(formatUnits(depositAmountsData[0][0], currencyB.wrapped.decimals)),
        amountMax: Number(formatUnits(depositAmountsData[0][1], currencyB.wrapped.decimals)),
      }
    }
    return
  }, [currencyB, depositAmountsData])

  const baseDepositAmount = useMemo(() => {
    if (
      depositAmountsData?.length > 0 &&
      !depositAmountQuery.isLoading &&
      depositAmountsData[1] &&
      depositAmountsData[1]?.length > 1 &&
      currencyA
    ) {
      return {
        amountMin: Number(formatUnits(depositAmountsData[1][0], currencyA.wrapped.decimals)),
        amountMax: Number(formatUnits(depositAmountsData[1][1], currencyA.wrapped.decimals)),
      }
    }
    return
  }, [currencyA, depositAmountsData])

  const defiEdgeStrategiesQuery = useDefiEdgeStrategiesQuery()

  const defiEdgeStrategy = defiEdgeStrategiesQuery.data?.find((strategy) => {
    return (
      (strategy?.token0?.address?.toLowerCase() === currencyA?.wrapped?.address?.toLowerCase() &&
        strategy?.token1?.address?.toLowerCase() === currencyB?.wrapped?.address?.toLowerCase()) ||
      (strategy?.token0?.address?.toLowerCase() === currencyB?.wrapped?.address?.toLowerCase() &&
        strategy?.token1?.address?.toLowerCase() === currencyA?.wrapped?.address?.toLowerCase())
    )
  })

  const defiEdgeLiquidityRatioQuery = useDefiEdgeLiquidityRatio(defiEdgeStrategy?.id)
  const liquidityRatio = defiEdgeLiquidityRatioQuery.data ?? 0

  const dependentAmount = useMemo(() => {
    if (isDoubleCurrencyAlmDeposit) {
      switch (liquidityRangeType) {
        case v3LiquidityRangeType.DEFI_EDGE: {
          if (!independentAmount || !currencyA || !currencyB) return

          if (!liquidityRatio) return

          if (independentField === Field.CURRENCY_A) {
            const amount0 = Number(independentAmount.toSignificant()).toFixed(currencyB.wrapped.decimals)
            const amount1 = Big(amount0).mul(liquidityRatio).toFixed(currencyB.wrapped.decimals)

            const quoteDeposit = parseUnits(amount1, currencyB.wrapped.decimals)

            return CurrencyAmount.fromRawAmount(
              currencyB.isNative ? currencyB.wrapped : currencyB,
              JSBI.BigInt(quoteDeposit),
            )
          } else {
            const amount1 = Number(independentAmount.toSignificant()).toFixed(currencyA.wrapped.decimals)
            const amount0 = Big(amount1 * 1)
              .div(liquidityRatio)
              .toFixed(currencyA.wrapped.decimals)

            const baseDeposit = parseUnits(amount0, currencyA.wrapped.decimals)

            return CurrencyAmount.fromRawAmount(
              currencyA.isNative ? currencyA.wrapped : currencyA,
              JSBI.BigInt(baseDeposit),
            )
          }
        }

        case v3LiquidityRangeType.GAMMA_RANGE: {
          if (
            !independentAmount ||
            !presetRange ||
            !presetRange.address ||
            !quoteDepositAmount ||
            !baseDepositAmount ||
            !currencyA ||
            !currencyB
          )
            return

          if (independentField === Field.CURRENCY_A) {
            const quoteDeposit = parseUnits(
              (
                ((quoteDepositAmount.amountMin + quoteDepositAmount.amountMax) / 2) *
                Number(independentAmount.toSignificant())
              ).toFixed(currencyB.wrapped.decimals),
              currencyB.wrapped.decimals,
            )
            return CurrencyAmount.fromRawAmount(
              currencyB.isNative ? currencyB.wrapped : currencyB,
              JSBI.BigInt(quoteDeposit),
            )
          } else {
            const baseDeposit = parseUnits(
              (
                ((baseDepositAmount.amountMin + baseDepositAmount.amountMax) / 2) *
                Number(independentAmount.toSignificant())
              ).toFixed(currencyA.wrapped.decimals),
              currencyA.wrapped.decimals,
            )
            return CurrencyAmount.fromRawAmount(
              currencyA.isNative ? currencyA.wrapped : currencyA,
              JSBI.BigInt(baseDeposit),
            )
          }
        }
      }
    }
    // we wrap the currencies just to get the price in terms of the other token
    const wrappedIndependentAmount = independentAmount?.wrapped
    const dependentCurrency = dependentField === Field.CURRENCY_B ? currencyB : currencyA
    if (
      independentAmount &&
      wrappedIndependentAmount &&
      typeof tickLower === 'number' &&
      typeof tickUpper === 'number' &&
      poolForPosition
    ) {
      // if price is out of range or invalid range - return 0 (single deposit will be independent)
      if (outOfRange || invalidRange) {
        return undefined
      }

      const position = wrappedIndependentAmount.currency.equals(poolForPosition.token0)
        ? Position.fromAmount0({
            pool: poolForPosition,
            tickLower,
            tickUpper,
            amount0: independentAmount.quotient,
            useFullPrecision: true, // we want full precision for the theoretical position
          })
        : Position.fromAmount1({
            pool: poolForPosition,
            tickLower,
            tickUpper,
            amount1: independentAmount.quotient,
          })

      const dependentTokenAmount = wrappedIndependentAmount.currency.equals(poolForPosition.token0)
        ? position.amount1
        : position.amount0
      return dependentCurrency && CurrencyAmount.fromRawAmount(dependentCurrency, dependentTokenAmount.quotient)
    }

    return undefined
  }, [
    liquidityRangeType,
    independentAmount,
    dependentField,
    currencyB,
    currencyA,
    tickLower,
    tickUpper,
    poolForPosition,
    presetRange,
    quoteDepositAmount,
    baseDepositAmount,
    independentField,
    outOfRange,
    invalidRange,
    liquidityRatio,
  ])

  const parsedAmounts = useMemo(() => {
    return {
      [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? independentAmount : dependentAmount,
      [Field.CURRENCY_B]: independentField === Field.CURRENCY_A ? dependentAmount : independentAmount,
    }
  }, [dependentAmount, independentAmount, independentField])

  // single deposit only if price is out of range
  const deposit0Disabled = Boolean(
    typeof tickUpper === 'number' && poolForPosition && poolForPosition.tickCurrent >= tickUpper,
  )
  const deposit1Disabled = Boolean(
    typeof tickLower === 'number' && poolForPosition && poolForPosition.tickCurrent <= tickLower,
  )

  // sorted for token order
  const depositADisabled =
    liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE &&
    (invalidRange ||
      Boolean(
        (deposit0Disabled && poolForPosition && tokenA && poolForPosition.token0.equals(tokenA)) ||
          (deposit1Disabled && poolForPosition && tokenA && poolForPosition.token1.equals(tokenA)),
      ))
  const depositBDisabled =
    liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE &&
    (invalidRange ||
      Boolean(
        (deposit0Disabled && poolForPosition && tokenB && poolForPosition.token0.equals(tokenB)) ||
          (deposit1Disabled && poolForPosition && tokenB && poolForPosition.token1.equals(tokenB)),
      ))

  // create position entity based on users selection
  const position = useMemo(() => {
    if (
      !poolForPosition ||
      !tokenA ||
      !tokenB ||
      typeof tickLower !== 'number' ||
      typeof tickUpper !== 'number' ||
      invalidRange
    ) {
      return undefined
    }

    // mark as 0 if disabled because out of range
    const amount0 = !deposit0Disabled
      ? parsedAmounts?.[tokenA.equals(poolForPosition.token0) ? Field.CURRENCY_A : Field.CURRENCY_B]?.quotient
      : BIG_INT_ZERO
    const amount1 = !deposit1Disabled
      ? parsedAmounts?.[tokenA.equals(poolForPosition.token0) ? Field.CURRENCY_B : Field.CURRENCY_A]?.quotient
      : BIG_INT_ZERO

    if (amount0 !== undefined && amount1 !== undefined) {
      return Position.fromAmounts({
        pool: poolForPosition,
        tickLower,
        tickUpper,
        amount0,
        amount1,
        useFullPrecision: true, // we want full precision for the theoretical position
      })
    } else {
      return undefined
    }
  }, [
    parsedAmounts,
    poolForPosition,
    tokenA,
    tokenB,
    deposit0Disabled,
    deposit1Disabled,
    invalidRange,
    tickLower,
    tickUpper,
  ])

  let errorMessage
  let errorCode

  if (!account || chainId !== defaultChainId) {
    errorMessage = `Connect Wallet`
    errorCode = errorCode ?? 0
  }

  if (poolState === PoolState.INVALID) {
    errorMessage = errorMessage ?? `Invalid pair`
    errorCode = errorCode ?? 1
  }

  if (invalidPrice) {
    errorMessage = errorMessage ?? `Invalid price input`
    errorCode = errorCode ?? 2
  }

  if (
    (!parsedAmounts[Field.CURRENCY_A] && !depositADisabled) ||
    (!parsedAmounts[Field.CURRENCY_B] && !depositBDisabled)
  ) {
    errorMessage = errorMessage ?? `Enter an amount`
    errorCode = errorCode ?? 3
  }

  const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts

  if (currencyAAmount && currencyBalances?.[Field.CURRENCY_A]?.lessThan(currencyAAmount)) {
    errorMessage = `Insufficient ${currencies[Field.CURRENCY_A]?.symbol} balance`
    errorCode = errorCode ?? 4
  }

  if (currencyBAmount && currencyBalances?.[Field.CURRENCY_B]?.lessThan(currencyBAmount)) {
    errorMessage = `Insufficient ${currencies[Field.CURRENCY_B]?.symbol} balance`
    errorCode = errorCode ?? 5
  }

  const invalidPool = poolState === PoolState.INVALID

  return {
    dependentField,
    currencies,
    pool,
    poolState,
    currencyBalances,
    parsedAmounts,
    ticks,
    price,
    feeAmount,
    pricesAtTicks,
    position,
    noLiquidity,
    errorMessage,
    errorCode,
    invalidPool,
    invalidRange,
    outOfRange,
    depositADisabled,
    depositBDisabled,
    invertPrice,
    ticksAtLimit,
    dynamicFee,
    lowerPrice,
    upperPrice,
    liquidityRangeType,
    presetRange,
  }
}

export const useRangeHopCallbacks = (baseCurrency, quoteCurrency, feeAmount, tickLower, tickUpper, pool) => {
  const dispatch = useDispatch()

  const baseToken = useMemo(() => baseCurrency?.wrapped, [baseCurrency])
  const quoteToken = useMemo(() => quoteCurrency?.wrapped, [quoteCurrency])

  const getDecrementLower = useCallback(
    (rate = 1) => {
      if (baseToken && quoteToken && typeof tickLower === 'number' && feeAmount) {
        const newPrice = tickToPrice(baseToken, quoteToken, tickLower - TICK_SPACINGS[feeAmount] * rate)
        return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
      }
      // use pool current tick as starting tick if we have pool but no tick input

      if (!(typeof tickLower === 'number') && baseToken && quoteToken && feeAmount && pool) {
        const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent - TICK_SPACINGS[feeAmount] * rate)
        return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
      }
      return ''
    },
    [baseToken, quoteToken, tickLower, feeAmount, pool],
  )

  const getIncrementLower = useCallback(
    (rate = 1) => {
      if (baseToken && quoteToken && typeof tickLower === 'number' && feeAmount) {
        const newPrice = tickToPrice(baseToken, quoteToken, tickLower + TICK_SPACINGS[feeAmount] * rate)
        return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
      }
      // use pool current tick as starting tick if we have pool but no tick input
      if (!(typeof tickLower === 'number') && baseToken && quoteToken && feeAmount && pool) {
        const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent + TICK_SPACINGS[feeAmount] * rate)
        return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
      }
      return ''
    },
    [baseToken, quoteToken, tickLower, feeAmount, pool],
  )

  const getDecrementUpper = useCallback(
    (rate = 1) => {
      if (baseToken && quoteToken && typeof tickUpper === 'number' && feeAmount) {
        const newPrice = tickToPrice(baseToken, quoteToken, tickUpper - TICK_SPACINGS[feeAmount] * rate)
        return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
      }
      // use pool current tick as starting tick if we have pool but no tick input
      if (!(typeof tickUpper === 'number') && baseToken && quoteToken && feeAmount && pool) {
        const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent - TICK_SPACINGS[feeAmount] * rate)
        return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
      }
      return ''
    },
    [baseToken, quoteToken, tickUpper, feeAmount, pool],
  )

  const getIncrementUpper = useCallback(
    (rate = 1) => {
      if (baseToken && quoteToken && typeof tickUpper === 'number' && feeAmount) {
        const newPrice = tickToPrice(baseToken, quoteToken, tickUpper + TICK_SPACINGS[feeAmount] * rate)
        return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
      }
      // use pool current tick as starting tick if we have pool but no tick input
      if (!(typeof tickUpper === 'number') && baseToken && quoteToken && feeAmount && pool) {
        const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent + TICK_SPACINGS[feeAmount] * rate)
        return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
      }
      return ''
    },
    [baseToken, quoteToken, tickUpper, feeAmount, pool],
  )

  const getSetRange = useCallback(
    (numTicks) => {
      if (baseToken && quoteToken && feeAmount && pool) {
        // calculate range around current price given `numTicks`
        const newPriceLower = tickToPrice(
          baseToken,
          quoteToken,
          Math.max(TickMath.MIN_TICK, pool.tickCurrent - numTicks),
        )
        const newPriceUpper = tickToPrice(
          baseToken,
          quoteToken,
          Math.min(TickMath.MAX_TICK, pool.tickCurrent + numTicks),
        )

        return [
          newPriceLower.toSignificant(5, undefined, Rounding.ROUND_UP),
          newPriceUpper.toSignificant(5, undefined, Rounding.ROUND_UP),
        ]
      }
      return ['', '']
    },
    [baseToken, quoteToken, feeAmount, pool],
  )

  const getSetFullRange = useCallback(() => {
    dispatch(setFullRange())
  }, [dispatch])

  return {
    getDecrementLower,
    getIncrementLower,
    getDecrementUpper,
    getIncrementUpper,
    getSetRange,
    getSetFullRange,
  }
}

export const useActivePreset = () => {
  const preset = useSelector((state) => state.mintV3.preset)
  return useMemo(() => preset, [preset])
}

export const useAddLiquidityTxHash = () => {
  const txHash = useSelector((state) => state.mintV3.txHash)
  return useMemo(() => txHash, [txHash])
}

export const useShowNewestPosition = () => {
  const newestPosition = useSelector((state) => state.mintV3.showNewestPosition)
  return useMemo(() => newestPosition, [newestPosition])
}

export const useInitialUSDPrices = () => {
  const initialUSDPrices = useSelector((state) => state.mintV3.initialUSDPrices)
  return useMemo(() => initialUSDPrices, [initialUSDPrices])
}

export const useInitialTokenPrice = () => {
  const initialTokenPrice = useSelector((state) => state.mintV3.initialTokenPrice)
  return useMemo(() => initialTokenPrice, [initialTokenPrice])
}

export const useCurrentStep = () => {
  const currentStep = useSelector((state) => state.mintV3.currentStep)
  return useMemo(() => currentStep, [currentStep])
}
