import { ConnectorNames } from '../../utils/connectors'

const connectorLocalStorageKey = 'thena-local-key'
// eslint-disable-next-line no-use-before-define
const defaultChainId = Number(process.env.REACT_APP_CHAIN_ID)

const REACT_APP_MUON_API_URL = 'https://dibs-shield.muon.net/'

const ChainId = {
  MAINNET: 137,
}

const TransactionType = {
  START: 'start',
  WAITING: 'waiting',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
}

const LotteryStatus = {
  UNKNOWN: 0,
  WON: 1,
  LOST: 2,
}

const connectors = [
  {
    key: 'metamask',
    logo: '/images/wallets/metamask-logo.svg',
    title: 'MetaMask',
    connector: ConnectorNames.MetaMask,
  },
  {
    key: 'trustwallet',
    logo: '/images/wallets/trustwallet-logo.svg',
    title: 'Trust Wallet',
    connector: ConnectorNames.TrustWallet,
  },
  {
    key: 'walletConnect',
    logo: '/images/wallets/walletconnect-logo.svg',
    title: 'Wallet Connect',
    connector: ConnectorNames.WalletConnect,
  },
  // {
  //   key: 'coinbase',
  //   logo: '/images/wallets/coinbase-wallet-logo.svg',
  //   title: 'Coinbase Wallet',
  //   connector: ConnectorNames.Coinbase,
  // },
  // {
  //   key: 'binance',
  //   logo: '/images/wallets/binance-wallet-logo.svg',
  //   title: 'Binance Wallet',
  //   connector: ConnectorNames.BinanceChainWallet,
  // },
  // {
  //   key: 'coin98',
  //   logo: '/images/wallets/coin98-wallet-logo.svg',
  //   title: 'Coin98 Wallet',
  //   connector: ConnectorNames.Coin98Wallet,
  // },
]

const routeAssets = [
  {
    name: 'Wrapped MATIC',
    symbol: 'WMATIC',
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://retro.finance/images/tokens/WMATIC.png',
  },
  {
    name: 'USD Circle',
    symbol: 'USDC',
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    chainId: 137,
    decimals: 6,
    logoURI: 'https://retro.finance/images/tokens/USDC.png',
  },
  {
    name: 'RETRO',
    symbol: 'RETRO',
    address: '0xBFA35599c7AEbb0dAcE9b5aa3ca5f2a79624D8Eb',
    chainId: 137,
    decimals: 18,
    logoURI: 'https://retro.finance/images/tokens/RETRO.png',
  },
  {
    name: 'Tether USD',
    symbol: 'USDT',
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    chainId: 137,
    decimals: 6,
    logoURI: 'https://retro.finance/images/tokens/USDT.png',
  },
  {
    name: 'CASH',
    symbol: 'CASH',
    address: '0x5D066D022EDE10eFa2717eD3D79f22F949F8C175',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://retro.finance/images/tokens/CASH.png',
  },
  // {
  //   name: 'USD Coin',
  //   symbol: 'USDC',
  //   address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   chainId: 56,
  //   decimals: 18,
  //   logoURI: 'https://retro.finance/images/tokens/USDC.png',
  // },
  // {
  //   name: 'Tether USD',
  //   symbol: 'USDT',
  //   address: '0x55d398326f99059fF775485246999027B3197955',
  //   chainId: 56,
  //   decimals: 18,
  //   logoURI: 'https://retro.finance/images/tokens/USDT.png',
  // },
  // {
  //   name: 'Frax',
  //   symbol: 'FRAX',
  //   address: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
  //   chainId: 56,
  //   decimals: 18,
  //   logoURI: 'https://retro.finance/images/tokens/FRAX.png',
  // },
  // {
  //   name: 'Liquid Staking BNB',
  //   symbol: 'BNBx',
  //   address: '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275',
  //   chainId: 56,
  //   decimals: 18,
  //   logoURI: 'https://retro.finance/images/tokens/BNBx.png',
  // },
  // {
  //   name: 'Coin98 Dollar',
  //   symbol: 'CUSD',
  //   address: '0xFa4BA88Cf97e282c505BEa095297786c16070129',
  //   chainId: 56,
  //   decimals: 18,
  //   logoURI: 'https://retro.finance/images/tokens/CUSD.png',
  // },
  // {
  //   name: 'Hay Destablecoin',
  //   symbol: 'HAY',
  //   address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
  //   chainId: 56,
  //   decimals: 18,
  //   logoURI: 'https://retro.finance/images/tokens/HAY.png',
  // },
  // {
  //   name: 'USD+',
  //   symbol: 'USD+',
  //   address: '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
  //   chainId: 56,
  //   decimals: 6,
  //   logoURI: 'https://retro.finance/images/tokens/USD+.png',
  // },
  // {
  //   name: 'Staked BNB',
  //   symbol: 'stkBNB',
  //   address: '0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16',
  //   chainId: 56,
  //   decimals: 18,
  //   logoURI: 'https://retro.finance/images/tokens/stkBNB.png',
  // },
  // {
  //   name: 'Ankr Reward Bearing BNB',
  //   symbol: 'ankrBNB',
  //   address: '0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827',
  //   chainId: 56,
  //   decimals: 18,
  //   logoURI: 'https://retro.finance/images/tokens/ankrBNB.png',
  // },
  // {
  //   name: 'THENA',
  //   symbol: 'THE',
  //   address: '0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11',
  //   chainId: 56,
  //   decimals: 18,
  //   logoURI: 'https://retro.finance/logo.png',
  // },
]
const periodLevels = [
  {
    value: 0,
    label: '2 weeks',
  },
  {
    value: 1,
    label: '6 months',
  },
  {
    value: 2,
    label: '1 year',
  },
  {
    value: 3,
    label: '2 years',
  },
]

const NumberOfRows = [10, 20, 30, 40]

const PoolTypes = {
  ALL: 'ALL',
  STABLE: 'sAMM',
  V1: 'vAMM',
  // FUSION: 'V3',
}

const TabSizes = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
}

const ReferralTabs = ['code', 'rewards', 'leaderboard', 'lottery']
const TaxAssets = [
  '0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979', // fBOMB
  '0xc95cd75dcea473a30c8470b232b36ee72ae5dcc2', // CHAM
  '0x3a806a3315e35b3f5f46111adb6e2baf4b14a70d', // LIBERA
  '0x9a7b04fd5788ea39819723e7eb9ef5f609bc57ab', // cpTHE
]
const NewPools = [
  '0xd714206a7D63F5a2d613064815995E9CC7061988', // vAMM-UNW/THE
  '0x2475FF2A7C81da27eA2e08e0d3B0Ad01e16225eC', // sAMM-BTCB/multiBTC
]

const STABLE_TOKENS = {
  // BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  USDT: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  USDC: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  // DAI: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  // DEI: '0xDE1E704dae0B4051e80DAbB26ab6ad6c12262DA0',
  // USD: '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
  // ETS: '0x5B852898CD47d2Be1d77D30377b3642290f5Ec75',
  // HAY: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
  // FRAX: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
  // CUSD: '0xFa4BA88Cf97e282c505BEa095297786c16070129',
  // MAI: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
  // DOLA: '0x2F29Bc0FFAF9bff337b31CBe6CB5Fb3bf12e5840',
  // wUSDR: '0x2952beb1326acCbB5243725bd4Da2fC937BCa087',
}

export {
  connectorLocalStorageKey,
  defaultChainId,
  connectors,
  TransactionType,
  routeAssets,
  periodLevels,
  NumberOfRows,
  PoolTypes,
  LotteryStatus,
  REACT_APP_MUON_API_URL,
  TaxAssets,
  STABLE_TOKENS,
  ReferralTabs,
  NewPools,
  TabSizes,
  ChainId,
}
