// import SwapIcon from '../../../icons/SwapIcon'
// import LiquidityIcon from '../../../icons/LiquidityIcon'
// import LockIcon from '../../../icons/LockIcon'
// import VoteIcon from '../../../icons/VoteIcon'
// import RewardIcon from '../../../icons/RewardIcon'
// import GaugesIcon from '../../../icons/GaugesIcon'
// import NftIcon from '../../../icons/NftIcon'
// import MoreIcon from '../../../icons/MoreIcon'

const socialLinks = [
  {
    link: 'https://discord.gg/stabllabs',
    image: '/images/retro/footer/DiscordLogo.svg',
    name: 'Discord',
  },
  {
    link: 'https://medium.com/@stabl.labs',
    image: '/images/retro/footer/MediumLogo.svg',
    name: 'Medium',
  },
  {
    link: 'https://twitter.com/Retro_finance',
    image: '/images/retro/footer/TwitterLogo.png',
    name: 'Twitter',
  },
  {
    link: 'https://github.com/Stabl-Labs-Official',
    image: '/images/retro/footer/GithubLogo.png',
    name: 'Github',
  },
  // {
  //   link: '#',
  //   image: '/images/retro/footer/InstagramLogo.svg',
  //   name: 'Instagram',
  // },
]

export default socialLinks
