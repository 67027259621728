import styles from './Home.module.css'
import { useContext, useEffect, useState } from 'react'

import { Manager } from './Manager'
import { useVeNftsQuery } from './hooks/useVeNftsQuery'
import { ManagerHeader } from './ManagerHeader'
import { veTHEsContext } from 'context/veTHEsConetext'

export const VeTokenManager = () => {
  const veTHEs = useContext(veTHEsContext)
  const [selectedVeNFT, setSelectedVeNft] = useState()

  const veNftsQuery = useVeNftsQuery()

  useEffect(() => {
    if (!veTHEs || veTHEs.length === 0) {
      return
    }

    if (veNftsQuery.data === undefined) {
      return
    }

    if (veNftsQuery.data.length === 0) {
      setSelectedVeNft(undefined)

      return
    }

    if (selectedVeNFT !== undefined) {
      return
    }

    setSelectedVeNft(veTHEs[0])
  }, [veNftsQuery.data, selectedVeNFT, veTHEs])

  const refetchData = () => {
    veNftsQuery.refetch()
  }

  const hasVeNFT = veNftsQuery.data && selectedVeNFT !== undefined
  const veNFTInfo = hasVeNFT ? veNftsQuery.data.find((nft) => nft.tokenId === selectedVeNFT.id) : undefined

  // if (veNftsQuery.isLoading) return <div className='text-white flex justify-center'>Loading</div>

  return (
    <div className={styles.container}>
      <main>
        <div className={styles.main}>
          <ManagerHeader
            tokenId={veNFTInfo?.tokenId}
            managerInfo={veNFTInfo?.managerInfo}
            refetchData={veNftsQuery.refetch}
            nfts={veNftsQuery.data}
            selectedVeNFT={selectedVeNFT}
            setSelectedVeNft={setSelectedVeNft}
            veTHEs={veTHEs}
            isManagerLoading={veNftsQuery.isLoading}
          />

          {veNFTInfo?.managerInfo === undefined || !veNFTInfo.managerInfo.approved ? (
            <></>
          ) : (
            <Manager reloadOnchainData={refetchData} managerInfo={veNFTInfo.managerInfo} />
          )}
        </div>
      </main>
    </div>
  )
}
