import React from 'react'

const SearchInput = ({ searchText, setSearchText, placeholder, full, className, onlyNumber = false }) => (
  <div
    className={`border-2 border-white w-full  rounded-[3px] flex items-center ${
      !full && 'lg:max-w-[250px]'
    } relative ${className}`}
  >
    <img className='pointer-events-none absolute left-3 w-6 h-6' alt='' src='/images/svgs/search.svg' />
    <input
      type={onlyNumber ? 'number' : 'text'}
      value={searchText}
      autoComplete='false'
      onChange={(e) => setSearchText(e.target.value)}
      placeholder={placeholder}
      className='bg-componentPrimary placeholder-placeholder h-[52px] w-full text-white text-base pl-[41px] pr-4 py-[14px] rounded-[3px]'
    />
  </div>
)

export default SearchInput
