import Big from 'big.js'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import NumericalInput from 'components/common/Input/NumericalInput'
import useTokenBalance from 'hooks/useTokenBalance'
import React, { useState } from 'react'
import { formatNumber } from 'utils/formatNumber'

export const Automation = (props) => {
  const maticBalance = useTokenBalance('MATIC')

  const createAutomation = async () => {
    try {
      const pools = Array.from(
        new Set(
          Object.keys(props.reocurringBribes).map((poolAddressTokenAddress) => poolAddressTokenAddress.split('-')[0]),
        ),
      )

      const bribesTokens = []
      const amountToFundThisContractPerPool = []
      const weeklyAmounts = []

      for (const pool of pools) {
        const poolBribesTokens = []
        const poolAmountToFundThisContractPerPool = []
        const poolWeeklyAmounts = []

        for (const poolAddressTokenAddress in props.reocurringBribes) {
          if (!poolAddressTokenAddress.startsWith(pool)) {
            continue
          }

          const { depositAmount, weeklyAmount } = props.reocurringBribes[poolAddressTokenAddress]

          poolBribesTokens.push(poolAddressTokenAddress.split('-')[1])

          poolAmountToFundThisContractPerPool.push(depositAmount)

          poolWeeklyAmounts.push(weeklyAmount)
        }
        bribesTokens.push(poolBribesTokens)
        amountToFundThisContractPerPool.push(poolAmountToFundThisContractPerPool)
        weeklyAmounts.push(poolWeeklyAmounts)
      }

      const noBribe = !props.automationProperties.withBribe

      const tx = await props.manager.createAutomation(
        bribesTokens,
        pools,
        amountToFundThisContractPerPool,
        weeklyAmounts,
        noBribe,
        props.automationProperties.withClaimAllAndVote,
        {
          value: Big(props.automationProperties.maticToDeposit)
            .mul(10 ** 18)
            .toString(),
        },
      )

      await tx.wait()
    } catch (e) {
      console.error(e)
    }

    props.reloadOnchainData()
  }

  const [withdrawOnStopAutomation, setWithdrawOnStopAutomation] = useState(true)

  const stopAutomation = async () => {
    try {
      const tx = await props.manager.stopAutomation(withdrawOnStopAutomation)

      await tx.wait()
    } catch (e) {
      console.error(e)
    }

    props.reloadOnchainData()
  }

  return (
    <div className='w-[670px] px-[24px]'>
      <div className='w-full gap-2 mb-10'>
        <h1 className='text-[34px] flex justify-center md:text-[42px] font-semibold f-f-fg'>Automation</h1>
        <div className='flex justify-between'>
          <div className='px-[24px] py-[12px] flex gap-2 bg-[#344054]'>
            <input
              className='w-[24px] h-[24px]'
              title='with-vote-and-claim'
              type='checkbox'
              checked={props.automationProperties.withClaimAllAndVote}
              onChange={() =>
                props.setAutomationProperties({
                  ...props.automationProperties,
                  withClaimAllAndVote: !props.automationProperties.withClaimAllAndVote,
                })
              }
            />
            <label
              htmlFor='with-vote-and-claim'
              className='cursor-pointer'
              onClick={() =>
                props.setAutomationProperties({
                  ...props.automationProperties,
                  withClaimAllAndVote: !props.automationProperties.withClaimAllAndVote,
                })
              }
            >
              With vote & claim rebase
            </label>
          </div>
          <div className='px-[24px] py-[12px] flex gap-2 bg-[#344054]'>
            <input
              className='w-[24px] h-[24px]'
              title='with-bribe'
              type='checkbox'
              checked={props.automationProperties.withBribe}
              onChange={() =>
                props.setAutomationProperties({
                  ...props.automationProperties,
                  withBribe: !props.automationProperties.withBribe,
                })
              }
            />
            <label
              htmlFor='with-bribe'
              className='cursor-pointer'
              onClick={() =>
                props.setAutomationProperties({
                  ...props.automationProperties,
                  withBribe: !props.automationProperties.withBribe,
                })
              }
            >
              With reocurring bribe
            </label>
          </div>
        </div>
      </div>

      <div className='flex justify-center mb-3'>MATIC to deposit:</div>
      <div className='flex justify-center items-center h-[56px]'>
        <div
          onClick={() =>
            props.setAutomationProperties({ ...props.automationProperties, maticToDeposit: maticBalance.toString() })
          }
          className='flex items-center mr-2 cursor-pointer'
        >
          Balance: {formatNumber(maticBalance.toString(), 5)} MATIC
        </div>
        <div className='w-[133px] h-full mr-3'>
          <NumericalInput
            className='bg-black h-full'
            value={props.automationProperties.maticToDeposit}
            onUserInput={(value) =>
              props.setAutomationProperties({ ...props.automationProperties, maticToDeposit: value })
            }
          />
        </div>
        <LinearGhostButton
          disabled={
            !props.automationProperties.maticToDeposit || maticBalance.lt(props.automationProperties.maticToDeposit)
          }
          smallCase
          title={`${props.managerInfo.automationActive ? 'Replace' : 'Create'} automation`}
          className='text-black px-[10px] h-full w-[250px]'
          onClickHanlder={createAutomation}
        />
      </div>
      {props.managerInfo.automationActive ? (
        <div className='flex flex-col items-center gap-2 mt-5'>
          <h1 className='text-[34px] flex justify-center md:text-[42px] font-semibold f-f-fg'>Current Automation</h1>
          <a
            href={`https://app.gelato.network/task/${props.managerInfo.currentTaskId}?chainId=137`}
            rel='noopener noreferrer'
            target='_blank'
            className='text-sky-600'
          >
            https://app.gelato.network/task/{props.managerInfo.currentTaskId}?chainId=137
          </a>
          <LinearGhostButton className='px-[10px] mb-2' title='Stop automation' onClickHanlder={stopAutomation} />
          <div className='flex flex-row gap-2'>
            <input
              className='w-[24px] h-[24px]'
              title='with-withdraw-on-stop-automation'
              type='checkbox'
              checked={withdrawOnStopAutomation}
              onChange={() => setWithdrawOnStopAutomation(!withdrawOnStopAutomation)}
            />
            <label
              htmlFor='with-withdraw-on-stop-automation'
              className='cursor-pointer'
              onClick={() => setWithdrawOnStopAutomation(!withdrawOnStopAutomation)}
            >
              Withdraw all tokens & MATIC
            </label>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
