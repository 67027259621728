import React from 'react'
import { useNavigate } from 'react-router-dom'
import BackIcon from '../../../icons/BackIcon'

const SubPage = ({ title, children }) => {
  const navigate = useNavigate()

  return (
    <>
      <div className='w-full py-12 md:py-28 xl:pb-0 2xl:pb-[150px] px-5 xl:px-0 '>
        <div className='w-full max-w-[595px] p-px rounded-[5px] mx-auto relative'>
          <div className='w-full px-3 py-3 bg-componentPrimary border-4 border-white rounded-[5px] md:px-6 md:py-5'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <button
                  className='mr-2.5 lg:mr-6'
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  <span className='block w-[20px] lg:w-[26px] text-white'>
                    <BackIcon />
                  </span>
                </button>
                <p className='text-[22px] lg:text-[27px] text-white font-semibold'>{title}</p>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default SubPage
