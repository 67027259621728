import React, { useContext, useMemo, useState } from 'react'
import { formatAmount } from '../../../utils/formatNumber'
import StyledButton from '../../common/Buttons/styledButton'
import VeTHEPopup from '../../common/VeTHEPopup'
import { veTHEsContext } from '../../../context/veTHEsConetext'
import { useMerge } from '../../../hooks/useLock'
import BigNumber from 'bignumber.js'

const MergeTab = ({ selected }) => {
  const [veTHE, setVeTHE] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const veTHEs = useContext(veTHEsContext)
  const filtered = useMemo(() => {
    return veTHEs.filter((item) => item.id !== selected.id && item.voting_amount.gt(0))
  }, [veTHEs])

  const { onMerge, pending } = useMerge()

  const errorMsg = useMemo(() => {
    if (!veTHE) {
      return 'SELECT veRETRO'
    }
    return null
  }, [veTHE])

  const votingPower = useMemo(() => {
    if (veTHE) {
      const end = Math.max(selected.lockEnd, veTHE.lockEnd)
      const current = new Date().getTime() / 1000
      return selected.amount
        .plus(veTHE.amount)
        .times(end - current)
        .div(86400 * 730)
    }
    return new BigNumber(0)
  }, [selected, veTHE])

  return (
    <>
      <div className='mt-5 flex flex-col items-center justify-center'>
        <div className='w-full'>
          <div className='flex items-center justify-between'>
            <p className='text-white text-sm md:text-base leading-10'>veRETRO ID</p>
            <p className='text-white text-sm md:text-base leading-10'>
              veRETRO Balance: {formatAmount(selected.voting_amount)}
            </p>
          </div>
          <div className='bg-componentPrimary mt-1.5 lg:mt-2.5 p-px w-full rounded-[3px]'>
            <div className='bg-body px-2.5 h-12 lg:h-[70px] rounded-[3px] flex items-center justify-between'>
              <p className='text-white text-[18px] md:text-[24px] font-medium'>{`Token #${selected.id}`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`flex flex-col w-full items-center justify-center mt-5`}>
        <div className={`w-full mb-5`}>
          <div className='flex items-center justify-between'>
            <p className='text-white texts-[13px] md:text-base'>Merge To</p>
            <p className='text-white texts-[13px] md:text-base'>
              veRETRO Balance: {veTHE ? formatAmount(veTHE.voting_amount) : '-'}
            </p>
          </div>
          <div className={`bg-componentPrimary mt-1.5 md:mt-2.5 p-px w-full rounded-[3px] relative`}>
            <div className='bg-body h-12 lg:h-[70px] rounded-[3px] flex items-center'>
              <div
                onClick={() => {
                  if (filtered && filtered.length > 0) {
                    setIsOpen(true)
                  }
                }}
                className={`bg-transparent w-full h-full cursor-pointer flex items-center relative z-10 py-[8px] lg:py-[15px] pl-2.5 lg:pl-4`}
              >
                <div
                  className={`w-full h-full font-normal ${
                    veTHE ? 'text-white font-medium' : 'text-[#757384]'
                  } text-lg md:text-2xl md:leading-10`}
                >
                  {veTHE ? 'Token #' + veTHE.id : filtered.length > 0 ? 'Select' : 'Not Found'}
                </div>
              </div>
              <img
                className={`${
                  isOpen ? 'rotate-180' : 'rotate-0'
                } transform transition-all duration-300 ease-in-out absolute right-4 top-4 md:top-7 `}
                alt=''
                src='/images/swap/dropdown-arrow.png'
              />
            </div>
            <VeTHEPopup popup={isOpen} setPopup={setIsOpen} setSelectedVeTHE={setVeTHE} veTHEs={filtered} />
          </div>
        </div>
      </div>
      {veTHE && (
        <div className='mb-[9px] sm:flex items-center justify-between'>
          <span className='text-base lg:text-xl text-white font-light'>Token #{veTHE.id} veRETRO Balance:</span>
          <div className='flex space-x-2'>
            <span className='text-lg lg:text-[22px] text-white font-medium'>{formatAmount(votingPower)}</span>
            <span className='text-lg lg:text-[22px] text-dimGray font-light'>
              (+{formatAmount(votingPower.minus(veTHE.voting_amount))})
            </span>
          </div>
        </div>
      )}
      <StyledButton
        disabled={errorMsg || pending}
        pending={pending}
        onClickHandler={() => {
          onMerge(selected, veTHE)
        }}
        content={errorMsg || 'MERGE'}
        className='py-[13px] md:py-[14.53px] mt-4 text-base  md:text-lg tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center leading-[30px] px-[19px] w-full rounded-[3px]'
      />
    </>
  )
}

export default MergeTab
