import React from 'react'
import styled from 'styled-components'
import TransparentButton from '../../../common/Buttons/transparentButton'

const Card = styled.div`
  background: #141b22;
  position: relative;
  border: 5px solid #fff;
`
const Index = ({ className, title, img, para, button, setOpen, cta }) => {
  const renderContent = () => {
    return (
      <div className='flex items-center gap-x-5 px-10 h-[52px] relative z-10'>
        <img src='/images/whiteList/add-icon.svg' alt='' />
        <span>{button}</span>
      </div>
    )
  }
  return (
    <Card className={`${className}  rounded-[5px] wrapper relative`}>
      <div className='inside md:py-[31px] px-[22px] pt-[26px] pb-4 md:px-[35px] rounded-[5px] md:flex items-start md:space-x-5 m-px relative'>
        <div className='max-w-[83px] mt-3 z-10 relative'>
          <img alt='' src={img} />
        </div>
        <div className='max-w-[328px] z-10 relative mt-4 md:mt-0'>
          <p className='text-[23px] md:text-3xl font-medium text-white'>{title}</p>
          <div className='mb-4 lg:min-h-[72px] md:mb-6 mt-1 md:mt-[6.25px] text-[#DEDBF2] opacity-[0.88] text-[15px] md:text-[17px] leading-[25px] md:leading-6'>
            {para}
          </div>
          {cta ? (
            <TransparentButton
              content={renderContent()}
              onClickHandler={setOpen}
              className='
          relative w-full md:w-auto max-w-[220px] py-[1px] flex flex-col items-center justify-center text-[12px] tracking-[1.36px] rounded-[3px] transition-all duration-300 ease-in-out'
            />
          ) : (
            <TransparentButton
              content={renderContent()}
              onClickHandler={setOpen}
              className='
            relative w-full md:w-auto max-w-[220px] flex flex-col items-center justify-center text-[12px] tracking-[1.36px] rounded-[3px] transition-all duration-300 ease-in-out'
            />
          )}
        </div>
      </div>
    </Card>
  )
}

export default Index
