import React from 'react'

const Tab = ({ leftTitle, rightTitle, isLeft, setIsLeft, className, multi, tabData, tab, setTab, tabClassName, activeTabClassName }) => (
  <div className={`${className} text-[15px] md:text-base lg:text-xl tracking-[1.6px] flex items-center gap-x-5`}>
    {multi ? (
      <div className='flex items-center justify-center gap-x-5 w-full'>
        {tabData.map((item, idx) => {
          return (
            <button
              onClick={() => {
                setTab(idx)
              }}
              key={idx}
              className={`${
                tab === idx ? ` bg-white text-black font-medium ${activeTabClassName}` : 'text-dimGray bg-black'
              } w-1/3 p-5 font-semibold font-montSerrat rounded-xl ${tabClassName}`}
            >
              {item}
            </button>
          )
        })}
      </div>
    ) : (
      <>
        <button
          onClick={() => {
            setIsLeft(true)
          }}
          className={`${isLeft ? ' bg-white text-black font-medium' : 'text-dimGray bg-black'} w-1/2 p-5 font-semibold font-montSerrat rounded-xl`}
        >
          {leftTitle}
        </button>
        <button
          onClick={() => {
            setIsLeft(false)
          }}
          className={`${!isLeft ? ' bg-white text-black font-medium' : 'text-dimGray bg-black'} w-1/2 p-5 font-semibold font-montSerrat rounded-xl`}
        >
          {rightTitle}
        </button>
      </>
    )}
  </div>
)

export default Tab
