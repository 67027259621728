import { ethers } from 'ethers'
import { useDiscount, useOption } from 'hooks/useOption'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useBlockNumber } from 'state/application/hooks'
import { useWeb3React } from '@web3-react/core'
import { format } from 'date-fns'
import { getOptionAddress, getBribeOptionAddress } from 'utils/addressHelpers'
import { formatAmount } from 'utils/formatNumber'
import SearchTokenPopup from '../../components/common/SearchTokenPopup'
import { useTransmutationContract } from 'hooks/useContract'
import { useTransmutation } from 'hooks/useTransmutation'
import { useTokenBalances } from 'hooks/useTokenBalance'

const OPTION_ADDRESS = getOptionAddress()
const BRIBE_OPTION_ADDRESS = getBribeOptionAddress()

const RedeemButton = ({ loading, onClick, disabled, label }) => {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 rounded text-lg font-semibold text-black flex items-center justify-center ${
        loading ? 'bg-gray-300' : 'bg-white'
      } disabled:opacity-30`}
      disabled={disabled}
    >
      {loading && (
        <svg
          className='animate-spin -ml-1 mr-3 h-5 w-5 text-black'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
        >
          <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
          <path
            className='opacity-75'
            fill='currentColor'
            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
          ></path>
        </svg>
      )}
      {label}
    </button>
  )
}

const DataTable = ({ rows }) => {
  return (
    <div className='flex flex-col space-y-2'>
      {rows.map((row, i) => (
        <div key={i} className='flex justify-between items-start'>
          <span className='text-gray-400'>{row.label}</span>
          <span className='font-semibold'>{row.value}</span>
        </div>
      ))}
    </div>
  )
}

const useTokenBalance = (tokenAddress, account) => {
  const [balance, setBalance] = useState(ethers.BigNumber.from(0))
  const blockNumber = useBlockNumber()

  useEffect(() => {
    if (account && tokenAddress) {
      const tokenContract = new ethers.Contract(
        tokenAddress,
        ['function balanceOf(address) view returns (uint256)'],
        ethers.getDefaultProvider('https://rpc-mainnet.maticvigil.com/'),
      )
      tokenContract.balanceOf(account).then((balance) => {
        setBalance(balance)
      })
    }
  }, [account, tokenAddress, blockNumber])

  return balance
}

const CryptoInput = ({
  value,
  onChange,
  maxAmount,
  decimals,
  selectedAsset,
  setSelectedAsset,
  locked,
  assets,
  loading,
}) => {
  const [tokenPopup, setTokenPopup] = useState(false)

  const handleChange = (event) => {
    let value = event.target.value

    if (value === '.') return

    const decimalRegex = new RegExp(`^\\d*(\\.\\d{0,${decimals}})?`)
    value = (value.match(decimalRegex) || [''])[0]
    return onChange(value)
  }

  const setMax = () => {
    onChange(maxAmount)
  }

  const disabled = locked || loading

  const handleSelectAsset = () => {
    setTokenPopup(false)
  }

  return (
    <>
      <div className='flex items-center gap-x-2 relative bg-black p-3 rounded-lg shadow'>
        {selectedAsset && (
          <button className='flex items-center font-medium' disabled={!assets} onClick={() => setTokenPopup(true)}>
            <div className='w-8 mr-2'>
              <img src={selectedAsset.logoURI} alt='' className='' />
            </div>
            {selectedAsset.symbol}
            {assets && (
              <img
                src='/images/svgs/dropdown.svg'
                alt=''
                className='ml-2 w-3 h-3 transform transition-all duration-300 ease-in-out'
              />
            )}
          </button>
        )}
        <input
          type='text'
          className={`flex-grow text-2xl w-full bg-transparent focus:outline-none ${
            loading ? 'text-white/50' : 'text-white'
          }`}
          value={value}
          onChange={handleChange}
          placeholder='0'
          disabled={disabled}
        />
        {maxAmount && (
          <button
            className='absolute inset-y-0 right-0 px-2 py-1 font-semibold rounded transition-colors'
            onClick={setMax}
          >
            Max
          </button>
        )}
      </div>
      {assets && (
        <SearchTokenPopup
          popup={tokenPopup}
          setPopup={setTokenPopup}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          baseAssets={assets}
          onAssetSelect={handleSelectAsset}
          showCommon={false}
        />
      )}
    </>
  )
}

const TabButton = ({ activeTab, tabName, handleTabSwitch }) => {
  const isActive = activeTab === tabName

  return (
    <button
      className={`w-full px-4 py-3 rounded ${isActive ? 'bg-white font-semibold text-black' : 'text-dimGray'} `}
      onClick={() => handleTabSwitch(tabName)}
    >
      {tabName}
    </button>
  )
}

const veExerciseAssets = [
  {
    name: 'Option to buy RETRO',
    symbol: 'oRETRO',
    logoURI: '/images/tokens/oretro.png',
    address: OPTION_ADDRESS,
  },
  {
    name: 'Bribe veRETRO',
    symbol: 'bveRETRO',
    logoURI: '/images/tokens/bveRETRO.png',
    address: BRIBE_OPTION_ADDRESS,
  },
]

const ExerciseVeRetro = () => {
  const [asset, setAsset] = useState(veExerciseAssets[0])
  const {fullLock, exerciseVePending, handleExerciseVe} = useOption()
  const [amount, setAmount] = useState('')
  const tokenAddresses = useMemo(() => [OPTION_ADDRESS, BRIBE_OPTION_ADDRESS], []);
  const balances = useTokenBalances(tokenAddresses);

  const unlocksAt = new Date(new Date().getTime() + fullLock * 1000)

  const isButtonDisabled = exerciseVePending || !amount || (balances && balances[asset.address] && ethers.utils.parseUnits(amount || '0').gt(balances[asset.address]))

  const exercise = async () => {
    try {
      switch (asset.address) {
        case OPTION_ADDRESS:
          await handleExerciseVe(ethers.utils.parseUnits(amount, 18), 'oretro')
          break
        case BRIBE_OPTION_ADDRESS:
          await handleExerciseVe(ethers.utils.parseUnits(amount, 18), 'bveretro')
          break
      }
    } catch (e) {
      console.error(e)
    }
  }
  
  const dataTableRows = useMemo(() => [
    {
      label: `${asset.symbol} balance`,
      value: balances && balances[asset.address] ? `${ethers.utils.formatUnits(balances[asset.address])} ${asset.symbol}` : '-',
    },
  ], [balances, asset])
  
  return (
    <div className='flex flex-col gap-y-4'>
      <DataTable rows={dataTableRows} />
      <div>
      <div className='mb-2'>You redeem</div>
      <CryptoInput
        value={amount}
        selectedAsset={asset}
        setSelectedAsset={setAsset}
        assets={veExerciseAssets}
        onChange={(value) => setAmount(value)}
        maxAmount={balances && balances[asset.address] && ethers.utils.formatUnits(balances[asset.address])}
        decimals={18}
      />
      </div>
      <div className='flex flex-col space-y-2'>
        <div className='flex justify-between items-start'>
          <span>Your voting power will be</span>
          <span>
            <span className='font-semibold mr-1'>{amount || 0}</span>
            veRETRO
          </span>
        </div>
        <div className='flex justify-between items-start'>
          <span>Unlocking at</span>
          <span>{format(unlocksAt, 'P')}</span>
        </div>
      </div>
      <RedeemButton
        disabled={isButtonDisabled}
        onClick={exercise}
        label={'Redeem into veRETRO'}
        loading={exerciseVePending}
      />
      <p>
        Redeeeming into veRETRO will create you a new locked veNFT. It is possible to merge it into single veNFT on the
        Vest page after redemption.
      </p>
    </div>
  )
}

const ExerciseRetro = ({ balance }) => {
  const { account } = useWeb3React()
  const discount = useDiscount()
  const { twap, paymentToken, exercisePending, handleExercise } = useOption()
  const paymentTokenBalance = useTokenBalance(paymentToken.address, account)

  const [valueA, setValueA] = useState('')
  const [valueB, setValueB] = useState('')

  const handleChangeA = (newValueA) => {
    const parsedValueA = ethers.utils.parseUnits(newValueA || '0', 18)
    setValueA(newValueA)
    setValueB(
      ethers.utils.formatUnits(
        parsedValueA.mul(twap).mul(discount).div(100).div(ethers.constants.WeiPerEther).toString(),
      ),
    )
  }

  const handleChangeB = (newValueB) => {
    const parsedValueB = ethers.utils.parseUnits(newValueB || '0', paymentToken.decimals)
    setValueB(newValueB)
    setValueA(
      ethers.utils.formatUnits(
        parsedValueB.mul(ethers.constants.WeiPerEther).div(twap.mul(discount).div(100)).toString(),
      ),
    )
  }

  const dataTableRows = [
    {
      label: 'oRETRO balance',
      value: `${ethers.utils.formatUnits(balance)} oRETRO`,
    },
    {
      label: `${paymentToken.symbol} balance`,
      value: `${formatAmount(ethers.utils.formatUnits(paymentTokenBalance))} ${paymentToken.symbol}`,
    },
    {
      label: 'Discount %',
      value: discount ? `${100 - discount}%` : '-',
    },
  ]

  const exercise = async () => {
    try {
      await handleExercise(ethers.utils.parseUnits(valueA, 18))
    } catch (e) {
      console.error(e)
    }
  }

  const isButtonDisabled =
    !valueA ||
    !valueB ||
    exercisePending ||
    ethers.utils.parseUnits(valueA).gt(balance) ||
    ethers.utils.parseUnits(valueB).gt(paymentTokenBalance)

  return (
    <div className='flex flex-col gap-y-4'>
      <DataTable rows={dataTableRows} />
      <div>
        <div className='mb-2'>You redeem oRETRO</div>
        <CryptoInput
          value={valueA}
          onChange={handleChangeA}
          maxAmount={ethers.utils.formatUnits(balance)}
          decimals={18}
        />
        <div className='mb-2 mt-2'>You pay CASH</div>
        <CryptoInput
          value={valueB}
          onChange={handleChangeB}
          maxAmount={ethers.utils.formatUnits(paymentTokenBalance)}
          decimals={paymentToken.decimals}
        />
      </div>
      <div className='flex flex-col space-y-2'>
        <div className='flex mb-2 justify-between items-start'>
          <span>You get</span>
          <span>
            <span className='font-semibold mr-1'>{valueA || 0}</span>
            RETRO
          </span>
        </div>
      </div>
      <RedeemButton
        disabled={isButtonDisabled}
        onClick={exercise}
        label={'Redeem into RETRO'}
        loading={exercisePending}
      />
    </div>
  )
}

const transmuteAssets = [
  {
    name: 'CASH',
    symbol: 'CASH',
    logoURI: '/images/tokens/CASH.png',
    address: '0x5d066d022ede10efa2717ed3d79f22f949f8c175',
    decimals: 18,
  },
  {
    name: 'Wrapped MATIC',
    symbol: 'WMATIC',
    logoURI: '/images/tokens/WMATIC.png',
    address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    decimals: 18,
  },
  {
    name: 'Wrapped Ether',
    symbol: 'WETH',
    logoURI: '/images/tokens/WETH.png',
    address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    decimals: 18,
  },
  {
    name: 'RETRO',
    symbol: 'RETRO',
    logoURI: '/logo.png',
    address: '0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb',
    decimals: 18,
  },
]

const ExerciseTransmute = ({ balance }) => {
  const transmutationContract = useTransmutationContract()
  const [value, setValue] = useState('')
  const [outputAsset, setOutputAsset] = useState(transmuteAssets[0])
  const [outputValue, setOutputValue] = useState('')
  const [loadingOutputValue, setLoadingOutputValue] = useState(false)
  const { transmute, transmutePending } = useTransmutation()
  const maxSlippageEl = useRef(null)
  const [maxSlippage, setMaxSlippage] = useState('0.5')
  const discount = useDiscount()

  useEffect(() => {
    if (maxSlippageEl.current) {
      maxSlippageEl.current.textContent = maxSlippage
      maxSlippageEl.current.blur()
      window.getSelection().removeAllRanges()
    }
  }, [maxSlippageEl])

  const dataTableRows = [
    {
      label: 'oRETRO balance',
      value: `${ethers.utils.formatUnits(balance)} oRETRO`,
    },
    {
      label: 'Discount %',
      value: discount ? `${100 - discount}%` : '-',
    },
  ]

  const handleTransmute = async () => {
    try {
      const outputValueFloat = parseFloat(outputValue)
      const minAmountOutValue = Math.floor(outputValueFloat - (outputValueFloat * parseFloat(maxSlippage)) / 100)
      const minAmountOut = ethers.utils.parseUnits(minAmountOutValue.toString(), outputAsset.decimals)
      await transmute(outputAsset, ethers.utils.parseUnits(value, 18), minAmountOut)
    } catch (e) {
      console.error(e)
    }
  }

  const isButtonDisabled =
    !value ||
    !outputAsset ||
    ethers.utils.parseUnits(value).gt(balance) ||
    ethers.utils.parseUnits(value || '0').eq(0) ||
    transmutePending ||
    !maxSlippage

  const fetchOutputValue = async (inputAmount, outputAsset) => {
    if (outputAsset) {
      if (inputAmount == 0) return '0'
      const outputAmount = await transmutationContract.methods.getAmountOut(outputAsset.address, inputAmount).call()
      const outputValue = ethers.utils.formatUnits(outputAmount, outputAsset.decimals)
      return outputValue
    }
  }

  useEffect(() => {
    if (!value || !outputAsset) return setOutputValue('')

    setLoadingOutputValue(true)
    const inputAmount = ethers.utils.parseUnits(value)
    fetchOutputValue(inputAmount, outputAsset)
      .then((value) => {
        // Deduct 3% fee
        value = (parseFloat(value) * 0.97).toFixed(outputAsset.decimals)
        setOutputValue(value)
      })
      .finally(() => {
        setLoadingOutputValue(false)
      })
  }, [value, outputAsset])

  const handleChangeMaxSlippage = (e) => {
    let value = e.currentTarget.textContent
    if (value === '') setMaxSlippage('')
    maxSlippageEl.current.textContent = ''
    maxSlippageEl.current.textContent = maxSlippage
    if (value === '.') return
    const decimalRegex = new RegExp(`^\\d*(\\.\\d{0,${2}})?`)
    value = (value.match(decimalRegex) || [''])[0]
    if (value !== '') {
      setMaxSlippage(value)
    }
    setCaretToEnd()
  }

  useEffect(() => {
    if (maxSlippageEl.current) {
      maxSlippageEl.current.textContent = maxSlippage
      setCaretToEnd()
    }
  }, [maxSlippageEl, maxSlippage])

  const setCaretToEnd = () => {
    const el = maxSlippageEl.current
    const range = document.createRange()
    const sel = window.getSelection()
    const length = el.textContent.length

    range.setStart(el.childNodes[0] || el, length)
    range.collapse(true)

    sel.removeAllRanges()
    sel.addRange(range)
  }

  return (
    <div className='relative'>
      <div className='flex flex-col gap-y-4'>
        <DataTable rows={dataTableRows} />
        <div>
          <div className='mb-2'>You redeem oRETRO</div>
          <CryptoInput value={value} onChange={setValue} maxAmount={ethers.utils.formatUnits(balance)} decimals={18} />
          <div className='mb-2 mt-4'>You receive</div>
          <CryptoInput
            value={outputValue}
            loading={loadingOutputValue}
            decimals={outputAsset.decimals}
            locked
            assets={transmuteAssets}
            selectedAsset={outputAsset}
            setSelectedAsset={setOutputAsset}
          />
        </div>
        <div>
          Max Slippage:{' '}
          <span
            className='rounded bg-black px-2 py-1 outline-none'
            onInput={handleChangeMaxSlippage}
            role='textbox'
            ref={maxSlippageEl}
            contentEditable
            suppressContentEditableWarning={true}
            onFocus={setCaretToEnd}
          />
          %
        </div>
        <RedeemButton
          disabled={isButtonDisabled}
          onClick={handleTransmute}
          loading={transmutePending}
          label={`Redeem oRETRO for ${outputAsset.name}`}
        />
        <div className='flex flex-col gap-y-1'>
          {maxSlippage ? (
            parseFloat(maxSlippage) < 0.1 && (
              <p className='text-sm text-error'>Warning: Slippage is too low, your transaction may fail.</p>
            )
          ) : (
            <p className='text-sm text-error'>Error: You need to specify a max slippage.</p>
          )}
          <p className='text-sm text-dimGray'>There is a fee of 3% on all transmutes.</p>
        </div>
      </div>
    </div>
  )
}

const Options = () => {
  const [activeTab, setActiveTab] = useState('veRETRO')
  const { account } = useWeb3React()
  const oRetroBalance = useTokenBalance(OPTION_ADDRESS, account)
  const handleTabSwitch = (tabName) => {
    setActiveTab(tabName)
  }

  return (
    <>
      <div className='w-full max-w-7xl px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 pt-5 pb-20 md:pt-[120px] mx-auto lg:pb-[75px]'>
        <div className='lg:flex items-end justify-between lg:space-x-[60px]'>
          <div className='w-full'>
            <h1 className='text-[34px] md:text-[42px] font-semibold text-white  f-f-fg'>Options</h1>
            <div className='max-w-5xl'>
              <p className='text-[#b8b6cb] md:text-lg '>
                oRETRO is a call option token that is used as the emission token for the Retro protocol. 1 oRETRO lets
                you purchase 1 RETRO token at a discounted rate or lock your oRETRO 1:1 for veRETRO (max locked).
                Holders of oRETRO can exercise the right to discounted RETRO by paying with CASH (MATIC, MAI, WETH etc.
                coming soon) to convert their oRETRO tokens into RETRO. The discount rate is subject to change and based
                on market conditions.
              </p>
              <p className='text-[#b8b6cb] text-sm leading-loose mt-1'>
                Note: You can also always sell your oRETRO in the market to exit the position without having to exercise
                the call option.
              </p>
            </div>
          </div>
        </div>
        <div className='max-w-5xl rounded p-8 bg-black lg:bg-componentPrimary flex flex-col mt-8'>
          <h1 className='text-xl font-semibold text-white'>Redeem into</h1>
          <div className='flex flex-row justify-evenly gap-2 mt-6'>
            <div className='container mx-auto'>
              <div className='flex flex-row justify-evenly gap-2'>
                <TabButton activeTab={activeTab} tabName='veRETRO' handleTabSwitch={handleTabSwitch} />
                <TabButton activeTab={activeTab} tabName='RETRO' handleTabSwitch={handleTabSwitch} />
                <TabButton activeTab={activeTab} tabName='Transmute' handleTabSwitch={handleTabSwitch} />
              </div>
              <div className='mt-6 text-white'>
                {activeTab === 'veRETRO' && <ExerciseVeRetro />}
                {activeTab === 'RETRO' && <ExerciseRetro balance={oRetroBalance} />}
                {activeTab === 'Transmute' && <ExerciseTransmute balance={oRetroBalance} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Options
