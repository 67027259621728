import React, { useContext, useState } from 'react'
import NumericalInput from 'components/common/Input/NumericalInput'
import PoolSelect from 'components/common/PoolSelect'
import { FusionsContext } from 'context/FusionsContext'
import { ZERO_ADDRESS } from 'utils/formatNumber'
import SearchTokenPopup from 'components/common/SearchTokenPopup'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'

export const ReoccuringBribe = ({ reocurringBribes, setReocurringBribes, approveToken }) => {
  const [selectedPool, setSelectedPool] = useState()
  const [selectedToken, setSelectedToken] = useState()
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false)
  const tokens = useContext(BaseAssetsConetext)

  const pools = useContext(FusionsContext)
  const poolsWithGauges = pools
    .filter((pair) => pair && pair.gauge.address !== ZERO_ADDRESS && pair.isValid)
    .reduce((acc, pool) => {
      acc[pool.address] = pool

      return acc
    }, {})

  return (
    <div className='flex flex-col items-center w-full gap-2'>
      <h1 className='text-[34px] md:text-[42px] font-semibold f-f-fg'>Reoccuring Bribe</h1>
      <div className='flex flex-col items-center'>
        <p>
          You can choose a list of weekly reoccuring bribes. Click on {'"'}Add to selection{'"'} to build the list.
        </p>
        <p>You can deposit several bribes at once when creating the automation but you need to approve each token.</p>
        <p>
          You can also deposit later, see {'"'}Deposit Bribes{'"'} section below.
        </p>
        <p>
          If you use this feature do not forget to check {'"'}With reocurring bribe{'"'} below
        </p>
      </div>
      <div className='flex w-full h-[56px] justify-center gap-2'>
        <div className='w-[256px] h-full'>
          <PoolSelect
            title='Select pool'
            setPool={setSelectedPool}
            pool={selectedPool}
            pools={Object.values(poolsWithGauges)}
          />
        </div>
        <div className='bg-body z-[90] rounded-[3px] flex items-center relative w-[256px]'>
          <div
            onClick={() => {
              setIsTokenModalOpen(!isTokenModalOpen)
            }}
            className={`bg-transparent w-full h-full flex items-center cursor-pointer px-2.5 lg:px-4`}
          >
            {selectedToken ? (
              <div
                className={`flex items-center absolute left-2.5 lg:left-4 space-x-3 mdLg:mt-1 lg:-mt-[3px] xl:-mt-[5px]`}
              >
                <div className='flex items-center space-x-2'>
                  <img
                    className='relative z-10 w-[26px] h-[26px] md:w-[30px] md:h-[30px]'
                    alt=''
                    src={selectedToken.logoURI}
                  />
                  {/* <img
                      className='relative z-[5] w-[26px] h-[26px] md:w-[30px] md:h-[30px]'
                      alt=''
                      src={pool.token1.logoURI}
                    /> */}
                </div>
                <div className='text-white'>
                  <p className='md:text-[19px] font-medium leading-5 md:leading-[30px] f-f-fg'>
                    {selectedToken.symbol}
                  </p>
                </div>
              </div>
            ) : (
              <div
                className={`w-full h-full flex items-center font-normal text-[#757384] text-lg md:text-2xl md:leading-10`}
              >
                Select token
              </div>
            )}
          </div>

          <img
            className={`transform transition-all duration-300 ease-in-out mr-3`}
            alt=''
            src='/images/swap/dropdown-arrow.png'
          />
        </div>
        <SearchTokenPopup
          hideCommon={true}
          popup={isTokenModalOpen}
          setPopup={setIsTokenModalOpen}
          selectedAsset={selectedToken}
          setSelectedAsset={setSelectedToken}
          baseAssets={tokens.filter((asset) => asset.address !== 'MATIC')}
        />

        <LinearGhostButton
          disabled={!selectedPool || !selectedToken}
          title='Add to selection'
          className='px-[5px]'
          onClickHanlder={() =>
            setReocurringBribes({
              ...reocurringBribes,
              [`${selectedPool.address}-${selectedToken.address}`]: {
                depositAmount: BigInt(0),
                weeklyAmount: BigInt(0),
              },
            })
          }
        />
      </div>
      {Object.keys(reocurringBribes).length == 0 ? (
        <></>
      ) : (
        <>
          <h2 className='text-2xl'>Selected Bribes:</h2>
          <div className='w-full flex flex-col gap-2'>
            <div className='flex flex-row items-center gap-2 w-full font-bold px-10'>
              <div className='w-1/5'>Pool</div>
              <div className='w-1/6'>Token</div>
              <div className='w-1/3'>Amount to deposit</div>
              <div className='w-1/3'>Amount to bribe weekly</div>
              <div className='w-1/6'></div>
              <div className='w-1/6'></div>
            </div>
            {Object.keys(reocurringBribes).map((poolAddrTokenAddr) => {
              const [poolAddr, tokenAddr] = poolAddrTokenAddr.split('-')

              const token = tokens.find((token) => token.address === tokenAddr)
              const pool = poolsWithGauges[poolAddr]

              return (
                <div key={poolAddrTokenAddr} className='flex flex-row px-10 items-center gap-2 w-full h-[56px]'>
                  <div className='bg-[#0C111D] w-1/5 h-full rounded-[3px] px-2 flex justify-center items-center'>
                    {pool.symbol}
                  </div>
                  <div className='bg-[#0C111D] w-1/6 h-full rounded-[3px] px-2 flex justify-center items-center'>
                    {token.symbol}
                  </div>
                  <div className='w-1/3 h-full'>
                    <NumericalInput
                      className='bg-black h-[56px]'
                      value={reocurringBribes[poolAddrTokenAddr].depositAmount}
                      onUserInput={(value) =>
                        setReocurringBribes({
                          ...reocurringBribes,
                          [poolAddrTokenAddr]: {
                            ...reocurringBribes[poolAddrTokenAddr],
                            depositAmount: value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className='w-1/3'>
                    <NumericalInput
                      value={reocurringBribes[poolAddrTokenAddr].weeklyAmount}
                      className='bg-black h-[56px]'
                      onUserInput={(value) =>
                        setReocurringBribes({
                          ...reocurringBribes,
                          [poolAddrTokenAddr]: {
                            ...reocurringBribes[poolAddrTokenAddr],
                            weeklyAmount: value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className='w-1/6 h-full'>
                    <LinearGhostButton
                      className='w-[165px] h-full'
                      title='Approve'
                      onClickHanlder={() => approveToken(poolAddrTokenAddr.split('-')[1])}
                    />
                  </div>
                  <div className='w-1/6 h-full'>
                    <LinearGhostButton
                      className='w-[165px] h-full'
                      title='Remove'
                      onClickHanlder={() => {
                        // eslint-disable-next-line
                        const { [poolAddrTokenAddr]: _, ...newObject } = reocurringBribes
                        setReocurringBribes(newObject)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
