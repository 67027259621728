export const ADDR_RETRO_VE_MANAGER_FACTORY = '0x3c9dC9Dd784173785b2A3fA041eA31D270C9c780'
export const ADDR_VE_RETRO = '0xB419cE2ea99f356BaE0caC47282B9409E38200fa'
export const ADDR_VOTER = '0xAcCbA5e852AB85E5E3a84bc8E36795bD8cEC5C73'
export const ADDR_ZERO = '0x0000000000000000000000000000000000000000'

export const STYLE_BUTTON = 'bg-gray-300 hover:bg-gray-400 p-1'
export const STYLE_SELECT = 'bg-gray-300 cursor-pointer p-1'
export const STYLE_TITLE = 'text-3xl pb-2 border-y-2 border-gray-400 w-full text-center'

export const range = (count) => Array.from(new Array(Number(count)).keys())
