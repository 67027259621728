import SearchInput from 'components/common/Input/SearchInput'
import { useIchiVaultAssets } from 'hooks/v3/useIchiVaultAssets'
import React, { useContext, useMemo, useState } from 'react'
import { VaultCard } from './VaultCard'
import { toToken } from 'config/constants/v3/routing'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
import { useTokenBalances } from 'hooks/useTokenBalance'
import { isAddress } from 'web3-utils'
import { useUserIchiPositions } from 'hooks/v3/useUserIchiPositions'
import Toggle from 'components/common/Toggle'
import useDebounce from 'hooks/useDebounce'
import { useGetAllLiquidityPools } from 'hooks/v3/useGetAllLiquidityPools'
import { useMerklClaimAll } from 'hooks/v3/useMerklClaimAll'
import Spinner from 'components/common/Spinner'
import { useGetMerklDataQuery } from 'hooks/queries/useGetMerklDataQuery'
import { useLocalStorage } from 'hooks/useLocalStorage'
import CloseModalIcon from 'icons/CloseModalIcon'
import TabFilter from 'components/common/TabFilter'

const tokenFilters = ['ALL', 'MATIC', 'USDC', 'WETH', 'CASH', 'RETRO']

export const SingleDeposit = () => {
  const [searchText, setSearchText] = useState('')
  const [showStaked, setShowStaked] = useState(false)
  const debouncedSearch = useDebounce(searchText, 100)
  const [selectedTokenFilter, setSelectedTokenFilter] = useState('ALL')

  const ichiVaultAssets = useIchiVaultAssets()
  const ichiPositionsQuery = useUserIchiPositions()
  const allPools = useGetAllLiquidityPools()
  const { claimAll, isLoading: isClaimLoading } = useMerklClaimAll()
  const { merklDataQuery, isLoading: isMerklLoading } = useGetMerklDataQuery()
  const [didCloseWithdrawWarning, setDidCloseWithdrawWarning] = useLocalStorage('single-withdraw-warning-closed', false)

  const baseAssets = useContext(BaseAssetsConetext)

  const balanceAssets = baseAssets.map((item) => item.address).filter((address) => isAddress(address))

  const balances = useTokenBalances(balanceAssets)

  const filteredVaults = [...ichiVaultAssets]
    .filter((asset) => {
      const position = ichiPositionsQuery.data?.find(
        (position) => position.vault.vaultAddress === asset.vault.vaultAddress,
      )

      const depositToken =
        asset.vault.depositTokenSymbol === asset.firstAsset.symbol
          ? toToken({ ...asset.firstAsset })
          : toToken({ ...asset.secondAsset })

      const searchFound = depositToken.symbol.toLowerCase().includes(debouncedSearch.toLowerCase())
      const hasPosition = Boolean(position)
      const tokenFilterFound = depositToken.symbol.toLowerCase().includes(selectedTokenFilter.toLowerCase())

      if (searchText && showStaked) {
        return searchFound && hasPosition
      }

      if (tokenFilterFound && showStaked) {
        return tokenFilterFound && hasPosition
      }

      if (selectedTokenFilter === 'ALL' && searchText) {
        return searchFound
      }

      if (selectedTokenFilter === 'ALL' && showStaked) {
        return hasPosition
      }

      if (selectedTokenFilter === 'ALL') {
        return true
      }

      if (tokenFilterFound) {
        return tokenFilterFound
      }
    })
    .sort((a, b) => {
      const poolA = allPools?.find(
        (pool) =>
          (pool?.token0.symbol.toLowerCase() === a.firstAsset?.symbol.toLowerCase() &&
            pool?.token1.symbol.toLowerCase() === a.secondAsset?.symbol.toLowerCase()) ||
          (pool?.token1.symbol.toLowerCase() === a.firstAsset?.symbol.toLowerCase() &&
            pool?.token0.symbol.toLowerCase() === a.secondAsset?.symbol.toLowerCase()),
      )

      const poolB = allPools?.find(
        (pool) =>
          (pool?.token0.symbol.toLowerCase() === b.firstAsset?.symbol.toLowerCase() &&
            pool?.token1.symbol.toLowerCase() === b.secondAsset?.symbol.toLowerCase()) ||
          (pool?.token1.symbol.toLowerCase() === b.firstAsset?.symbol.toLowerCase() &&
            pool?.token0.symbol.toLowerCase() === b.secondAsset?.symbol.toLowerCase()),
      )

      const aprA = poolA?.fullMerklData?.aprs[`Ichi ${a.vault.vaultAddress.toLowerCase()}`]
      const aprB = poolB?.fullMerklData?.aprs[`Ichi ${b.vault.vaultAddress.toLowerCase()}`]

      return Number(aprB) - Number(aprA)
    })

  const earnedPairs = useMemo(() => {
    return allPools.filter((pair) => !pair.account?.earnedUsd?.isZero?.() && pair.alm === 'ICHI')
  }, [allPools])

  const hasTransactionDataToClaim = Object.keys(merklDataQuery.data?.transactionData ?? {})?.length > 0

  const isClaimDisabled = earnedPairs.length === 0 || !hasTransactionDataToClaim || isClaimLoading

  return (
    <div className='w-full max-w-7xl px-5 sm:px-16 md:pt-14  mx-auto'>
      <div className='lg:flex justify-between items-start mb-4'>
        <div className='max-w-[532px] w-full'>
          <h1 className='text-[34px] md:text-[42px] font-semibold text-white  f-f-fg'>Single deposit</h1>

          <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
            Deposit single sided liquidity, let ICHI do the rest. Just sit back and earn!
          </p>
        </div>
      </div>
      {!didCloseWithdrawWarning && (
        <div className='text-[#b8b6cb] border-2 border-white px-4 py-2 relative mt-3 mb-3'>
          <p>
            Single deposits allow users to deposit one token into a vault. When withdrawaing, users may receive some
            tokens from the other side of the LP. The yield is realized in a proportion to the vaults token balance
          </p>
          <button
            onClick={() => setDidCloseWithdrawWarning(true)}
            className='focus:outline-none ml-auto absolute top-2 right-2'
          >
            <span className='block w-5 h-5'>
              <CloseModalIcon />
            </span>
          </button>
        </div>
      )}
      <div className='flex w-full'>
        <button
          disabled={earnedPairs.length === 0 || isClaimDisabled}
          onClick={claimAll}
          content={`CLAIM ALL EARNINGS (${earnedPairs.length})`}
          className={`ml-auto mb-3 transition-all duration-300 ease-in-out py-[13px] md:py-[14.53px] bg-white ${
            isClaimDisabled ? 'opacity-70' : ''
          } text-black w-full mt-3 lg:mt-0 mdLg:w-auto flex items-center justify-center text-sm tracking-[0.84px] md:tracking-[1.36px] px-[33px] lg:px-[43px] xl:px-[33px] rounded-[3px]`}
        >
          {isClaimLoading && (
            <span className='mr-1'>
              <Spinner color='#00000' />
            </span>
          )}{' '}
          CLAIM ALL EARNINGS {isMerklLoading ? null : `(${earnedPairs.length})`}
        </button>
      </div>
      <div className='flex justify-between items-center'>
        <div className='mr-2'>
          <SearchInput searchText={searchText} setSearchText={setSearchText} placeholder='Search token' />
        </div>

        <div className='text-white w-[200px] flex'>
          <div>
            <Toggle checked={showStaked} onChange={() => setShowStaked(!showStaked)} small toggleId='toggle-staked' />
          </div>
          <div className='ml-3'>My positions</div>
        </div>
      </div>

      <div className='flex justify-center mt-2'>
        <TabFilter data={tokenFilters} filter={selectedTokenFilter} setFilter={setSelectedTokenFilter} />
      </div>
      <div className='flex justify-center gap-10 flex-wrap mt-[55px]'>
        {filteredVaults.map((asset) => {
          const depositToken =
            asset.vault.depositTokenSymbol === asset.firstAsset.symbol ? asset.firstAsset : asset.secondAsset

          const balance = balances[depositToken.address.toLowerCase()]

          const position = ichiPositionsQuery.data?.find(
            (position) => position.vault.vaultAddress === asset.vault.vaultAddress,
          )

          const pool = allPools?.find(
            (pool) =>
              (pool?.token0.symbol.toLowerCase() === asset.firstAsset?.symbol.toLowerCase() &&
                pool?.token1.symbol.toLowerCase() === asset.secondAsset?.symbol.toLowerCase()) ||
              (pool?.token1.symbol.toLowerCase() === asset.firstAsset?.symbol.toLowerCase() &&
                pool?.token0.symbol.toLowerCase() === asset.secondAsset?.symbol.toLowerCase()),
          )

          const earnedData = earnedPairs.find((pool) => pool.vault.vaultAddress === asset.vault.vaultAddress)?.account

          return (
            <VaultCard
              key={asset.vault.vaultAddress}
              asset={asset}
              depositToken={depositToken}
              balance={balance}
              position={position}
              pool={pool}
              earnedData={earnedData}
              isEarnedDataLoading={isMerklLoading}
              refetchPositions={ichiPositionsQuery.refetch}
            />
          )
        })}
      </div>
    </div>
  )
}
