import { ExternalLinkIcon } from 'icons/ExternalLinkIcon'
import { formatAmount } from 'utils/formatNumber'

export const PartnerItem = ({ url, apr, disabled, description, active, logoUrl, onSelect }) => {
  const stats = [
    // { name: 'Total Value Locked', value: tvl ? `$${formatAmount(tvl, true)}` : '-' },
    { name: 'APR', value: apr && !disabled ? `${formatAmount(apr, true)}%` : '-' },
  ]

  return (
    <li className={`w-full flex  ${active && 'border-[3px] border-[#75FD3A] border-solid rounded-[13px]'}`}>
      <div className={`flex w-full flex-col gap-5 rounded-xl bg-[#080808] px-3 py-9`}>
        {/* <div className='w-full flex md:hidden items-center gap-4 font-montSerrat'>
          <a
            href='/'
            target='_blank'
            rel='noreferrer'
            className='flex items-center text-white text-decoration-none text-[14px] font-medium gap-2'
          >
            Vault
            <img className='flex w-3 h-3' alt='x' src='/images/liquidity/inner-link.svg' />
          </a>
          <a
            href='/'
            target='_blank'
            rel='noreferrer'
            className='flex items-center text-white text-decoration-none text-[14px] font-medium gap-2'
          >
            Pool
            <img className='flex w-3 h-3' alt='x' src='/images/liquidity/inner-link.svg' />
          </a>
        </div> */}
        <div className={`flex flex-col w-full items-start justify-between`}>
          <div
            onClick={onSelect}
            className={`${
              disabled && 'pointer-events-none'
            } cursor-pointer md:w-5 md:h-5 w-[18px] h-[18px] flex flex-col items-center justify-center border border-[#B8B6CB] hover:border-[#2EF80D] rounded-full`}
          >
            <div className={`md:w-2.5 w-2 h-2 md:h-2.5 ${active ? 'bg-[#2EF80D]' : 'bg-transparent'}  rounded-full`} />
          </div>

          <div onClick={!disabled && onSelect} className='mt-4 cursor-pointer flex md:w-full items-center gap-x-2 ml-2'>
            <img className='flex w-full max-w-[100px] object-contain' alt='' src={logoUrl} />
            <div className='w-[30px] h-[30px] ml-2 flex items-center'>
              <a href={url} target='_blank' rel='noreferrer'>
                <ExternalLinkIcon />
              </a>
            </div>
          </div>
        </div>
        {stats.map((stat) => {
          return (
            <div key={stat.name} className='w-full flex items-center justify-between gap-x-2 font-montSerrat'>
              <span className='text-[13px] text-white font-medium'>{stat.name}</span>
              <span className='text-[14px] text-white font-semibold'>{stat.value}</span>
            </div>
          )
        })}

        <button
          disabled={disabled}
          onClick={onSelect}
          className={`${
            disabled && 'opacity-25 pointer-events-none'
          } mt-auto w-full py-[13px] px-[26px] rounded-lg !text-black flex items-center justify-center text-[13px] md:text-base font-semibold hover:bg-white ${
            active ? 'bg-[#2EF80D]' : 'bg-white '
          }`}
        >
          Select
        </button>

        <div className='flex justify-center text-white'>{description}</div>
      </div>
    </li>
  )
}
