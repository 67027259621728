import Big from 'big.js'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import NumericalInput from 'components/common/Input/NumericalInput'
import SearchTokenPopup from 'components/common/SearchTokenPopup'
import { BaseAssetsConetext } from 'context/BaseAssetsConetext'
import { useTokenBalances } from 'hooks/useTokenBalance'
import React, { useContext, useState } from 'react'
import { formatNumber } from 'utils/formatNumber'

export const DepositBribes = ({ managerInfo, withdrawToken, depositToken }) => {
  const tokens = useContext(BaseAssetsConetext)
  const balances = useTokenBalances(tokens.map((token) => token.address))
  const [tokenAmountToDeposit, setTokenAmountToDeposit] = useState('')
  const [selectedToken, setSelectedToken] = useState(tokens.find((token) => token.symbol === 'RETRO'))
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false)

  if (!managerInfo) return null

  const selectedBalance = balances[selectedToken.address]
    ? Big(balances[selectedToken.address]?.toString()).div(10 ** selectedToken.decimals)
    : Big(0)

  return (
    <div className='py-5 flex flex-col items-center gap-5 bg-componentPrimary max-w-[866px]'>
      <h1 className='text-[34px] md:text-[42px] font-semibold f-f-fg'>Deposit Bribes</h1>
      <div className='flex flex-col items-center'>
        <p>You need to deposit tokens on the manager to pay for weekly reoccuring bribes. You can withdraw anytime.</p>
        <p>
          You can also deposit when creating the automation, see {'"'}Reoccuring Bribe{'"'} section.
        </p>
      </div>
      <div className='flex justify-center gap-2'>
        <div className={`mt-1.5 md:mt-2.5 p-px w-[250px] rounded-[3px]`}>
          <div className='bg-body h-12 md:h-[70px] z-[90] rounded-[3px] flex items-center relative'>
            <div
              onClick={() => {
                setIsTokenModalOpen(!isTokenModalOpen)
              }}
              className={`bg-transparent w-full h-full flex items-center cursor-pointer py-[8px] lg:py-[15px] pl-2.5 lg:pl-4`}
            >
              {selectedToken ? (
                <div
                  className={`flex items-center absolute left-2.5 lg:left-4 space-x-3 mdLg:mt-1 lg:-mt-[3px] xl:-mt-[5px]`}
                >
                  <div className='flex items-center  -space-x-2'>
                    <img
                      className='relative z-10 w-[26px] h-[26px] md:w-[30px] md:h-[30px]'
                      alt=''
                      src={selectedToken.logoURI}
                    />
                    {/* <img
                      className='relative z-[5] w-[26px] h-[26px] md:w-[30px] md:h-[30px]'
                      alt=''
                      src={pool.token1.logoURI}
                    /> */}
                  </div>
                  <div className='text-white'>
                    <p className='text-base md:text-[19px] font-medium leading-5 md:leading-[30px] f-f-fg'>
                      {selectedToken.symbol}
                    </p>
                  </div>
                </div>
              ) : (
                <div className={`w-full h-full font-normal text-[#757384] text-lg md:text-2xl md:leading-10`}>
                  Select
                </div>
              )}
            </div>
            <img
              className={`transform transition-all duration-300 ease-in-out absolute right-4 top-4 md:top-7`}
              alt=''
              src='/images/swap/dropdown-arrow.png'
            />
          </div>
          <SearchTokenPopup
            hideCommon={true}
            popup={isTokenModalOpen}
            setPopup={setIsTokenModalOpen}
            selectedAsset={selectedToken}
            setSelectedAsset={setSelectedToken}
            baseAssets={tokens.filter((asset) => asset.address !== 'MATIC')}
          />
        </div>
      </div>
      <div className='flex justify-center h-[56px]'>
        <div
          onClick={() => setTokenAmountToDeposit(selectedBalance.toString())}
          className='flex items-center mr-2 cursor-pointer'
        >
          Balance: {formatNumber(selectedBalance.toString(), 5)} {selectedToken.symbol}
        </div>
        <div className='w-[133px] mr-3'>
          <NumericalInput
            className='bg-black h-full'
            value={tokenAmountToDeposit}
            onUserInput={setTokenAmountToDeposit}
          />
        </div>
        <LinearGhostButton
          disabled={!tokenAmountToDeposit || selectedBalance.lt(tokenAmountToDeposit)}
          smallCase
          title={selectedBalance.lt(tokenAmountToDeposit || 0) ? 'Not enough balance' : 'Deposit'}
          className='text-black px-[10px] w-[250px]'
          onClickHanlder={() => {
            depositToken(
              selectedToken.address,
              Big(tokenAmountToDeposit)
                .mul(10 ** 18)
                .toString(),
            )
          }}
        />
      </div>
      <div className='flex justify-center gap-2 w-full h-[56px] px-[50px]'>
        <p className='h-full w-[250px] text-[24px] font-semibold flex justify-center items-center'>Manager balance: </p>
        <div className='bg-[#0C111D] h-full rounded-[3px] w-[250px] flex justify-center items-center'>
          {managerInfo.tokenBalance[selectedToken.address] &&
            formatNumber(
              Big(managerInfo.tokenBalance[selectedToken.address]?.balance.toString())
                .div(10 ** 18)
                .toString(),
              5,
            )}{' '}
          {selectedToken.symbol}
        </div>
        <LinearGhostButton
          disabled={managerInfo.tokenBalance[selectedToken.address].balance?.lte(0)}
          smallCase
          title='Withdraw'
          className='text-black px-[10px] w-[250px]'
          onClickHanlder={() => withdrawToken(selectedToken.address)}
        />
      </div>
    </div>
  )
}
