import Benefits from '../benefits'
import Partners from '../partners'
import './content.scss'

const Content = () => {
  return (
    <div className='content'>
      <img src='/images/retro/background/background-plus.svg' alt='' className='background-plus' />
      <img src='/images/retro/background/background-cloud2.svg' alt='' className='background-cloud' />
      <Benefits />
      <Partners />

      <div className='flex flex-col items-center'>
        <a rel='noreferrer' href='https://blog.openzeppelin.com/retro-thena-audit' target='_blank'>
          <img src='/images/retro/lead/audited.png' alt='' className='p-8' />
        </a>
      </div>
    </div>
  )
}

export default Content
