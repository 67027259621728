import { MAX_UINT256 } from '../utils/formatNumber'
import { TransactionType } from 'config/constants'
import { useWeb3React } from '@web3-react/core'
import { getAllowance, sendContract } from '../utils/api'
import { openTransaction, updateTransaction, completeTransaction } from 'state/transactions/actions'
import { useState } from 'react'
import { useTransmutationContract } from 'hooks/useContract'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { ethers } from 'ethers'
import { useOptionContract } from 'hooks/useContract'

export const useTransmutation = () => {
  const { account } = useWeb3React()
  const optionContract = useOptionContract()
  const optionToken = {
    symbol: 'oRETRO',
  }
  const [transmutePending, setTransmutePending] = useState(false)
  const transmutationContract = useTransmutationContract()
  const dispatch = useDispatch()

  const transmute = async (outputAsset, amount, minAmountOut) => {
    const want = outputAsset.address

    const key = uuidv4()
    const approveUuid = uuidv4()
    const transmuteUuid = uuidv4()

    //TODO: Use real numbers and not names
    setTransmutePending(true)
    dispatch(
      openTransaction({
        key,
        title: `Exercise ${parseFloat(ethers.utils.formatUnits(amount, optionToken.decimals)).toFixed(2)} ${
          optionToken.symbol
        } for ${outputAsset.symbol}`,
        transactions: {
          [approveUuid]: {
            desc: `Approve Transmute.fi to spend ${optionToken.symbol}`,
            status: TransactionType.WAITING,
            hash: null,
          },
          [transmuteUuid]: {
            desc: `Transmute ${parseFloat(ethers.utils.formatUnits(amount, optionToken.decimals)).toFixed(2)} ${
              optionToken.symbol
            }`,
            status: TransactionType.START,
            hash: null,
          },
        },
      }),
    )

    const allowance = await getAllowance(optionContract, transmutationContract._address, account)

    if (ethers.BigNumber.from(amount).gt(allowance)) {
      try {
        await sendContract(
          dispatch,
          key,
          approveUuid,
          optionContract,
          'approve',
          [transmutationContract._address, MAX_UINT256],
          account,
        )
      } catch (err) {
        console.log('approve 0 error :>> ', err)
        setTransmutePending(false)
        return
      }
    } else {
      dispatch(
        updateTransaction({
          key,
          uuid: approveUuid,
          status: TransactionType.SUCCESS,
        }),
      )
    }

    const func = 'transmute'
    const args = [want, amount, minAmountOut]

    try {
      await sendContract(dispatch, key, transmutePending, transmutationContract, func, args, account)
    } catch (err) {
      console.log('transmutation error :>> ', err)
      setTransmutePending(false)
      return
    }

    dispatch(
      completeTransaction({
        key,
        final: 'Transmutation Successful',
      }),
    )

    setTransmutePending(false)
  }

  return {
    transmute,
    transmutePending,
  }
}
