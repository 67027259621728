import React, { useMemo, useState } from 'react'
import TablePairs from '../../components/pages/liquidity/tablePairs'
import Toggle from '../../components/common/Toggle'
import TransparentButton from '../../components/common/Buttons/transparentButton'
import { Link, useNavigate } from 'react-router-dom'
import TabFilter from '../../components/common/TabFilter'
import MobileFilterModal from '../../components/common/MobileFilterModal'
import { NewPools, PoolTypes } from '../../config/constants'
import { ZERO_ADDRESS } from '../../utils/formatNumber'
import FilterIcon from '../../icons/FilterIcon'
import { useGetAllLiquidityPools } from 'hooks/v3/useGetAllLiquidityPools'
import { useMerklClaimAll } from 'hooks/v3/useMerklClaimAll'
import { useGetMerklDataQuery } from 'hooks/queries/useGetMerklDataQuery'
import Spinner from 'components/common/Spinner'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import { ElapsedSinceLastDistribution } from './ElapsedSinceLastDistribution'

const sortOptions = [
  {
    label: 'Avg. APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'TVL',
    value: 'totalTvl',
    isDesc: true,
  },
  {
    label: 'My Pool',
    value: 'pool',
    isDesc: true,
  },
  // {
  //   label: 'My Stake',
  //   value: 'stake',
  //   isDesc: true,
  // },
  {
    label: 'Earnings',
    value: 'earn',
    isDesc: true,
  },
]
// const newSortOptions = [
//   {
//     label: 'Projected APR',
//     value: 'apr',
//     isDesc: true,
//   },
//   {
//     label: 'Total Deposited',
//     value: 'totalTvl',
//     isDesc: true,
//   },
//   {
//     label: 'My Pool',
//     value: 'pool',
//     isDesc: true,
//   },
//   // {
//   //   label: 'My Stake',
//   //   value: 'stake',
//   //   isDesc: true,
//   // },
//   {
//     label: 'Earnings',
//     value: 'earn',
//     isDesc: true,
//   },
// ]

const Index = () => {
  const [filter, setFilter] = useState(PoolTypes.ALL)
  const [mobileFilter, setMobileFilter] = useState(false)
  const [isStaked, setIsStaked] = useState(false)
  const [isInactive, setIsInactive] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState({
    label: 'APR',
    value: 'apr',
    isDesc: true,
  })

  // const { pairs } = useContext(PairsContext)
  const { merklDataQuery } = useGetMerklDataQuery()
  const { claimAll, isLoading: isClaimLoading } = useMerklClaimAll()
  const allPools = useGetAllLiquidityPools()

  const pools = [...new Map(allPools.map((item) => [item.poolAddress.toLowerCase(), item])).values()]

  const filteredPairs = useMemo(() => {
    let final

    if (isInactive) {
      final = pools.filter((ele) => ele.gauge.apr.isZero())

      // final = [...final, ...pairs]
    } else {
      final = pools
    }

    const result = final.filter((item) => {
      const isCorrect = item.gauge.address !== ZERO_ADDRESS
      return isCorrect && ((isStaked && !item.account.totalUsd.isZero()) || !isStaked)
    })
    const res = filter === PoolTypes.ALL ? result : result.filter((item) => item.kind === filter)
    if (!searchText || searchText === '') {
      return res
    }
    return (
      res &&
      res.filter((item) => {
        const withSpace = item.symbol.replace('/', ' ')
        const withComma = item.symbol.replace('/', ',')

        if (item.symbol.includes('miMATIC') && searchText.toLowerCase().includes('mai')) return true

        return (
          item.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [allPools, filter, searchText, isStaked, isInactive])

  const oldFilteredPairs = useMemo(() => {
    // if (new Date().getTime() / 1000 < timestamp) {
    //   return filteredPairs.filter((item) => !NewPools.includes(item.address))
    // }
    return filteredPairs.filter((item) => !(NewPools.includes(item.address) && item.gauge.apr.isZero()))
  }, [filteredPairs])

  const sortedPairs = useMemo(() => {
    return oldFilteredPairs.sort((a, b) => {
      let res
      switch (sort.value) {
        case 'apr':
          res = a.gauge.apr
            .minus(b.gauge.apr)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'totalTvl':
          res = a.totalTvl
            .minus(b.totalTvl)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'pool': {
          res = a.account.totalUsd
            .minus(b.account.totalUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        }
        case 'stake':
          res = a.account.stakedUsd
            .minus(b.account.stakedUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'earn':
          res = a.account.earnedUsd
            .minus?.(b.account.earnedUsd)
            .times?.(sort.isDesc ? -1 : 1)
            .toNumber()
          break

        default:
          break
      }
      return res
    })
  }, [oldFilteredPairs, sort])

  // const newSortedPairs = useMemo(() => {
  //   return newFilteredPairs.sort((a, b) => {
  //     let res
  //     switch (newSort.value) {
  //       case 'apr':
  //         res = a.gauge.projectedApr
  //           .minus(b.gauge.projectedApr)
  //           .times(newSort.isDesc ? -1 : 1)
  //           .toNumber()
  //         break
  //       case 'totalTvl':
  //         res = a.totalTvl
  //           .minus(b.totalTvl)
  //           .times(newSort.isDesc ? -1 : 1)
  //           .toNumber()
  //         break
  //       case 'pool':
  //         res = a.account.totalUsd
  //           .minus(b.account.totalUsd)
  //           .times(newSort.isDesc ? -1 : 1)
  //           .toNumber()
  //         break
  //       case 'stake':
  //         res = a.account.stakedUsd
  //           .minus(b.account.stakedUsd)
  //           .times(newSort.isDesc ? -1 : 1)
  //           .toNumber()
  //         break
  //       case 'earn':
  //         res = a.account.earnedUsd
  //           .minus(b.account.earnedUsd)
  //           .times(newSort.isDesc ? -1 : 1)
  //           .toNumber()
  //         break

  //       default:
  //         break
  //     }
  //     return res
  //   })
  // }, [newFilteredPairs, newSort])

  const earnedPairs = useMemo(() => {
    return allPools.filter((pair) => {
      return !pair.account?.earnedUsd?.isZero?.()
    })
  }, [allPools, isInactive])

  const navigate = useNavigate()

  const handlePopup = () => {
    navigate('/liquidity/managev3')
  }

  const hasTransactionDataToClaim = Object.keys(merklDataQuery.data?.transactionData ?? {})?.length > 0

  const isClaimDisabled = earnedPairs.length === 0 || !hasTransactionDataToClaim || isClaimLoading

  return (
    <>
      <div className='w-full max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 pt-5 pb-10 xl:pt-20 md:pt-14 mx-auto'>
        <div className='lg:flex items-end justify-between'>
          <div className='max-w-[532px] w-full'>
            <h1 className='text-[34px] md:text-[42px] font-semibold text-white  f-f-fg'>Liquidity</h1>
            <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
              Pair your tokens to provide liquidity in incentivized pools/ fee tiers. Your rewards are correlated to the
              amount of fees your position generates. The more fees the position generates the more rewards you receive.
            </p>
          </div>
          <div className='flex flex-col'>
            <div className='flex justify-center lg:self-end mb-4'>
              <Link to='/boost-calculator'>
                <LinearGhostButton
                  smallCase
                  title='veRETRO Boost Calculator'
                  className='text-black inline px-[25px] !leading-3 !text-[13px]'
                />
              </Link>
            </div>
            <ElapsedSinceLastDistribution />

            <button
              disabled={earnedPairs.length === 0 || !hasTransactionDataToClaim || isClaimLoading}
              onClick={claimAll}
              content={`CLAIM ALL EARNINGS (${earnedPairs.length})`}
              className={`transition-all duration-300 ease-in-out py-[13px] md:py-[14.53px] bg-[#2166fa] ${
                isClaimDisabled ? 'opacity-70' : ''
              } text-white w-full mt-3 lg:mt-0 mdLg:w-auto flex items-center justify-center text-sm tracking-[0.84px] md:tracking-[1.36px] px-[33px] lg:px-[43px] xl:px-[33px] rounded-[3px]`}
            >
              {isClaimLoading && (
                <span className='mr-1'>
                  <Spinner />
                </span>
              )}{' '}
              CLAIM ALL EARNINGS ({earnedPairs.length})
            </button>
          </div>
        </div>
        <div className='flex flex-col mt-3 lg:mt-0 lg:flex-row justify-between'>
          <TransparentButton
            content={'ADD/REMOVE LIQUIDITY'}
            className={
              'py-[13px] md:h-[52px] px-7 lg:hidden flex flex-col items-center justify-center w-full mdLg:w-auto text-sm mdLg:text-[17px] tracking-[1.12px] md:tracking-[1.36px]'
            }
            onClickHandler={handlePopup}
          />
        </div>
        <div className='flex items-center justify-between w-full mt-3 xl:mt-9 lg:space-x-8 xl:gap-x-5 relative'>
          <div className='w-full flex justify-between items-center gap-x-5'>
            <div className='hidden lg:flex'>
              <TabFilter data={Object.values(PoolTypes)} filter={filter} setFilter={setFilter} />
            </div>
            <div className='hidden lg:flex items-center space-x-2'>
              <Toggle checked={isStaked} onChange={() => setIsStaked(!isStaked)} toggleId='isStaked' />
              <p className='text-white text-sm xl:text-[17px] whitespace-nowrap'>My LPs</p>
            </div>
            {/* <div className='flex items-center space-x-2'>
              <Toggle checked={isInactive} onChange={() => setIsInactive(!isInactive)} toggleId='isInactive' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Inactive Gauges</p>
            </div> */}
            <div className='w-[220px] flex gap-x-2'>
              <div className='rounded-[3px] flex items-center relative bg-componentPrimary flex-grow'>
                <img
                  className='hidden lg:block pointer-events-none absolute left-3 w-6 h-6'
                  alt=''
                  src='/images/svgs/search.svg'
                />
                <input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder='Search Pair or Token'
                  className='bg-componentPrimary placeholder-placeholder xl:h-[52px] w-full  text-white text-sm xl:text-base pl-10 xl:pl-[41px] pr-4 py-3 md:py-[18px] rounded-[3px]'
                />
              </div>
              {/* filter button for mobile */}
              <button
                onClick={() => {
                  setMobileFilter(!mobileFilter)
                }}
                className='flex w-16 flex-shrink-0 lg:hidden'
              >
                <FilterIcon />
              </button>
            </div>
            <TransparentButton
              content={'ADD/REMOVE LIQUIDITY'}
              className={
                'xl:max-w-[270px] max-w-[270px] h-[52px] px-3 xl:px-7 hidden lg:flex flex-col items-center justify-center w-full text-sm xl:text-base tracking-[1.04px] xl:tracking-[1.36px]'
              }
              onClickHandler={handlePopup}
            />
            <div className='w-[70px] h-[70px] relative cursor-pointer'>
              <Link to='/zap'>
                <img className='w-full h-full' src='/images/retro/zap-icon.png' />
              </Link>
            </div>
          </div>

          {/* mobile filters popup */}
          {mobileFilter && (
            <MobileFilterModal
              setMobileFilter={setMobileFilter}
              setFilter={setFilter}
              filter={filter}
              tabs={Object.values(PoolTypes)}
              isStaked={isStaked}
              setIsStaked={setIsStaked}
              isInactive={isInactive}
              setIsInactive={setIsInactive}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
            />
          )}
        </div>
        {/* <TablePairs pairsData={sortedPairs} sort={sort} setSort={setSort} sortOptions={sortOptions} active={active} filter={filter} /> */}
        {/* <NewTablePairs
          pairsData={newSortedPairs}
          sort={newSort}
          setSort={setNewSort}
          sortOptions={newSortOptions}
          filter={filter}
          searchText={searchText}
        /> */}
        <TablePairs
          pairsData={sortedPairs}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          filter={filter}
          searchText={searchText}
          isStaked={isStaked}
        />
      </div>
    </>
  )
}

export default Index
