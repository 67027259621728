import { useCallback, useState } from 'react'
import { getContract } from 'utils/contract'
import { ADDR_ZERO } from './constants'
import RetroVeManager from 'config/abi/v3/RetroVeManager.json'
import ERC20 from 'config/abi/erc20.json'
import { DepositGas } from './DepositGas'
import { ReoccuringBribe } from './ReoccuringBribe'
import { Vote } from './Vote'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import { MaxUint256 } from '@uniswap/sdk-core'
import { DepositBribes } from './DepositBribes'
import { ethers } from 'ethers'
import { Automation } from './Automation'

export const Manager = ({ reloadOnchainData, managerInfo }) => {
  const { library, account } = useActiveWeb3React()

  const manager = getContract(managerInfo.address, RetroVeManager, library, account)

  const setVote = async (pools, weights) => {
    try {
      const tx = await manager.vote(pools, weights)

      await tx.wait()
    } catch (e) {
      console.error(e)
    }

    reloadOnchainData()
  }

  const approveToken = async (tokenAddress) => {
    try {
      const token = getContract(tokenAddress, ERC20, library, account)

      const tx = await token.approve(managerInfo.address, MaxUint256.toString())

      await tx.wait()
    } catch (e) {
      console.error(e)
    }
  }

  const withdrawToken = async (tokenAddress) => {
    try {
      const tx = await manager.withdrawTokens(tokenAddress, tokenAddress === ADDR_ZERO)

      await tx.wait()
    } catch (e) {
      console.error(e)
    }

    reloadOnchainData()
  }

  const depositToken = async (tokenAddress, amount) => {
    try {
      if (tokenAddress !== ADDR_ZERO) {
        const token = getContract(tokenAddress, ERC20, library, account)

        const tx = await token.transfer(managerInfo.address, amount)

        await tx.wait()
      } else {
        const provider = new ethers.providers.Web3Provider(window.ethereum)

        const signer = provider.getSigner()

        const tx = await signer.sendTransaction({
          to: managerInfo.address,
          value: amount,
        })

        await tx.wait()
      }
    } catch (e) {
      console.error(e)
    }

    reloadOnchainData()
  }

  const [reocurringBribes, setReocurringBribes] = useState({})
  const [selectedPoolsVotePercentages, setSelectedPoolsVotePercentages] = useState({})
  const [automationProperties, setAutomationProperties] = useState({
    withBribe: false,
    withClaimAllAndVote: false,
    maticToDeposit: BigInt(0),
  })

  const updateAutomationWithBribeProperty = useCallback(
    (newReocurringBribes) => {
      if (Object.keys(newReocurringBribes).length > 0) {
        setAutomationProperties({ ...automationProperties, withBribe: true })
      } else {
        setAutomationProperties({ ...automationProperties, withBribe: false })
      }
    },
    [setAutomationProperties, automationProperties],
  )

  const updateAutomationWithVoteAndClaimProperty = useCallback(
    (newSelectedPoolsPercentages) => {
      if (Object.keys(newSelectedPoolsPercentages).length > 0) {
        setAutomationProperties({ ...automationProperties, withClaimAllAndVote: true })
      } else {
        setAutomationProperties({ ...automationProperties, withClaimAllAndVote: false })
      }
    },
    [setAutomationProperties, automationProperties],
  )

  return (
    <>
      <div className='flex flex-col gap-4'>
        <DepositGas managerInfo={managerInfo} depositToken={depositToken} withdrawToken={withdrawToken} />

        <DepositBribes managerInfo={managerInfo} depositToken={depositToken} withdrawToken={withdrawToken} />

        <Vote
          selectedPoolsPercentages={selectedPoolsVotePercentages}
          setSelectedPoolsPercentages={(value) => {
            setSelectedPoolsVotePercentages(value)
            updateAutomationWithVoteAndClaimProperty(value)
          }}
          setVote={setVote}
        />
      </div>

      <div className='mt-5 py-5 flex flex-col items-center gap-5 bg-componentPrimary w-[90%]'>
        <Automation
          reocurringBribes={reocurringBribes}
          automationProperties={automationProperties}
          setAutomationProperties={setAutomationProperties}
          manager={manager}
          managerInfo={managerInfo}
          reloadOnchainData={reloadOnchainData}
        />

        <ReoccuringBribe
          reocurringBribes={reocurringBribes}
          setReocurringBribes={(value) => {
            setReocurringBribes(value)
            updateAutomationWithBribeProperty(value)
          }}
          approveToken={approveToken}
        />
      </div>
    </>
  )
}
