import { useContext, useState } from 'react'
import { FusionsContext } from 'context/FusionsContext'
import PoolSelect from 'components/common/PoolSelect'
import { ZERO_ADDRESS } from 'config/constants/v3/misc'
import LinearGhostButton from 'components/common/Buttons/linearGhostButton'
import NumericalInput from 'components/common/Input/NumericalInput'

export const Vote = ({ selectedPoolsPercentages, setSelectedPoolsPercentages, setVote }) => {
  const pools = useContext(FusionsContext)
  const [selectedPool, setSelectedPool] = useState()

  const poolsWithGauges = pools
    .filter((pair) => pair && pair.gauge.address !== ZERO_ADDRESS && pair.isValid)
    .reduce((acc, pool) => {
      acc[pool.address] = pool

      return acc
    }, {})

  return (
    <div className='py-5 flex flex-col items-center gap-5 bg-componentPrimary max-w-[866px]'>
      <h1 className='text-[34px] md:text-[42px] font-semibold f-f-fg'>Vote</h1>
      <div className='flex flex-col items-center'>
        <p>
          You can choose a list of pools to vote for. Click on {'"'}Add to selection{'"'} to build the list.
        </p>
        <p>
          Clicking on {'"'}Set vote & claim rebase{'"'} will cast the vote and claim your rebase.
        </p>
        <p>
          If you want the vote to be casted each week, create an automation with {'"'}vote & claim rebase{'"'} enabled.
        </p>
      </div>
      <div className='flex justify-center w-full h-[56px] gap-2'>
        {/* <select
          id='vote-pool-select'
          title='vote-pool-select'
          className={STYLE_SELECT}
          onChange={(event) => setSelectedPool(parseInt(event.target.value))}
        >
          {pools.map((pool, index) => {
            return (
              <option key={pool.address} value={index} selected={index == selectedPool}>
                {voterInfo.poolNames[poolAddress]}
              </option>
            )
          })}
        </select> */}
        <div className='w-[256px] h-full'>
          <PoolSelect
            title='Select pool'
            setPool={setSelectedPool}
            pool={selectedPool}
            pools={Object.values(poolsWithGauges)}
          />
        </div>
        <LinearGhostButton
          disabled={!selectedPool}
          className='w-[256px]'
          title='Add to selection'
          onClickHanlder={() =>
            setSelectedPoolsPercentages({
              ...selectedPoolsPercentages,
              [selectedPool.address]: 0,
            })
          }
        />
      </div>
      {Object.keys(selectedPoolsPercentages).length == 0 ? (
        <></>
      ) : (
        <>
          <h2 className='text-2xl'>Selected Pools:</h2>
          <div className='w-full flex flex-col gap-2'>
            <div className='flex flex-row items-center justify-center gap-2 w-full px-[60px] font-bold'>
              <div className='w-1/3'>Pool</div>
              <div className='w-1/3'>Vote weight</div>
              <div className='w-1/3'></div>
            </div>
            {Object.keys(selectedPoolsPercentages).map((poolAddr) => (
              <div key={poolAddr} className='flex items-end justify-center gap-2 px-[60px] w-full '>
                <div className='w-1/3'>
                  <div className='bg-[#0C111D] rounded-[3px] flex justify-center items-center h-[56px]'>
                    {poolsWithGauges[poolAddr].symbol}
                  </div>
                </div>

                <div className='w-1/3 h-full mr-3'>
                  <NumericalInput
                    className='bg-black h-[56px]'
                    value={selectedPoolsPercentages[poolAddr]}
                    onUserInput={(val) =>
                      setSelectedPoolsPercentages({
                        ...selectedPoolsPercentages,
                        [poolAddr]: Math.max(0, Math.min(100, parseInt(val))),
                      })
                    }
                  />
                </div>
                <div className='w-1/3 h-full flex'>
                  <LinearGhostButton
                    title='Remove'
                    className='w-[256px]'
                    onClickHanlder={() => {
                      // eslint-disable-next-line
                      const { [poolAddr]: _, ...newObject } = selectedPoolsPercentages
                      setSelectedPoolsPercentages(newObject)
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <LinearGhostButton
            title='Set vote & claim rebase'
            className='h-[56px] w-[256px]'
            onClickHanlder={() => {
              setVote(Object.keys(selectedPoolsPercentages), Object.values(selectedPoolsPercentages))
            }}
          />
        </>
      )}
    </div>
  )
}
